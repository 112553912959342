import Spinner from "@/common/assets/png/spinner.png";
import { CSSProperties } from "react";

interface LoadingSpinnerIconProps {
  spinnerHeight: number;
  spinnerWidth: number;
  spinnerMarginBottom: number;
  style?: CSSProperties;
}

export function LoadingSpinnerIcon({
  spinnerHeight = 80,
  spinnerWidth = 80,
  spinnerMarginBottom = 200,
  style,
}: LoadingSpinnerIconProps) {
  const styles = {
    spinner: {
      width: spinnerWidth,
      height: spinnerHeight,
      marginBottom: spinnerMarginBottom,
      animation: "spin 2s linear infinite",
    },
    keyframe: `
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `,
  };

  return (
    <>
      <style>{styles.keyframe}</style>

      <img src={Spinner} alt="Loading..." style={{ ...styles.spinner, ...style }} />
    </>
  );
}
