import { useAuth0 } from "@auth0/auth0-react";
import { ArrowRightStartOnRectangleIcon } from "@heroicons/react/24/outline";

const useHeaderMenuItems = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    return logout({
      logoutParams: {
        returnTo: `${window.location.origin}`,
      },
    });
  };

  const headerMenuItems = [
    {
      label: "Log Out",
      icon: <ArrowRightStartOnRectangleIcon width={28} color="#1A2135" />,
      onClick: () => handleLogout(),
    },
  ];

  return { headerMenuItems };
};

export default useHeaderMenuItems;
