import Checkbox from "@/common/components/checkbox/Checkbox";
import { Box, Link, Typography } from "@mui/material";
import PrimaryBadge from "./primary-badge";

interface TransferNumberItemProps {
  number: string;
  name: string;
  selected: boolean;
  showBadge: boolean;
  onClick: (event: any) => void;
  props: any;
}

export default function TransferNumberItem({
  number,
  name,
  selected,
  showBadge,
  onClick,
  props,
}: TransferNumberItemProps) {
  return (
    <li
      {...props}
      sx={{
        backgroundColor: "#fff",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Checkbox checked={selected} />
          <Box>
            <Typography
              style={{
                fontSize: "14px",
                color: "#1a2135",
              }}
            >
              {number}
            </Typography>
            <Typography
              style={{
                fontSize: "12px",
                color: "#6b747b",
              }}
            >
              {name}
            </Typography>
          </Box>
        </Box>
        {showBadge ? (
          <PrimaryBadge />
        ) : (
          <Link
            sx={{
              color: "#5789d9",
              fontSize: "14px",
            }}
            underline="none"
            onClick={onClick}
          >
            Set as Primary
          </Link>
        )}
      </Box>
    </li>
  );
}
