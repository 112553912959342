import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import FormLabel from "@/common/components/form-label/FormLabel";
import SearchBar from "@/common/components/searchbar/Searchbar";
import ToolTip from "@/common/components/tool-tip";
import AudioPlayer from "@/pages/inbox/AudioPlayer";
import { useAppSelector } from "@/redux/store";
import { KnVoice } from "@/services/generated";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/16/solid";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { Grid as SwiperGrid } from "swiper/modules";
import { VOICE_LANGUAGE_OPTIONS } from "../utils";

type VoicesGalleryProps = {
  onSelect?: (voiceId: string) => void;
  language?: string;
  voiceId?: string;
  error?: string;
};

function formatVoiceTags(tags?: string[]) {
  return tags?.map((tag) => tag.replace(/_/g, " ")).join(", ");
}

export default function VoicesGallery({ voiceId, language = "", onSelect, error }: VoicesGalleryProps) {
  const theme = useTheme();
  const swiperRef = useRef<SwiperCore | null>(null);
  const voices = useAppSelector(({ aiVoice }) => aiVoice.upsertAgent.voices);
  const [search, setSearch] = useState("");
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);

  const languageTag = useMemo(
    () => VOICE_LANGUAGE_OPTIONS.find((voice) => voice.value === language)?.tag || "",
    [language],
  );

  const filteredVoices = useMemo(() => {
    return (voices || [])
      .filter((voice) =>
        search ? voice.name?.toLowerCase().includes(search.toLowerCase()) || voice.id === voiceId : true,
      )
      .sort((a, b) => {
        const aHasTag = a.tags?.includes(languageTag) ? 1 : 0;
        const bHasTag = b.tags?.includes(languageTag) ? 1 : 0;
        return bHasTag - aHasTag;
      });
  }, [voices, search, voiceId, languageTag]);

  const isVoiceDisabled = useCallback((voice: KnVoice) => !voice.tags?.includes(languageTag), [languageTag]);

  useEffect(() => {
    if (!filteredVoices.length) return;

    if (voiceId) {
      const voiceIndex = filteredVoices.findIndex((voice) => voiceId === voice.id);
      const voice = filteredVoices[voiceIndex];

      if (!voice || isVoiceDisabled(voice)) {
        onSelect?.("");
      } else {
        swiperRef.current?.slideTo(voiceIndex / 2);
      }
    }
  }, [filteredVoices, voiceId, isVoiceDisabled, onSelect]);

  useEffect(() => {
    if (swiperRef.current?.activeIndex !== 0) swiperRef.current?.slideTo(0);
  }, [language]);

  const selectedVoice = useMemo(() => {
    return voices?.find((voice) => voice.id === voiceId);
  }, [voiceId, voices]);

  if (!voices?.length) return null;

  return (
    <Stack gap={2}>
      <SearchBar placeholder="Search Voice Agents" value={search} onChange={(e) => setSearch(e.target.value)} />

      {filteredVoices.length === 0 && "No agents with search"}
      {filteredVoices?.length > 0 && (
        <>
          <Box sx={{ position: "relative" }}>
            <Box
              onClick={() => swiperRef.current?.slidePrev()}
              sx={{
                cursor: "pointer",
                position: "absolute",
                left: -70,
                zIndex: 10,
                top: "50%",
                transform: "translateY(-50%)",
                borderRadius: 24,
              }}
            >
              <ChevronLeftIcon width={24} />
            </Box>
            <Box
              onClick={() => swiperRef.current?.slideNext()}
              sx={{
                cursor: "pointer",
                position: "absolute",
                right: -70,
                zIndex: 10,
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              <ChevronRightIcon width={24} />
            </Box>
            <Swiper
              style={{ height: "300px", width: "100%" }}
              slidesPerView={4}
              grid={{ rows: 2 }}
              navigation
              spaceBetween={8}
              modules={[SwiperGrid]}
              onSwiper={(swiper: SwiperCore) => (swiperRef.current = swiper)}
              onRealIndexChange={(swiper: SwiperCore) => {
                setActiveSlideIndex(swiper.realIndex);
              }}
            >
              {filteredVoices.map((voice) => (
                <SwiperSlide
                  key={voice.id}
                  onClick={() => !isVoiceDisabled(voice) && voice.id && onSelect?.(voice.id)}
                  style={{ cursor: "pointer", position: "relative" }}
                >
                  <Box
                    component="img"
                    sx={{
                      width: "100%",
                      height: "96px",
                      borderRadius: 3,
                      objectFit: "cover",
                      border: `4px solid ${voiceId === voice.id ? theme.palette.info.main : "transparent"}`,
                      boxSizing: "border-box",
                      "&:hover": {
                        border: `4px solid ${theme.palette.info.main}`,
                      },
                    }}
                    src={voice.image_url}
                    alt={voice.name}
                  />

                  <Typography textAlign="center">{voice.name}</Typography>
                  <ToolTip title={formatVoiceTags(voice.tags)} placement="right">
                    <Typography
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "1",
                        WebkitBoxOrient: "vertical",
                      }}
                      fontSize="8px"
                      textAlign="center"
                    >
                      {formatVoiceTags(voice.tags)}
                    </Typography>
                  </ToolTip>
                  {isVoiceDisabled(voice) && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(255, 255, 255, 0.5)",
                        borderRadius: 3,
                      }}
                    />
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {new Array(Math.max(1, filteredVoices.length >= 3 ? Math.ceil(filteredVoices.length / 2 - 3) : 1))
                .fill(null)
                .map((_, index) => (
                  <Box
                    onClick={() => {
                      setActiveSlideIndex(index);
                      swiperRef.current?.slideTo(index);
                    }}
                    key={index}
                    sx={
                      activeSlideIndex === index
                        ? {
                            border: `2px solid ${theme.palette.mint.p}`,
                            backgroundColor: theme.palette.midnight.p,
                            height: 16,
                            width: 16,
                            borderRadius: "50%",
                          }
                        : {
                            backgroundColor: theme.palette.midnight[40],
                            borderRadius: "50%",
                            height: 8,
                            width: 8,
                            cursor: "pointer",
                          }
                    }
                  />
                ))}
            </Box>
          </Box>
          {error && (
            <Typography
              textAlign="center"
              variant="body2"
              sx={{ color: theme.palette.commonColors.danger, fontSize: "12px", mt: 2 }}
            >
              {error}
            </Typography>
          )}
        </>
      )}
      <FormLabel label="Voice Sample" />
      {selectedVoice?.voice_sample_url && <AudioPlayer url={selectedVoice.voice_sample_url} />}
    </Stack>
  );
}
