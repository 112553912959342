import { Box, FormControl, Stack } from "@mui/material";

import PhoneInput from "@/common/components/phone-input";
import Input from "@/common/components/input";
import Button from "@/common/components/button";
import FormLabel from "@/common/components/form-label/FormLabel";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { TransferNumberRequest } from "@/services/generated";
import { useNavigate } from "react-router-dom";
import routes from "@/common/constants/routes";

const schema = Yup.object({
  number: Yup.string().required(),
  name: Yup.string().required(),
});

type TransferNumberFormProps = {
  onSubmit: (data: TransferNumberRequest) => void;
  defaultValues?: Yup.InferType<typeof schema>;
  canEdit?: boolean;
};

export default function TransferNumberForm({ onSubmit, defaultValues, canEdit }: TransferNumberFormProps) {
  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { control, reset, formState, handleSubmit } = methods;

  const goBack = () => {
    navigate(routes.aiVoice.transferNumberLibrary.path);
  };

  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxHeight: "100%",
          flex: "1",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            overflowY: "auto",
            height: "100%",
            paddingX: "8px",
          }}
        >
          <Controller
            name="number"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl fullWidth>
                <FormLabel label="Phone Number" aria-required infoIcon />
                <PhoneInput
                  disabled={!canEdit}
                  error={fieldState.error?.message}
                  value={field.value}
                  onChange={(phone) => field.onChange(phone)}
                />
              </FormControl>
            )}
          />

          <Controller
            name="name"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl fullWidth>
                <FormLabel label="Name" aria-required infoIcon />
                <Input
                  disabled={!canEdit}
                  error={fieldState.error?.message}
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                />
              </FormControl>
            )}
          />
        </Box>
        <Stack direction={{ xs: "column", md: "row" }} gap={3} padding={3} width="100%">
          <Button variant="secondary" onClick={goBack} sx={{ width: "100%" }}>
            Cancel
          </Button>

          <Button
            type="button"
            variant="primary"
            sx={{ width: "100%" }}
            onClick={handleSubmit(onSubmit)}
            disabled={!canEdit}
          >
            Save
          </Button>
        </Stack>
      </Box>
    </FormProvider>
  );
}
