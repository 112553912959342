import { OptionType } from "@/common/components/autocomplete/GenericAutocomplete";
import MultiAutocomplete from "@/common/components/autocomplete/MultiAutocomplete";
import FormLabel from "@/common/components/form-label/FormLabel";
import Input from "@/common/components/input";
import toast from "@/lib/toast";
import { lookupPhoneNumbers } from "@/redux/reducers/ai-voice/listTransferPhoneNumber";
import { useAppDispatch } from "@/redux/store";
import { Box, FormControl, Stack } from "@mui/material";
import { uniqueId } from "lodash";
import { useEffect, useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { AiAgentForm } from "../../../../..";
import TransferNumberHours from "./transfer-number-hours";
import TransferNumberItem from "./transfer-number-item";
import TransferNumberSelected from "./transfer-number-selected";

export interface TransferPhoneNumber {
  number: string;
  name: string;
  is_default: boolean;
  transfer_number_library_id: string;
}

const DEFAULT_SCHEDULE: any[] = [
  {
    day_of_week: "MONDAY",
    start_time: "09:00:00",
    end_time: "17:00:00",
  },
  {
    day_of_week: "TUESDAY",
    start_time: "09:00:00",
    end_time: "17:00:00",
  },
  {
    day_of_week: "WEDNESDAY",
    start_time: "09:00:00",
    end_time: "17:00:00",
  },
  {
    day_of_week: "THURSDAY",
    start_time: "09:00:00",
    end_time: "17:00:00",
  },
  {
    day_of_week: "FRIDAY",
    start_time: "09:00:00",
    end_time: "17:00:00",
  },
];

export default function TransferNumberLibrary() {
  const form = useFormContext<AiAgentForm>();

  const { control, setValue } = form;

  const [transferPhoneNumbers, setTransferPhoneNumbers] = useState<TransferPhoneNumber[]>([]);
  const [selectedTransferNumbers, setSelectedTransferNumbers] = useState<OptionType<TransferPhoneNumber>[]>([]);

  const dispatch = useAppDispatch();

  const toOptionContact = (transferNumber: TransferPhoneNumber): OptionType<TransferPhoneNumber> => {
    return {
      label: transferNumber.number,
      value: transferNumber,
    };
  };

  const fetchTransferNumbers = async (search: string) => {
    try {
      const results = await dispatch(
        lookupPhoneNumbers({
          search,
          pageSize: 10,
        }),
      ).unwrap();

      if (results.data.results) {
        const mappedResults: TransferPhoneNumber[] = results.data.results.map((result) => ({
          transfer_number_library_id: result.id ?? "",
          name: result.name ?? "",
          number: result.number ?? "",
          is_default: false,
        }));

        selectedTransferNumbers.forEach((selected) => {
          if (
            !mappedResults.some(
              (result) => result.transfer_number_library_id === selected.value.transfer_number_library_id,
            )
          ) {
            mappedResults.push(selected.value);
          }
        });

        setTransferPhoneNumbers(mappedResults);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const transferNumbersArray = useFieldArray({
    control,
    name: "transfer_numbers",
  });

  useEffect(() => {
    setSelectedTransferNumbers(
      transferNumbersArray.fields.map((field) => ({
        label: field.number ?? "",
        value: {
          transfer_number_library_id: field.transfer_number_library_id ?? "",
          name: field.name ?? "",
          number: field.number ?? "",
          is_default: !!field.is_default,
        },
      })),
    );
  }, [transferNumbersArray.fields]);

  const setDefaultNumber = (id: string) => {
    const option = transferPhoneNumbers.find((transferNumber) => transferNumber.transfer_number_library_id === id);

    if (!option) return;

    setSelectedTransferNumbers((previousValues) => {
      return previousValues.map((value) => ({
        ...value,
        value: {
          ...value.value,
          is_default: value.value.transfer_number_library_id === id,
        },
      }));
    });

    const index = transferNumbersArray.fields.findIndex((field) => field.transfer_number_library_id === id);

    if (index === -1) {
      transferNumbersArray.fields.forEach((_, i) => {
        setValue(`transfer_numbers.${i}.is_default`, false as never);
      });

      transferNumbersArray.append({
        transfer_number_library_id: option.transfer_number_library_id,
        name: option.name,
        number: option.number,
        is_default: true,
        transfer_number_hours: DEFAULT_SCHEDULE,
      });

      return;
    }

    transferNumbersArray.fields.forEach((_, i) => {
      setValue(`transfer_numbers.${i}.is_default`, (i === index) as never);
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <FormControl fullWidth>
        <FormLabel label="Transfer Number Library" aria-required />
        <Controller
          control={control}
          name={`transfer_numbers`}
          render={({ field, fieldState }) => (
            <>
              <MultiAutocomplete
                value={selectedTransferNumbers}
                onChange={(_, newValue) => {
                  newValue.forEach((selected) => {
                    if (
                      !transferNumbersArray.fields.some(
                        (field) => field.transfer_number_library_id === selected.value.transfer_number_library_id,
                      )
                    ) {
                      transferNumbersArray.append({
                        ...selected.value,
                        transfer_number_hours: DEFAULT_SCHEDULE,
                      });
                    }
                  });

                  const idsToRemove: string[] = [];

                  transferNumbersArray.fields.forEach((field) => {
                    if (
                      !newValue.some(
                        (selected) => selected.value.transfer_number_library_id === field.transfer_number_library_id,
                      )
                    ) {
                      idsToRemove.push(field.transfer_number_library_id ?? "");
                    }
                  });

                  idsToRemove.forEach((id) => {
                    const index = transferNumbersArray.fields.findIndex(
                      (field) => field.transfer_number_library_id === id,
                    );

                    transferNumbersArray.remove(index);
                  });
                }}
                state={transferPhoneNumbers}
                fetchData={fetchTransferNumbers}
                mapData={toOptionContact}
                isOptionEqualToValue={(option, value) =>
                  option.value.transfer_number_library_id === value.value.transfer_number_library_id
                }
                getOptionKey={(option) => uniqueId(option.value.transfer_number_library_id)}
                renderOption={(props, option, { selected }) => {
                  return (
                    <TransferNumberItem
                      key={props.key}
                      props={props}
                      name={option.value.name}
                      number={option.value.number}
                      onClick={(e) => {
                        e.stopPropagation();

                        setDefaultNumber(option.value.transfer_number_library_id ?? "");
                      }}
                      selected={selected}
                      showBadge={selectedTransferNumbers.some(
                        (value) =>
                          value.value.is_default &&
                          value.value.transfer_number_library_id === option.value.transfer_number_library_id,
                      )}
                    />
                  );
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    const props = getTagProps({ index });

                    return (
                      <TransferNumberSelected
                        key={props.key}
                        props={props}
                        number={option.value.number}
                        isDefault={option.value.is_default}
                      />
                    );
                  })
                }
              />
            </>
          )}
        />
      </FormControl>
      <Box sx={{ width: "100%" }}>
        <Stack spacing={2}>
          {transferNumbersArray.fields.map((_, index) => (
            <TransferNumberHours key={`transfer-number-${index}`} index={index} />
          ))}
        </Stack>
      </Box>
      <FormControl sx={{ width: "100%" }}>
        <Controller
          name="transfer_initiation_phrase"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label={"Transfer Initiation Phrase"} aria-required />
              <Input
                {...field}
                placeholder="Transfer Initiation Phrase"
                onChange={field.onChange}
                error={fieldState.error?.message}
                multiline
                rows={2}
              />
            </FormControl>
          )}
        />
      </FormControl>
    </Box>
  );
}
