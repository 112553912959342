import Drawer from "@mui/material/Drawer";
import DrawerLayout from "@/layouts/DrawerLayout";
import { TransferNumberDto, TransferNumberRequest } from "@/services/generated";
import { useAppDispatch } from "@/redux/store";
import { upsertTransferNumber } from "@/redux/reducers/ai-voice/listTransferPhoneNumber";
import TransferNumberForm from "../form";
import toast from "@/lib/toast";
import { useNavigate } from "react-router-dom";
import routes from "@/common/constants/routes";

type TransferNumberViewDrawerProps = {
  open?: boolean;
  currentNumber?: TransferNumberDto;
};

export default function TransferNumberViewDrawer({ open, currentNumber }: TransferNumberViewDrawerProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toggleDrawer = () => {
    navigate(routes.aiVoice.transferNumberLibrary.path);
  };
  const onSubmit = (data: TransferNumberRequest) => {
    if (!data) {
      return;
    }

    dispatch(upsertTransferNumber({ ...data, id: currentNumber?.id }))
      .unwrap()
      .then(() => {
        toast.success("Transfer Number updates successfully!");
      })
      .catch(() => {
        toast.error("Something went wrong!");
      })
      .finally(() => {
        toggleDrawer();
      });
  };

  return (
    <Drawer open={open} onClose={toggleDrawer} anchor="right">
      <DrawerLayout customWidth="420px" title="Edit Transfer Number" closeDrawer={toggleDrawer}>
        <TransferNumberForm
          canEdit={!currentNumber?.is_user}
          onSubmit={onSubmit}
          defaultValues={{
            name: currentNumber?.name || "",
            number: currentNumber?.number || "",
          }}
        />
      </DrawerLayout>
    </Drawer>
  );
}
