import React, { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "@/redux/store";
import {
  googleTokenExchange,
  outlookTokenExchange,
  googleCalendarTokenExchange,
  outlookCalendarTokenExchange,
  hubspotTokenExchange,
  facebookTokenExhange,
} from "@/redux/reducers/oauth/token";
import routes from "@/common/constants/routes";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import toast from "@/lib/toast";

const OauthCallback: React.FC = () => {
  const { application } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const getQueryParams = (query: string) => {
    return new URLSearchParams(query);
  };

  const queryParams = getQueryParams(location.search);
  const code = queryParams.get("code");
  const scope = queryParams.get("scope");

  useEffect(() => {
    if (code === "" || code === null) {
      navigate(routes.settings.path);
      toast.error("Something went wrong!");

      return;
    }

    if (application === "gmail") {
      dispatch(googleTokenExchange({ code: code, shared: false }));
    } else if (application === "gmail-shared") {
      dispatch(googleTokenExchange({ code: code, shared: true }));
    } else if (application === "outlook") {
      dispatch(outlookTokenExchange({ code: code, shared: false }));
    } else if (application === "outlook-shared") {
      dispatch(outlookTokenExchange({ code: code, shared: true }));
    } else if (application === "google") {
      dispatch(googleCalendarTokenExchange(code));
    } else if (application === "graph") {
      dispatch(outlookCalendarTokenExchange(code));
    } else if (application === "hubspot") {
      dispatch(hubspotTokenExchange(code));
    } else if (application === "facebook") {
      dispatch(facebookTokenExhange(code));
    }

    navigate(routes.settings.path);
  }, [code, scope, application, dispatch, navigate]);
  return <LoadingSpinner />;
};

export default OauthCallback;
