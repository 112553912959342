import { Box, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Button from "@/common/components/button";
import Drawer from "@mui/material/Drawer";
import { useEffect } from "react";
import { useAppSelector } from "@/redux/store";
import { Icon } from "@/pages/tasks/helpers";
import { TaskRequestTypeEnum } from "@/services/generated";
import { getTasks, setCurrentTask } from "@/redux/reducers/tasks/slices/listTask";
import { useAppDispatch } from "@/redux/store";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import NoTasks from "./emptyData/NoTasks";
import { ViewTaskDrawerForm } from "@/pages/tasks/view/ViewTaskDrawerForm";
import { closeViewTaskDrawer } from "@/redux/reducers/tasks/slices/listTask";
import { openViewTaskDrawer } from "@/redux/reducers/tasks/slices/listTask";
import config from "@/common/constants/config";
import { useNavigate } from "react-router-dom";

export default function OutstandingTasks() {
  const dispatch = useAppDispatch();
  const { tasks, loadingTasks } = useAppSelector(({ tasks }) => tasks.listTask);
  const viewDrawerIsOpen = useAppSelector(({ tasks }) => tasks.listTask.viewDrawerIsOpen);

  const mapTypeAction = (type: string | undefined) => {
    switch (type) {
      case TaskRequestTypeEnum.Call:
        return "Call";
      case TaskRequestTypeEnum.ApproveAiMessage:
        return "Approve message to";
      case TaskRequestTypeEnum.AttendMeeting:
        return "Meet with";
      case TaskRequestTypeEnum.Other:
        return "Other with";
      default:
        return "Other with";
    }
  };

  useEffect(() => {
    dispatch(
      getTasks({
        pageNumber: 0,
        pageSize: 4,
      }),
    );
  }, [dispatch]);

  const router = useNavigate();

  const Item = ({
    name,
    type,
    onClick,
  }: {
    name: string | undefined;
    type: string | undefined | TaskRequestTypeEnum;
    onClick: () => void;
  }) => (
    <Box
      component="button"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "10px 12px",
        background: "#FBFBFB",
        borderRadius: "6px",
        cursor: "pointer",
        border: "none",
        alignItems: "center",
        "&:hover": {
          background: "#F2F4F8",
        },
      }}
      onClick={() => {
        dispatch(openViewTaskDrawer());
        onClick();
      }}
    >
      <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
        <Icon action={type} />
        <Typography variant="body1">
          {mapTypeAction(type)} {name}
        </Typography>
      </div>
      <KeyboardArrowRightIcon />
    </Box>
  );

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", gap: "4px", width: "100%", maxHeight: "214px" }}>
        {loadingTasks || (tasks && tasks?.length > 4) ? (
          <LoadingSpinner />
        ) : !tasks?.length ? (
          <NoTasks />
        ) : (
          <>
            {tasks?.map((task, index) => (
              <Item
                key={index}
                name={task?.assignee?.name}
                type={task?.type}
                onClick={() => {
                  if (task && task?.id) {
                    dispatch(setCurrentTask(task?.id));
                  }
                }}
              />
            ))}
          </>
        )}
      </div>

      {config.flagAiVoice && tasks?.length ? (
        <Button
          sx={{
            background: "#374667",
            color: "white",
            width: "fit-content",
            padding: "4px 8px",
            alignSelf: "flex-end",

            "&:hover": {
              background: "#2C3B4D",
            },
            "&:active": {
              background: "#2C3B4D",
            },
          }}
          size="sm"
          onClick={() => {
            router("/tasks");
          }}
        >
          View all tasks
        </Button>
      ) : null}

      <Drawer open={viewDrawerIsOpen} onClose={() => dispatch(closeViewTaskDrawer())} anchor="right">
        <ViewTaskDrawerForm disablePagination={true} />
      </Drawer>
    </>
  );
}
