/* tslint:disable */
/* eslint-disable */
/**
 * SalesAI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: swe@kennected.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface Activities
 */
export interface Activities {
    /**
     * 
     * @type {number}
     * @memberof Activities
     */
    'count_call'?: number;
    /**
     * 
     * @type {number}
     * @memberof Activities
     */
    'count_email'?: number;
    /**
     * 
     * @type {number}
     * @memberof Activities
     */
    'count_meet'?: number;
    /**
     * 
     * @type {number}
     * @memberof Activities
     */
    'count_sms'?: number;
    /**
     * 
     * @type {number}
     * @memberof Activities
     */
    'count_total'?: number;
}
/**
 * 
 * @export
 * @interface AddTeamUserRequest
 */
export interface AddTeamUserRequest {
    /**
     * 
     * @type {string}
     * @memberof AddTeamUserRequest
     */
    'teamId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddTeamUserRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddTeamUserRequest
     */
    'userRole'?: AddTeamUserRequestUserRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof AddTeamUserRequest
     */
    'sortDirection'?: AddTeamUserRequestSortDirectionEnum;
}

export const AddTeamUserRequestUserRoleEnum = {
    SalesRepresentative: 'SALES_REPRESENTATIVE',
    SalesManager: 'SALES_MANAGER',
    Admin: 'ADMIN',
    Superadmin: 'SUPERADMIN'
} as const;

export type AddTeamUserRequestUserRoleEnum = typeof AddTeamUserRequestUserRoleEnum[keyof typeof AddTeamUserRequestUserRoleEnum];
export const AddTeamUserRequestSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;

export type AddTeamUserRequestSortDirectionEnum = typeof AddTeamUserRequestSortDirectionEnum[keyof typeof AddTeamUserRequestSortDirectionEnum];

/**
 * 
 * @export
 * @interface AiAgentCallOutboundRequest
 */
export interface AiAgentCallOutboundRequest {
    /**
     * 
     * @type {string}
     * @memberof AiAgentCallOutboundRequest
     */
    'ai_agent_id': string;
    /**
     * 
     * @type {string}
     * @memberof AiAgentCallOutboundRequest
     */
    'contact_id': string;
}
/**
 * 
 * @export
 * @interface AiAgentCallOutboundTestRequest
 */
export interface AiAgentCallOutboundTestRequest {
    /**
     * 
     * @type {string}
     * @memberof AiAgentCallOutboundTestRequest
     */
    'ai_agent_id': string;
    /**
     * 
     * @type {string}
     * @memberof AiAgentCallOutboundTestRequest
     */
    'phone_number': string;
}
/**
 * 
 * @export
 * @interface AiAgentPhoneNumberListResponse
 */
export interface AiAgentPhoneNumberListResponse {
    /**
     * 
     * @type {string}
     * @memberof AiAgentPhoneNumberListResponse
     */
    'nextPageUri'?: string;
    /**
     * 
     * @type {Array<KnPhoneNumber>}
     * @memberof AiAgentPhoneNumberListResponse
     */
    'list'?: Array<KnPhoneNumber>;
}
/**
 * 
 * @export
 * @interface AiCompanyDetails
 */
export interface AiCompanyDetails {
    /**
     * 
     * @type {string}
     * @memberof AiCompanyDetails
     */
    'company_headcount'?: AiCompanyDetailsCompanyHeadcountEnum;
    /**
     * 
     * @type {KnAddress2}
     * @memberof AiCompanyDetails
     */
    'company_location'?: KnAddress2;
    /**
     * 
     * @type {string}
     * @memberof AiCompanyDetails
     */
    'company_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiCompanyDetails
     */
    'company_value_proposition'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiCompanyDetails
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiCompanyDetails
     */
    'link_to_faq'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiCompanyDetails
     */
    'link_to_knowledge_base'?: string;
}

export const AiCompanyDetailsCompanyHeadcountEnum = {
    _1To10: '1 to 10',
    _11To25: '11 to 25',
    _26To50: '26 to 50',
    _51To100: '51 to 100',
    _101To250: '101 to 250',
    _251To500: '251 to 500',
    _501To1000: '501 to 1000',
    _1001To5000: '1001 to 5000',
    _5001To10000: '5001 to 10000',
    _10000: '10000+'
} as const;

export type AiCompanyDetailsCompanyHeadcountEnum = typeof AiCompanyDetailsCompanyHeadcountEnum[keyof typeof AiCompanyDetailsCompanyHeadcountEnum];

/**
 * 
 * @export
 * @interface AiPersona
 */
export interface AiPersona {
    /**
     * 
     * @type {string}
     * @memberof AiPersona
     */
    'agent_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof AiPersona
     */
    'friendly_or_professional'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiPersona
     */
    'humorous_or_serious'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiPersona
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AiPersona
     */
    'laid_back_or_assertive'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiPersona
     */
    'responsiveness'?: AiPersonaResponsivenessEnum;
    /**
     * 
     * @type {number}
     * @memberof AiPersona
     */
    'short_or_verbose'?: number;
}

export const AiPersonaResponsivenessEnum = {
    Immediate: 'IMMEDIATE',
    DelaySec30: 'DELAY_SEC_30',
    DelaySec60: 'DELAY_SEC_60'
} as const;

export type AiPersonaResponsivenessEnum = typeof AiPersonaResponsivenessEnum[keyof typeof AiPersonaResponsivenessEnum];

/**
 * 
 * @export
 * @interface AiPersonaRequest
 */
export interface AiPersonaRequest {
    /**
     * 
     * @type {string}
     * @memberof AiPersonaRequest
     */
    'agent_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof AiPersonaRequest
     */
    'friendly_or_professional'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiPersonaRequest
     */
    'humorous_or_serious'?: number;
    /**
     * 
     * @type {number}
     * @memberof AiPersonaRequest
     */
    'laid_back_or_assertive'?: number;
    /**
     * 
     * @type {string}
     * @memberof AiPersonaRequest
     */
    'responsiveness'?: AiPersonaRequestResponsivenessEnum;
    /**
     * 
     * @type {number}
     * @memberof AiPersonaRequest
     */
    'short_or_verbose'?: number;
}

export const AiPersonaRequestResponsivenessEnum = {
    Immediate: 'IMMEDIATE',
    DelaySec30: 'DELAY_SEC_30',
    DelaySec60: 'DELAY_SEC_60'
} as const;

export type AiPersonaRequestResponsivenessEnum = typeof AiPersonaRequestResponsivenessEnum[keyof typeof AiPersonaRequestResponsivenessEnum];

/**
 * 
 * @export
 * @interface AiSalesProcess
 */
export interface AiSalesProcess {
    /**
     * 
     * @type {Array<AiSalesProcessObjection>}
     * @memberof AiSalesProcess
     */
    'objections'?: Array<AiSalesProcessObjection>;
}
/**
 * 
 * @export
 * @interface AiSalesProcessObjection
 */
export interface AiSalesProcessObjection {
    /**
     * 
     * @type {string}
     * @memberof AiSalesProcessObjection
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiSalesProcessObjection
     */
    'objection'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiSalesProcessObjection
     */
    'objection_strategy'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiSalesProcessObjection
     */
    'objection_type'?: AiSalesProcessObjectionObjectionTypeEnum;
}

export const AiSalesProcessObjectionObjectionTypeEnum = {
    Price: 'PRICE',
    Timing: 'TIMING',
    Competition: 'COMPETITION',
    Authority: 'AUTHORITY',
    Need: 'NEED',
    Other: 'OTHER'
} as const;

export type AiSalesProcessObjectionObjectionTypeEnum = typeof AiSalesProcessObjectionObjectionTypeEnum[keyof typeof AiSalesProcessObjectionObjectionTypeEnum];

/**
 * 
 * @export
 * @interface AiVoiceTeamSettings
 */
export interface AiVoiceTeamSettings {
    /**
     * 
     * @type {string}
     * @memberof AiVoiceTeamSettings
     */
    'transfer_phone_number_billing'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiVoiceTeamSettings
     */
    'transfer_phone_number_default'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiVoiceTeamSettings
     */
    'transfer_phone_number_sales'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiVoiceTeamSettings
     */
    'transfer_phone_number_support'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiVoiceTeamSettings
     */
    'twilio_account_sid'?: string;
}
/**
 * 
 * @export
 * @interface AiVoiceTransferRequest
 */
export interface AiVoiceTransferRequest {
    /**
     * 
     * @type {string}
     * @memberof AiVoiceTransferRequest
     */
    'transfer_phone_number_billing'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiVoiceTransferRequest
     */
    'transfer_phone_number_default'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiVoiceTransferRequest
     */
    'transfer_phone_number_sales'?: string;
    /**
     * 
     * @type {string}
     * @memberof AiVoiceTransferRequest
     */
    'transfer_phone_number_support'?: string;
}
/**
 * 
 * @export
 * @interface AiVoiceTwilioRequest
 */
export interface AiVoiceTwilioRequest {
    /**
     * 
     * @type {string}
     * @memberof AiVoiceTwilioRequest
     */
    'twilio_account_sid': string;
    /**
     * 
     * @type {string}
     * @memberof AiVoiceTwilioRequest
     */
    'twilio_auth_token': string;
}
/**
 * User assigned to this task
 * @export
 * @interface AssigneeDto
 */
export interface AssigneeDto {
    /**
     * User id
     * @type {string}
     * @memberof AssigneeDto
     */
    'id'?: string;
    /**
     * Link to a profile picture
     * @type {string}
     * @memberof AssigneeDto
     */
    'imgUrl'?: string;
    /**
     * User full name
     * @type {string}
     * @memberof AssigneeDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface AttachmentDTO
 */
export interface AttachmentDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof AttachmentDTO
     */
    'content'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDTO
     */
    'fileName'?: string;
}
/**
 * List of presaved attachment links
 * @export
 * @interface AttachmentDto
 */
export interface AttachmentDto {
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    'media'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    'name'?: string;
}
/**
 * This is a list of the ids regarding the attachment files in this step template.
 * @export
 * @interface AttachmentResponse
 */
export interface AttachmentResponse {
    /**
     * File name
     * @type {string}
     * @memberof AttachmentResponse
     */
    'fileName'?: string;
    /**
     * attachment identification.
     * @type {string}
     * @memberof AttachmentResponse
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface BaseAccessTokenDTO
 */
export interface BaseAccessTokenDTO {
    /**
     * 
     * @type {string}
     * @memberof BaseAccessTokenDTO
     */
    'accessToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseAccessTokenDTO
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseAccessTokenDTO
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseAccessTokenDTO
     */
    'expireSeconds'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseAccessTokenDTO
     */
    'expireTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseAccessTokenDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseAccessTokenDTO
     */
    'refreshToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseAccessTokenDTO
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface CallLog
 */
export interface CallLog {
    /**
     * 
     * @type {number}
     * @memberof CallLog
     */
    'call_began_time'?: number;
    /**
     * 
     * @type {number}
     * @memberof CallLog
     */
    'call_duration'?: number;
    /**
     * 
     * @type {string}
     * @memberof CallLog
     */
    'call_direction'?: CallLogCallDirectionEnum;
    /**
     * 
     * @type {number}
     * @memberof CallLog
     */
    'call_ended_time'?: number;
    /**
     * 
     * @type {string}
     * @memberof CallLog
     */
    'call_flow'?: CallLogCallFlowEnum;
    /**
     * 
     * @type {string}
     * @memberof CallLog
     */
    'call_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallLog
     */
    'call_outcome'?: CallLogCallOutcomeEnum;
    /**
     * 
     * @type {string}
     * @memberof CallLog
     */
    'call_recording_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallLog
     */
    'call_status'?: CallLogCallStatusEnum;
    /**
     * 
     * @type {KnContact}
     * @memberof CallLog
     */
    'contact'?: KnContact;
    /**
     * 
     * @type {boolean}
     * @memberof CallLog
     */
    'is_contact_deleted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CallLog
     */
    'is_contact_test'?: boolean;
}

export const CallLogCallDirectionEnum = {
    Inbound: 'INBOUND',
    Outbound: 'OUTBOUND'
} as const;

export type CallLogCallDirectionEnum = typeof CallLogCallDirectionEnum[keyof typeof CallLogCallDirectionEnum];
export const CallLogCallFlowEnum = {
    QualifyAndBookAMeeting: 'QUALIFY_AND_BOOK_A_MEETING',
    BookAMeeting: 'BOOK_A_MEETING',
    QualifyAndLiveTransfer: 'QUALIFY_AND_LIVE_TRANSFER',
    AnswerFaqs: 'ANSWER_FAQS',
    ConfirmAMeeting: 'CONFIRM_A_MEETING',
    PromptAndQualify: 'PROMPT_AND_QUALIFY',
    Prompt: 'PROMPT',
    Script: 'SCRIPT',
    PromptAndQualifyV1: 'PROMPT_AND_QUALIFY_V1',
    PromptV1: 'PROMPT_V1',
    ScriptV1: 'SCRIPT_V1',
    Sales: 'SALES',
    TierOneSupport: 'TIER_ONE_SUPPORT',
    DiscoveryTransfer: 'DISCOVERY_TRANSFER'
} as const;

export type CallLogCallFlowEnum = typeof CallLogCallFlowEnum[keyof typeof CallLogCallFlowEnum];
export const CallLogCallOutcomeEnum = {
    Unspecified: 'UNSPECIFIED',
    Error: 'ERROR',
    Inbound: 'INBOUND',
    Dna: 'DNA',
    Dnc: 'DNC',
    OutboundDnc: 'OUTBOUND_DNC',
    Voicemail: 'VOICEMAIL',
    OutboundVoicemail: 'OUTBOUND_VOICEMAIL',
    Answered: 'ANSWERED',
    OutboundAnswered: 'OUTBOUND_ANSWERED',
    Booked: 'BOOKED',
    BookedAMeeting: 'BOOKED_A_MEETING',
    Transfer: 'TRANSFER',
    Transferred: 'TRANSFERRED',
    TransferSales: 'TRANSFER_SALES',
    TransferBilling: 'TRANSFER_BILLING',
    TransferSupport: 'TRANSFER_SUPPORT'
} as const;

export type CallLogCallOutcomeEnum = typeof CallLogCallOutcomeEnum[keyof typeof CallLogCallOutcomeEnum];
export const CallLogCallStatusEnum = {
    Queued: 'QUEUED',
    InProgress: 'IN_PROGRESS',
    Success: 'SUCCESS',
    Failure: 'FAILURE'
} as const;

export type CallLogCallStatusEnum = typeof CallLogCallStatusEnum[keyof typeof CallLogCallStatusEnum];

/**
 * 
 * @export
 * @interface CampaignPhoneNumberRequestDto
 */
export interface CampaignPhoneNumberRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CampaignPhoneNumberRequestDto
     */
    'campaign_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignPhoneNumberRequestDto
     */
    'phone_number'?: string;
}
/**
 * 
 * @export
 * @interface CompanyDetailsRequest
 */
export interface CompanyDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof CompanyDetailsRequest
     */
    'company_headcount'?: CompanyDetailsRequestCompanyHeadcountEnum;
    /**
     * 
     * @type {KnAddress2}
     * @memberof CompanyDetailsRequest
     */
    'company_location'?: KnAddress2;
    /**
     * 
     * @type {string}
     * @memberof CompanyDetailsRequest
     */
    'company_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDetailsRequest
     */
    'company_value_proposition'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDetailsRequest
     */
    'link_to_faq'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDetailsRequest
     */
    'link_to_knowledge_base'?: string;
}

export const CompanyDetailsRequestCompanyHeadcountEnum = {
    _1To10: '1 to 10',
    _11To25: '11 to 25',
    _26To50: '26 to 50',
    _51To100: '51 to 100',
    _101To250: '101 to 250',
    _251To500: '251 to 500',
    _501To1000: '501 to 1000',
    _1001To5000: '1001 to 5000',
    _5001To10000: '5001 to 10000',
    _10000: '10000+'
} as const;

export type CompanyDetailsRequestCompanyHeadcountEnum = typeof CompanyDetailsRequestCompanyHeadcountEnum[keyof typeof CompanyDetailsRequestCompanyHeadcountEnum];

/**
 * 
 * @export
 * @interface ConsentDTO
 */
export interface ConsentDTO {
    /**
     * 
     * @type {BaseAccessTokenDTO}
     * @memberof ConsentDTO
     */
    'accessToken'?: BaseAccessTokenDTO;
    /**
     * 
     * @type {string}
     * @memberof ConsentDTO
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsentDTO
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof ConsentDTO
     */
    'emailProvider'?: ConsentDTOEmailProviderEnum;
    /**
     * 
     * @type {string}
     * @memberof ConsentDTO
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConsentDTO
     */
    'shared'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConsentDTO
     */
    'teamId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsentDTO
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsentDTO
     */
    'userId'?: string;
}

export const ConsentDTOEmailProviderEnum = {
    Gmail: 'GMAIL',
    Outlook: 'OUTLOOK'
} as const;

export type ConsentDTOEmailProviderEnum = typeof ConsentDTOEmailProviderEnum[keyof typeof ConsentDTOEmailProviderEnum];

/**
 * 
 * @export
 * @interface CreateBrandRequestDto
 */
export interface CreateBrandRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequestDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequestDto
     */
    'company_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequestDto
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequestDto
     */
    'display_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequestDto
     */
    'ein'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequestDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequestDto
     */
    'entity_type'?: CreateBrandRequestDtoEntityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequestDto
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequestDto
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequestDto
     */
    'mobile_phone'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateBrandRequestDto
     */
    'mock'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequestDto
     */
    'owner_team_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequestDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequestDto
     */
    'postal_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequestDto
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequestDto
     */
    'street'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequestDto
     */
    'vertical'?: CreateBrandRequestDtoVerticalEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequestDto
     */
    'website'?: string;
}

export const CreateBrandRequestDtoEntityTypeEnum = {
    PrivateProfit: 'PRIVATE_PROFIT',
    PublicProfit: 'PUBLIC_PROFIT',
    NonProfit: 'NON_PROFIT',
    SoleProprietor: 'SOLE_PROPRIETOR',
    Government: 'GOVERNMENT'
} as const;

export type CreateBrandRequestDtoEntityTypeEnum = typeof CreateBrandRequestDtoEntityTypeEnum[keyof typeof CreateBrandRequestDtoEntityTypeEnum];
export const CreateBrandRequestDtoVerticalEnum = {
    Agriculture: 'AGRICULTURE',
    Communication: 'COMMUNICATION',
    Construction: 'CONSTRUCTION',
    Education: 'EDUCATION',
    Energy: 'ENERGY',
    Entertainment: 'ENTERTAINMENT',
    Financial: 'FINANCIAL',
    Gambling: 'GAMBLING',
    Government: 'GOVERNMENT',
    Healthcare: 'HEALTHCARE',
    Hospitality: 'HOSPITALITY',
    Insurance: 'INSURANCE',
    Manufacturing: 'MANUFACTURING',
    NgoNgo: 'NGO(\"NGO\"),',
    RealEstate: 'REAL_ESTATE',
    Retail: 'RETAIL',
    Technology: 'TECHNOLOGY'
} as const;

export type CreateBrandRequestDtoVerticalEnum = typeof CreateBrandRequestDtoVerticalEnum[keyof typeof CreateBrandRequestDtoVerticalEnum];

/**
 * 
 * @export
 * @interface CreateCampaignRequestDto
 */
export interface CreateCampaignRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCampaignRequestDto
     */
    'brand_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCampaignRequestDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCampaignRequestDto
     */
    'help_keywords'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCampaignRequestDto
     */
    'help_message'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCampaignRequestDto
     */
    'message_flow'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCampaignRequestDto
     */
    'mock'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateCampaignRequestDto
     */
    'optin_keywords'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCampaignRequestDto
     */
    'optout_keywords'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCampaignRequestDto
     */
    'optout_message'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCampaignRequestDto
     */
    'owner_team_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCampaignRequestDto
     */
    'sample1'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCampaignRequestDto
     */
    'use_case'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCampaignRequestDto
     */
    'webhook_url'?: string;
}
/**
 * 
 * @export
 * @interface CreateCustomerPortalUrlResponse
 */
export interface CreateCustomerPortalUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerPortalUrlResponse
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface DashboardRequest
 */
export interface DashboardRequest {
    /**
     * 
     * @type {string}
     * @memberof DashboardRequest
     */
    'date_range'?: DashboardRequestDateRangeEnum;
}

export const DashboardRequestDateRangeEnum = {
    ThisMonth: 'THIS_MONTH',
    LastMonth: 'LAST_MONTH',
    Last30Days: 'LAST_30_DAYS',
    Last90Days: 'LAST_90_DAYS'
} as const;

export type DashboardRequestDateRangeEnum = typeof DashboardRequestDateRangeEnum[keyof typeof DashboardRequestDateRangeEnum];

/**
 * Deal associated with a task
 * @export
 * @interface DealDto
 */
export interface DealDto {
    /**
     * 
     * @type {number}
     * @memberof DealDto
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof DealDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DealDto
     */
    'name'?: string;
}
/**
 * User from the deal owner/assignee
 * @export
 * @interface DealFlowUser
 */
export interface DealFlowUser {
    /**
     * User\'s id.
     * @type {string}
     * @memberof DealFlowUser
     */
    'id'?: string;
    /**
     * Link to user\'s image URL.
     * @type {string}
     * @memberof DealFlowUser
     */
    'imageUrl'?: string;
    /**
     * User name.
     * @type {string}
     * @memberof DealFlowUser
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DealRequestCreate
 */
export interface DealRequestCreate {
    /**
     * User id from the deal owner/assignee
     * @type {string}
     * @memberof DealRequestCreate
     */
    'assigneeId'?: string;
    /**
     * Close date for the deal
     * @type {string}
     * @memberof DealRequestCreate
     */
    'closeDate'?: string;
    /**
     * Prospect id for the contact
     * @type {Array<string>}
     * @memberof DealRequestCreate
     */
    'contactIdList'?: Array<string>;
    /**
     * Should allow company timeline activity
     * @type {Array<LineItemsRequestCreate>}
     * @memberof DealRequestCreate
     */
    'lineItems'?: Array<LineItemsRequestCreate>;
    /**
     * Deal name.
     * @type {string}
     * @memberof DealRequestCreate
     */
    'name'?: string;
    /**
     * Pipeline stage id where deal should start
     * @type {string}
     * @memberof DealRequestCreate
     */
    'pipelineStageId'?: string;
}
/**
 * 
 * @export
 * @interface DealRequestUpdate
 */
export interface DealRequestUpdate {
    /**
     * User id from the deal owner/assignee
     * @type {string}
     * @memberof DealRequestUpdate
     */
    'assigneeId'?: string;
    /**
     * Close date for the deal
     * @type {string}
     * @memberof DealRequestUpdate
     */
    'closeDate'?: string;
    /**
     * Prospect id list for the contacts
     * @type {Array<string>}
     * @memberof DealRequestUpdate
     */
    'contactIdList'?: Array<string>;
    /**
     * Deal\'s id.
     * @type {string}
     * @memberof DealRequestUpdate
     */
    'id'?: string;
    /**
     * Should allow company timeline activity
     * @type {Array<LineItemsRequestUpdate>}
     * @memberof DealRequestUpdate
     */
    'lineItems'?: Array<LineItemsRequestUpdate>;
    /**
     * Deal name.
     * @type {string}
     * @memberof DealRequestUpdate
     */
    'name'?: string;
    /**
     * Pipeline stage id where deal should start
     * @type {string}
     * @memberof DealRequestUpdate
     */
    'pipelineStageId'?: string;
}
/**
 * 
 * @export
 * @interface DripStepResponse
 */
export interface DripStepResponse {
    /**
     * 
     * @type {SequenceContact}
     * @memberof DripStepResponse
     */
    'contact'?: SequenceContact;
    /**
     * Date and time when the step was created
     * @type {string}
     * @memberof DripStepResponse
     */
    'createdAt'?: string;
    /**
     * drip template identification.
     * @type {string}
     * @memberof DripStepResponse
     */
    'dripTemplateId'?: string;
    /**
     * The date and time when the next step will be executed
     * @type {string}
     * @memberof DripStepResponse
     */
    'executionDate'?: string;
    /**
     * drip identification.
     * @type {string}
     * @memberof DripStepResponse
     */
    'id'?: string;
    /**
     * step identification.
     * @type {string}
     * @memberof DripStepResponse
     */
    'sequenceStepId'?: string;
    /**
     * Date and time when the step was last updated
     * @type {string}
     * @memberof DripStepResponse
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface DripTemplateRequest
 */
export interface DripTemplateRequest {
    /**
     * Action name
     * @type {string}
     * @memberof DripTemplateRequest
     */
    'actionName': string;
    /**
     * Batch size
     * @type {number}
     * @memberof DripTemplateRequest
     */
    'batchSize': number;
    /**
     * drip template identification.
     * @type {string}
     * @memberof DripTemplateRequest
     */
    'id'?: string;
    /**
     * Sequence template step id
     * @type {string}
     * @memberof DripTemplateRequest
     */
    'sequenceTemplateStepId': string;
    /**
     * Days interval in drip
     * @type {number}
     * @memberof DripTemplateRequest
     */
    'days'?: number;
    /**
     * Hours interval in drip
     * @type {number}
     * @memberof DripTemplateRequest
     */
    'hours'?: number;
    /**
     * Minutes interval in drip
     * @type {number}
     * @memberof DripTemplateRequest
     */
    'minutes'?: number;
}
/**
 * 
 * @export
 * @interface DripTemplateResponse
 */
export interface DripTemplateResponse {
    /**
     * Action name
     * @type {string}
     * @memberof DripTemplateResponse
     */
    'actionName'?: string;
    /**
     * Batch size
     * @type {number}
     * @memberof DripTemplateResponse
     */
    'batchSize'?: number;
    /**
     * The date and time when the step was created
     * @type {string}
     * @memberof DripTemplateResponse
     */
    'createdAt'?: string;
    /**
     * User id from the creator
     * @type {string}
     * @memberof DripTemplateResponse
     */
    'createdBy'?: string;
    /**
     * drip template identification.
     * @type {string}
     * @memberof DripTemplateResponse
     */
    'id'?: string;
    /**
     * Days interval in drip
     * @type {number}
     * @memberof DripTemplateResponse
     */
    'days'?: number;
    /**
     * Hours interval in drip
     * @type {number}
     * @memberof DripTemplateResponse
     */
    'hours'?: number;
    /**
     * Minutes interval in drip
     * @type {number}
     * @memberof DripTemplateResponse
     */
    'minutes'?: number;
    /**
     * The total sum of the interval in milliseconds
     * @type {number}
     * @memberof DripTemplateResponse
     */
    'totalIntervalInMillis'?: number;
    /**
     * Number of prospects in the drip
     * @type {number}
     * @memberof DripTemplateResponse
     */
    'prospectsInDrip'?: number;
    /**
     * Sequence template step id
     * @type {string}
     * @memberof DripTemplateResponse
     */
    'sequenceTemplateStepId'?: string;
    /**
     * The date and time when the step template was last updated
     * @type {string}
     * @memberof DripTemplateResponse
     */
    'updatedAt'?: string;
    /**
     * User id from the last updater
     * @type {string}
     * @memberof DripTemplateResponse
     */
    'updatedBy'?: string;
}
/**
 * 
 * @export
 * @interface EmailDTO
 */
export interface EmailDTO {
    /**
     * 
     * @type {string}
     * @memberof EmailDTO
     */
    '_CHANGE_TYPE'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailDTO
     */
    'action'?: EmailDTOActionEnum;
    /**
     * 
     * @type {Array<AttachmentDTO>}
     * @memberof EmailDTO
     */
    'attachments'?: Array<AttachmentDTO>;
    /**
     * 
     * @type {string}
     * @memberof EmailDTO
     */
    'body': string;
    /**
     * 
     * @type {string}
     * @memberof EmailDTO
     */
    'createdAt'?: string;
    /**
     * 
     * @type {Array<MessageEventDTO>}
     * @memberof EmailDTO
     */
    'events'?: Array<MessageEventDTO>;
    /**
     * 
     * @type {string}
     * @memberof EmailDTO
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailDTO
     */
    'from_user_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmailDTO
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EmailDTO
     */
    'is_ai'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmailDTO
     */
    'is_ai_user_intv'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EmailDTO
     */
    'outreach_thread_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmailDTO
     */
    'prospect_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailDTO
     */
    'provider'?: EmailDTOProviderEnum;
    /**
     * 
     * @type {string}
     * @memberof EmailDTO
     */
    'provider_message_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailDTO
     */
    'provider_sequence_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailDTO
     */
    'provider_thread_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailDTO
     */
    'sequence_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailDTO
     */
    'source'?: EmailDTOSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof EmailDTO
     */
    'status'?: EmailDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof EmailDTO
     */
    'subject': string;
    /**
     * 
     * @type {string}
     * @memberof EmailDTO
     */
    'team_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailDTO
     */
    'to'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailDTO
     */
    'to_user_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailDTO
     */
    'updatedAt'?: string;
}

export const EmailDTOActionEnum = {
    Draft: 'DRAFT',
    Send: 'SEND',
    Receive: 'RECEIVE'
} as const;

export type EmailDTOActionEnum = typeof EmailDTOActionEnum[keyof typeof EmailDTOActionEnum];
export const EmailDTOProviderEnum = {
    Gmail: 'GMAIL',
    Outlook: 'OUTLOOK'
} as const;

export type EmailDTOProviderEnum = typeof EmailDTOProviderEnum[keyof typeof EmailDTOProviderEnum];
export const EmailDTOSourceEnum = {
    Inbound: 'INBOUND',
    Outbound: 'OUTBOUND'
} as const;

export type EmailDTOSourceEnum = typeof EmailDTOSourceEnum[keyof typeof EmailDTOSourceEnum];
export const EmailDTOStatusEnum = {
    Created: 'CREATED',
    Updated: 'UPDATED',
    Saved: 'SAVED',
    Forwarded: 'FORWARDED',
    Queued: 'QUEUED',
    Sending: 'SENDING',
    Sent: 'SENT',
    SendingFailed: 'SENDING_FAILED',
    DeliveredFailed: 'DELIVERED_FAILED',
    DeliveredUnconfirmed: 'DELIVERED_UNCONFIRMED',
    Delivered: 'DELIVERED',
    Received: 'RECEIVED',
    Broadcasted: 'BROADCASTED',
    BroadcastedEvent: 'BROADCASTED_EVENT',
    Open: 'OPEN'
} as const;

export type EmailDTOStatusEnum = typeof EmailDTOStatusEnum[keyof typeof EmailDTOStatusEnum];

/**
 * 
 * @export
 * @interface Entity
 */
export interface Entity {
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    'entity_type'?: EntityEntityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    'user_id'?: string;
}

export const EntityEntityTypeEnum = {
    User: 'USER',
    SalesAi: 'SALES_AI'
} as const;

export type EntityEntityTypeEnum = typeof EntityEntityTypeEnum[keyof typeof EntityEntityTypeEnum];

/**
 * 
 * @export
 * @interface EntityActivities
 */
export interface EntityActivities {
    /**
     * 
     * @type {Activities}
     * @memberof EntityActivities
     */
    'activities'?: Activities;
    /**
     * 
     * @type {Entity}
     * @memberof EntityActivities
     */
    'entity'?: Entity;
}
/**
 * 
 * @export
 * @interface ExchangeAuthCodeDto
 */
export interface ExchangeAuthCodeDto {
    /**
     * 
     * @type {string}
     * @memberof ExchangeAuthCodeDto
     */
    'code'?: string;
    /**
     * 
     * @type {ConsentDTO}
     * @memberof ExchangeAuthCodeDto
     */
    'consent'?: ConsentDTO;
    /**
     * 
     * @type {string}
     * @memberof ExchangeAuthCodeDto
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeAuthCodeDto
     */
    'redirectUri'?: string;
}
/**
 * 
 * @export
 * @interface GenMsgCustom
 */
export interface GenMsgCustom {
    /**
     * 
     * @type {string}
     * @memberof GenMsgCustom
     */
    'contact_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GenMsgCustom
     */
    'message_type'?: GenMsgCustomMessageTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GenMsgCustom
     */
    'prompt': string;
}

export const GenMsgCustomMessageTypeEnum = {
    Sms: 'SMS',
    Email: 'EMAIL'
} as const;

export type GenMsgCustomMessageTypeEnum = typeof GenMsgCustomMessageTypeEnum[keyof typeof GenMsgCustomMessageTypeEnum];

/**
 * 
 * @export
 * @interface GenMsgSuggestMeetingTimes
 */
export interface GenMsgSuggestMeetingTimes {
    /**
     * 
     * @type {string}
     * @memberof GenMsgSuggestMeetingTimes
     */
    'contact_id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenMsgSuggestMeetingTimes
     */
    'meet_with_user_id_list'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GenMsgSuggestMeetingTimes
     */
    'message_type'?: GenMsgSuggestMeetingTimesMessageTypeEnum;
}

export const GenMsgSuggestMeetingTimesMessageTypeEnum = {
    Sms: 'SMS',
    Email: 'EMAIL'
} as const;

export type GenMsgSuggestMeetingTimesMessageTypeEnum = typeof GenMsgSuggestMeetingTimesMessageTypeEnum[keyof typeof GenMsgSuggestMeetingTimesMessageTypeEnum];

/**
 * 
 * @export
 * @interface GmailAccessTokenDto
 */
export interface GmailAccessTokenDto {
    /**
     * 
     * @type {string}
     * @memberof GmailAccessTokenDto
     */
    'accessToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof GmailAccessTokenDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof GmailAccessTokenDto
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {number}
     * @memberof GmailAccessTokenDto
     */
    'expireSeconds'?: number;
    /**
     * 
     * @type {string}
     * @memberof GmailAccessTokenDto
     */
    'expireTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof GmailAccessTokenDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GmailAccessTokenDto
     */
    'refreshToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof GmailAccessTokenDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof GmailAccessTokenDto
     */
    'watchExpirationTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof GmailAccessTokenDto
     */
    'watchHistoryId'?: number;
}
/**
 * 
 * @export
 * @interface KnAcceptTermsOfService
 */
export interface KnAcceptTermsOfService {
    /**
     * String value representing the version of the Terms Of Service the User wishes to accept
     * @type {string}
     * @memberof KnAcceptTermsOfService
     */
    'version': string;
}
/**
 * 
 * @export
 * @interface KnAccountBillingInfoResponse
 */
export interface KnAccountBillingInfoResponse {
    /**
     * Date when the SalesAi account was activated. Format \'yyyy-mm-dd\'
     * @type {string}
     * @memberof KnAccountBillingInfoResponse
     */
    'accountActivationDate'?: string;
    /**
     * Minutes remaining
     * @type {number}
     * @memberof KnAccountBillingInfoResponse
     */
    'minutesRemaining'?: number;
    /**
     * Total minutes
     * @type {number}
     * @memberof KnAccountBillingInfoResponse
     */
    'minutesTotal'?: number;
    /**
     * Next day of payment in format \'yyyy-mm-dd\'
     * @type {string}
     * @memberof KnAccountBillingInfoResponse
     */
    'nextPaymentDate'?: string;
    /**
     * Value of the next payment for the tier/plan
     * @type {string}
     * @memberof KnAccountBillingInfoResponse
     */
    'nextPaymentValue'?: string;
    /**
     * Texts remaining
     * @type {number}
     * @memberof KnAccountBillingInfoResponse
     */
    'textsRemaining'?: number;
    /**
     * Texts total
     * @type {number}
     * @memberof KnAccountBillingInfoResponse
     */
    'textsTotal'?: number;
    /**
     * Tier/plan
     * @type {string}
     * @memberof KnAccountBillingInfoResponse
     */
    'tierName'?: string;
}
/**
 * 
 * @export
 * @interface KnAddress
 */
export interface KnAddress {
    /**
     * 
     * @type {string}
     * @memberof KnAddress
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnAddress
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnAddress
     */
    'postal_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnAddress
     */
    'state'?: string;
}
/**
 * 
 * @export
 * @interface KnAddress2
 */
export interface KnAddress2 {
    /**
     * 
     * @type {string}
     * @memberof KnAddress2
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnAddress2
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnAddress2
     */
    'postal_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnAddress2
     */
    'state'?: string;
}
/**
 * 
 * @export
 * @interface KnAddressFilter
 */
export interface KnAddressFilter {
    /**
     * 
     * @type {string}
     * @memberof KnAddressFilter
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnAddressFilter
     */
    'state'?: string;
}
/**
 * 
 * @export
 * @interface KnAiAgent
 */
export interface KnAiAgent {
    /**
     * 
     * @type {string}
     * @memberof KnAiAgent
     */
    'agent_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgent
     */
    'agent_label'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgent
     */
    'agent_status'?: KnAiAgentAgentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgent
     */
    'booking_mode'?: KnAiAgentBookingModeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof KnAiAgent
     */
    'booking_with'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgent
     */
    'call_direction'?: KnAiAgentCallDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgent
     */
    'call_flow'?: KnAiAgentCallFlowEnum;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgent
     */
    'call_flow_prompt'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgent
     */
    'call_flow_prompt_qualify'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgent
     */
    'call_to_action'?: KnAiAgentCallToActionEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof KnAiAgent
     */
    'call_to_action_2'?: Array<KnAiAgentCallToAction2Enum>;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgent
     */
    'catch_webhook'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnAiAgent
     */
    'creativity'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof KnAiAgent
     */
    'double_call'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgent
     */
    'hook_statement'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnAiAgent
     */
    'interrupt'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgent
     */
    'knowledge_base_faq_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgent
     */
    'knowledge_base_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgent
     */
    'language'?: KnAiAgentLanguageEnum;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgent
     */
    'phone_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgent
     */
    'transfer'?: KnAiAgentTransferEnum;
    /**
     * 
     * @type {Array<KnAiAgentTransferNumber>}
     * @memberof KnAiAgent
     */
    'transfer_numbers'?: Array<KnAiAgentTransferNumber>;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgent
     */
    'transfer_phrase'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgent
     */
    'voice_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgent
     */
    'voice_image_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgent
     */
    'voice_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnAiAgent
     */
    'buffer_time'?: number;
    /**
     * 
     * @type {Array<KnMeetingOptionsBusinessHours>}
     * @memberof KnAiAgent
     */
    'business_hours'?: Array<KnMeetingOptionsBusinessHours>;
    /**
     * 
     * @type {number}
     * @memberof KnAiAgent
     */
    'meeting_duration'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgent
     */
    'meeting_title'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgent
     */
    'meeting_description'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnAiAgent
     */
    'notice_time'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgent
     */
    'time_zone'?: string;
}

export const KnAiAgentAgentStatusEnum = {
    Incomplete: 'INCOMPLETE',
    Active: 'ACTIVE'
} as const;

export type KnAiAgentAgentStatusEnum = typeof KnAiAgentAgentStatusEnum[keyof typeof KnAiAgentAgentStatusEnum];
export const KnAiAgentBookingModeEnum = {
    RoundRobin: 'ROUND_ROBIN',
    OneToOne: 'ONE_TO_ONE'
} as const;

export type KnAiAgentBookingModeEnum = typeof KnAiAgentBookingModeEnum[keyof typeof KnAiAgentBookingModeEnum];
export const KnAiAgentCallDirectionEnum = {
    Inbound: 'INBOUND',
    Outbound: 'OUTBOUND'
} as const;

export type KnAiAgentCallDirectionEnum = typeof KnAiAgentCallDirectionEnum[keyof typeof KnAiAgentCallDirectionEnum];
export const KnAiAgentCallFlowEnum = {
    QualifyAndBookAMeeting: 'QUALIFY_AND_BOOK_A_MEETING',
    BookAMeeting: 'BOOK_A_MEETING',
    QualifyAndLiveTransfer: 'QUALIFY_AND_LIVE_TRANSFER',
    AnswerFaqs: 'ANSWER_FAQS',
    ConfirmAMeeting: 'CONFIRM_A_MEETING',
    PromptAndQualify: 'PROMPT_AND_QUALIFY',
    Prompt: 'PROMPT',
    Script: 'SCRIPT',
    PromptAndQualifyV1: 'PROMPT_AND_QUALIFY_V1',
    PromptV1: 'PROMPT_V1',
    ScriptV1: 'SCRIPT_V1',
    Sales: 'SALES',
    TierOneSupport: 'TIER_ONE_SUPPORT',
    DiscoveryTransfer: 'DISCOVERY_TRANSFER'
} as const;

export type KnAiAgentCallFlowEnum = typeof KnAiAgentCallFlowEnum[keyof typeof KnAiAgentCallFlowEnum];
export const KnAiAgentCallToActionEnum = {
    BookMeeting: 'BOOK_MEETING',
    CreateTicket: 'CREATE_TICKET',
    LiveTransfer: 'LIVE_TRANSFER',
    None: 'NONE'
} as const;

export type KnAiAgentCallToActionEnum = typeof KnAiAgentCallToActionEnum[keyof typeof KnAiAgentCallToActionEnum];
export const KnAiAgentCallToAction2Enum = {
    BookMeeting: 'BOOK_MEETING',
    CreateTicket: 'CREATE_TICKET',
    LiveTransfer: 'LIVE_TRANSFER'
} as const;

export type KnAiAgentCallToAction2Enum = typeof KnAiAgentCallToAction2Enum[keyof typeof KnAiAgentCallToAction2Enum];
export const KnAiAgentLanguageEnum = {
    EnUs: 'en-US',
    EnAu: 'en-AU',
    EnIn: 'en-IN',
    EnGb: 'en-GB',
    Fr: 'fr',
    FrCa: 'fr-CA',
    Nl: 'nl',
    It: 'it',
    Zh: 'zh',
    Pt: 'pt',
    PtBr: 'pt-BR',
    Es: 'es',
    Hi: 'hi',
    HiLatn: 'hi-Latn',
    Es419: 'es-419'
} as const;

export type KnAiAgentLanguageEnum = typeof KnAiAgentLanguageEnum[keyof typeof KnAiAgentLanguageEnum];
export const KnAiAgentTransferEnum = {
    Default: 'DEFAULT',
    Sales: 'SALES',
    Billing: 'BILLING',
    Support: 'SUPPORT'
} as const;

export type KnAiAgentTransferEnum = typeof KnAiAgentTransferEnum[keyof typeof KnAiAgentTransferEnum];

/**
 * 
 * @export
 * @interface KnAiAgentRequest
 */
export interface KnAiAgentRequest {
    /**
     * 
     * @type {string}
     * @memberof KnAiAgentRequest
     */
    'agent_label'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgentRequest
     */
    'agent_phone_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgentRequest
     */
    'booking_mode'?: KnAiAgentRequestBookingModeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof KnAiAgentRequest
     */
    'booking_with'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgentRequest
     */
    'call_direction'?: KnAiAgentRequestCallDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgentRequest
     */
    'call_flow'?: KnAiAgentRequestCallFlowEnum;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgentRequest
     */
    'call_flow_prompt'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgentRequest
     */
    'call_flow_prompt_qualify'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgentRequest
     */
    'call_to_action'?: KnAiAgentRequestCallToActionEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof KnAiAgentRequest
     */
    'call_to_action_2'?: Array<KnAiAgentRequestCallToAction2Enum>;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgentRequest
     */
    'catch_webhook'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnAiAgentRequest
     */
    'creativity'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof KnAiAgentRequest
     */
    'double_call'?: boolean;
    // /**
    //  * 
    //  * @type {string}
    //  * @memberof KnAiAgentRequest
    //  */
    // 'hook_statement'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnAiAgentRequest
     */
    'interrupt'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgentRequest
     */
    'knowledge_base_faq_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgentRequest
     */
    'knowledge_base_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgentRequest
     */
    'language'?: KnAiAgentRequestLanguageEnum;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgentRequest
     */
    'transfer'?: KnAiAgentRequestTransferEnum;
    /**
     * 
     * @type {Array<KnAiAgentTransferNumberRequest>}
     * @memberof KnAiAgentRequest
     */
    'transfer_numbers'?: Array<KnAiAgentTransferNumberRequest>;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgentRequest
     */
    'transfer_phrase'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgentRequest
     */
    'voice_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnAiAgentRequest
     */
    'buffer_time'?: number;
    /**
     * 
     * @type {Array<KnMeetingOptionsBusinessHours>}
     * @memberof KnAiAgentRequest
     */
    'business_hours'?: Array<KnMeetingOptionsBusinessHours>;
    /**
     * 
     * @type {number}
     * @memberof KnAiAgentRequest
     */
    'meeting_duration'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgentRequest
     */
    'meeting_title'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgentRequest
     */
    'meeting_description'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnAiAgentRequest
     */
    'notice_time'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgentRequest
     */
    'time_zone'?: string;
}

export const KnAiAgentRequestBookingModeEnum = {
    RoundRobin: 'ROUND_ROBIN',
    OneToOne: 'ONE_TO_ONE'
} as const;

export type KnAiAgentRequestBookingModeEnum = typeof KnAiAgentRequestBookingModeEnum[keyof typeof KnAiAgentRequestBookingModeEnum];
export const KnAiAgentRequestCallDirectionEnum = {
    Inbound: 'INBOUND',
    Outbound: 'OUTBOUND'
} as const;

export type KnAiAgentRequestCallDirectionEnum = typeof KnAiAgentRequestCallDirectionEnum[keyof typeof KnAiAgentRequestCallDirectionEnum];
export const KnAiAgentRequestCallFlowEnum = {
    QualifyAndBookAMeeting: 'QUALIFY_AND_BOOK_A_MEETING',
    BookAMeeting: 'BOOK_A_MEETING',
    QualifyAndLiveTransfer: 'QUALIFY_AND_LIVE_TRANSFER',
    AnswerFaqs: 'ANSWER_FAQS',
    ConfirmAMeeting: 'CONFIRM_A_MEETING',
    PromptAndQualify: 'PROMPT_AND_QUALIFY',
    Prompt: 'PROMPT',
    Script: 'SCRIPT',
    PromptAndQualifyV1: 'PROMPT_AND_QUALIFY_V1',
    PromptV1: 'PROMPT_V1',
    ScriptV1: 'SCRIPT_V1',
    Sales: 'SALES',
    TierOneSupport: 'TIER_ONE_SUPPORT',
    DiscoveryTransfer: 'DISCOVERY_TRANSFER'
} as const;

export type KnAiAgentRequestCallFlowEnum = typeof KnAiAgentRequestCallFlowEnum[keyof typeof KnAiAgentRequestCallFlowEnum];
export const KnAiAgentRequestCallToActionEnum = {
    BookMeeting: 'BOOK_MEETING',
    CreateTicket: 'CREATE_TICKET',
    LiveTransfer: 'LIVE_TRANSFER',
    None: 'NONE'
} as const;

export type KnAiAgentRequestCallToActionEnum = typeof KnAiAgentRequestCallToActionEnum[keyof typeof KnAiAgentRequestCallToActionEnum];
export const KnAiAgentRequestCallToAction2Enum = {
    BookMeeting: 'BOOK_MEETING',
    CreateTicket: 'CREATE_TICKET',
    LiveTransfer: 'LIVE_TRANSFER'
} as const;

export type KnAiAgentRequestCallToAction2Enum = typeof KnAiAgentRequestCallToAction2Enum[keyof typeof KnAiAgentRequestCallToAction2Enum];
export const KnAiAgentRequestLanguageEnum = {
    EnUs: 'en-US',
    EnAu: 'en-AU',
    EnIn: 'en-IN',
    EnGb: 'en-GB',
    Fr: 'fr',
    FrCa: 'fr-CA',
    Nl: 'nl',
    It: 'it',
    Zh: 'zh',
    Pt: 'pt',
    PtBr: 'pt-BR',
    Es: 'es',
    Hi: 'hi',
    HiLatn: 'hi-Latn',
    Es419: 'es-419'
} as const;

export type KnAiAgentRequestLanguageEnum = typeof KnAiAgentRequestLanguageEnum[keyof typeof KnAiAgentRequestLanguageEnum];
export const KnAiAgentRequestTransferEnum = {
    Default: 'DEFAULT',
    Sales: 'SALES',
    Billing: 'BILLING',
    Support: 'SUPPORT'
} as const;

export type KnAiAgentRequestTransferEnum = typeof KnAiAgentRequestTransferEnum[keyof typeof KnAiAgentRequestTransferEnum];

/**
 * 
 * @export
 * @interface KnAiAgentTransferNumber
 */
export interface KnAiAgentTransferNumber {
    /**
     * 
     * @type {string}
     * @memberof KnAiAgentTransferNumber
     */
    'transfer_number_library_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof KnAiAgentTransferNumber
     */
    'is_default'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgentTransferNumber
     */
    'time_zone'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof KnAiAgentTransferNumber
     */
    'is_user'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgentTransferNumber
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgentTransferNumber
     */
    'number'?: string;
    /**
     * 
     * @type {Array<KnTransferNumberHours>}
     * @memberof KnAiAgentTransferNumber
     */
    'transfer_number_hours'?: Array<KnTransferNumberHours>;
}
/**
 * 
 * @export
 * @interface KnAiAgentTransferNumberRequest
 */
export interface KnAiAgentTransferNumberRequest {
    /**
     * 
     * @type {string}
     * @memberof KnAiAgentTransferNumberRequest
     */
    'transfer_number_library_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof KnAiAgentTransferNumberRequest
     */
    'is_default'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgentTransferNumberRequest
     */
    'time_zone'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgentTransferNumberRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnAiAgentTransferNumberRequest
     */
    'number'?: string;
    /**
     * 
     * @type {Array<KnTransferNumberHours>}
     * @memberof KnAiAgentTransferNumberRequest
     */
    'transfer_number_hours'?: Array<KnTransferNumberHours>;
}
/**
 * 
 * @export
 * @interface KnAuthorizeUrlRequest
 */
export interface KnAuthorizeUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof KnAuthorizeUrlRequest
     */
    'redirectUri': string;
}
/**
 * 
 * @export
 * @interface KnBillingMinutesInfoResponse
 */
export interface KnBillingMinutesInfoResponse {
    /**
     * Minutes remaining
     * @type {number}
     * @memberof KnBillingMinutesInfoResponse
     */
    'minutesRemaining'?: number;
    /**
     * Total minutes
     * @type {number}
     * @memberof KnBillingMinutesInfoResponse
     */
    'minutesTotal'?: number;
}
/**
 * 
 * @export
 * @interface KnBillingSmsInfoResponse
 */
export interface KnBillingSmsInfoResponse {
    /**
     * Sms remaining
     * @type {number}
     * @memberof KnBillingSmsInfoResponse
     */
    'smsRemaining'?: number;
    /**
     * Total sms
     * @type {number}
     * @memberof KnBillingSmsInfoResponse
     */
    'smsTotal'?: number;
}
/**
 * 
 * @export
 * @interface KnBqCategoryKeywordSearchRequest
 */
export interface KnBqCategoryKeywordSearchRequest {
    /**
     * 
     * @type {number}
     * @memberof KnBqCategoryKeywordSearchRequest
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof KnBqCategoryKeywordSearchRequest
     */
    'pageSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnBqCategoryKeywordSearchRequest
     */
    'searchTerm'?: string;
}
/**
 * 
 * @export
 * @interface KnBqCategoryKeywordSearchResponseKnAddress
 */
export interface KnBqCategoryKeywordSearchResponseKnAddress {
    /**
     * 
     * @type {boolean}
     * @memberof KnBqCategoryKeywordSearchResponseKnAddress
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnBqCategoryKeywordSearchResponseKnAddress
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnAddress2>}
     * @memberof KnBqCategoryKeywordSearchResponseKnAddress
     */
    'results'?: Array<KnAddress2>;
}
/**
 * 
 * @export
 * @interface KnBqCategoryKeywordSearchResponseString
 */
export interface KnBqCategoryKeywordSearchResponseString {
    /**
     * 
     * @type {boolean}
     * @memberof KnBqCategoryKeywordSearchResponseString
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnBqCategoryKeywordSearchResponseString
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof KnBqCategoryKeywordSearchResponseString
     */
    'results'?: Array<string>;
}
/**
 * 
 * @export
 * @interface KnBqCategoryPageRequest
 */
export interface KnBqCategoryPageRequest {
    /**
     * 
     * @type {number}
     * @memberof KnBqCategoryPageRequest
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof KnBqCategoryPageRequest
     */
    'pageSize'?: number;
}
/**
 * 
 * @export
 * @interface KnBqCategoryPageResponseKnAddress
 */
export interface KnBqCategoryPageResponseKnAddress {
    /**
     * 
     * @type {boolean}
     * @memberof KnBqCategoryPageResponseKnAddress
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnBqCategoryPageResponseKnAddress
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnAddress2>}
     * @memberof KnBqCategoryPageResponseKnAddress
     */
    'results'?: Array<KnAddress2>;
}
/**
 * 
 * @export
 * @interface KnBqCategoryPageResponseString
 */
export interface KnBqCategoryPageResponseString {
    /**
     * 
     * @type {boolean}
     * @memberof KnBqCategoryPageResponseString
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnBqCategoryPageResponseString
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof KnBqCategoryPageResponseString
     */
    'results'?: Array<string>;
}
/**
 * 
 * @export
 * @interface KnBqCompany
 */
export interface KnBqCompany {
    /**
     * 
     * @type {string}
     * @memberof KnBqCompany
     */
    'address1'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqCompany
     */
    'address2'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqCompany
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqCompany
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqCompany
     */
    'employeeCount'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqCompany
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqCompany
     */
    'industry'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqCompany
     */
    'linkedinUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqCompany
     */
    'logoUri'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqCompany
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqCompany
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqCompany
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqCompany
     */
    'revenue'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqCompany
     */
    'state'?: string;
}
/**
 * 
 * @export
 * @interface KnBqContact
 */
export interface KnBqContact {
    /**
     * 
     * @type {string}
     * @memberof KnBqContact
     */
    'companyDomain'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqContact
     */
    'corporateAddress1'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqContact
     */
    'corporateAddress2'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqContact
     */
    'corporateCity'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqContact
     */
    'corporateEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqContact
     */
    'corporatePhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqContact
     */
    'corporatePhoneExtension'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqContact
     */
    'corporatePostalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqContact
     */
    'corporateState'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqContact
     */
    'department'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqContact
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqContact
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqContact
     */
    'jobTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqContact
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqContact
     */
    'linkedinUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqContact
     */
    'personalAddress1'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqContact
     */
    'personalAddress2'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqContact
     */
    'personalCity'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqContact
     */
    'personalEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqContact
     */
    'personalPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqContact
     */
    'personalPostalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqContact
     */
    'personalState'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqContact
     */
    'seniorityLevel'?: string;
}
/**
 * 
 * @export
 * @interface KnBqContactRequest
 */
export interface KnBqContactRequest {
    /**
     * 
     * @type {KnBqContact}
     * @memberof KnBqContactRequest
     */
    'contact'?: KnBqContact;
}
/**
 * 
 * @export
 * @interface KnBqFilter
 */
export interface KnBqFilter {
    /**
     * 
     * @type {Array<KnBqFilterOption>}
     * @memberof KnBqFilter
     */
    'options'?: Array<KnBqFilterOption>;
    /**
     * 
     * @type {string}
     * @memberof KnBqFilter
     */
    'property'?: string;
}
/**
 * 
 * @export
 * @interface KnBqFilterOption
 */
export interface KnBqFilterOption {
    /**
     * 
     * @type {string}
     * @memberof KnBqFilterOption
     */
    'operator'?: KnBqFilterOptionOperatorEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof KnBqFilterOption
     */
    'values'?: Array<string>;
}

export const KnBqFilterOptionOperatorEnum = {
    Between: 'between',
    Equal: 'equal',
    NotEqual: 'not_equal',
    GreaterThan: 'greater_than',
    GreaterThanOrEq: 'greater_than_or_eq',
    LessThan: 'less_than',
    LessThanOrEq: 'less_than_or_eq'
} as const;

export type KnBqFilterOptionOperatorEnum = typeof KnBqFilterOptionOperatorEnum[keyof typeof KnBqFilterOptionOperatorEnum];

/**
 * 
 * @export
 * @interface KnBqKeywordSearchTermRequest
 */
export interface KnBqKeywordSearchTermRequest {
    /**
     * 
     * @type {number}
     * @memberof KnBqKeywordSearchTermRequest
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof KnBqKeywordSearchTermRequest
     */
    'pageSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnBqKeywordSearchTermRequest
     */
    'searchTerm'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqKeywordSearchTermRequest
     */
    'sortBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqKeywordSearchTermRequest
     */
    'sortDirection'?: KnBqKeywordSearchTermRequestSortDirectionEnum;
}

export const KnBqKeywordSearchTermRequestSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;

export type KnBqKeywordSearchTermRequestSortDirectionEnum = typeof KnBqKeywordSearchTermRequestSortDirectionEnum[keyof typeof KnBqKeywordSearchTermRequestSortDirectionEnum];

/**
 * 
 * @export
 * @interface KnBqKeywordSearchTermResponseKnBqCompany
 */
export interface KnBqKeywordSearchTermResponseKnBqCompany {
    /**
     * 
     * @type {boolean}
     * @memberof KnBqKeywordSearchTermResponseKnBqCompany
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnBqKeywordSearchTermResponseKnBqCompany
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnBqCompany>}
     * @memberof KnBqKeywordSearchTermResponseKnBqCompany
     */
    'results'?: Array<KnBqCompany>;
}
/**
 * 
 * @export
 * @interface KnBqKeywordSearchTermResponseKnBqContact
 */
export interface KnBqKeywordSearchTermResponseKnBqContact {
    /**
     * 
     * @type {boolean}
     * @memberof KnBqKeywordSearchTermResponseKnBqContact
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnBqKeywordSearchTermResponseKnBqContact
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnBqContact>}
     * @memberof KnBqKeywordSearchTermResponseKnBqContact
     */
    'results'?: Array<KnBqContact>;
}
/**
 * 
 * @export
 * @interface KnBqLookupRequest
 */
export interface KnBqLookupRequest {
    /**
     * 
     * @type {Array<KnBqFilter>}
     * @memberof KnBqLookupRequest
     */
    'filters'?: Array<KnBqFilter>;
    /**
     * 
     * @type {number}
     * @memberof KnBqLookupRequest
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof KnBqLookupRequest
     */
    'pageSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnBqLookupRequest
     */
    'sortBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnBqLookupRequest
     */
    'sortDirection'?: KnBqLookupRequestSortDirectionEnum;
}

export const KnBqLookupRequestSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;

export type KnBqLookupRequestSortDirectionEnum = typeof KnBqLookupRequestSortDirectionEnum[keyof typeof KnBqLookupRequestSortDirectionEnum];

/**
 * 
 * @export
 * @interface KnBqLookupResponseKnBqContact
 */
export interface KnBqLookupResponseKnBqContact {
    /**
     * 
     * @type {boolean}
     * @memberof KnBqLookupResponseKnBqContact
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnBqLookupResponseKnBqContact
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnBqContact>}
     * @memberof KnBqLookupResponseKnBqContact
     */
    'results'?: Array<KnBqContact>;
}
/**
 * 
 * @export
 * @interface KnBqLookupResponseKnCallMessage
 */
export interface KnBqLookupResponseKnCallMessage {
    /**
     * 
     * @type {boolean}
     * @memberof KnBqLookupResponseKnCallMessage
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnBqLookupResponseKnCallMessage
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnCallMessage>}
     * @memberof KnBqLookupResponseKnCallMessage
     */
    'results'?: Array<KnCallMessage>;
}
/**
 * 
 * @export
 * @interface KnCallMessage
 */
export interface KnCallMessage {
    /**
     * 
     * @type {string}
     * @memberof KnCallMessage
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCallMessage
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCallMessage
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCallMessage
     */
    'user'?: string;
}
/**
 * 
 * @export
 * @interface KnCompany
 */
export interface KnCompany {
    /**
     * 
     * @type {KnAddress2}
     * @memberof KnCompany
     */
    'address'?: KnAddress2;
    /**
     * 
     * @type {number}
     * @memberof KnCompany
     */
    'budgetAds'?: number;
    /**
     * 
     * @type {KnCompanyBudgetAdsCurrency}
     * @memberof KnCompany
     */
    'budgetAdsCurrency'?: KnCompanyBudgetAdsCurrency;
    /**
     * 
     * @type {number}
     * @memberof KnCompany
     */
    'budgetSolution'?: number;
    /**
     * 
     * @type {KnCompanyBudgetAdsCurrency}
     * @memberof KnCompany
     */
    'budgetSolutionCurrency'?: KnCompanyBudgetAdsCurrency;
    /**
     * 
     * @type {Timestamp2}
     * @memberof KnCompany
     */
    'createdAt'?: Timestamp2;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof KnCompany
     */
    'customDataFields'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof KnCompany
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompany
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompany
     */
    'headcount'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompany
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompany
     */
    'industry'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompany
     */
    'leadSource'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompany
     */
    'leadSourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompany
     */
    'linkedinUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompany
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompany
     */
    'ownerTeamId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompany
     */
    'ownerUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompany
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompany
     */
    'revenue'?: string;
    /**
     * 
     * @type {Timestamp2}
     * @memberof KnCompany
     */
    'updatedAt'?: Timestamp2;
}
/**
 * 
 * @export
 * @interface KnCompanyBudgetAdsCurrency
 */
export interface KnCompanyBudgetAdsCurrency {
    /**
     * 
     * @type {string}
     * @memberof KnCompanyBudgetAdsCurrency
     */
    'currencyCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnCompanyBudgetAdsCurrency
     */
    'defaultFractionDigits'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyBudgetAdsCurrency
     */
    'displayName'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnCompanyBudgetAdsCurrency
     */
    'numericCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyBudgetAdsCurrency
     */
    'numericCodeAsString'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyBudgetAdsCurrency
     */
    'symbol'?: string;
}
/**
 * 
 * @export
 * @interface KnCompanyFilter
 */
export interface KnCompanyFilter {
    /**
     * 
     * @type {string}
     * @memberof KnCompanyFilter
     */
    'headcount'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyFilter
     */
    'industry'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyFilter
     */
    'revenue'?: string;
}
/**
 * 
 * @export
 * @interface KnCompanyImportJob
 */
export interface KnCompanyImportJob {
    /**
     * 
     * @type {Timestamp2}
     * @memberof KnCompanyImportJob
     */
    'createdAt'?: Timestamp2;
    /**
     * 
     * @type {number}
     * @memberof KnCompanyImportJob
     */
    'createdDocumentResultCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof KnCompanyImportJob
     */
    'errorDocumentResultCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyImportJob
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyImportJob
     */
    'importJobName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyImportJob
     */
    'importJobType'?: KnCompanyImportJobImportJobTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyImportJob
     */
    'importStatus'?: KnCompanyImportJobImportStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyImportJob
     */
    'originalFileName'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnCompanyImportJob
     */
    'originalFileRowCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyImportJob
     */
    'ownerTeamId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyImportJob
     */
    'ownerUserId'?: string;
    /**
     * 
     * @type {Timestamp2}
     * @memberof KnCompanyImportJob
     */
    'updatedAt'?: Timestamp2;
    /**
     * 
     * @type {number}
     * @memberof KnCompanyImportJob
     */
    'updatedDocumentResultCount'?: number;
}

export const KnCompanyImportJobImportJobTypeEnum = {
    Company: 'company',
    Contact: 'contact',
    Product: 'product'
} as const;

export type KnCompanyImportJobImportJobTypeEnum = typeof KnCompanyImportJobImportJobTypeEnum[keyof typeof KnCompanyImportJobImportJobTypeEnum];
export const KnCompanyImportJobImportStatusEnum = {
    Unspecified: 'unspecified',
    FileUploaded: 'file_uploaded',
    Created: 'created',
    Processing: 'processing',
    Success: 'success',
    Failed: 'failed'
} as const;

export type KnCompanyImportJobImportStatusEnum = typeof KnCompanyImportJobImportStatusEnum[keyof typeof KnCompanyImportJobImportStatusEnum];

/**
 * 
 * @export
 * @interface KnCompanyImportJobRequest
 */
export interface KnCompanyImportJobRequest {
    /**
     * 
     * @type {number}
     * @memberof KnCompanyImportJobRequest
     */
    'createdDocumentResultCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof KnCompanyImportJobRequest
     */
    'errorDocumentResultCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyImportJobRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyImportJobRequest
     */
    'importJobName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyImportJobRequest
     */
    'importStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyImportJobRequest
     */
    'originalFileName'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnCompanyImportJobRequest
     */
    'originalFileRowCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyImportJobRequest
     */
    'ownerTeamId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyImportJobRequest
     */
    'ownerUserId'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnCompanyImportJobRequest
     */
    'updatedDocumentResultCount'?: number;
}
/**
 * 
 * @export
 * @interface KnCompanyRequest
 */
export interface KnCompanyRequest {
    /**
     * 
     * @type {KnAddress2}
     * @memberof KnCompanyRequest
     */
    'address'?: KnAddress2;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyRequest
     */
    'budgetAds'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyRequest
     */
    'budgetAdsCurrency'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyRequest
     */
    'budgetSolution'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyRequest
     */
    'budgetSolutionCurrency'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof KnCompanyRequest
     */
    'customDataFields'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof KnCompanyRequest
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyRequest
     */
    'headcount'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyRequest
     */
    'industry'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyRequest
     */
    'leadSource'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyRequest
     */
    'leadSourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyRequest
     */
    'linkedinUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyRequest
     */
    'ownerTeamId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyRequest
     */
    'ownerUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyRequest
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCompanyRequest
     */
    'revenue'?: string;
}
/**
 * 
 * @export
 * @interface KnConsentSource
 */
export interface KnConsentSource {
    /**
     * 
     * @type {Timestamp}
     * @memberof KnConsentSource
     */
    'consentGrantedTimestamp'?: Timestamp;
    /**
     * 
     * @type {Timestamp}
     * @memberof KnConsentSource
     */
    'consentRevokedTimestamp'?: Timestamp;
    /**
     * 
     * @type {string}
     * @memberof KnConsentSource
     */
    'declaredConsentOrigin'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnConsentSource
     */
    'level'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnConsentSource
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface KnConsentSource2
 */
export interface KnConsentSource2 {
    /**
     * 
     * @type {Timestamp2}
     * @memberof KnConsentSource2
     */
    'consentGrantedTimestamp'?: Timestamp2;
    /**
     * 
     * @type {Timestamp2}
     * @memberof KnConsentSource2
     */
    'consentRevokedTimestamp'?: Timestamp2;
    /**
     * 
     * @type {string}
     * @memberof KnConsentSource2
     */
    'declaredConsentOrigin'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnConsentSource2
     */
    'level'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnConsentSource2
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface KnContact
 */
export interface KnContact {
    /**
     * 
     * @type {KnAddress}
     * @memberof KnContact
     */
    'address'?: KnAddress;
    /**
     * 
     * @type {string}
     * @memberof KnContact
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact
     */
    'companyName'?: string;
    /**
     * 
     * @type {{ [key: string]: KnConsentSource; }}
     * @memberof KnContact
     */
    'consentSources'?: { [key: string]: KnConsentSource; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof KnContact
     */
    'customDataFields'?: { [key: string]: object; };
    /**
     * 
     * @type {Timestamp}
     * @memberof KnContact
     */
    'decisionTimeframe'?: Timestamp;
    /**
     * 
     * @type {string}
     * @memberof KnContact
     */
    'department'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact
     */
    'firstName'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnContact
     */
    'heatScore'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnContact
     */
    'heatScoreLevel'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact
     */
    'jobTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact
     */
    'leadSource'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact
     */
    'leadSourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact
     */
    'leadType'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact
     */
    'linkedinUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact
     */
    'ownerTeamId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact
     */
    'ownerUserId'?: string;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof KnContact
     */
    'painPointMapped'?: Array<boolean>;
    /**
     * 
     * @type {Array<string>}
     * @memberof KnContact
     */
    'painPoints'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof KnContact
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact
     */
    'seniorityLevel'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact
     */
    'timezone'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact
     */
    'timezoneSource'?: KnContactTimezoneSourceEnum;
}

export const KnContactTimezoneSourceEnum = {
    Unspecified: 'unspecified',
    ProspectContactAddress: 'prospect_contact_address',
    ProspectContactCompanyAddress: 'prospect_contact_company_address',
    ProspectContactPhoneAreaCode: 'prospect_contact_phone_area_code',
    ProspectFinderCompanyAddress: 'prospect_finder_company_address',
    ProspectFinderContactPersonalAddress: 'prospect_finder_contact_personal_address',
    ProspectFinderContactCorporateAddress: 'prospect_finder_contact_corporate_address',
    Ui: 'ui'
} as const;

export type KnContactTimezoneSourceEnum = typeof KnContactTimezoneSourceEnum[keyof typeof KnContactTimezoneSourceEnum];

/**
 * 
 * @export
 * @interface KnContact2
 */
export interface KnContact2 {
    /**
     * 
     * @type {KnAddress2}
     * @memberof KnContact2
     */
    'address'?: KnAddress2;
    /**
     * 
     * @type {string}
     * @memberof KnContact2
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact2
     */
    'companyName'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof KnContact2
     */
    'customDataFields'?: { [key: string]: object; };
    /**
     * 
     * @type {Timestamp2}
     * @memberof KnContact2
     */
    'decisionTimeframe'?: Timestamp2;
    /**
     * 
     * @type {string}
     * @memberof KnContact2
     */
    'department'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact2
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact2
     */
    'firstName'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnContact2
     */
    'heatScore'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnContact2
     */
    'heatScoreLevel'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact2
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact2
     */
    'jobTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact2
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact2
     */
    'leadSource'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact2
     */
    'leadSourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact2
     */
    'leadType'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact2
     */
    'linkedinUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact2
     */
    'ownerTeamId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact2
     */
    'ownerUserId'?: string;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof KnContact2
     */
    'painPointMapped'?: Array<boolean>;
    /**
     * 
     * @type {Array<string>}
     * @memberof KnContact2
     */
    'painPoints'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof KnContact2
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact2
     */
    'seniorityLevel'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact2
     */
    'timezone'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContact2
     */
    'timezoneSource'?: KnContact2TimezoneSourceEnum;
}

export const KnContact2TimezoneSourceEnum = {
    Unspecified: 'unspecified',
    ProspectContactAddress: 'prospect_contact_address',
    ProspectContactCompanyAddress: 'prospect_contact_company_address',
    ProspectContactPhoneAreaCode: 'prospect_contact_phone_area_code',
    ProspectFinderCompanyAddress: 'prospect_finder_company_address',
    ProspectFinderContactPersonalAddress: 'prospect_finder_contact_personal_address',
    ProspectFinderContactCorporateAddress: 'prospect_finder_contact_corporate_address',
    Ui: 'ui'
} as const;

export type KnContact2TimezoneSourceEnum = typeof KnContact2TimezoneSourceEnum[keyof typeof KnContact2TimezoneSourceEnum];

/**
 * 
 * @export
 * @interface KnContactFilter
 */
export interface KnContactFilter {
    /**
     * 
     * @type {string}
     * @memberof KnContactFilter
     */
    'firstName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof KnContactFilter
     */
    'hasEmail'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnContactFilter
     */
    'hasPhoneNumber'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof KnContactFilter
     */
    'jobTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContactFilter
     */
    'lastName'?: string;
}
/**
 * 
 * @export
 * @interface KnContactIdListRequest
 */
export interface KnContactIdListRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof KnContactIdListRequest
     */
    'contactIdList'?: Array<string>;
}
/**
 * 
 * @export
 * @interface KnContactImportJob
 */
export interface KnContactImportJob {
    /**
     * 
     * @type {string}
     * @memberof KnContactImportJob
     */
    'consentLevel'?: KnContactImportJobConsentLevelEnum;
    /**
     * 
     * @type {Timestamp2}
     * @memberof KnContactImportJob
     */
    'createdAt'?: Timestamp2;
    /**
     * 
     * @type {number}
     * @memberof KnContactImportJob
     */
    'createdDocumentResultCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnContactImportJob
     */
    'declaredConsentOriginSource'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnContactImportJob
     */
    'errorDocumentResultCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnContactImportJob
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContactImportJob
     */
    'importJobName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContactImportJob
     */
    'importJobType'?: KnContactImportJobImportJobTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof KnContactImportJob
     */
    'importStatus'?: KnContactImportJobImportStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof KnContactImportJob
     */
    'leadSource'?: KnContactImportJobLeadSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof KnContactImportJob
     */
    'leadType'?: KnContactImportJobLeadTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof KnContactImportJob
     */
    'originalFileName'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnContactImportJob
     */
    'originalFileRowCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnContactImportJob
     */
    'ownerTeamId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContactImportJob
     */
    'ownerUserId'?: string;
    /**
     * 
     * @type {Timestamp2}
     * @memberof KnContactImportJob
     */
    'updatedAt'?: Timestamp2;
    /**
     * 
     * @type {number}
     * @memberof KnContactImportJob
     */
    'updatedDocumentResultCount'?: number;
}

export const KnContactImportJobConsentLevelEnum = {
    None: 'none',
    Lvl1: 'lvl_1',
    Lvl2: 'lvl_2'
} as const;

export type KnContactImportJobConsentLevelEnum = typeof KnContactImportJobConsentLevelEnum[keyof typeof KnContactImportJobConsentLevelEnum];
export const KnContactImportJobImportJobTypeEnum = {
    Company: 'company',
    Contact: 'contact',
    Product: 'product'
} as const;

export type KnContactImportJobImportJobTypeEnum = typeof KnContactImportJobImportJobTypeEnum[keyof typeof KnContactImportJobImportJobTypeEnum];
export const KnContactImportJobImportStatusEnum = {
    Unspecified: 'unspecified',
    FileUploaded: 'file_uploaded',
    Created: 'created',
    Processing: 'processing',
    Success: 'success',
    Failed: 'failed'
} as const;

export type KnContactImportJobImportStatusEnum = typeof KnContactImportJobImportStatusEnum[keyof typeof KnContactImportJobImportStatusEnum];
export const KnContactImportJobLeadSourceEnum = {
    Unspecified: 'unspecified',
    ProspectFinder: 'prospect_finder',
    CrmHubspot: 'crm_hubspot',
    CrmSalesforce: 'crm_salesforce',
    InboundGoogleForms: 'inbound_google_forms',
    InboundFacebookLeadAds: 'inbound_facebook_lead_ads',
    InboundWebhook: 'inbound_webhook',
    InboundWebhookZapier: 'inbound_webhook_zapier',
    DataImportCsv: 'data_import_csv',
    DataImportIdk: 'data_import_idk',
    Ui: 'ui'
} as const;

export type KnContactImportJobLeadSourceEnum = typeof KnContactImportJobLeadSourceEnum[keyof typeof KnContactImportJobLeadSourceEnum];
export const KnContactImportJobLeadTypeEnum = {
    Unspecified: 'unspecified',
    Inbound: 'inbound',
    Outbound: 'outbound'
} as const;

export type KnContactImportJobLeadTypeEnum = typeof KnContactImportJobLeadTypeEnum[keyof typeof KnContactImportJobLeadTypeEnum];

/**
 * 
 * @export
 * @interface KnContactImportJobRequest
 */
export interface KnContactImportJobRequest {
    /**
     * 
     * @type {string}
     * @memberof KnContactImportJobRequest
     */
    'consentLevel'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnContactImportJobRequest
     */
    'createdDocumentResultCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnContactImportJobRequest
     */
    'declaredConsentOriginSource'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnContactImportJobRequest
     */
    'errorDocumentResultCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnContactImportJobRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContactImportJobRequest
     */
    'importJobName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContactImportJobRequest
     */
    'importStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContactImportJobRequest
     */
    'leadSource'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContactImportJobRequest
     */
    'leadType'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContactImportJobRequest
     */
    'originalFileName'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnContactImportJobRequest
     */
    'originalFileRowCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnContactImportJobRequest
     */
    'ownerTeamId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContactImportJobRequest
     */
    'ownerUserId'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnContactImportJobRequest
     */
    'updatedDocumentResultCount'?: number;
}
/**
 * 
 * @export
 * @interface KnContactLastNameSearchRequest
 */
export interface KnContactLastNameSearchRequest {
    /**
     * 
     * @type {string}
     * @memberof KnContactLastNameSearchRequest
     */
    'lastName'?: string;
}
/**
 * 
 * @export
 * @interface KnContactLastNameSearchResponse
 */
export interface KnContactLastNameSearchResponse {
    /**
     * 
     * @type {Array<KnContact2>}
     * @memberof KnContactLastNameSearchResponse
     */
    'results'?: Array<KnContact2>;
}
/**
 * 
 * @export
 * @interface KnContactRequest
 */
export interface KnContactRequest {
    /**
     * 
     * @type {KnAddress2}
     * @memberof KnContactRequest
     */
    'address'?: KnAddress2;
    /**
     * 
     * @type {string}
     * @memberof KnContactRequest
     */
    'companyId'?: string;
    /**
     * 
     * @type {{ [key: string]: KnConsentSource2; }}
     * @memberof KnContactRequest
     */
    'consentSources'?: { [key: string]: KnConsentSource2; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof KnContactRequest
     */
    'customDataFields'?: { [key: string]: object; };
    /**
     * 
     * @type {Timestamp2}
     * @memberof KnContactRequest
     */
    'decisionTimeframe'?: Timestamp2;
    /**
     * 
     * @type {string}
     * @memberof KnContactRequest
     */
    'department'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContactRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContactRequest
     */
    'firstName'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnContactRequest
     */
    'heatScore'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnContactRequest
     */
    'heatScoreLevel'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContactRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContactRequest
     */
    'jobTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContactRequest
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContactRequest
     */
    'leadSource'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContactRequest
     */
    'leadSourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContactRequest
     */
    'leadType'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContactRequest
     */
    'linkedinUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContactRequest
     */
    'ownerTeamId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContactRequest
     */
    'ownerUserId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof KnContactRequest
     */
    'painPoints'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof KnContactRequest
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContactRequest
     */
    'seniorityLevel'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContactRequest
     */
    'timezone'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContactRequest
     */
    'timezoneSource'?: string;
}
/**
 * 
 * @export
 * @interface KnContactUpdate
 */
export interface KnContactUpdate {
    /**
     * 
     * @type {string}
     * @memberof KnContactUpdate
     */
    'contact_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContactUpdate
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContactUpdate
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnContactUpdate
     */
    'value_unit'?: string;
}
/**
 * 
 * @export
 * @interface KnCreateCompanyImportJobRequest
 */
export interface KnCreateCompanyImportJobRequest {
    /**
     * 
     * @type {string}
     * @memberof KnCreateCompanyImportJobRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateCompanyImportJobRequest
     */
    'importJobName'?: string;
}
/**
 * 
 * @export
 * @interface KnCreateCompanyRequest
 */
export interface KnCreateCompanyRequest {
    /**
     * 
     * @type {string}
     * @memberof KnCreateCompanyRequest
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateCompanyRequest
     */
    'headcount'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateCompanyRequest
     */
    'industry'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateCompanyRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateCompanyRequest
     */
    'revenue'?: string;
}
/**
 * 
 * @export
 * @interface KnCreateContactImportJobRequest
 */
export interface KnCreateContactImportJobRequest {
    /**
     * 
     * @type {string}
     * @memberof KnCreateContactImportJobRequest
     */
    'consentLevel'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateContactImportJobRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateContactImportJobRequest
     */
    'importJobName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateContactImportJobRequest
     */
    'leadSource'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateContactImportJobRequest
     */
    'leadType'?: string;
}
/**
 * 
 * @export
 * @interface KnCreateContactRequest
 */
export interface KnCreateContactRequest {
    /**
     * 
     * @type {KnAddress2}
     * @memberof KnCreateContactRequest
     */
    'address'?: KnAddress2;
    /**
     * 
     * @type {string}
     * @memberof KnCreateContactRequest
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateContactRequest
     */
    'consentLevel'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateContactRequest
     */
    'consentStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateContactRequest
     */
    'declaredConsentOrigin'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateContactRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateContactRequest
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateContactRequest
     */
    'jobTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateContactRequest
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateContactRequest
     */
    'leadSource'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateContactRequest
     */
    'leadType'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateContactRequest
     */
    'linkedinUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateContactRequest
     */
    'phoneNumber'?: string;
}
/**
 * 
 * @export
 * @interface KnCreateDataImportCompanyRequest
 */
export interface KnCreateDataImportCompanyRequest {
    /**
     * 
     * @type {KnAddress2}
     * @memberof KnCreateDataImportCompanyRequest
     */
    'address'?: KnAddress2;
    /**
     * 
     * @type {number}
     * @memberof KnCreateDataImportCompanyRequest
     */
    'budgetAds'?: number;
    /**
     * 
     * @type {KnCompanyBudgetAdsCurrency}
     * @memberof KnCreateDataImportCompanyRequest
     */
    'budgetAdsCurrency'?: KnCompanyBudgetAdsCurrency;
    /**
     * 
     * @type {number}
     * @memberof KnCreateDataImportCompanyRequest
     */
    'budgetSolution'?: number;
    /**
     * 
     * @type {KnCompanyBudgetAdsCurrency}
     * @memberof KnCreateDataImportCompanyRequest
     */
    'budgetSolutionCurrency'?: KnCompanyBudgetAdsCurrency;
    /**
     * 
     * @type {string}
     * @memberof KnCreateDataImportCompanyRequest
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateDataImportCompanyRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateDataImportCompanyRequest
     */
    'headcount'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateDataImportCompanyRequest
     */
    'industry'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateDataImportCompanyRequest
     */
    'leadSource'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateDataImportCompanyRequest
     */
    'leadSourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateDataImportCompanyRequest
     */
    'linkedinUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateDataImportCompanyRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateDataImportCompanyRequest
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateDataImportCompanyRequest
     */
    'revenue'?: string;
}
/**
 * 
 * @export
 * @interface KnCreateDataImportContactRequest
 */
export interface KnCreateDataImportContactRequest {
    /**
     * 
     * @type {KnAddress2}
     * @memberof KnCreateDataImportContactRequest
     */
    'address'?: KnAddress2;
    /**
     * 
     * @type {string}
     * @memberof KnCreateDataImportContactRequest
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateDataImportContactRequest
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateDataImportContactRequest
     */
    'consentLevel'?: string;
    /**
     * 
     * @type {{ [key: string]: KnConsentSource2; }}
     * @memberof KnCreateDataImportContactRequest
     */
    'consentSources'?: { [key: string]: KnConsentSource2; };
    /**
     * 
     * @type {Timestamp2}
     * @memberof KnCreateDataImportContactRequest
     */
    'decisionTimeframe'?: Timestamp2;
    /**
     * 
     * @type {string}
     * @memberof KnCreateDataImportContactRequest
     */
    'declaredConsentOrigin'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateDataImportContactRequest
     */
    'department'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateDataImportContactRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateDataImportContactRequest
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateDataImportContactRequest
     */
    'jobTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateDataImportContactRequest
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateDataImportContactRequest
     */
    'leadSource'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateDataImportContactRequest
     */
    'leadSourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateDataImportContactRequest
     */
    'leadType'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateDataImportContactRequest
     */
    'linkedinUrl'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof KnCreateDataImportContactRequest
     */
    'painPoints'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof KnCreateDataImportContactRequest
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateDataImportContactRequest
     */
    'seniorityLevel'?: string;
}
/**
 * 
 * @export
 * @interface KnCreateImportJobResponse
 */
export interface KnCreateImportJobResponse {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KnCreateImportJobResponse
     */
    'firestoreDocumentFieldAndMatchedColumnHeaderMap'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof KnCreateImportJobResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateImportJobResponse
     */
    'importStatus'?: KnCreateImportJobResponseImportStatusEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KnCreateImportJobResponse
     */
    'originalFileColumnNameAndExampleDataMap'?: { [key: string]: string; };
}

export const KnCreateImportJobResponseImportStatusEnum = {
    Unspecified: 'unspecified',
    FileUploaded: 'file_uploaded',
    Created: 'created',
    Processing: 'processing',
    Success: 'success',
    Failed: 'failed'
} as const;

export type KnCreateImportJobResponseImportStatusEnum = typeof KnCreateImportJobResponseImportStatusEnum[keyof typeof KnCreateImportJobResponseImportStatusEnum];

/**
 * 
 * @export
 * @interface KnCreateProductImportJobRequest
 */
export interface KnCreateProductImportJobRequest {
    /**
     * 
     * @type {string}
     * @memberof KnCreateProductImportJobRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnCreateProductImportJobRequest
     */
    'importJobName'?: string;
}
/**
 * 
 * @export
 * @interface KnDateRangeFilterRequest
 */
export interface KnDateRangeFilterRequest {
    /**
     * 
     * @type {string}
     * @memberof KnDateRangeFilterRequest
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnDateRangeFilterRequest
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof KnDateRangeFilterRequest
     */
    'pageSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnDateRangeFilterRequest
     */
    'sortBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnDateRangeFilterRequest
     */
    'sortDirection'?: KnDateRangeFilterRequestSortDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof KnDateRangeFilterRequest
     */
    'startDate'?: string;
}

export const KnDateRangeFilterRequestSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;

export type KnDateRangeFilterRequestSortDirectionEnum = typeof KnDateRangeFilterRequestSortDirectionEnum[keyof typeof KnDateRangeFilterRequestSortDirectionEnum];

/**
 * 
 * @export
 * @interface KnDateRangeFilterResponseKnFirestoreImportJob
 */
export interface KnDateRangeFilterResponseKnFirestoreImportJob {
    /**
     * 
     * @type {boolean}
     * @memberof KnDateRangeFilterResponseKnFirestoreImportJob
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnDateRangeFilterResponseKnFirestoreImportJob
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnFirestoreImportJob>}
     * @memberof KnDateRangeFilterResponseKnFirestoreImportJob
     */
    'results'?: Array<KnFirestoreImportJob>;
    /**
     * 
     * @type {number}
     * @memberof KnDateRangeFilterResponseKnFirestoreImportJob
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface KnDeal
 */
export interface KnDeal {
    /**
     * Deal amount.
     * @type {number}
     * @memberof KnDeal
     */
    'amount'?: number;
    /**
     * 
     * @type {DealFlowUser}
     * @memberof KnDeal
     */
    'assignee'?: DealFlowUser;
    /**
     * The date when the deal passed to the final stage (either won or lost)
     * @type {string}
     * @memberof KnDeal
     */
    'closeDate'?: string;
    /**
     * Contact id list
     * @type {Array<string>}
     * @memberof KnDeal
     */
    'contactIdList'?: Array<string>;
    /**
     * The date and time when the deal was created
     * @type {string}
     * @memberof KnDeal
     */
    'createdAt'?: string;
    /**
     * User id from the creator
     * @type {string}
     * @memberof KnDeal
     */
    'createdBy'?: string;
    /**
     * Deal\'s id.
     * @type {string}
     * @memberof KnDeal
     */
    'id'?: string;
    /**
     * Should allow company timeline activity
     * @type {Array<KnDealLineItem>}
     * @memberof KnDeal
     */
    'lineItems'?: Array<KnDealLineItem>;
    /**
     * Deal name.
     * @type {string}
     * @memberof KnDeal
     */
    'name'?: string;
    /**
     * Pipeline Id where deal should be placed
     * @type {string}
     * @memberof KnDeal
     */
    'pipelineId'?: string;
    /**
     * 
     * @type {KnDealPipelineStage}
     * @memberof KnDeal
     */
    'pipelineStage'?: KnDealPipelineStage;
    /**
     * The date and time when the deal was last updated
     * @type {string}
     * @memberof KnDeal
     */
    'updatedAt'?: string;
    /**
     * User id from the last updater
     * @type {string}
     * @memberof KnDeal
     */
    'updatedBy'?: string;
}
/**
 * Should allow company timeline activity
 * @export
 * @interface KnDealLineItem
 */
export interface KnDealLineItem {
    /**
     * 
     * @type {string}
     * @memberof KnDealLineItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnDealLineItem
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnDealLineItem
     */
    'price'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnDealLineItem
     */
    'quantity'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnDealLineItem
     */
    'userId'?: string;
}
/**
 * 
 * @export
 * @interface KnDealPipelineStage
 */
export interface KnDealPipelineStage {
    /**
     * Pipeline stage\'s id.
     * @type {string}
     * @memberof KnDealPipelineStage
     */
    'id'?: string;
    /**
     * The name of the pipeline stage
     * @type {string}
     * @memberof KnDealPipelineStage
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface KnDocumentListResponseKnCompany
 */
export interface KnDocumentListResponseKnCompany {
    /**
     * 
     * @type {Array<KnCompany>}
     * @memberof KnDocumentListResponseKnCompany
     */
    'results'?: Array<KnCompany>;
}
/**
 * 
 * @export
 * @interface KnDocumentListResponseKnCompanyImportJob
 */
export interface KnDocumentListResponseKnCompanyImportJob {
    /**
     * 
     * @type {KnDocumentListResponseKnFirestoreImportJobResults}
     * @memberof KnDocumentListResponseKnCompanyImportJob
     */
    'results'?: KnDocumentListResponseKnFirestoreImportJobResults;
}
/**
 * 
 * @export
 * @interface KnDocumentListResponseKnContact
 */
export interface KnDocumentListResponseKnContact {
    /**
     * 
     * @type {Array<KnContact2>}
     * @memberof KnDocumentListResponseKnContact
     */
    'results'?: Array<KnContact2>;
}
/**
 * 
 * @export
 * @interface KnDocumentListResponseKnContactImportJob
 */
export interface KnDocumentListResponseKnContactImportJob {
    /**
     * 
     * @type {KnDocumentListResponseKnFirestoreImportJobResults}
     * @memberof KnDocumentListResponseKnContactImportJob
     */
    'results'?: KnDocumentListResponseKnFirestoreImportJobResults;
}
/**
 * 
 * @export
 * @interface KnDocumentListResponseKnFirestoreImportJob
 */
export interface KnDocumentListResponseKnFirestoreImportJob {
    /**
     * 
     * @type {KnDocumentListResponseKnFirestoreImportJobResults}
     * @memberof KnDocumentListResponseKnFirestoreImportJob
     */
    'results'?: KnDocumentListResponseKnFirestoreImportJobResults;
}
/**
 * 
 * @export
 * @interface KnDocumentListResponseKnFirestoreImportJobResults
 */
export interface KnDocumentListResponseKnFirestoreImportJobResults {
    /**
     * 
     * @type {boolean}
     * @memberof KnDocumentListResponseKnFirestoreImportJobResults
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface KnDocumentListResponseKnProductImportJob
 */
export interface KnDocumentListResponseKnProductImportJob {
    /**
     * 
     * @type {KnDocumentListResponseKnFirestoreImportJobResults}
     * @memberof KnDocumentListResponseKnProductImportJob
     */
    'results'?: KnDocumentListResponseKnFirestoreImportJobResults;
}
/**
 * 
 * @export
 * @interface KnDocumentListResponseKnTeamCompany
 */
export interface KnDocumentListResponseKnTeamCompany {
    /**
     * 
     * @type {Array<KnTeamCompany>}
     * @memberof KnDocumentListResponseKnTeamCompany
     */
    'results'?: Array<KnTeamCompany>;
}
/**
 * 
 * @export
 * @interface KnDocumentListResponseKnTeamContact
 */
export interface KnDocumentListResponseKnTeamContact {
    /**
     * 
     * @type {Array<KnTeamContact>}
     * @memberof KnDocumentListResponseKnTeamContact
     */
    'results'?: Array<KnTeamContact>;
}
/**
 * 
 * @export
 * @interface KnDocumentResponseKnCompany
 */
export interface KnDocumentResponseKnCompany {
    /**
     * 
     * @type {KnCompany}
     * @memberof KnDocumentResponseKnCompany
     */
    'result'?: KnCompany;
}
/**
 * 
 * @export
 * @interface KnDocumentResponseKnCompanyImportJob
 */
export interface KnDocumentResponseKnCompanyImportJob {
    /**
     * 
     * @type {KnCompanyImportJob}
     * @memberof KnDocumentResponseKnCompanyImportJob
     */
    'result'?: KnCompanyImportJob;
}
/**
 * 
 * @export
 * @interface KnDocumentResponseKnContact
 */
export interface KnDocumentResponseKnContact {
    /**
     * 
     * @type {KnContact2}
     * @memberof KnDocumentResponseKnContact
     */
    'result'?: KnContact2;
}
/**
 * 
 * @export
 * @interface KnDocumentResponseKnContactImportJob
 */
export interface KnDocumentResponseKnContactImportJob {
    /**
     * 
     * @type {KnContactImportJob}
     * @memberof KnDocumentResponseKnContactImportJob
     */
    'result'?: KnContactImportJob;
}
/**
 * 
 * @export
 * @interface KnDocumentResponseKnProductImportJob
 */
export interface KnDocumentResponseKnProductImportJob {
    /**
     * 
     * @type {KnProductImportJob}
     * @memberof KnDocumentResponseKnProductImportJob
     */
    'result'?: KnProductImportJob;
}
/**
 * 
 * @export
 * @interface KnDocumentResponseKnTeamCompany
 */
export interface KnDocumentResponseKnTeamCompany {
    /**
     * 
     * @type {KnTeamCompany}
     * @memberof KnDocumentResponseKnTeamCompany
     */
    'result'?: KnTeamCompany;
}
/**
 * 
 * @export
 * @interface KnDocumentResponseKnTeamContact
 */
export interface KnDocumentResponseKnTeamContact {
    /**
     * 
     * @type {KnTeamContact}
     * @memberof KnDocumentResponseKnTeamContact
     */
    'result'?: KnTeamContact;
}
/**
 * 
 * @export
 * @interface KnFaqCrawlUrlRequest
 */
export interface KnFaqCrawlUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof KnFaqCrawlUrlRequest
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface KnFaqCrawlUrlResponse
 */
export interface KnFaqCrawlUrlResponse {
    /**
     * 
     * @type {number}
     * @memberof KnFaqCrawlUrlResponse
     */
    'countQa'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnFaqCrawlUrlResponse
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface KnFilter
 */
export interface KnFilter {
    /**
     * 
     * @type {Array<KnFilterOption>}
     * @memberof KnFilter
     */
    'options'?: Array<KnFilterOption>;
    /**
     * 
     * @type {string}
     * @memberof KnFilter
     */
    'property'?: string;
}
/**
 * 
 * @export
 * @interface KnFilter2
 */
export interface KnFilter2 {
    /**
     * 
     * @type {Array<KnFilterOption2>}
     * @memberof KnFilter2
     */
    'options'?: Array<KnFilterOption2>;
    /**
     * 
     * @type {string}
     * @memberof KnFilter2
     */
    'property'?: string;
}
/**
 * List of filters to be applied
 * @export
 * @interface KnFilterDealProperties
 */
export interface KnFilterDealProperties {
    /**
     * 
     * @type {Array<KnFilterOption2>}
     * @memberof KnFilterDealProperties
     */
    'options'?: Array<KnFilterOption2>;
    /**
     * 
     * @type {string}
     * @memberof KnFilterDealProperties
     */
    'property'?: KnFilterDealPropertiesPropertyEnum;
}

export const KnFilterDealPropertiesPropertyEnum = {
    PipelineId: 'PIPELINE_ID',
    StageId: 'STAGE_ID',
    Name: 'NAME',
    AssigneeId: 'ASSIGNEE_ID',
    TeamId: 'TEAM_ID',
    ContactId: 'CONTACT_ID',
    CreatedAt: 'CREATED_AT',
    Amount: 'AMOUNT',
    ConsentLvl: 'CONSENT_LVL',
    IsDeleted: 'IS_DELETED'
} as const;

export type KnFilterDealPropertiesPropertyEnum = typeof KnFilterDealPropertiesPropertyEnum[keyof typeof KnFilterDealPropertiesPropertyEnum];

/**
 * 
 * @export
 * @interface KnFilterOption
 */
export interface KnFilterOption {
    /**
     * 
     * @type {string}
     * @memberof KnFilterOption
     */
    'operator'?: KnFilterOptionOperatorEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof KnFilterOption
     */
    'values'?: Array<string>;
}

export const KnFilterOptionOperatorEnum = {
    Between: 'between',
    Equal: 'equal',
    NotEqual: 'not_equal',
    GreaterThan: 'greater_than',
    GreaterThanOrEq: 'greater_than_or_eq',
    LessThan: 'less_than',
    LessThanOrEq: 'less_than_or_eq',
    Contains: 'contains'
} as const;

export type KnFilterOptionOperatorEnum = typeof KnFilterOptionOperatorEnum[keyof typeof KnFilterOptionOperatorEnum];

/**
 * 
 * @export
 * @interface KnFilterOption2
 */
export interface KnFilterOption2 {
    /**
     * 
     * @type {string}
     * @memberof KnFilterOption2
     */
    'operator'?: KnFilterOption2OperatorEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof KnFilterOption2
     */
    'values'?: Array<string>;
}

export const KnFilterOption2OperatorEnum = {
    Between: 'between',
    Equal: 'equal',
    NotEqual: 'not_equal',
    GreaterThan: 'greater_than',
    GreaterThanOrEq: 'greater_than_or_eq',
    LessThan: 'less_than',
    LessThanOrEq: 'less_than_or_eq',
    Contains: 'contains',
    StartsWith: 'starts_with'
} as const;

export type KnFilterOption2OperatorEnum = typeof KnFilterOption2OperatorEnum[keyof typeof KnFilterOption2OperatorEnum];

/**
 * 
 * @export
 * @interface KnFirestoreImportJob
 */
export interface KnFirestoreImportJob {
    /**
     * 
     * @type {Timestamp2}
     * @memberof KnFirestoreImportJob
     */
    'createdAt'?: Timestamp2;
    /**
     * 
     * @type {number}
     * @memberof KnFirestoreImportJob
     */
    'createdDocumentResultCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof KnFirestoreImportJob
     */
    'errorDocumentResultCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnFirestoreImportJob
     */
    'importJobName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnFirestoreImportJob
     */
    'importJobType'?: KnFirestoreImportJobImportJobTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof KnFirestoreImportJob
     */
    'importStatus'?: KnFirestoreImportJobImportStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof KnFirestoreImportJob
     */
    'originalFileName'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnFirestoreImportJob
     */
    'originalFileRowCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnFirestoreImportJob
     */
    'ownerTeamId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnFirestoreImportJob
     */
    'ownerUserId'?: string;
    /**
     * 
     * @type {Timestamp2}
     * @memberof KnFirestoreImportJob
     */
    'updatedAt'?: Timestamp2;
    /**
     * 
     * @type {number}
     * @memberof KnFirestoreImportJob
     */
    'updatedDocumentResultCount'?: number;
}

export const KnFirestoreImportJobImportJobTypeEnum = {
    Company: 'company',
    Contact: 'contact',
    Product: 'product'
} as const;

export type KnFirestoreImportJobImportJobTypeEnum = typeof KnFirestoreImportJobImportJobTypeEnum[keyof typeof KnFirestoreImportJobImportJobTypeEnum];
export const KnFirestoreImportJobImportStatusEnum = {
    Unspecified: 'unspecified',
    FileUploaded: 'file_uploaded',
    Created: 'created',
    Processing: 'processing',
    Success: 'success',
    Failed: 'failed'
} as const;

export type KnFirestoreImportJobImportStatusEnum = typeof KnFirestoreImportJobImportStatusEnum[keyof typeof KnFirestoreImportJobImportStatusEnum];

/**
 * 
 * @export
 * @interface KnFirestoreImportJobTypeResponse
 */
export interface KnFirestoreImportJobTypeResponse {
    /**
     * 
     * @type {KnDocumentListResponseKnFirestoreImportJobResults}
     * @memberof KnFirestoreImportJobTypeResponse
     */
    'results'?: KnDocumentListResponseKnFirestoreImportJobResults;
}
/**
 * 
 * @export
 * @interface KnFirestoreImportTypeRequest
 */
export interface KnFirestoreImportTypeRequest {
    /**
     * 
     * @type {string}
     * @memberof KnFirestoreImportTypeRequest
     */
    'importJobType'?: string;
}
/**
 * 
 * @export
 * @interface KnKeywordSearchRequest
 */
export interface KnKeywordSearchRequest {
    /**
     * 
     * @type {number}
     * @memberof KnKeywordSearchRequest
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof KnKeywordSearchRequest
     */
    'pageSize'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof KnKeywordSearchRequest
     */
    'searchTermList'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof KnKeywordSearchRequest
     */
    'sortBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnKeywordSearchRequest
     */
    'sortDirection'?: KnKeywordSearchRequestSortDirectionEnum;
}

export const KnKeywordSearchRequestSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;

export type KnKeywordSearchRequestSortDirectionEnum = typeof KnKeywordSearchRequestSortDirectionEnum[keyof typeof KnKeywordSearchRequestSortDirectionEnum];

/**
 * 
 * @export
 * @interface KnKeywordSearchRequest2
 */
export interface KnKeywordSearchRequest2 {
    /**
     * 
     * @type {number}
     * @memberof KnKeywordSearchRequest2
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof KnKeywordSearchRequest2
     */
    'pageSize'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof KnKeywordSearchRequest2
     */
    'searchTermList'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof KnKeywordSearchRequest2
     */
    'sortBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnKeywordSearchRequest2
     */
    'sortDirection'?: KnKeywordSearchRequest2SortDirectionEnum;
}

export const KnKeywordSearchRequest2SortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;

export type KnKeywordSearchRequest2SortDirectionEnum = typeof KnKeywordSearchRequest2SortDirectionEnum[keyof typeof KnKeywordSearchRequest2SortDirectionEnum];

/**
 * 
 * @export
 * @interface KnKeywordSearchResponseKnCompany
 */
export interface KnKeywordSearchResponseKnCompany {
    /**
     * 
     * @type {boolean}
     * @memberof KnKeywordSearchResponseKnCompany
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnKeywordSearchResponseKnCompany
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnCompany>}
     * @memberof KnKeywordSearchResponseKnCompany
     */
    'results'?: Array<KnCompany>;
    /**
     * 
     * @type {number}
     * @memberof KnKeywordSearchResponseKnCompany
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface KnKeywordSearchResponseKnCompanyImportJob
 */
export interface KnKeywordSearchResponseKnCompanyImportJob {
    /**
     * 
     * @type {boolean}
     * @memberof KnKeywordSearchResponseKnCompanyImportJob
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnKeywordSearchResponseKnCompanyImportJob
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {KnDocumentListResponseKnFirestoreImportJobResults}
     * @memberof KnKeywordSearchResponseKnCompanyImportJob
     */
    'results'?: KnDocumentListResponseKnFirestoreImportJobResults;
}
/**
 * 
 * @export
 * @interface KnKeywordSearchResponseKnContact
 */
export interface KnKeywordSearchResponseKnContact {
    /**
     * 
     * @type {boolean}
     * @memberof KnKeywordSearchResponseKnContact
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnKeywordSearchResponseKnContact
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnContact2>}
     * @memberof KnKeywordSearchResponseKnContact
     */
    'results'?: Array<KnContact2>;
    /**
     * 
     * @type {number}
     * @memberof KnKeywordSearchResponseKnContact
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface KnKeywordSearchResponseKnContactImportJob
 */
export interface KnKeywordSearchResponseKnContactImportJob {
    /**
     * 
     * @type {boolean}
     * @memberof KnKeywordSearchResponseKnContactImportJob
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnKeywordSearchResponseKnContactImportJob
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {KnDocumentListResponseKnFirestoreImportJobResults}
     * @memberof KnKeywordSearchResponseKnContactImportJob
     */
    'results'?: KnDocumentListResponseKnFirestoreImportJobResults;
}
/**
 * 
 * @export
 * @interface KnKeywordSearchResponseKnFirestoreImportJob
 */
export interface KnKeywordSearchResponseKnFirestoreImportJob {
    /**
     * 
     * @type {boolean}
     * @memberof KnKeywordSearchResponseKnFirestoreImportJob
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnKeywordSearchResponseKnFirestoreImportJob
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {KnDocumentListResponseKnFirestoreImportJobResults}
     * @memberof KnKeywordSearchResponseKnFirestoreImportJob
     */
    'results'?: KnDocumentListResponseKnFirestoreImportJobResults;
}
/**
 * 
 * @export
 * @interface KnKeywordSearchResponseKnProductImportJob
 */
export interface KnKeywordSearchResponseKnProductImportJob {
    /**
     * 
     * @type {boolean}
     * @memberof KnKeywordSearchResponseKnProductImportJob
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnKeywordSearchResponseKnProductImportJob
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {KnDocumentListResponseKnFirestoreImportJobResults}
     * @memberof KnKeywordSearchResponseKnProductImportJob
     */
    'results'?: KnDocumentListResponseKnFirestoreImportJobResults;
}
/**
 * 
 * @export
 * @interface KnKeywordSearchResponseKnTeamCompany
 */
export interface KnKeywordSearchResponseKnTeamCompany {
    /**
     * 
     * @type {boolean}
     * @memberof KnKeywordSearchResponseKnTeamCompany
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnKeywordSearchResponseKnTeamCompany
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnTeamCompany>}
     * @memberof KnKeywordSearchResponseKnTeamCompany
     */
    'results'?: Array<KnTeamCompany>;
    /**
     * 
     * @type {number}
     * @memberof KnKeywordSearchResponseKnTeamCompany
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface KnKeywordSearchResponseKnTeamContact
 */
export interface KnKeywordSearchResponseKnTeamContact {
    /**
     * 
     * @type {boolean}
     * @memberof KnKeywordSearchResponseKnTeamContact
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnKeywordSearchResponseKnTeamContact
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnTeamContact>}
     * @memberof KnKeywordSearchResponseKnTeamContact
     */
    'results'?: Array<KnTeamContact>;
    /**
     * 
     * @type {number}
     * @memberof KnKeywordSearchResponseKnTeamContact
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface KnLead
 */
export interface KnLead {
    /**
     * 
     * @type {LfContact}
     * @memberof KnLead
     */
    'contact'?: LfContact;
    /**
     * 
     * @type {string}
     * @memberof KnLead
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnLead
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnLead
     */
    'lead_state'?: KnLeadLeadStateEnum;
    /**
     * 
     * @type {string}
     * @memberof KnLead
     */
    'lead_state_lost_reason'?: KnLeadLeadStateLostReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof KnLead
     */
    'owner_team_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnLead
     */
    'owner_user_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnLead
     */
    'updated_at'?: string;
}

export const KnLeadLeadStateEnum = {
    Unspecified: 'LEAD_FLOW_STATE_UNSPECIFIED',
    NotEligible: 'LEAD_FLOW_STATE_NOT_ELIGIBLE',
    PendingConfig: 'LEAD_FLOW_STATE_PENDING_CONFIG',
    PendingQuota: 'LEAD_FLOW_STATE_PENDING_QUOTA',
    Qualifying: 'LEAD_FLOW_STATE_QUALIFYING',
    Booking: 'LEAD_FLOW_STATE_BOOKING',
    Booked: 'LEAD_FLOW_STATE_BOOKED',
    Lost: 'LEAD_FLOW_STATE_LOST'
} as const;

export type KnLeadLeadStateEnum = typeof KnLeadLeadStateEnum[keyof typeof KnLeadLeadStateEnum];
export const KnLeadLeadStateLostReasonEnum = {
    Unspecified: 'UNSPECIFIED',
    Unqualified: 'UNQUALIFIED',
    SmsOptOut: 'SMS_OPT_OUT',
    Stale: 'STALE'
} as const;

export type KnLeadLeadStateLostReasonEnum = typeof KnLeadLeadStateLostReasonEnum[keyof typeof KnLeadLeadStateLostReasonEnum];

/**
 * 
 * @export
 * @interface KnLeadSourceLookupRequest
 */
export interface KnLeadSourceLookupRequest {
    /**
     * 
     * @type {Array<KnFilter2>}
     * @memberof KnLeadSourceLookupRequest
     */
    'filters'?: Array<KnFilter2>;
    /**
     * 
     * @type {string}
     * @memberof KnLeadSourceLookupRequest
     */
    'leadSourceId'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnLeadSourceLookupRequest
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof KnLeadSourceLookupRequest
     */
    'pageSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnLeadSourceLookupRequest
     */
    'sortBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnLeadSourceLookupRequest
     */
    'sortDirection'?: KnLeadSourceLookupRequestSortDirectionEnum;
}

export const KnLeadSourceLookupRequestSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;

export type KnLeadSourceLookupRequestSortDirectionEnum = typeof KnLeadSourceLookupRequestSortDirectionEnum[keyof typeof KnLeadSourceLookupRequestSortDirectionEnum];

/**
 * 
 * @export
 * @interface KnLookupRequest
 */
export interface KnLookupRequest {
    /**
     * 
     * @type {Array<KnFilter>}
     * @memberof KnLookupRequest
     */
    'filters'?: Array<KnFilter>;
    /**
     * 
     * @type {number}
     * @memberof KnLookupRequest
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof KnLookupRequest
     */
    'pageSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnLookupRequest
     */
    'sortBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnLookupRequest
     */
    'sortDirection'?: KnLookupRequestSortDirectionEnum;
}

export const KnLookupRequestSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;

export type KnLookupRequestSortDirectionEnum = typeof KnLookupRequestSortDirectionEnum[keyof typeof KnLookupRequestSortDirectionEnum];

/**
 * 
 * @export
 * @interface KnLookupRequest2
 */
export interface KnLookupRequest2 {
    /**
     * 
     * @type {Array<KnFilter2>}
     * @memberof KnLookupRequest2
     */
    'filters'?: Array<KnFilter2>;
    /**
     * 
     * @type {number}
     * @memberof KnLookupRequest2
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof KnLookupRequest2
     */
    'pageSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnLookupRequest2
     */
    'sortBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnLookupRequest2
     */
    'sortDirection'?: KnLookupRequest2SortDirectionEnum;
}

export const KnLookupRequest2SortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;

export type KnLookupRequest2SortDirectionEnum = typeof KnLookupRequest2SortDirectionEnum[keyof typeof KnLookupRequest2SortDirectionEnum];

/**
 * 
 * @export
 * @interface KnLookupResponse
 */
export interface KnLookupResponse {
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponse
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnTeamContact>}
     * @memberof KnLookupResponse
     */
    'results'?: Array<KnTeamContact>;
    /**
     * 
     * @type {number}
     * @memberof KnLookupResponse
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface KnLookupResponse2
 */
export interface KnLookupResponse2 {
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponse2
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponse2
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnSequenceContactDTO>}
     * @memberof KnLookupResponse2
     */
    'results'?: Array<KnSequenceContactDTO>;
    /**
     * 
     * @type {number}
     * @memberof KnLookupResponse2
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface KnLookupResponseKnAiAgent
 */
export interface KnLookupResponseKnAiAgent {
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseKnAiAgent
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseKnAiAgent
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnAiAgent>}
     * @memberof KnLookupResponseKnAiAgent
     */
    'results'?: Array<KnAiAgent>;
    /**
     * 
     * @type {number}
     * @memberof KnLookupResponseKnAiAgent
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface KnLookupResponseKnCompany
 */
export interface KnLookupResponseKnCompany {
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseKnCompany
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseKnCompany
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnCompany>}
     * @memberof KnLookupResponseKnCompany
     */
    'results'?: Array<KnCompany>;
}
/**
 * 
 * @export
 * @interface KnLookupResponseKnCompanyImportJob
 */
export interface KnLookupResponseKnCompanyImportJob {
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseKnCompanyImportJob
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseKnCompanyImportJob
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnCompanyImportJob>}
     * @memberof KnLookupResponseKnCompanyImportJob
     */
    'results'?: Array<KnCompanyImportJob>;
}
/**
 * 
 * @export
 * @interface KnLookupResponseKnContact
 */
export interface KnLookupResponseKnContact {
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseKnContact
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseKnContact
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnContact2>}
     * @memberof KnLookupResponseKnContact
     */
    'results'?: Array<KnContact2>;
}
/**
 * 
 * @export
 * @interface KnLookupResponseKnContactImportJob
 */
export interface KnLookupResponseKnContactImportJob {
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseKnContactImportJob
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseKnContactImportJob
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnContactImportJob>}
     * @memberof KnLookupResponseKnContactImportJob
     */
    'results'?: Array<KnContactImportJob>;
}
/**
 * 
 * @export
 * @interface KnLookupResponseKnFirestoreImportJob
 */
export interface KnLookupResponseKnFirestoreImportJob {
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseKnFirestoreImportJob
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseKnFirestoreImportJob
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnFirestoreImportJob>}
     * @memberof KnLookupResponseKnFirestoreImportJob
     */
    'results'?: Array<KnFirestoreImportJob>;
}
/**
 * 
 * @export
 * @interface KnLookupResponseKnLead
 */
export interface KnLookupResponseKnLead {
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseKnLead
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseKnLead
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnLead>}
     * @memberof KnLookupResponseKnLead
     */
    'results'?: Array<KnLead>;
}
/**
 * 
 * @export
 * @interface KnLookupResponseKnProductImportJob
 */
export interface KnLookupResponseKnProductImportJob {
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseKnProductImportJob
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseKnProductImportJob
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnProductImportJob>}
     * @memberof KnLookupResponseKnProductImportJob
     */
    'results'?: Array<KnProductImportJob>;
}
/**
 * 
 * @export
 * @interface KnLookupResponseKnTeam
 */
export interface KnLookupResponseKnTeam {
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseKnTeam
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseKnTeam
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnTeam>}
     * @memberof KnLookupResponseKnTeam
     */
    'results'?: Array<KnTeam>;
    /**
     * 
     * @type {number}
     * @memberof KnLookupResponseKnTeam
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface KnLookupResponseKnTeamCompany
 */
export interface KnLookupResponseKnTeamCompany {
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseKnTeamCompany
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseKnTeamCompany
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnTeamCompany>}
     * @memberof KnLookupResponseKnTeamCompany
     */
    'results'?: Array<KnTeamCompany>;
}
/**
 * 
 * @export
 * @interface KnLookupResponseKnTeamContact
 */
export interface KnLookupResponseKnTeamContact {
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseKnTeamContact
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseKnTeamContact
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnTeamContact>}
     * @memberof KnLookupResponseKnTeamContact
     */
    'results'?: Array<KnTeamContact>;
}
/**
 * 
 * @export
 * @interface KnLookupResponseKnTeamUserRole
 */
export interface KnLookupResponseKnTeamUserRole {
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseKnTeamUserRole
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseKnTeamUserRole
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnTeamUserRole>}
     * @memberof KnLookupResponseKnTeamUserRole
     */
    'results'?: Array<KnTeamUserRole>;
    /**
     * 
     * @type {number}
     * @memberof KnLookupResponseKnTeamUserRole
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface KnLookupResponseMessageDTO
 */
export interface KnLookupResponseMessageDTO {
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseMessageDTO
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseMessageDTO
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<MessageDTO>}
     * @memberof KnLookupResponseMessageDTO
     */
    'results'?: Array<MessageDTO>;
}
/**
 * 
 * @export
 * @interface KnLookupResponseTaskLine
 */
export interface KnLookupResponseTaskLine {
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseTaskLine
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseTaskLine
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<TaskLine>}
     * @memberof KnLookupResponseTaskLine
     */
    'results'?: Array<TaskLine>;
    /**
     * 
     * @type {number}
     * @memberof KnLookupResponseTaskLine
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface KnLookupResponseTransferNumberDto
 */
export interface KnLookupResponseTransferNumberDto {
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseTransferNumberDto
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseTransferNumberDto
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<TransferNumberDto>}
     * @memberof KnLookupResponseTransferNumberDto
     */
    'results'?: Array<TransferNumberDto>;
    /**
     * 
     * @type {number}
     * @memberof KnLookupResponseTransferNumberDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface KnLookupResponseTrigger
 */
export interface KnLookupResponseTrigger {
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseTrigger
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnLookupResponseTrigger
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<Trigger>}
     * @memberof KnLookupResponseTrigger
     */
    'results'?: Array<Trigger>;
    /**
     * 
     * @type {number}
     * @memberof KnLookupResponseTrigger
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface KnMeetingOptionsBusinessHours
 */
export interface KnMeetingOptionsBusinessHours {
    /**
     * 
     * @type {string}
     * @memberof KnMeetingOptionsBusinessHours
     */
    'day_of_week'?: KnMeetingOptionsBusinessHoursDayOfWeekEnum;
    /**
     * 
     * @type {LocalTime}
     * @memberof KnMeetingOptionsBusinessHours
     */
    'end_time'?: LocalTime;
    /**
     * 
     * @type {LocalTime}
     * @memberof KnMeetingOptionsBusinessHours
     */
    'start_time'?: LocalTime;
}

export const KnMeetingOptionsBusinessHoursDayOfWeekEnum = {
    Monday: 'MONDAY',
    Tuesday: 'TUESDAY',
    Wednesday: 'WEDNESDAY',
    Thursday: 'THURSDAY',
    Friday: 'FRIDAY',
    Saturday: 'SATURDAY',
    Sunday: 'SUNDAY',
    Everyday: 'EVERYDAY',
    MondayToFriday: 'MONDAY_TO_FRIDAY'
} as const;

export type KnMeetingOptionsBusinessHoursDayOfWeekEnum = typeof KnMeetingOptionsBusinessHoursDayOfWeekEnum[keyof typeof KnMeetingOptionsBusinessHoursDayOfWeekEnum];

/**
 * 
 * @export
 * @interface KnPageRequest
 */
export interface KnPageRequest {
    /**
     * 
     * @type {number}
     * @memberof KnPageRequest
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof KnPageRequest
     */
    'pageSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnPageRequest
     */
    'sortBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnPageRequest
     */
    'sortDirection'?: KnPageRequestSortDirectionEnum;
}

export const KnPageRequestSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;

export type KnPageRequestSortDirectionEnum = typeof KnPageRequestSortDirectionEnum[keyof typeof KnPageRequestSortDirectionEnum];

/**
 * 
 * @export
 * @interface KnPageRequest2
 */
export interface KnPageRequest2 {
    /**
     * 
     * @type {number}
     * @memberof KnPageRequest2
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof KnPageRequest2
     */
    'pageSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnPageRequest2
     */
    'sortBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnPageRequest2
     */
    'sortDirection'?: KnPageRequest2SortDirectionEnum;
}

export const KnPageRequest2SortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;

export type KnPageRequest2SortDirectionEnum = typeof KnPageRequest2SortDirectionEnum[keyof typeof KnPageRequest2SortDirectionEnum];

/**
 * 
 * @export
 * @interface KnPageResponseCallLog
 */
export interface KnPageResponseCallLog {
    /**
     * 
     * @type {boolean}
     * @memberof KnPageResponseCallLog
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnPageResponseCallLog
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<CallLog>}
     * @memberof KnPageResponseCallLog
     */
    'results'?: Array<CallLog>;
    /**
     * 
     * @type {number}
     * @memberof KnPageResponseCallLog
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface KnPageResponseDripStepResponse
 */
export interface KnPageResponseDripStepResponse {
    /**
     * 
     * @type {boolean}
     * @memberof KnPageResponseDripStepResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnPageResponseDripStepResponse
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<DripStepResponse>}
     * @memberof KnPageResponseDripStepResponse
     */
    'results'?: Array<DripStepResponse>;
    /**
     * 
     * @type {number}
     * @memberof KnPageResponseDripStepResponse
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface KnPageResponseKnCompany
 */
export interface KnPageResponseKnCompany {
    /**
     * 
     * @type {boolean}
     * @memberof KnPageResponseKnCompany
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnPageResponseKnCompany
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnCompany>}
     * @memberof KnPageResponseKnCompany
     */
    'results'?: Array<KnCompany>;
    /**
     * 
     * @type {number}
     * @memberof KnPageResponseKnCompany
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface KnPageResponseKnCompanyImportJob
 */
export interface KnPageResponseKnCompanyImportJob {
    /**
     * 
     * @type {boolean}
     * @memberof KnPageResponseKnCompanyImportJob
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnPageResponseKnCompanyImportJob
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnCompanyImportJob>}
     * @memberof KnPageResponseKnCompanyImportJob
     */
    'results'?: Array<KnCompanyImportJob>;
    /**
     * 
     * @type {number}
     * @memberof KnPageResponseKnCompanyImportJob
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface KnPageResponseKnContact
 */
export interface KnPageResponseKnContact {
    /**
     * 
     * @type {boolean}
     * @memberof KnPageResponseKnContact
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnPageResponseKnContact
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnContact2>}
     * @memberof KnPageResponseKnContact
     */
    'results'?: Array<KnContact2>;
    /**
     * 
     * @type {number}
     * @memberof KnPageResponseKnContact
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface KnPageResponseKnContactImportJob
 */
export interface KnPageResponseKnContactImportJob {
    /**
     * 
     * @type {boolean}
     * @memberof KnPageResponseKnContactImportJob
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnPageResponseKnContactImportJob
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnContactImportJob>}
     * @memberof KnPageResponseKnContactImportJob
     */
    'results'?: Array<KnContactImportJob>;
    /**
     * 
     * @type {number}
     * @memberof KnPageResponseKnContactImportJob
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface KnPageResponseKnFirestoreImportJob
 */
export interface KnPageResponseKnFirestoreImportJob {
    /**
     * 
     * @type {boolean}
     * @memberof KnPageResponseKnFirestoreImportJob
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnPageResponseKnFirestoreImportJob
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnFirestoreImportJob>}
     * @memberof KnPageResponseKnFirestoreImportJob
     */
    'results'?: Array<KnFirestoreImportJob>;
    /**
     * 
     * @type {number}
     * @memberof KnPageResponseKnFirestoreImportJob
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface KnPageResponseKnProductImportJob
 */
export interface KnPageResponseKnProductImportJob {
    /**
     * 
     * @type {boolean}
     * @memberof KnPageResponseKnProductImportJob
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnPageResponseKnProductImportJob
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnProductImportJob>}
     * @memberof KnPageResponseKnProductImportJob
     */
    'results'?: Array<KnProductImportJob>;
    /**
     * 
     * @type {number}
     * @memberof KnPageResponseKnProductImportJob
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface KnPageResponseKnTeamCompany
 */
export interface KnPageResponseKnTeamCompany {
    /**
     * 
     * @type {boolean}
     * @memberof KnPageResponseKnTeamCompany
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnPageResponseKnTeamCompany
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnTeamCompany>}
     * @memberof KnPageResponseKnTeamCompany
     */
    'results'?: Array<KnTeamCompany>;
    /**
     * 
     * @type {number}
     * @memberof KnPageResponseKnTeamCompany
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface KnPageResponseKnTeamContact
 */
export interface KnPageResponseKnTeamContact {
    /**
     * 
     * @type {boolean}
     * @memberof KnPageResponseKnTeamContact
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnPageResponseKnTeamContact
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnTeamContact>}
     * @memberof KnPageResponseKnTeamContact
     */
    'results'?: Array<KnTeamContact>;
    /**
     * 
     * @type {number}
     * @memberof KnPageResponseKnTeamContact
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface KnPageResponseKnWebhook
 */
export interface KnPageResponseKnWebhook {
    /**
     * 
     * @type {boolean}
     * @memberof KnPageResponseKnWebhook
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnPageResponseKnWebhook
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<KnWebhook>}
     * @memberof KnPageResponseKnWebhook
     */
    'results'?: Array<KnWebhook>;
    /**
     * 
     * @type {number}
     * @memberof KnPageResponseKnWebhook
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface KnPageResponseMessageDTO
 */
export interface KnPageResponseMessageDTO {
    /**
     * 
     * @type {boolean}
     * @memberof KnPageResponseMessageDTO
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnPageResponseMessageDTO
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<MessageDTO>}
     * @memberof KnPageResponseMessageDTO
     */
    'results'?: Array<MessageDTO>;
    /**
     * 
     * @type {number}
     * @memberof KnPageResponseMessageDTO
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface KnPageResponseSequenceTemplateResponseGroup
 */
export interface KnPageResponseSequenceTemplateResponseGroup {
    /**
     * 
     * @type {boolean}
     * @memberof KnPageResponseSequenceTemplateResponseGroup
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnPageResponseSequenceTemplateResponseGroup
     */
    'hasPrev'?: boolean;
    /**
     * 
     * @type {Array<SequenceTemplateResponseGroup>}
     * @memberof KnPageResponseSequenceTemplateResponseGroup
     */
    'results'?: Array<SequenceTemplateResponseGroup>;
    /**
     * 
     * @type {number}
     * @memberof KnPageResponseSequenceTemplateResponseGroup
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface KnPhoneNumber
 */
export interface KnPhoneNumber {
    /**
     * 
     * @type {boolean}
     * @memberof KnPhoneNumber
     */
    'is_used_inbound'?: boolean;
    /**
     * 
     * @type {KnAddress}
     * @memberof KnPhoneNumber
     */
    'location'?: KnAddress;
    /**
     * 
     * @type {string}
     * @memberof KnPhoneNumber
     */
    'number'?: string;
}
/**
 * 
 * @export
 * @interface KnPipeline
 */
export interface KnPipeline {
    /**
     * The date and time it was created
     * @type {string}
     * @memberof KnPipeline
     */
    'createdAt'?: string;
    /**
     * User id from the creator
     * @type {string}
     * @memberof KnPipeline
     */
    'createdBy'?: string;
    /**
     * Pipeline\'s id.
     * @type {string}
     * @memberof KnPipeline
     */
    'id'?: string;
    /**
     * The name of the pipeline
     * @type {string}
     * @memberof KnPipeline
     */
    'name'?: string;
    /**
     * List of Stages within the pipeline
     * @type {Array<KnPipelineStage>}
     * @memberof KnPipeline
     */
    'stagesList'?: Array<KnPipelineStage>;
    /**
     * The id of the team that owns the pipeline
     * @type {string}
     * @memberof KnPipeline
     */
    'teamId'?: string;
    /**
     * The date and time it was last updated
     * @type {string}
     * @memberof KnPipeline
     */
    'updatedAt'?: string;
    /**
     * User id from the last updater
     * @type {string}
     * @memberof KnPipeline
     */
    'updatedBy'?: string;
}
/**
 * 
 * @export
 * @interface KnPipelineStage
 */
export interface KnPipelineStage {
    /**
     * The color in RGB format
     * @type {string}
     * @memberof KnPipelineStage
     */
    'color'?: string;
    /**
     * The date and time when the stage was created
     * @type {string}
     * @memberof KnPipelineStage
     */
    'createdAt'?: string;
    /**
     * User id from the creator
     * @type {string}
     * @memberof KnPipelineStage
     */
    'createdBy'?: string;
    /**
     * A brief description of the stage
     * @type {string}
     * @memberof KnPipelineStage
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof KnPipelineStage
     */
    'close'?: boolean;
    /**
     * Stage\'s id.
     * @type {string}
     * @memberof KnPipelineStage
     */
    'id'?: string;
    /**
     * The name of the stage
     * @type {string}
     * @memberof KnPipelineStage
     */
    'name'?: string;
    /**
     * The order in which the stage should be displayed
     * @type {number}
     * @memberof KnPipelineStage
     */
    'order'?: number;
    /**
     * Pipeline\'s id.
     * @type {string}
     * @memberof KnPipelineStage
     */
    'pipelineId'?: string;
    /**
     * The date and time when the stage was last updated
     * @type {string}
     * @memberof KnPipelineStage
     */
    'updatedAt'?: string;
    /**
     * User id from the last updater
     * @type {string}
     * @memberof KnPipelineStage
     */
    'updatedBy'?: string;
}
/**
 * 
 * @export
 * @interface KnProcessCompanyImportJobRequest
 */
export interface KnProcessCompanyImportJobRequest {
    /**
     * 
     * @type {KnDocumentListResponseKnFirestoreImportJobResults}
     * @memberof KnProcessCompanyImportJobRequest
     */
    'columnMappingStrategyList'?: KnDocumentListResponseKnFirestoreImportJobResults;
    /**
     * 
     * @type {string}
     * @memberof KnProcessCompanyImportJobRequest
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface KnProcessContactImportJobRequest
 */
export interface KnProcessContactImportJobRequest {
    /**
     * 
     * @type {KnDocumentListResponseKnFirestoreImportJobResults}
     * @memberof KnProcessContactImportJobRequest
     */
    'columnMappingStrategyList'?: KnDocumentListResponseKnFirestoreImportJobResults;
    /**
     * 
     * @type {string}
     * @memberof KnProcessContactImportJobRequest
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface KnProcessImportJobResponse
 */
export interface KnProcessImportJobResponse {
    /**
     * 
     * @type {number}
     * @memberof KnProcessImportJobResponse
     */
    'createdDocumentResultCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof KnProcessImportJobResponse
     */
    'errorDocumentResultCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnProcessImportJobResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnProcessImportJobResponse
     */
    'importStatus'?: KnProcessImportJobResponseImportStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof KnProcessImportJobResponse
     */
    'updatedDocumentResultCount'?: number;
}

export const KnProcessImportJobResponseImportStatusEnum = {
    Unspecified: 'unspecified',
    FileUploaded: 'file_uploaded',
    Created: 'created',
    Processing: 'processing',
    Success: 'success',
    Failed: 'failed'
} as const;

export type KnProcessImportJobResponseImportStatusEnum = typeof KnProcessImportJobResponseImportStatusEnum[keyof typeof KnProcessImportJobResponseImportStatusEnum];

/**
 * 
 * @export
 * @interface KnProcessProductImportJobRequest
 */
export interface KnProcessProductImportJobRequest {
    /**
     * 
     * @type {KnDocumentListResponseKnFirestoreImportJobResults}
     * @memberof KnProcessProductImportJobRequest
     */
    'columnMappingStrategyList'?: KnDocumentListResponseKnFirestoreImportJobResults;
    /**
     * 
     * @type {string}
     * @memberof KnProcessProductImportJobRequest
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface KnProduct
 */
export interface KnProduct {
    /**
     * The date and time it was created
     * @type {string}
     * @memberof KnProduct
     */
    'createdAt'?: string;
    /**
     * User id from the creator
     * @type {string}
     * @memberof KnProduct
     */
    'createdBy'?: string;
    /**
     * The description of the product
     * @type {string}
     * @memberof KnProduct
     */
    'description'?: string;
    /**
     * Product\'s id.
     * @type {string}
     * @memberof KnProduct
     */
    'id'?: string;
    /**
     * The amount of months it is valid
     * @type {number}
     * @memberof KnProduct
     */
    'months'?: number;
    /**
     * The name of the product
     * @type {string}
     * @memberof KnProduct
     */
    'name'?: string;
    /**
     * The price of the product
     * @type {number}
     * @memberof KnProduct
     */
    'price'?: number;
    /**
     * The product status
     * @type {string}
     * @memberof KnProduct
     */
    'status'?: KnProductStatusEnum;
    /**
     * A list of potential customer pain points the product or service addresses.
     * @type {Array<string>}
     * @memberof KnProduct
     */
    'feature'?: Array<string>;
    /**
     * A list of potential customer pain points the product or service addresses.
     * @type {Array<string>}
     * @memberof KnProduct
     */
    'valueProp'?: Array<string>;
    /**
     * The date and time it was last updated
     * @type {string}
     * @memberof KnProduct
     */
    'updatedAt'?: string;
    /**
     * User id from the last updater
     * @type {string}
     * @memberof KnProduct
     */
    'updatedBy'?: string;
}

export const KnProductStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type KnProductStatusEnum = typeof KnProductStatusEnum[keyof typeof KnProductStatusEnum];

/**
 * 
 * @export
 * @interface KnProductImportJob
 */
export interface KnProductImportJob {
    /**
     * 
     * @type {Timestamp2}
     * @memberof KnProductImportJob
     */
    'createdAt'?: Timestamp2;
    /**
     * 
     * @type {number}
     * @memberof KnProductImportJob
     */
    'createdDocumentResultCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof KnProductImportJob
     */
    'errorDocumentResultCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnProductImportJob
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnProductImportJob
     */
    'importJobName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnProductImportJob
     */
    'importJobType'?: KnProductImportJobImportJobTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof KnProductImportJob
     */
    'importStatus'?: KnProductImportJobImportStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof KnProductImportJob
     */
    'originalFileName'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnProductImportJob
     */
    'originalFileRowCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnProductImportJob
     */
    'ownerTeamId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnProductImportJob
     */
    'ownerUserId'?: string;
    /**
     * 
     * @type {Timestamp2}
     * @memberof KnProductImportJob
     */
    'updatedAt'?: Timestamp2;
    /**
     * 
     * @type {number}
     * @memberof KnProductImportJob
     */
    'updatedDocumentResultCount'?: number;
}

export const KnProductImportJobImportJobTypeEnum = {
    Company: 'company',
    Contact: 'contact',
    Product: 'product'
} as const;

export type KnProductImportJobImportJobTypeEnum = typeof KnProductImportJobImportJobTypeEnum[keyof typeof KnProductImportJobImportJobTypeEnum];
export const KnProductImportJobImportStatusEnum = {
    Unspecified: 'unspecified',
    FileUploaded: 'file_uploaded',
    Created: 'created',
    Processing: 'processing',
    Success: 'success',
    Failed: 'failed'
} as const;

export type KnProductImportJobImportStatusEnum = typeof KnProductImportJobImportStatusEnum[keyof typeof KnProductImportJobImportStatusEnum];

/**
 * 
 * @export
 * @interface KnProductImportJobRequest
 */
export interface KnProductImportJobRequest {
    /**
     * 
     * @type {number}
     * @memberof KnProductImportJobRequest
     */
    'createdDocumentResultCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof KnProductImportJobRequest
     */
    'errorDocumentResultCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnProductImportJobRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnProductImportJobRequest
     */
    'importJobName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnProductImportJobRequest
     */
    'importStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnProductImportJobRequest
     */
    'originalFileName'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnProductImportJobRequest
     */
    'originalFileRowCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnProductImportJobRequest
     */
    'ownerTeamId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnProductImportJobRequest
     */
    'ownerUserId'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnProductImportJobRequest
     */
    'updatedDocumentResultCount'?: number;
}
/**
 * 
 * @export
 * @interface KnProspectAdvancedFilterRequest
 */
export interface KnProspectAdvancedFilterRequest {
    /**
     * 
     * @type {KnAddressFilter}
     * @memberof KnProspectAdvancedFilterRequest
     */
    'addressFilters'?: KnAddressFilter;
    /**
     * 
     * @type {KnCompanyFilter}
     * @memberof KnProspectAdvancedFilterRequest
     */
    'companyFilters'?: KnCompanyFilter;
    /**
     * 
     * @type {KnContactFilter}
     * @memberof KnProspectAdvancedFilterRequest
     */
    'contactFilters'?: KnContactFilter;
    /**
     * 
     * @type {number}
     * @memberof KnProspectAdvancedFilterRequest
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof KnProspectAdvancedFilterRequest
     */
    'pageSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnProspectAdvancedFilterRequest
     */
    'sortBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnProspectAdvancedFilterRequest
     */
    'sortDirection'?: KnProspectAdvancedFilterRequestSortDirectionEnum;
}

export const KnProspectAdvancedFilterRequestSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;

export type KnProspectAdvancedFilterRequestSortDirectionEnum = typeof KnProspectAdvancedFilterRequestSortDirectionEnum[keyof typeof KnProspectAdvancedFilterRequestSortDirectionEnum];

/**
 * 
 * @export
 * @interface KnSequenceContactDTO
 */
export interface KnSequenceContactDTO {
    /**
     * 
     * @type {string}
     * @memberof KnSequenceContactDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnSequenceContactDTO
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnSequenceContactDTO
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnSequenceContactDTO
     */
    'enrollmentDate'?: string;
    /**
     * Sequence status.
     * @type {string}
     * @memberof KnSequenceContactDTO
     */
    'status'?: KnSequenceContactDTOStatusEnum;
}

export const KnSequenceContactDTOStatusEnum = {
    InProgress: 'IN_PROGRESS',
    PausedInProspectLevel: 'PAUSED_IN_PROSPECT_LEVEL',
    PausedInTemplateLevel: 'PAUSED_IN_TEMPLATE_LEVEL',
    PausedInTeamLevel: 'PAUSED_IN_TEAM_LEVEL',
    Completed: 'COMPLETED',
    ContactAnswered: 'CONTACT_ANSWERED',
    Canceled: 'CANCELED',
    Unenrolled: 'UNENROLLED'
} as const;

export type KnSequenceContactDTOStatusEnum = typeof KnSequenceContactDTOStatusEnum[keyof typeof KnSequenceContactDTOStatusEnum];

/**
 * 
 * @export
 * @interface KnTeam
 */
export interface KnTeam {
    /**
     * String value for Team entity\'s \'domain\'
     * @type {string}
     * @memberof KnTeam
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeam
     */
    'id'?: string;
    /**
     * String value for Team location
     * @type {string}
     * @memberof KnTeam
     */
    'location'?: string;
    /**
     * String link to a Team logo stored in a GCP storage
     * @type {string}
     * @memberof KnTeam
     */
    'logoImgUrl'?: string;
    /**
     * String value for Team entity\'s \'name\'
     * @type {string}
     * @memberof KnTeam
     */
    'name'?: string;
    /**
     * String value for Team phone number
     * @type {string}
     * @memberof KnTeam
     */
    'phone'?: string;
    /**
     * Long value for amount of users of the team
     * @type {number}
     * @memberof KnTeam
     */
    'userCount'?: number;
}
/**
 * 
 * @export
 * @interface KnTeamAggregationSummary
 */
export interface KnTeamAggregationSummary {
    /**
     * 
     * @type {number}
     * @memberof KnTeamAggregationSummary
     */
    'admin'?: number;
    /**
     * 
     * @type {number}
     * @memberof KnTeamAggregationSummary
     */
    'superadmin'?: number;
    /**
     * 
     * @type {number}
     * @memberof KnTeamAggregationSummary
     */
    'sales_manager'?: number;
    /**
     * 
     * @type {number}
     * @memberof KnTeamAggregationSummary
     */
    'sales_representative'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnTeamAggregationSummary
     */
    'users_last_login'?: string;
}
/**
 * 
 * @export
 * @interface KnTeamCompany
 */
export interface KnTeamCompany {
    /**
     * 
     * @type {KnAddress2}
     * @memberof KnTeamCompany
     */
    'address'?: KnAddress2;
    /**
     * 
     * @type {number}
     * @memberof KnTeamCompany
     */
    'budgetAds'?: number;
    /**
     * 
     * @type {KnCompanyBudgetAdsCurrency}
     * @memberof KnTeamCompany
     */
    'budgetAdsCurrency'?: KnCompanyBudgetAdsCurrency;
    /**
     * 
     * @type {number}
     * @memberof KnTeamCompany
     */
    'budgetSolution'?: number;
    /**
     * 
     * @type {KnCompanyBudgetAdsCurrency}
     * @memberof KnTeamCompany
     */
    'budgetSolutionCurrency'?: KnCompanyBudgetAdsCurrency;
    /**
     * 
     * @type {Timestamp2}
     * @memberof KnTeamCompany
     */
    'createdAt'?: Timestamp2;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof KnTeamCompany
     */
    'customDataFields'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof KnTeamCompany
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamCompany
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamCompany
     */
    'headcount'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamCompany
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamCompany
     */
    'industry'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamCompany
     */
    'leadSource'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamCompany
     */
    'leadSourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamCompany
     */
    'linkedinUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamCompany
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamCompany
     */
    'ownerTeamId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamCompany
     */
    'ownerUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamCompany
     */
    'ownerUserName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamCompany
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamCompany
     */
    'revenue'?: string;
    /**
     * 
     * @type {Timestamp2}
     * @memberof KnTeamCompany
     */
    'updatedAt'?: Timestamp2;
}
/**
 * 
 * @export
 * @interface KnTeamContact
 */
export interface KnTeamContact {
    /**
     * 
     * @type {KnAddress2}
     * @memberof KnTeamContact
     */
    'address'?: KnAddress2;
    /**
     * 
     * @type {string}
     * @memberof KnTeamContact
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamContact
     */
    'companyName'?: string;
    /**
     * 
     * @type {{ [key: string]: KnConsentSource2; }}
     * @memberof KnTeamContact
     */
    'consentSources'?: { [key: string]: KnConsentSource2; };
    /**
     * 
     * @type {Timestamp2}
     * @memberof KnTeamContact
     */
    'createdAt'?: Timestamp2;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof KnTeamContact
     */
    'customDataFields'?: { [key: string]: object; };
    /**
     * 
     * @type {Timestamp2}
     * @memberof KnTeamContact
     */
    'decisionTimeframe'?: Timestamp2;
    /**
     * 
     * @type {string}
     * @memberof KnTeamContact
     */
    'department'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamContact
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamContact
     */
    'firstName'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnTeamContact
     */
    'heatScore'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnTeamContact
     */
    'heatScoreLevel'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamContact
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamContact
     */
    'jobTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamContact
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamContact
     */
    'leadSource'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamContact
     */
    'leadSourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamContact
     */
    'leadType'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamContact
     */
    'linkedinUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamContact
     */
    'ownerTeamId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamContact
     */
    'ownerUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamContact
     */
    'ownerUserName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof KnTeamContact
     */
    'painPoints'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof KnTeamContact
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamContact
     */
    'seniorityLevel'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamContact
     */
    'timezone'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamContact
     */
    'timezoneSource'?: KnTeamContactTimezoneSourceEnum;
    /**
     * 
     * @type {Timestamp2}
     * @memberof KnTeamContact
     */
    'updatedAt'?: Timestamp2;
}

export const KnTeamContactTimezoneSourceEnum = {
    Unspecified: 'unspecified',
    ProspectContactAddress: 'prospect_contact_address',
    ProspectContactCompanyAddress: 'prospect_contact_company_address',
    ProspectContactPhoneAreaCode: 'prospect_contact_phone_area_code',
    ProspectFinderCompanyAddress: 'prospect_finder_company_address',
    ProspectFinderContactPersonalAddress: 'prospect_finder_contact_personal_address',
    ProspectFinderContactCorporateAddress: 'prospect_finder_contact_corporate_address',
    Ui: 'ui'
} as const;

export type KnTeamContactTimezoneSourceEnum = typeof KnTeamContactTimezoneSourceEnum[keyof typeof KnTeamContactTimezoneSourceEnum];

/**
 * 
 * @export
 * @interface KnTeamSummary
 */
export interface KnTeamSummary {
    /**
     * 
     * @type {number}
     * @memberof KnTeamSummary
     */
    'contacts'?: number;
}
/**
 * 
 * @export
 * @interface KnTeamSummary2
 */
export interface KnTeamSummary2 {
    /**
     * Number of active sequences by teamId
     * @type {number}
     * @memberof KnTeamSummary2
     */
    'active'?: number;
}
/**
 * 
 * @export
 * @interface KnTeamTriggerSummary
 */
export interface KnTeamTriggerSummary {
    /**
     * 
     * @type {number}
     * @memberof KnTeamTriggerSummary
     */
    'activated'?: number;
}
/**
 * 
 * @export
 * @interface KnTeamUserRole
 */
export interface KnTeamUserRole {
    /**
     * 
     * @type {boolean}
     * @memberof KnTeamUserRole
     */
    'calendarConnected'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof KnTeamUserRole
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamUserRole
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamUserRole
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamUserRole
     */
    'imgUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamUserRole
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamUserRole
     */
    'team_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnTeamUserRole
     */
    'loginMethod'?: KnTeamUserRoleLoginMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof KnTeamUserRole
     */
    'userRole'?: KnTeamUserRoleUserRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof KnTeamUserRole
     */
    'user_id'?: string;
}

export const KnTeamUserRoleLoginMethodEnum = {
    Email: 'EMAIL',
    Google: 'GOOGLE'
} as const;

export type KnTeamUserRoleLoginMethodEnum = typeof KnTeamUserRoleLoginMethodEnum[keyof typeof KnTeamUserRoleLoginMethodEnum];
export const KnTeamUserRoleUserRoleEnum = {
    SalesRepresentative: 'SALES_REPRESENTATIVE',
    SalesManager: 'SALES_MANAGER',
    Admin: 'ADMIN',
    Superadmin: 'SUPERADMIN'
} as const;

export type KnTeamUserRoleUserRoleEnum = typeof KnTeamUserRoleUserRoleEnum[keyof typeof KnTeamUserRoleUserRoleEnum];

/**
 * 
 * @export
 * @interface KnTokenRequest
 */
export interface KnTokenRequest {
    /**
     * Code obtained from the authorize URL
     * @type {string}
     * @memberof KnTokenRequest
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface KnTokenRequest2
 */
export interface KnTokenRequest2 {
    /**
     * 
     * @type {string}
     * @memberof KnTokenRequest2
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface KnTransferNumberHours
 */
export interface KnTransferNumberHours {
    /**
     * 
     * @type {string}
     * @memberof KnTransferNumberHours
     */
    'day_of_week'?: KnTransferNumberHoursDayOfWeekEnum;
    /**
     * 
     * @type {LocalTime}
     * @memberof KnTransferNumberHours
     */
    'end_time'?: LocalTime;
    /**
     * 
     * @type {LocalTime}
     * @memberof KnTransferNumberHours
     */
    'start_time'?: LocalTime;
}

export const KnTransferNumberHoursDayOfWeekEnum = {
    Monday: 'MONDAY',
    Tuesday: 'TUESDAY',
    Wednesday: 'WEDNESDAY',
    Thursday: 'THURSDAY',
    Friday: 'FRIDAY',
    Saturday: 'SATURDAY',
    Sunday: 'SUNDAY'
} as const;

export type KnTransferNumberHoursDayOfWeekEnum = typeof KnTransferNumberHoursDayOfWeekEnum[keyof typeof KnTransferNumberHoursDayOfWeekEnum];

/**
 * 
 * @export
 * @interface KnUser
 */
export interface KnUser {
    /**
     * 
     * @type {string}
     * @memberof KnUser
     */
    'email'?: string;
    /**
     * User\'s String value for their first name
     * @type {string}
     * @memberof KnUser
     */
    'firstName'?: string;
    /**
     * User PK Identifier
     * @type {string}
     * @memberof KnUser
     */
    'id'?: string;
    /**
     * User\'s profile picture link
     * @type {string}
     * @memberof KnUser
     */
    'imgUrl'?: string;
    /**
     * User\'s String value for their last name
     * @type {string}
     * @memberof KnUser
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnUser
     */
    'phone'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof KnUser
     */
    'subscribed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KnUser
     */
    'tosAccepted'?: boolean;
}
/**
 * 
 * @export
 * @interface KnUserUpdateRequest
 */
export interface KnUserUpdateRequest {
    /**
     * User\'s String value for their email
     * @type {string}
     * @memberof KnUserUpdateRequest
     */
    'email'?: string;
    /**
     * User\'s String value for their first name
     * @type {string}
     * @memberof KnUserUpdateRequest
     */
    'firstName'?: string;
    /**
     * User\'s String value for their last name
     * @type {string}
     * @memberof KnUserUpdateRequest
     */
    'lastName'?: string;
    /**
     * User\'s String value for their phone number
     * @type {string}
     * @memberof KnUserUpdateRequest
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface KnVoice
 */
export interface KnVoice {
    /**
     * 
     * @type {Array<string>}
     * @memberof KnVoice
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof KnVoice
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnVoice
     */
    'image_url'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof KnVoice
     */
    'languages'?: Array<KnVoiceLanguagesEnum>;
    /**
     * 
     * @type {string}
     * @memberof KnVoice
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnVoice
     */
    'voice_sample_url'?: string;
}

export const KnVoiceLanguagesEnum = {
    EnUs: 'en-US',
    EnAu: 'en-AU',
    EnIn: 'en-IN',
    EnGb: 'en-GB',
    Fr: 'fr',
    FrCa: 'fr-CA',
    Nl: 'nl',
    It: 'it',
    Zh: 'zh',
    Pt: 'pt',
    PtBr: 'pt-BR',
    Es: 'es',
    Hi: 'hi',
    HiLatn: 'hi-Latn',
    Es419: 'es-419'
} as const;

export type KnVoiceLanguagesEnum = typeof KnVoiceLanguagesEnum[keyof typeof KnVoiceLanguagesEnum];

/**
 * 
 * @export
 * @interface KnVoiceTeamSummary
 */
export interface KnVoiceTeamSummary {
    /**
     * 
     * @type {number}
     * @memberof KnVoiceTeamSummary
     */
    'total_agents'?: number;
    /**
     * 
     * @type {number}
     * @memberof KnVoiceTeamSummary
     */
    'total_calls_answered'?: number;
    /**
     * 
     * @type {number}
     * @memberof KnVoiceTeamSummary
     */
    'total_calls_transferred'?: number;
    /**
     * 
     * @type {number}
     * @memberof KnVoiceTeamSummary
     */
    'avg_call_duration'?: number;
    /**
     * 
     * @type {number}
     * @memberof KnVoiceTeamSummary
     */
    'total_calls_made'?: number;
    /**
     * 
     * @type {number}
     * @memberof KnVoiceTeamSummary
     */
    'total_meetings_booked'?: number;
    /**
     * 
     * @type {number}
     * @memberof KnVoiceTeamSummary
     */
    'total_inbound_calls'?: number;
    /**
     * 
     * @type {number}
     * @memberof KnVoiceTeamSummary
     */
    'total_outbound_calls'?: number;
}
/**
 * 
 * @export
 * @interface KnWebhook
 */
export interface KnWebhook {
    /**
     * 
     * @type {string}
     * @memberof KnWebhook
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnWebhook
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnWebhook
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnWebhook
     */
    'teamId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnWebhook
     */
    'type'?: KnWebhookTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof KnWebhook
     */
    'url'?: string;
}

export const KnWebhookTypeEnum = {
    Contact: 'CONTACT'
} as const;

export type KnWebhookTypeEnum = typeof KnWebhookTypeEnum[keyof typeof KnWebhookTypeEnum];

/**
 * 
 * @export
 * @interface LeadFlowEnroll
 */
export interface LeadFlowEnroll {
    /**
     * 
     * @type {string}
     * @memberof LeadFlowEnroll
     */
    'contact_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadFlowEnroll
     */
    'team_id'?: string;
}
/**
 * 
 * @export
 * @interface LeadFlowPerf
 */
export interface LeadFlowPerf {
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerf
     */
    'created'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerf
     */
    'created_today'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerf
     */
    'lost'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerf
     */
    'lost_stale'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerf
     */
    'lost_stale_today'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerf
     */
    'lost_stop'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerf
     */
    'lost_stop_today'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerf
     */
    'lost_today'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerf
     */
    'unqual'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerf
     */
    'unqual_budget'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerf
     */
    'unqual_budget_ads'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerf
     */
    'unqual_budget_ads_today'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerf
     */
    'unqual_budget_today'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerf
     */
    'unqual_company_size'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerf
     */
    'unqual_company_size_today'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerf
     */
    'unqual_industry'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerf
     */
    'unqual_industry_today'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerf
     */
    'unqual_location'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerf
     */
    'unqual_location_today'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerf
     */
    'unqual_pain_point'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerf
     */
    'unqual_pain_point_today'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerf
     */
    'unqual_seniority'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerf
     */
    'unqual_seniority_today'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerf
     */
    'unqual_timeframe'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerf
     */
    'unqual_timeframe_today'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerf
     */
    'unqual_today'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerf
     */
    'won'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerf
     */
    'won_today'?: number;
}
/**
 * 
 * @export
 * @interface LeadFlowPerfByLeadSource
 */
export interface LeadFlowPerfByLeadSource {
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerfByLeadSource
     */
    'in_process'?: number;
    /**
     * 
     * @type {LeadSource}
     * @memberof LeadFlowPerfByLeadSource
     */
    'lead_source'?: LeadSource;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerfByLeadSource
     */
    'lost'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerfByLeadSource
     */
    'unqual'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowPerfByLeadSource
     */
    'won'?: number;
}
/**
 * 
 * @export
 * @interface LeadFlowTeamConfig
 */
export interface LeadFlowTeamConfig {
    /**
     * 
     * @type {number}
     * @memberof LeadFlowTeamConfig
     */
    'ads_budget_gt'?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadFlowTeamConfig
     */
    'ads_budget_gt_unit'?: string;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowTeamConfig
     */
    'budget_gt'?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadFlowTeamConfig
     */
    'budget_gt_unit'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeadFlowTeamConfig
     */
    'company_size_in'?: Array<LeadFlowTeamConfigCompanySizeInEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeadFlowTeamConfig
     */
    'industry_in'?: Array<LeadFlowTeamConfigIndustryInEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof LeadFlowTeamConfig
     */
    'is_pain_point'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LeadFlowTeamConfig
     */
    'is_pain_point_mapped'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LeadFlowTeamConfig
     */
    'is_require_address'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LeadFlowTeamConfig
     */
    'is_require_ads_budget'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LeadFlowTeamConfig
     */
    'is_require_budget'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LeadFlowTeamConfig
     */
    'is_require_company'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LeadFlowTeamConfig
     */
    'is_require_company_size'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LeadFlowTeamConfig
     */
    'is_require_industry'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LeadFlowTeamConfig
     */
    'is_require_job_title'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LeadFlowTeamConfig
     */
    'is_require_location'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LeadFlowTeamConfig
     */
    'is_require_pain_point'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LeadFlowTeamConfig
     */
    'is_require_seniority'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LeadFlowTeamConfig
     */
    'is_require_timeframe'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LeadFlowTeamConfig
     */
    'location_city'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadFlowTeamConfig
     */
    'location_proximity'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadFlowTeamConfig
     */
    'location_proximity_unit'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadFlowTeamConfig
     */
    'location_state'?: string;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowTeamConfig
     */
    'qual_conditions_min'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeadFlowTeamConfig
     */
    'seniority_in'?: Array<LeadFlowTeamConfigSeniorityInEnum>;
    /**
     * 
     * @type {string}
     * @memberof LeadFlowTeamConfig
     */
    'team_config_info_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadFlowTeamConfig
     */
    'team_config_qual_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof LeadFlowTeamConfig
     */
    'timeframe'?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadFlowTeamConfig
     */
    'timeframe_unit'?: LeadFlowTeamConfigTimeframeUnitEnum;
}

export const LeadFlowTeamConfigCompanySizeInEnum = {
    _1To10: '1 to 10',
    _11To25: '11 to 25',
    _26To50: '26 to 50',
    _51To100: '51 to 100',
    _101To250: '101 to 250',
    _251To500: '251 to 500',
    _501To1000: '501 to 1000',
    _1001To5000: '1001 to 5000',
    _5001To10000: '5001 to 10000',
    _10000: '10000+'
} as const;

export type LeadFlowTeamConfigCompanySizeInEnum = typeof LeadFlowTeamConfigCompanySizeInEnum[keyof typeof LeadFlowTeamConfigCompanySizeInEnum];
export const LeadFlowTeamConfigIndustryInEnum = {
    FinancialServices: 'FINANCIAL_SERVICES',
    TechnologyAndSoftware: 'TECHNOLOGY_AND_SOFTWARE',
    HealthCareAndPharmaceutical: 'HEALTH_CARE_AND_PHARMACEUTICAL',
    Manufacturing: 'MANUFACTURING',
    Retail: 'RETAIL',
    RealEstateAndConstruction: 'REAL_ESTATE_AND_CONSTRUCTION',
    Education: 'EDUCATION',
    Automotive: 'AUTOMOTIVE',
    HospitalityAndTravel: 'HOSPITALITY_AND_TRAVEL',
    Telecommunications: 'TELECOMMUNICATIONS'
} as const;

export type LeadFlowTeamConfigIndustryInEnum = typeof LeadFlowTeamConfigIndustryInEnum[keyof typeof LeadFlowTeamConfigIndustryInEnum];
export const LeadFlowTeamConfigSeniorityInEnum = {
    Staff: 'STAFF',
    Manager: 'MANAGER',
    Director: 'DIRECTOR',
    Vp: 'VP',
    Cxo: 'CXO'
} as const;

export type LeadFlowTeamConfigSeniorityInEnum = typeof LeadFlowTeamConfigSeniorityInEnum[keyof typeof LeadFlowTeamConfigSeniorityInEnum];
export const LeadFlowTeamConfigTimeframeUnitEnum = {
    Hours: 'HOURS',
    Days: 'DAYS',
    Weeks: 'WEEKS',
    Months: 'MONTHS',
    Years: 'YEARS'
} as const;

export type LeadFlowTeamConfigTimeframeUnitEnum = typeof LeadFlowTeamConfigTimeframeUnitEnum[keyof typeof LeadFlowTeamConfigTimeframeUnitEnum];

/**
 * 
 * @export
 * @interface LeadQualifying
 */
export interface LeadQualifying {
    /**
     * 
     * @type {string}
     * @memberof LeadQualifying
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof LeadQualifying
     */
    'total'?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadQualifying
     */
    'updated_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof LeadQualifying
     */
    'valid'?: number;
}
/**
 * 
 * @export
 * @interface LeadSource
 */
export interface LeadSource {
    /**
     * 
     * @type {string}
     * @memberof LeadSource
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadSource
     */
    'type'?: LeadSourceTypeEnum;
}

export const LeadSourceTypeEnum = {
    Unspecified: 'unspecified',
    ProspectFinder: 'prospect_finder',
    CrmHubspot: 'crm_hubspot',
    CrmSalesforce: 'crm_salesforce',
    InboundGoogleForms: 'inbound_google_forms',
    InboundFacebookLeadAds: 'inbound_facebook_lead_ads',
    InboundWebhook: 'inbound_webhook',
    InboundWebhookZapier: 'inbound_webhook_zapier',
    DataImportCsv: 'data_import_csv',
    DataImportIdk: 'data_import_idk',
    Ui: 'ui'
} as const;

export type LeadSourceTypeEnum = typeof LeadSourceTypeEnum[keyof typeof LeadSourceTypeEnum];

/**
 * 
 * @export
 * @interface LeadSourceTriggerResponse
 */
export interface LeadSourceTriggerResponse {
    /**
     * The date and time when the sequence was canceled
     * @type {string}
     * @memberof LeadSourceTriggerResponse
     */
    'canceledAt'?: string;
    /**
     * Number of prospects canceled sequence
     * @type {number}
     * @memberof LeadSourceTriggerResponse
     */
    'countProspectsCancelledSequence'?: number;
    /**
     * Number of prospects completed sequence
     * @type {number}
     * @memberof LeadSourceTriggerResponse
     */
    'countProspectsCompletedSequence'?: number;
    /**
     * Number of prospects currently in sequence
     * @type {number}
     * @memberof LeadSourceTriggerResponse
     */
    'countProspectsCurrentlyInSequence'?: number;
    /**
     * Number of prospects responded to sequence
     * @type {number}
     * @memberof LeadSourceTriggerResponse
     */
    'countProspectsRespondedSequence'?: number;
    /**
     * The id of the lead source trigger
     * @type {string}
     * @memberof LeadSourceTriggerResponse
     */
    'leadSourceTriggerId'?: string;
    /**
     * Lead Source id.
     * @type {string}
     * @memberof LeadSourceTriggerResponse
     */
    'leadSourceId'?: string;
    /**
     * Sequence Template id.
     * @type {string}
     * @memberof LeadSourceTriggerResponse
     */
    'sequenceTemplateId'?: string;
    /**
     * Sequence Template name.
     * @type {string}
     * @memberof LeadSourceTriggerResponse
     */
    'sequenceTemplateName'?: string;
    /**
     * Lead Source Trigger status
     * @type {string}
     * @memberof LeadSourceTriggerResponse
     */
    'status'?: LeadSourceTriggerResponseStatusEnum;
    /**
     * The date and time when the sequence was triggered
     * @type {string}
     * @memberof LeadSourceTriggerResponse
     */
    'triggeredAt'?: string;
    /**
     * 
     * @type {SequenceUser}
     * @memberof LeadSourceTriggerResponse
     */
    'triggeredBy'?: SequenceUser;
}

export const LeadSourceTriggerResponseStatusEnum = {
    InProgress: 'IN_PROGRESS',
    Paused: 'PAUSED',
    Canceled: 'CANCELED',
    Completed: 'COMPLETED'
} as const;

export type LeadSourceTriggerResponseStatusEnum = typeof LeadSourceTriggerResponseStatusEnum[keyof typeof LeadSourceTriggerResponseStatusEnum];

/**
 * 
 * @export
 * @interface LfContact
 */
export interface LfContact {
    /**
     * 
     * @type {string}
     * @memberof LfContact
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof LfContact
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LfContact
     */
    'lastName'?: string;
}
/**
 * Should allow company timeline activity
 * @export
 * @interface LineItemsRequestCreate
 */
export interface LineItemsRequestCreate {
    /**
     * Deal Line Item name.
     * @type {string}
     * @memberof LineItemsRequestCreate
     */
    'name'?: string;
    /**
     * Deal Line Item price.
     * @type {number}
     * @memberof LineItemsRequestCreate
     */
    'price'?: number;
    /**
     * Product id.
     * @type {string}
     * @memberof LineItemsRequestCreate
     */
    'productId'?: string;
    /**
     * The quantity of product (seats)
     * @type {number}
     * @memberof LineItemsRequestCreate
     */
    'quantity'?: number;
}
/**
 * Should allow company timeline activity
 * @export
 * @interface LineItemsRequestUpdate
 */
export interface LineItemsRequestUpdate {
    /**
     * Deal Line Item id.
     * @type {string}
     * @memberof LineItemsRequestUpdate
     */
    'lineItemId'?: string;
    /**
     * Deal Line Item name.
     * @type {string}
     * @memberof LineItemsRequestUpdate
     */
    'name'?: string;
    /**
     * Deal Line Item price.
     * @type {number}
     * @memberof LineItemsRequestUpdate
     */
    'price'?: number;
    /**
     * Product id.
     * @type {string}
     * @memberof LineItemsRequestUpdate
     */
    'productId'?: string;
    /**
     * The quantity of product (seats)
     * @type {number}
     * @memberof LineItemsRequestUpdate
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface LinkAccountRequest
 */
export interface LinkAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof LinkAccountRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof LinkAccountRequest
     */
    'teamId': string;
}
/**
 * 
 * @export
 * @interface LocalTime
 */
export interface LocalTime {
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    'hour'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    'minute'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    'nano'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    'second'?: number;
}
/**
 * 
 * @export
 * @interface LookupDealRequest
 */
export interface LookupDealRequest {
    /**
     * List of filters to be applied
     * @type {Array<KnFilterDealProperties>}
     * @memberof LookupDealRequest
     */
    'filters'?: Array<KnFilterDealProperties>;
    /**
     * The page number starting with 0
     * @type {number}
     * @memberof LookupDealRequest
     */
    'pageNumber'?: number;
    /**
     * The amount of elements to be returned by page
     * @type {number}
     * @memberof LookupDealRequest
     */
    'pageSize'?: number;
    /**
     * the field to sort by
     * @type {string}
     * @memberof LookupDealRequest
     */
    'sortBy'?: LookupDealRequestSortByEnum;
    /**
     * The direction of the search (ASC or DESC)
     * @type {string}
     * @memberof LookupDealRequest
     */
    'sortDirection'?: LookupDealRequestSortDirectionEnum;
}

export const LookupDealRequestSortByEnum = {
    PipelineId: 'PIPELINE_ID',
    StageId: 'STAGE_ID',
    Name: 'NAME',
    AssigneeId: 'ASSIGNEE_ID',
    TeamId: 'TEAM_ID',
    ContactId: 'CONTACT_ID',
    CreatedAt: 'CREATED_AT',
    Amount: 'AMOUNT',
    ConsentLvl: 'CONSENT_LVL',
    IsDeleted: 'IS_DELETED'
} as const;

export type LookupDealRequestSortByEnum = typeof LookupDealRequestSortByEnum[keyof typeof LookupDealRequestSortByEnum];
export const LookupDealRequestSortDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type LookupDealRequestSortDirectionEnum = typeof LookupDealRequestSortDirectionEnum[keyof typeof LookupDealRequestSortDirectionEnum];

/**
 * 
 * @export
 * @interface LookupDripStepRequest
 */
export interface LookupDripStepRequest {
    /**
     * Partial match on the name of the contact ignoring case
     * @type {string}
     * @memberof LookupDripStepRequest
     */
    'contactName'?: string;
    /**
     * drip template identification.
     * @type {string}
     * @memberof LookupDripStepRequest
     */
    'dripTemplateId': string;
    /**
     * The page number starting with 0
     * @type {number}
     * @memberof LookupDripStepRequest
     */
    'pageNumber'?: number;
    /**
     * The amount of elements to be returned by page
     * @type {number}
     * @memberof LookupDripStepRequest
     */
    'pageSize'?: number;
    /**
     * The direction of the search order by execution date (ASC or DESC)
     * @type {string}
     * @memberof LookupDripStepRequest
     */
    'sortDirection'?: LookupDripStepRequestSortDirectionEnum;
}

export const LookupDripStepRequestSortDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type LookupDripStepRequestSortDirectionEnum = typeof LookupDripStepRequestSortDirectionEnum[keyof typeof LookupDripStepRequestSortDirectionEnum];

/**
 * 
 * @export
 * @interface MessageDTO
 */
export interface MessageDTO {
    /**
     * 
     * @type {string}
     * @memberof MessageDTO
     */
    '_CHANGE_TYPE'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDTO
     */
    'body': string;
    /**
     * 
     * @type {string}
     * @memberof MessageDTO
     */
    'createdAt'?: string;
    /**
     * 
     * @type {Array<MessageEventDTO>}
     * @memberof MessageDTO
     */
    'events'?: Array<MessageEventDTO>;
    /**
     * 
     * @type {string}
     * @memberof MessageDTO
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDTO
     */
    'from_user_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MessageDTO
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MessageDTO
     */
    'is_ai'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MessageDTO
     */
    'is_ai_user_intv'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MessageDTO
     */
    'outreach_thread_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MessageDTO
     */
    'prospect_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDTO
     */
    'provider_sequence_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDTO
     */
    'sequence_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDTO
     */
    'source'?: MessageDTOSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof MessageDTO
     */
    'team_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDTO
     */
    'to'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDTO
     */
    'to_user_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDTO
     */
    'updatedAt'?: string;
}

export const MessageDTOSourceEnum = {
    Inbound: 'INBOUND',
    Outbound: 'OUTBOUND'
} as const;

export type MessageDTOSourceEnum = typeof MessageDTOSourceEnum[keyof typeof MessageDTOSourceEnum];

/**
 * 
 * @export
 * @interface MessageEventDTO
 */
export interface MessageEventDTO {
    /**
     * 
     * @type {string}
     * @memberof MessageEventDTO
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageEventDTO
     */
    'occurred_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof MessageEventDTO
     */
    'outreach_message_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MessageEventDTO
     */
    'provider_message_id'?: string;
    /**
     * 
     * @type {object}
     * @memberof MessageEventDTO
     */
    'status'?: object;
    /**
     * 
     * @type {string}
     * @memberof MessageEventDTO
     */
    'to'?: string;
}
/**
 * 
 * @export
 * @interface MessagingProfileRequestDto
 */
export interface MessagingProfileRequestDto {
    /**
     * 
     * @type {string}
     * @memberof MessagingProfileRequestDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessagingProfileRequestDto
     */
    'owner_team_id'?: string;
}
/**
 * 
 * @export
 * @interface OutlookAccessTokenDto
 */
export interface OutlookAccessTokenDto {
    /**
     * 
     * @type {string}
     * @memberof OutlookAccessTokenDto
     */
    'accessToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutlookAccessTokenDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutlookAccessTokenDto
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {number}
     * @memberof OutlookAccessTokenDto
     */
    'expireSeconds'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutlookAccessTokenDto
     */
    'expireTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof OutlookAccessTokenDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutlookAccessTokenDto
     */
    'refreshToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutlookAccessTokenDto
     */
    'subscriptionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutlookAccessTokenDto
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface PhoneNumberListFilter
 */
export interface PhoneNumberListFilter {
    /**
     * 
     * @type {string}
     * @memberof PhoneNumberListFilter
     */
    'administrative_area'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PhoneNumberListFilter
     */
    'best_effort'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PhoneNumberListFilter
     */
    'contains'?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoneNumberListFilter
     */
    'country_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoneNumberListFilter
     */
    'ends_with'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PhoneNumberListFilter
     */
    'exclude_held_numbers'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PhoneNumberListFilter
     */
    'features'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PhoneNumberListFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof PhoneNumberListFilter
     */
    'locality'?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoneNumberListFilter
     */
    'national_destination_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoneNumberListFilter
     */
    'phone_number_type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PhoneNumberListFilter
     */
    'quickship'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PhoneNumberListFilter
     */
    'rate_center'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PhoneNumberListFilter
     */
    'reservable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PhoneNumberListFilter
     */
    'starts_with'?: string;
}
/**
 * 
 * @export
 * @interface PhoneNumberOrderRequestDto
 */
export interface PhoneNumberOrderRequestDto {
    /**
     * 
     * @type {string}
     * @memberof PhoneNumberOrderRequestDto
     */
    'messaging_profile_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoneNumberOrderRequestDto
     */
    'owner_team_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoneNumberOrderRequestDto
     */
    'phone_number'?: string;
}
/**
 * 
 * @export
 * @interface PipelineListResponse
 */
export interface PipelineListResponse {
    /**
     * Pipeline\'s id.
     * @type {string}
     * @memberof PipelineListResponse
     */
    'id'?: string;
    /**
     * The name of the pipeline
     * @type {string}
     * @memberof PipelineListResponse
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface PipelineRequestCreate
 */
export interface PipelineRequestCreate {
    /**
     * The name of the pipeline
     * @type {string}
     * @memberof PipelineRequestCreate
     */
    'name'?: string;
    /**
     * The stages within the pipeline
     * @type {Array<StageRequestCreate>}
     * @memberof PipelineRequestCreate
     */
    'stages'?: Array<StageRequestCreate>;
}
/**
 * 
 * @export
 * @interface PipelineRequestUpdate
 */
export interface PipelineRequestUpdate {
    /**
     * Pipeline\'s id.
     * @type {string}
     * @memberof PipelineRequestUpdate
     */
    'id'?: string;
    /**
     * The name of the pipeline
     * @type {string}
     * @memberof PipelineRequestUpdate
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface PipelineRequestUpdateOrder
 */
export interface PipelineRequestUpdateOrder {
    /**
     * Pipeline\'s id.
     * @type {string}
     * @memberof PipelineRequestUpdateOrder
     */
    'pipelineId'?: string;
    /**
     * List of Stages Id in the new order
     * @type {Array<string>}
     * @memberof PipelineRequestUpdateOrder
     */
    'stagesIdList'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ProductRequestCreate
 */
export interface ProductRequestCreate {
    /**
     * The description of the product
     * @type {string}
     * @memberof ProductRequestCreate
     */
    'description'?: string;
    /**
     * The amount of months it is valid
     * @type {number}
     * @memberof ProductRequestCreate
     */
    'months'?: number;
    /**
     * The name of the product
     * @type {string}
     * @memberof ProductRequestCreate
     */
    'name'?: string;
    /**
     * The price of the product
     * @type {number}
     * @memberof ProductRequestCreate
     */
    'price'?: number;
    /**
     * A list of potential customer pain points the product or service addresses.
     * @type {Array<string>}
     * @memberof ProductRequestCreate
     */
    'feature'?: Array<string>;
    /**
     * A list of potential customer pain points the product or service addresses.
     * @type {Array<string>}
     * @memberof ProductRequestCreate
     */
    'valueProp'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ProductRequestUpdate
 */
export interface ProductRequestUpdate {
    /**
     * The description of the product
     * @type {string}
     * @memberof ProductRequestUpdate
     */
    'description'?: string;
    /**
     * Product\'s id.
     * @type {string}
     * @memberof ProductRequestUpdate
     */
    'id'?: string;
    /**
     * The amount of months it is valid
     * @type {number}
     * @memberof ProductRequestUpdate
     */
    'months'?: number;
    /**
     * The name of the product
     * @type {string}
     * @memberof ProductRequestUpdate
     */
    'name'?: string;
    /**
     * The price of the product
     * @type {number}
     * @memberof ProductRequestUpdate
     */
    'price'?: number;
    /**
     * The product status
     * @type {string}
     * @memberof ProductRequestUpdate
     */
    'status'?: ProductRequestUpdateStatusEnum;
    /**
     * A list of potential customer pain points the product or service addresses.
     * @type {Array<string>}
     * @memberof ProductRequestUpdate
     */
    'feature'?: Array<string>;
    /**
     * A list of potential customer pain points the product or service addresses.
     * @type {Array<string>}
     * @memberof ProductRequestUpdate
     */
    'valueProp'?: Array<string>;
}

export const ProductRequestUpdateStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type ProductRequestUpdateStatusEnum = typeof ProductRequestUpdateStatusEnum[keyof typeof ProductRequestUpdateStatusEnum];

/**
 * 
 * @export
 * @interface ProspectAutomationByWeek
 */
export interface ProspectAutomationByWeek {
    /**
     * 
     * @type {number}
     * @memberof ProspectAutomationByWeek
     */
    'prospect_in_lead_flow'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProspectAutomationByWeek
     */
    'prospect_in_sequence'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProspectAutomationByWeek
     */
    'week'?: number;
}
/**
 * Prospect associated with this task
 * @export
 * @interface ProspectDto
 */
export interface ProspectDto {
    /**
     * Prospect company email
     * @type {string}
     * @memberof ProspectDto
     */
    'companyEmail'?: string;
    /**
     * Contact email of a Prospect
     * @type {string}
     * @memberof ProspectDto
     */
    'email'?: string;
    /**
     * First name of a Prospect
     * @type {string}
     * @memberof ProspectDto
     */
    'firstName'?: string;
    /**
     * Prospect id
     * @type {string}
     * @memberof ProspectDto
     */
    'id'?: string;
    /**
     * Job title of a prospect
     * @type {string}
     * @memberof ProspectDto
     */
    'jobTitle'?: string;
    /**
     * Last name of a Prospect
     * @type {string}
     * @memberof ProspectDto
     */
    'lastName'?: string;
    /**
     * Link to a prospect linkedin page
     * @type {string}
     * @memberof ProspectDto
     */
    'linkedinUrl'?: string;
    /**
     * Contact phone number of a Prospect
     * @type {string}
     * @memberof ProspectDto
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface ReportActivities
 */
export interface ReportActivities {
    /**
     * 
     * @type {number}
     * @memberof ReportActivities
     */
    'ai_messages_sent'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportActivities
     */
    'ai_time_saved_hours'?: number;
    /**
     * 
     * @type {Array<EntityActivities>}
     * @memberof ReportActivities
     */
    'entity_activities'?: Array<EntityActivities>;
    /**
     * 
     * @type {string}
     * @memberof ReportActivities
     */
    'status'?: ReportActivitiesStatusEnum;
}

export const ReportActivitiesStatusEnum = {
    LeadFlowNoConfig: 'LEAD_FLOW_NO_CONFIG',
    NoData: 'NO_DATA',
    Render: 'RENDER'
} as const;

export type ReportActivitiesStatusEnum = typeof ReportActivitiesStatusEnum[keyof typeof ReportActivitiesStatusEnum];

/**
 * 
 * @export
 * @interface ReportLeadFlowPerf
 */
export interface ReportLeadFlowPerf {
    /**
     * 
     * @type {LeadFlowPerf}
     * @memberof ReportLeadFlowPerf
     */
    'data'?: LeadFlowPerf;
    /**
     * 
     * @type {string}
     * @memberof ReportLeadFlowPerf
     */
    'status'?: ReportLeadFlowPerfStatusEnum;
}

export const ReportLeadFlowPerfStatusEnum = {
    LeadFlowNoConfig: 'LEAD_FLOW_NO_CONFIG',
    NoData: 'NO_DATA',
    Render: 'RENDER'
} as const;

export type ReportLeadFlowPerfStatusEnum = typeof ReportLeadFlowPerfStatusEnum[keyof typeof ReportLeadFlowPerfStatusEnum];

/**
 * 
 * @export
 * @interface ReportLeadFlowPerfByLeadSource
 */
export interface ReportLeadFlowPerfByLeadSource {
    /**
     * 
     * @type {Array<LeadFlowPerfByLeadSource>}
     * @memberof ReportLeadFlowPerfByLeadSource
     */
    'data'?: Array<LeadFlowPerfByLeadSource>;
    /**
     * 
     * @type {string}
     * @memberof ReportLeadFlowPerfByLeadSource
     */
    'status'?: ReportLeadFlowPerfByLeadSourceStatusEnum;
}

export const ReportLeadFlowPerfByLeadSourceStatusEnum = {
    LeadFlowNoConfig: 'LEAD_FLOW_NO_CONFIG',
    NoData: 'NO_DATA',
    Render: 'RENDER'
} as const;

export type ReportLeadFlowPerfByLeadSourceStatusEnum = typeof ReportLeadFlowPerfByLeadSourceStatusEnum[keyof typeof ReportLeadFlowPerfByLeadSourceStatusEnum];

/**
 * 
 * @export
 * @interface ReportLeadFlowQual
 */
export interface ReportLeadFlowQual {
    /**
     * 
     * @type {Array<LeadQualifying>}
     * @memberof ReportLeadFlowQual
     */
    'data'?: Array<LeadQualifying>;
    /**
     * 
     * @type {string}
     * @memberof ReportLeadFlowQual
     */
    'status'?: ReportLeadFlowQualStatusEnum;
}

export const ReportLeadFlowQualStatusEnum = {
    LeadFlowNoConfig: 'LEAD_FLOW_NO_CONFIG',
    NoData: 'NO_DATA',
    Render: 'RENDER'
} as const;

export type ReportLeadFlowQualStatusEnum = typeof ReportLeadFlowQualStatusEnum[keyof typeof ReportLeadFlowQualStatusEnum];

/**
 * 
 * @export
 * @interface ReportProspectAutomation
 */
export interface ReportProspectAutomation {
    /**
     * 
     * @type {Array<ProspectAutomationByWeek>}
     * @memberof ReportProspectAutomation
     */
    'data'?: Array<ProspectAutomationByWeek>;
    /**
     * 
     * @type {string}
     * @memberof ReportProspectAutomation
     */
    'status'?: ReportProspectAutomationStatusEnum;
}

export const ReportProspectAutomationStatusEnum = {
    LeadFlowNoConfig: 'LEAD_FLOW_NO_CONFIG',
    NoData: 'NO_DATA',
    Render: 'RENDER'
} as const;

export type ReportProspectAutomationStatusEnum = typeof ReportProspectAutomationStatusEnum[keyof typeof ReportProspectAutomationStatusEnum];

/**
 * 
 * @export
 * @interface RequestAuthorizeUrlDto
 */
export interface RequestAuthorizeUrlDto {
    /**
     * 
     * @type {string}
     * @memberof RequestAuthorizeUrlDto
     */
    'redirectUri': string;
}
/**
 * 
 * @export
 * @interface SalesProcessRequest
 */
export interface SalesProcessRequest {
    /**
     * 
     * @type {Array<AiSalesProcessObjection>}
     * @memberof SalesProcessRequest
     */
    'objections'?: Array<AiSalesProcessObjection>;
}
/**
 * contact info
 * @export
 * @interface SequenceContact
 */
export interface SequenceContact {
    /**
     * contact email
     * @type {string}
     * @memberof SequenceContact
     */
    'email'?: string;
    /**
     * contact id
     * @type {string}
     * @memberof SequenceContact
     */
    'id'?: string;
    /**
     * contact name
     * @type {string}
     * @memberof SequenceContact
     */
    'name'?: string;
    /**
     * phone number
     * @type {string}
     * @memberof SequenceContact
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface SequenceRequestLeadSourceTrigger
 */
export interface SequenceRequestLeadSourceTrigger {
    /**
     * Lead Source ID.
     * @type {string}
     * @memberof SequenceRequestLeadSourceTrigger
     */
    'leadSourceId'?: string;
    /**
     * Sequence Template ID
     * @type {string}
     * @memberof SequenceRequestLeadSourceTrigger
     */
    'sequenceTemplateId'?: string;
}
/**
 * 
 * @export
 * @interface SequenceRequestMultipleTrigger
 */
export interface SequenceRequestMultipleTrigger {
    /**
     * List of contact IDs.
     * @type {Array<string>}
     * @memberof SequenceRequestMultipleTrigger
     */
    'contacts'?: Array<string>;
    /**
     * Sequence Template ID
     * @type {string}
     * @memberof SequenceRequestMultipleTrigger
     */
    'sequenceTemplateId'?: string;
}
/**
 * 
 * @export
 * @interface SequenceRequestTrigger
 */
export interface SequenceRequestTrigger {
    /**
     * Sequence Template ID
     * @type {string}
     * @memberof SequenceRequestTrigger
     */
    'sequenceTemplateId'?: string;
    /**
     * contact ID.
     * @type {string}
     * @memberof SequenceRequestTrigger
     */
    'contactId'?: string;
}
/**
 * 
 * @export
 * @interface SequenceResponse
 */
export interface SequenceResponse {
    /**
     * Sequence identification.
     * @type {string}
     * @memberof SequenceResponse
     */
    'id'?: string;
    /**
     * Campaign/Sequence name.
     * @type {string}
     * @memberof SequenceResponse
     */
    'name'?: string;
    /**
     * 
     * @type {SequenceTriggerScheduleResponse}
     * @memberof SequenceResponse
     */
    'schedule'?: SequenceTriggerScheduleResponse;
    /**
     * Sequence status.
     * @type {string}
     * @memberof SequenceResponse
     */
    'status'?: SequenceResponseStatusEnum;
    /**
     * Total amount of steps in a sequence.
     * @type {number}
     * @memberof SequenceResponse
     */
    'countSteps'?: number;
    /**
     * Amount of steps with status completed.
     * @type {number}
     * @memberof SequenceResponse
     */
    'countStepsCompleted'?: number;
    /**
     * Milliseconds representing the time left in a sequence.
     * @type {number}
     * @memberof SequenceResponse
     */
    'missingStepsDuration'?: number;
    /**
     * The date and time when the sequence was triggered.
     * @type {string}
     * @memberof SequenceResponse
     */
    'triggeredAt'?: string;
    /**
     * User id from the user that triggered it.
     * @type {string}
     * @memberof SequenceResponse
     */
    'triggeredBy'?: string;
}

export const SequenceResponseStatusEnum = {
    InProgress: 'IN_PROGRESS',
    PausedInProspectLevel: 'PAUSED_IN_PROSPECT_LEVEL',
    PausedInTemplateLevel: 'PAUSED_IN_TEMPLATE_LEVEL',
    PausedInTeamLevel: 'PAUSED_IN_TEAM_LEVEL',
    Completed: 'COMPLETED',
    ContactAnswered: 'CONTACT_ANSWERED',
    Canceled: 'CANCELED',
    Unenrolled: 'UNENROLLED'
} as const;

export type SequenceResponseStatusEnum = typeof SequenceResponseStatusEnum[keyof typeof SequenceResponseStatusEnum];

/**
 * 
 * @export
 * @interface SequenceTemplateRequestCreate
 */
export interface SequenceTemplateRequestCreate {
    /**
     * The name of the sequence/campaign
     * @type {string}
     * @memberof SequenceTemplateRequestCreate
     */
    'name'?: string;
    /**
     * 
     * @type {SequenceTriggerScheduleRequest}
     * @memberof SequenceTemplateRequestCreate
     */
    'schedule'?: SequenceTriggerScheduleRequest;
}
/**
 * 
 * @export
 * @interface SequenceTemplateRequestUpdate
 */
export interface SequenceTemplateRequestUpdate {
    /**
     * Sequence Template identification.
     * @type {string}
     * @memberof SequenceTemplateRequestUpdate
     */
    'id': string;
    /**
     * The name of the sequence/campaign
     * @type {string}
     * @memberof SequenceTemplateRequestUpdate
     */
    'name'?: string;
    /**
     * DRAFT - no one can enroll in it yet, all types of updates are allowed. Only one version in this state can exist each time. Cannot be manually set, system will determine when to use it. ACTIVE - is the only status that a contact can be enrolled. Only information updates are allowed (name, message…). Only one ACTIVE or INACTIVE version can exist each time. INACTIVE -  it cannot be reverted. No new contacts will be able to enroll on it, and the version will remain in this state while any sequence remains in progress. Once all contacts have finished their sequence, it will automatically be updated to DELETED. Cannot be manually set, system will determine when to use it. DELETED - it cannot be reverted. To get to this status the version cannot have any contacts enrolled to it. All information about this version steps will be deleted.
     * @type {string}
     * @memberof SequenceTemplateRequestUpdate
     */
    'status'?: SequenceTemplateRequestUpdateStatusEnum;
    /**
     * 
     * @type {SequenceTriggerScheduleRequest}
     * @memberof SequenceTemplateRequestUpdate
     */
    'schedule'?: SequenceTriggerScheduleRequest;
}

export const SequenceTemplateRequestUpdateStatusEnum = {
    Active: 'ACTIVE',
    Draft: 'DRAFT',
    Inactive: 'INACTIVE',
    PausedInTemplateLevel: 'PAUSED_IN_TEMPLATE_LEVEL',
    PausedInTeamLevel: 'PAUSED_IN_TEAM_LEVEL',
    Deleted: 'DELETED'
} as const;

export type SequenceTemplateRequestUpdateStatusEnum = typeof SequenceTemplateRequestUpdateStatusEnum[keyof typeof SequenceTemplateRequestUpdateStatusEnum];

/**
 * 
 * @export
 * @interface SequenceTemplateResponse
 */
export interface SequenceTemplateResponse {
    /**
     * Sequence Template identification.
     * @type {string}
     * @memberof SequenceTemplateResponse
     */
    'id'?: string;
    /**
     * The name of the sequence/campaign
     * @type {string}
     * @memberof SequenceTemplateResponse
     */
    'name'?: string;
    /**
     * 
     * @type {SequenceTriggerScheduleResponse}
     * @memberof SequenceTemplateResponse
     */
    'schedule'?: SequenceTriggerScheduleResponse;
    /**
     * DRAFT - no one can enroll in it yet, all types of updates are allowed. Only one version in this state can exist each time. Cannot be manually set, system will determine when to use it. ACTIVE - is the only status that a contact can be enrolled. Only information updates are allowed (name, message ...). Only one ACTIVE or INACTIVE version can exist each time. INACTIVE - it cannot be reverted. No new contacts will be able to enroll on it, and the version will remain in this state while any sequence remains in progress. Once all contacts have finished their sequence, it will automatically be updated to DELETED. Cannot be manually set, system will determine when to use it. DELETED - it cannot be reverted. To get to this status the version cannot have any contacts enrolled to it. All information about this version steps will be deleted.
     * @type {string}
     * @memberof SequenceTemplateResponse
     */
    'status'?: SequenceTemplateResponseStatusEnum;
    /**
     * The version of the sequence-template.
     * @type {number}
     * @memberof SequenceTemplateResponse
     */
    'version'?: number;
    /**
     * Amount of prospects ever enrolled up to this version of the sequence template.
     * @type {number}
     * @memberof SequenceTemplateResponse
     */
    'countContactsEnrolledUpToVersion'?: number;
    /**
     * Amount of prospects currently enrolled in this sequence template.
     * @type {number}
     * @memberof SequenceTemplateResponse
     */
    'countProspectsCurrentEnrolled'?: number;
    /**
     * Amount of prospects ever enrolled in this sequence template.
     * @type {number}
     * @memberof SequenceTemplateResponse
     */
    'countProspectsEverEnrolled'?: number;
    /**
     * The id of the first version of this sequence template.
     * @type {string}
     * @memberof SequenceTemplateResponse
     */
    'originalSequenceTemplateId'?: string;
    /**
     * The id of the previous version of this sequence template.
     * @type {string}
     * @memberof SequenceTemplateResponse
     */
    'parentSequenceTemplateId'?: string;
    /**
     * Team id from the team that owns this sequence template.
     * @type {string}
     * @memberof SequenceTemplateResponse
     */
    'teamId'?: string;
    /**
     * The date and time when the sequence template was created
     * @type {string}
     * @memberof SequenceTemplateResponse
     */
    'createdAt'?: string;
    /**
     * The date and time when the sequence template was last updated
     * @type {string}
     * @memberof SequenceTemplateResponse
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {SequenceUser}
     * @memberof SequenceTemplateResponse
     */
    'createdBy'?: SequenceUser;
    /**
     * User id from the last updater
     * @type {string}
     * @memberof SequenceTemplateResponse
     */
    'updatedBy'?: string;
}

export const SequenceTemplateResponseStatusEnum = {
    Active: 'ACTIVE',
    Draft: 'DRAFT',
    Inactive: 'INACTIVE',
    PausedInTemplateLevel: 'PAUSED_IN_TEMPLATE_LEVEL',
    PausedInTeamLevel: 'PAUSED_IN_TEAM_LEVEL',
    Deleted: 'DELETED'
} as const;

export type SequenceTemplateResponseStatusEnum = typeof SequenceTemplateResponseStatusEnum[keyof typeof SequenceTemplateResponseStatusEnum];

/**
 * 
 * @export
 * @interface SequenceTemplateResponseDesc
 */
export interface SequenceTemplateResponseDesc {
    /**
     * The name of the sequence/campaign
     * @type {string}
     * @memberof SequenceTemplateResponseDesc
     */
    'name'?: string;
    /**
     * ACTIVE for sequences that are ready to be used DRAFT for sequences that are still being constructed
     * @type {string}
     * @memberof SequenceTemplateResponseDesc
     */
    'status'?: SequenceTemplateResponseDescStatusEnum;
    /**
     * Sequence identification
     * @type {string}
     * @memberof SequenceTemplateResponseDesc
     */
    'id'?: string;
    /**
     * The version of the sequence-template.
     * @type {number}
     * @memberof SequenceTemplateResponseDesc
     */
    'version'?: number;
    /**
     * Total amount of steps in a sequence.
     * @type {number}
     * @memberof SequenceTemplateResponseDesc
     */
    'countSteps'?: number;
    /**
     * Amount of SMS in a sequence.
     * @type {number}
     * @memberof SequenceTemplateResponseDesc
     */
    'countSMS'?: number;
    /**
     * Amount of emails  in a sequence.
     * @type {number}
     * @memberof SequenceTemplateResponseDesc
     */
    'countEmails'?: number;
    /**
     * Amount of calls  in a sequence.
     * @type {number}
     * @memberof SequenceTemplateResponseDesc
     */
    'countCalls'?: number;
    /**
     * Amount other type of steps in a sequence template.
     * @type {number}
     * @memberof SequenceTemplateResponseDesc
     */
    'countOthers'?: number;
    /**
     * Milliseconds representing the time a sequence should take to be completed.
     * @type {number}
     * @memberof SequenceTemplateResponseDesc
     */
    'totalDuration'?: number;
    /**
     * Amount of prospects currently enrolled in this sequence template.
     * @type {number}
     * @memberof SequenceTemplateResponseDesc
     */
    'countProspectsCurrentEnrolled'?: number;
    /**
     * Amount of prospects ever enrolled in this sequence template.
     * @type {number}
     * @memberof SequenceTemplateResponseDesc
     */
    'countProspectsEverEnrolled'?: number;
    /**
     * Amount of prospects ever enrolled up to this version of the sequence template.
     * @type {number}
     * @memberof SequenceTemplateResponseDesc
     */
    'countContactsEnrolledUpToVersion'?: number;
    /**
     * The date and time when the sequence template was created
     * @type {string}
     * @memberof SequenceTemplateResponseDesc
     */
    'createdAt'?: string;
    /**
     * 
     * @type {SequenceUser}
     * @memberof SequenceTemplateResponseDesc
     */
    'createdBy'?: SequenceUser;
}

export const SequenceTemplateResponseDescStatusEnum = {
    Active: 'ACTIVE',
    Draft: 'DRAFT',
    Inactive: 'INACTIVE',
    PausedInTemplateLevel: 'PAUSED_IN_TEMPLATE_LEVEL',
    PausedInTeamLevel: 'PAUSED_IN_TEAM_LEVEL',
    Deleted: 'DELETED'
} as const;

export type SequenceTemplateResponseDescStatusEnum = typeof SequenceTemplateResponseDescStatusEnum[keyof typeof SequenceTemplateResponseDescStatusEnum];

/**
 * 
 * @export
 * @interface SequenceTemplateResponseGroup
 */
export interface SequenceTemplateResponseGroup {
    /**
     * Whether the sequence template can be paused
     * @type {boolean}
     * @memberof SequenceTemplateResponseGroup
     */
    'canPause'?: boolean;
    /**
     * The date and time when the sequence template was last updated
     * @type {string}
     * @memberof SequenceTemplateResponseGroup
     */
    'updatedAt'?: string;
    /**
     * The date and time when the sequence template was first created
     * @type {string}
     * @memberof SequenceTemplateResponseGroup
     */
    'createdAt'?: string;
    /**
     * 
     * @type {SequenceUser}
     * @memberof SequenceTemplateResponseGroup
     */
    'createdBy'?: SequenceUser;
    /**
     * The id of the latest version of this sequence template.
     * @type {string}
     * @memberof SequenceTemplateResponseGroup
     */
    'latestSequenceTemplateId'?: string;
    /**
     * The status of the last version of the sequence templates (matching the filter)
     * @type {string}
     * @memberof SequenceTemplateResponseGroup
     */
    'latestStatus'?: SequenceTemplateResponseGroupLatestStatusEnum;
    /**
     * The latest version of the sequence-template.
     * @type {number}
     * @memberof SequenceTemplateResponseGroup
     */
    'latestVersion'?: number;
    /**
     * The name of the latest version of the sequence
     * @type {string}
     * @memberof SequenceTemplateResponseGroup
     */
    'name'?: string;
    /**
     * The id of the first version of this sequence template.
     * @type {string}
     * @memberof SequenceTemplateResponseGroup
     */
    'originalSequenceTemplateId'?: string;
    /**
     * List of all versions of the sequence-template matching the filter
     * @type {Array<SequenceTemplateResponse>}
     * @memberof SequenceTemplateResponseGroup
     */
    'sequenceTemplateVersionsList'?: Array<SequenceTemplateResponse>;
    /**
     * Sum of current amount of prospects enrolled in every version of this sequence-template.
     * @type {number}
     * @memberof SequenceTemplateResponseGroup
     */
    'sumProspectsEnrolled'?: number;
    /**
     * Sum of amount of prospects ever enrolled in every version of this sequence-template.
     * @type {number}
     * @memberof SequenceTemplateResponseGroup
     */
    'sumProspectsEverEnrolled'?: number;
}

export const SequenceTemplateResponseGroupLatestStatusEnum = {
    Active: 'ACTIVE',
    Draft: 'DRAFT',
    Inactive: 'INACTIVE',
    PausedInTemplateLevel: 'PAUSED_IN_TEMPLATE_LEVEL',
    PausedInTeamLevel: 'PAUSED_IN_TEAM_LEVEL',
    Deleted: 'DELETED'
} as const;

export type SequenceTemplateResponseGroupLatestStatusEnum = typeof SequenceTemplateResponseGroupLatestStatusEnum[keyof typeof SequenceTemplateResponseGroupLatestStatusEnum];

/**
 * 
 * @export
 * @interface SequenceTemplateResponseWithStepsDTO
 */
export interface SequenceTemplateResponseWithStepsDTO {
    /**
     * 
     * @type {SequenceTemplateResponse}
     * @memberof SequenceTemplateResponseWithStepsDTO
     */
    'sequenceTemplate'?: SequenceTemplateResponse;
    /**
     * 
     * @type {Array<StepTemplateResponse>}
     * @memberof SequenceTemplateResponseWithStepsDTO
     */
    'steps'?: Array<StepTemplateResponse>;
    /**
     * 
     * @type {Array<SequenceTemplateVersionResponse>}
     * @memberof SequenceTemplateResponseWithStepsDTO
     */
    'versions'?: Array<SequenceTemplateVersionResponse>;
}
/**
 * 
 * @export
 * @interface SequenceTemplateVersionResponse
 */
export interface SequenceTemplateVersionResponse {
    /**
     * Sequence Template identification.
     * @type {string}
     * @memberof SequenceTemplateVersionResponse
     */
    'id'?: string;
    /**
     * The status of the sequence-template.
     * @type {string}
     * @memberof SequenceTemplateVersionResponse
     */
    'status'?: SequenceTemplateVersionResponseStatusEnum;
    /**
     * The version of the sequence-template.
     * @type {number}
     * @memberof SequenceTemplateVersionResponse
     */
    'version'?: number;
}

export const SequenceTemplateVersionResponseStatusEnum = {
    Active: 'ACTIVE',
    Draft: 'DRAFT',
    Inactive: 'INACTIVE',
    PausedInTemplateLevel: 'PAUSED_IN_TEMPLATE_LEVEL',
    PausedInTeamLevel: 'PAUSED_IN_TEAM_LEVEL',
    Deleted: 'DELETED'
} as const;

export type SequenceTemplateVersionResponseStatusEnum = typeof SequenceTemplateVersionResponseStatusEnum[keyof typeof SequenceTemplateVersionResponseStatusEnum];

/**
 * The schedule in which the steps will be allowed to be sent
 * @export
 * @interface SequenceTriggerScheduleRequest
 */
export interface SequenceTriggerScheduleRequest {
    /**
     * The hour in which the business hours start
     * @type {string}
     * @memberof SequenceTriggerScheduleRequest
     */
    'businessHoursEnd'?: string;
    /**
     * The hour in which the business hours ends
     * @type {string}
     * @memberof SequenceTriggerScheduleRequest
     */
    'businessHoursStart'?: string;
    /**
     * The timezone in which the business hours are in
     * @type {string}
     * @memberof SequenceTriggerScheduleRequest
     */
    'businessHoursTimezone'?: string;
    /**
     * The days of the week the steps can be sent
     * @type {Array<string>}
     * @memberof SequenceTriggerScheduleRequest
     */
    'daysOfWeek'?: Array<SequenceTriggerScheduleRequestDaysOfWeekEnum>;
    /**
     * schedule ID
     * @type {string}
     * @memberof SequenceTriggerScheduleRequest
     */
    'id'?: string;
}

export const SequenceTriggerScheduleRequestDaysOfWeekEnum = {
    Monday: 'MONDAY',
    Tuesday: 'TUESDAY',
    Wednesday: 'WEDNESDAY',
    Thursday: 'THURSDAY',
    Friday: 'FRIDAY',
    Saturday: 'SATURDAY',
    Sunday: 'SUNDAY'
} as const;

export type SequenceTriggerScheduleRequestDaysOfWeekEnum = typeof SequenceTriggerScheduleRequestDaysOfWeekEnum[keyof typeof SequenceTriggerScheduleRequestDaysOfWeekEnum];

/**
 * The schedule in which the steps will be allowed to be sent
 * @export
 * @interface SequenceTriggerScheduleResponse
 */
export interface SequenceTriggerScheduleResponse {
    /**
     * The hour in which the business hours start
     * @type {string}
     * @memberof SequenceTriggerScheduleResponse
     */
    'businessHoursEnd'?: string;
    /**
     * The hour in which the business hours ends
     * @type {string}
     * @memberof SequenceTriggerScheduleResponse
     */
    'businessHoursStart'?: string;
    /**
     * The timezone in which the business hours are in
     * @type {string}
     * @memberof SequenceTriggerScheduleResponse
     */
    'businessHoursTimezone'?: string;
    /**
     * The days of the week the steps can be sent
     * @type {Array<string>}
     * @memberof SequenceTriggerScheduleResponse
     */
    'daysOfWeek'?: Array<SequenceTriggerScheduleResponseDaysOfWeekEnum>;
    /**
     * schedule ID
     * @type {string}
     * @memberof SequenceTriggerScheduleResponse
     */
    'id'?: string;
}

export const SequenceTriggerScheduleResponseDaysOfWeekEnum = {
    Monday: 'MONDAY',
    Tuesday: 'TUESDAY',
    Wednesday: 'WEDNESDAY',
    Thursday: 'THURSDAY',
    Friday: 'FRIDAY',
    Saturday: 'SATURDAY',
    Sunday: 'SUNDAY'
} as const;

export type SequenceTriggerScheduleResponseDaysOfWeekEnum = typeof SequenceTriggerScheduleResponseDaysOfWeekEnum[keyof typeof SequenceTriggerScheduleResponseDaysOfWeekEnum];

/**
 * User who created the sequence template
 * @export
 * @interface SequenceUser
 */
export interface SequenceUser {
    /**
     * User\'s id.
     * @type {string}
     * @memberof SequenceUser
     */
    'id'?: string;
    /**
     * Link to user\'s image URL.
     * @type {string}
     * @memberof SequenceUser
     */
    'imageUrl'?: string;
    /**
     * User name.
     * @type {string}
     * @memberof SequenceUser
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface SmsDTO
 */
export interface SmsDTO {
    /**
     * 
     * @type {string}
     * @memberof SmsDTO
     */
    '_CHANGE_TYPE'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsDTO
     */
    'action'?: SmsDTOActionEnum;
    /**
     * 
     * @type {string}
     * @memberof SmsDTO
     */
    'body': string;
    /**
     * 
     * @type {string}
     * @memberof SmsDTO
     */
    'createdAt'?: string;
    /**
     * 
     * @type {Array<MessageEventDTO>}
     * @memberof SmsDTO
     */
    'events'?: Array<MessageEventDTO>;
    /**
     * 
     * @type {string}
     * @memberof SmsDTO
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsDTO
     */
    'from_user_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof SmsDTO
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SmsDTO
     */
    'is_ai'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SmsDTO
     */
    'is_ai_user_intv'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SmsDTO
     */
    'outreach_thread_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SmsDTO
     */
    'prospect_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsDTO
     */
    'provider_message_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsDTO
     */
    'provider_sequence_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsDTO
     */
    'provider_thread_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsDTO
     */
    'sequence_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsDTO
     */
    'source'?: SmsDTOSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof SmsDTO
     */
    'status'?: SmsDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SmsDTO
     */
    'team_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsDTO
     */
    'to'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsDTO
     */
    'to_user_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SmsDTO
     */
    'updatedAt'?: string;
}

export const SmsDTOActionEnum = {
    Draft: 'DRAFT',
    Send: 'SEND',
    Receive: 'RECEIVE'
} as const;

export type SmsDTOActionEnum = typeof SmsDTOActionEnum[keyof typeof SmsDTOActionEnum];
export const SmsDTOSourceEnum = {
    Inbound: 'INBOUND',
    Outbound: 'OUTBOUND'
} as const;

export type SmsDTOSourceEnum = typeof SmsDTOSourceEnum[keyof typeof SmsDTOSourceEnum];
export const SmsDTOStatusEnum = {
    Created: 'CREATED',
    Updated: 'UPDATED',
    Saved: 'SAVED',
    Forwarded: 'FORWARDED',
    Queued: 'QUEUED',
    Sending: 'SENDING',
    Sent: 'SENT',
    SendingFailed: 'SENDING_FAILED',
    DeliveredFailed: 'DELIVERED_FAILED',
    DeliveredUnconfirmed: 'DELIVERED_UNCONFIRMED',
    Delivered: 'DELIVERED',
    Received: 'RECEIVED',
    Broadcasted: 'BROADCASTED',
    BroadcastedEvent: 'BROADCASTED_EVENT',
    Open: 'OPEN'
} as const;

export type SmsDTOStatusEnum = typeof SmsDTOStatusEnum[keyof typeof SmsDTOStatusEnum];

/**
 * 
 * @export
 * @interface StageRequestCreate
 */
export interface StageRequestCreate {
    /**
     * The color in RGB format
     * @type {string}
     * @memberof StageRequestCreate
     */
    'color'?: string;
    /**
     * A brief description of the stage
     * @type {string}
     * @memberof StageRequestCreate
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StageRequestCreate
     */
    'close'?: boolean;
    /**
     * The name of the stage
     * @type {string}
     * @memberof StageRequestCreate
     */
    'name'?: string;
    /**
     * The order in which the stage should be displayed
     * @type {number}
     * @memberof StageRequestCreate
     */
    'order'?: number;
    /**
     * Pipeline\'s id.
     * @type {string}
     * @memberof StageRequestCreate
     */
    'pipelineId'?: string;
}
/**
 * 
 * @export
 * @interface StageRequestUpdate
 */
export interface StageRequestUpdate {
    /**
     * The color in RGB format
     * @type {string}
     * @memberof StageRequestUpdate
     */
    'color'?: string;
    /**
     * A brief description of the stage
     * @type {string}
     * @memberof StageRequestUpdate
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StageRequestUpdate
     */
    'close'?: boolean;
    /**
     * Stage\'s id.
     * @type {string}
     * @memberof StageRequestUpdate
     */
    'id'?: string;
    /**
     * The name of the stage
     * @type {string}
     * @memberof StageRequestUpdate
     */
    'name'?: string;
    /**
     * The order in which the stage should be displayed
     * @type {number}
     * @memberof StageRequestUpdate
     */
    'order'?: number;
}
/**
 * 
 * @export
 * @interface StepTemplateRequestCreate
 */
export interface StepTemplateRequestCreate {
    /**
     * Sequence Template ID.
     * @type {string}
     * @memberof StepTemplateRequestCreate
     */
    'sequenceTemplateId'?: string;
    /**
     * The type of the step
     * @type {string}
     * @memberof StepTemplateRequestCreate
     */
    'type'?: StepTemplateRequestCreateTypeEnum;
    /**
     * The amount of delay days that should be applied
     * @type {number}
     * @memberof StepTemplateRequestCreate
     */
    'delayDays'?: number;
    /**
     * The amount of delay hours that should be applied
     * @type {number}
     * @memberof StepTemplateRequestCreate
     */
    'delayHours'?: number;
    /**
     * The amount of delay minutes that should be applied
     * @type {number}
     * @memberof StepTemplateRequestCreate
     */
    'delayMinutes'?: number;
    /**
     * The amount of delay seconds that should be applied
     * @type {number}
     * @memberof StepTemplateRequestCreate
     */
    'delaySeconds'?: number;
    /**
     * The from email to be sent in this step.
     * @type {string}
     * @memberof StepTemplateRequestCreate
     */
    'fromEmail'?: string;
    /**
     * The message subject to be sent in this step.
     * @type {string}
     * @memberof StepTemplateRequestCreate
     */
    'subjectTemplate'?: string;
    /**
     * The message to be sent in this step.
     * @type {string}
     * @memberof StepTemplateRequestCreate
     */
    'messageTemplate'?: string;
    /**
     * The id of the agent that will perform the ai call.
     * @type {string}
     * @memberof StepTemplateRequestCreate
     */
    'agentId'?: string;
    /**
     * A list of ids from the previous steps that will lead to this step. The first stepTemplate will not have a parent. System will always call the child step after each parent. Same child can have more than one parent (paths that lead to that step). And if a step has more than one child it will be determined which one to trigger by the flowControl field.
     * @type {Array<string>}
     * @memberof StepTemplateRequestCreate
     */
    'parentsIds'?: Array<string>;
    /**
     * The type of flow the step should take
     * @type {string}
     * @memberof StepTemplateRequestCreate
     */
    'flowControl'?: StepTemplateRequestCreateFlowControlEnum;
    /**
     * When step has an AB testing, this field will determine which step is A and which is B.
     * @type {string}
     * @memberof StepTemplateRequestCreate
     */
    'flowControlAB'?: StepTemplateRequestCreateFlowControlABEnum;
    /**
     * When step has a consent condition, this field will determine for which consent types the step can be applied.
     * @type {Array<string>}
     * @memberof StepTemplateRequestCreate
     */
    'flowControlConsentList'?: Array<StepTemplateRequestCreateFlowControlConsentListEnum>;
    /**
     * When true, the steps that had the parent in request will be updated to have this step as parent.
     * @type {boolean}
     * @memberof StepTemplateRequestCreate
     */
    'automaticallyUpdateParents'?: boolean;
    /**
     * When true, if the parent had a DRIP it will be associated to the new step.
     * @type {boolean}
     * @memberof StepTemplateRequestCreate
     */
    'reclaimDrip'?: boolean;
    /**
     * 
     * @type {DripTemplateRequest}
     * @memberof StepTemplateRequestCreate
     */
    'dripTemplate'?: DripTemplateRequest;
}

export const StepTemplateRequestCreateTypeEnum = {
    Email: 'EMAIL',
    Sms: 'SMS',
    Call: 'CALL',
    Manual: 'MANUAL'
} as const;

export type StepTemplateRequestCreateTypeEnum = typeof StepTemplateRequestCreateTypeEnum[keyof typeof StepTemplateRequestCreateTypeEnum];
export const StepTemplateRequestCreateFlowControlEnum = {
    Ab: 'AB',
    Consent: 'CONSENT'
} as const;

export type StepTemplateRequestCreateFlowControlEnum = typeof StepTemplateRequestCreateFlowControlEnum[keyof typeof StepTemplateRequestCreateFlowControlEnum];
export const StepTemplateRequestCreateFlowControlABEnum = {
    A: 'A',
    B: 'B'
} as const;

export type StepTemplateRequestCreateFlowControlABEnum = typeof StepTemplateRequestCreateFlowControlABEnum[keyof typeof StepTemplateRequestCreateFlowControlABEnum];
export const StepTemplateRequestCreateFlowControlConsentListEnum = {
    None: 'OPT_IN_NONE',
    Single: 'OPT_IN_SINGLE',
    Double: 'OPT_IN_DOUBLE'
} as const;

export type StepTemplateRequestCreateFlowControlConsentListEnum = typeof StepTemplateRequestCreateFlowControlConsentListEnum[keyof typeof StepTemplateRequestCreateFlowControlConsentListEnum];

/**
 * 
 * @export
 * @interface StepTemplateRequestUpdateBehavior
 */
export interface StepTemplateRequestUpdateBehavior {
    /**
     * Step Template Id
     * @type {string}
     * @memberof StepTemplateRequestUpdateBehavior
     */
    'stepTemplateId'?: string;
    /**
     * A list of ids from the previous steps that will lead to this step.
     * @type {Array<string>}
     * @memberof StepTemplateRequestUpdateBehavior
     */
    'parentsIds'?: Array<string>;
    /**
     * The type of flow the step should take
     * @type {string}
     * @memberof StepTemplateRequestUpdateBehavior
     */
    'flowControl'?: StepTemplateRequestUpdateBehaviorFlowControlEnum;
    /**
     * When step has an AB testing, this field will determine which step is A and which is B.
     * @type {string}
     * @memberof StepTemplateRequestUpdateBehavior
     */
    'flowControlAB'?: StepTemplateRequestUpdateBehaviorFlowControlABEnum;
    /**
     * When step has a consent condition, this field will determine for which consent types the step can be applied.
     * @type {Array<string>}
     * @memberof StepTemplateRequestUpdateBehavior
     */
    'flowControlConsentList'?: Array<StepTemplateRequestUpdateBehaviorFlowControlConsentListEnum>;
}

export const StepTemplateRequestUpdateBehaviorFlowControlEnum = {
    Ab: 'AB',
    Consent: 'CONSENT'
} as const;

export type StepTemplateRequestUpdateBehaviorFlowControlEnum = typeof StepTemplateRequestUpdateBehaviorFlowControlEnum[keyof typeof StepTemplateRequestUpdateBehaviorFlowControlEnum];
export const StepTemplateRequestUpdateBehaviorFlowControlABEnum = {
    A: 'A',
    B: 'B'
} as const;

export type StepTemplateRequestUpdateBehaviorFlowControlABEnum = typeof StepTemplateRequestUpdateBehaviorFlowControlABEnum[keyof typeof StepTemplateRequestUpdateBehaviorFlowControlABEnum];
export const StepTemplateRequestUpdateBehaviorFlowControlConsentListEnum = {
    None: 'OPT_IN_NONE',
    Single: 'OPT_IN_SINGLE',
    Double: 'OPT_IN_DOUBLE'
} as const;

export type StepTemplateRequestUpdateBehaviorFlowControlConsentListEnum = typeof StepTemplateRequestUpdateBehaviorFlowControlConsentListEnum[keyof typeof StepTemplateRequestUpdateBehaviorFlowControlConsentListEnum];

/**
 * 
 * @export
 * @interface StepTemplateRequestUpdateInfo
 */
export interface StepTemplateRequestUpdateInfo {
    /**
     * Step Id
     * @type {string}
     * @memberof StepTemplateRequestUpdateInfo
     */
    'id'?: string;
    /**
     * The type of the step
     * @type {string}
     * @memberof StepTemplateRequestUpdateInfo
     */
    'type'?: StepTemplateRequestUpdateInfoTypeEnum;
    /**
     * The amount of delay days that should be applied
     * @type {number}
     * @memberof StepTemplateRequestUpdateInfo
     */
    'delayDays'?: number;
    /**
     * The amount of delay hours that should be applied
     * @type {number}
     * @memberof StepTemplateRequestUpdateInfo
     */
    'delayHours'?: number;
    /**
     * The amount of delay minutes that should be applied
     * @type {number}
     * @memberof StepTemplateRequestUpdateInfo
     */
    'delayMinutes'?: number;
    /**
     * The amount of delay seconds that should be applied
     * @type {number}
     * @memberof StepTemplateRequestUpdateInfo
     */
    'delaySeconds'?: number;
    /**
     * The from email to be sent in this step.
     * @type {string}
     * @memberof StepTemplateRequestUpdateInfo
     */
    'fromEmail'?: string;
    /**
     * The message subject to be sent in this step.
     * @type {string}
     * @memberof StepTemplateRequestUpdateInfo
     */
    'subjectTemplate'?: string;
    /**
     * The message to be sent in this step.
     * @type {string}
     * @memberof StepTemplateRequestUpdateInfo
     */
    'messageTemplate'?: string;
    /**
     * The id of the agent that will perform the ai call.
     * @type {string}
     * @memberof StepTemplateRequestUpdateInfo
     */
    'agentId'?: string;
}

export const StepTemplateRequestUpdateInfoTypeEnum = {
    Email: 'EMAIL',
    Sms: 'SMS',
    Call: 'CALL',
    Manual: 'MANUAL'
} as const;

export type StepTemplateRequestUpdateInfoTypeEnum = typeof StepTemplateRequestUpdateInfoTypeEnum[keyof typeof StepTemplateRequestUpdateInfoTypeEnum];

/**
 * 
 * @export
 * @interface StepTemplateResponse
 */
export interface StepTemplateResponse {
    /**
     * step template identification.
     * @type {string}
     * @memberof StepTemplateResponse
     */
    'id'?: string;
    /**
     * Sequence Template ID.
     * @type {string}
     * @memberof StepTemplateResponse
     */
    'sequenceTemplateId'?: string;
    /**
     * The type of the step template
     * @type {string}
     * @memberof StepTemplateResponse
     */
    'type'?: StepTemplateResponseTypeEnum;
    /**
     * The amount of delay days that should be applied
     * @type {number}
     * @memberof StepTemplateResponse
     */
    'delayDays'?: number;
    /**
     * The amount of delay hours that should be applied
     * @type {number}
     * @memberof StepTemplateResponse
     */
    'delayHours'?: number;
    /**
     * The amount of delay minutes that should be applied
     * @type {number}
     * @memberof StepTemplateResponse
     */
    'delayMinutes'?: number;
    /**
     * The amount of delay seconds that should be applied
     * @type {number}
     * @memberof StepTemplateResponse
     */
    'delaySeconds'?: number;
    /**
     * The amount of time this step should wait to be triggered after previous step was marked as complete
     * @type {number}
     * @memberof StepTemplateResponse
     */
    'delayInMillis'?: number;
    /**
     * 
     * @type {DripTemplateResponse}
     * @memberof StepTemplateResponse
     */
    'dripTemplate'?: DripTemplateResponse;
    /**
     * The from email to be sent in this step.
     * @type {string}
     * @memberof StepTemplateResponse
     */
    'fromEmail'?: string;
    /**
     * The message subject to be sent in this step.
     * @type {string}
     * @memberof StepTemplateResponse
     */
    'subjectTemplate'?: string;
    /**
     * The message template to be sent in this step.
     * @type {string}
     * @memberof StepTemplateResponse
     */
    'messageTemplate'?: string;
    /**
     * The id of the agent that will perform the ai call.
     * @type {string}
     * @memberof StepTemplateResponse
     */
    'agentId'?: string;
    /**
     * A list of ids from the previous steps template that will lead to this step template.
     * @type {Array<string>}
     * @memberof StepTemplateResponse
     */
    'parentsIds'?: Array<string>;
    /**
     * The type of flow the step should take
     * @type {string}
     * @memberof StepTemplateResponse
     */
    'flowControl'?: StepTemplateResponseFlowControlEnum;
    /**
     * When step template has an AB testing, this field determines which is A and which is B.
     * @type {string}
     * @memberof StepTemplateResponse
     */
    'flowControlAB'?: StepTemplateResponseFlowControlABEnum;
    /**
     * When step has a consent condition, this field will determine for which consent types the step template can be applied.
     * @type {Array<string>}
     * @memberof StepTemplateResponse
     */
    'flowControlConsentList'?: Array<StepTemplateResponseFlowControlConsentListEnum>;
    /**
     * This is a list of the attachment files in this step template.
     * @type {Array<AttachmentResponse>}
     * @memberof StepTemplateResponse
     */
    'attachments'?: Array<AttachmentResponse>;
    /**
     * The date and time when the step was created
     * @type {string}
     * @memberof StepTemplateResponse
     */
    'createdAt'?: string;
    /**
     * The date and time when the step template was last updated
     * @type {string}
     * @memberof StepTemplateResponse
     */
    'updatedAt'?: string;
    /**
     * User id from the creator
     * @type {string}
     * @memberof StepTemplateResponse
     */
    'createdBy'?: string;
    /**
     * User id from the last updater
     * @type {string}
     * @memberof StepTemplateResponse
     */
    'updatedBy'?: string;
}

export const StepTemplateResponseTypeEnum = {
    Email: 'EMAIL',
    Sms: 'SMS',
    Call: 'CALL',
    Manual: 'MANUAL'
} as const;

export type StepTemplateResponseTypeEnum = typeof StepTemplateResponseTypeEnum[keyof typeof StepTemplateResponseTypeEnum];
export const StepTemplateResponseFlowControlEnum = {
    Ab: 'AB',
    Consent: 'CONSENT'
} as const;

export type StepTemplateResponseFlowControlEnum = typeof StepTemplateResponseFlowControlEnum[keyof typeof StepTemplateResponseFlowControlEnum];
export const StepTemplateResponseFlowControlABEnum = {
    A: 'A',
    B: 'B'
} as const;

export type StepTemplateResponseFlowControlABEnum = typeof StepTemplateResponseFlowControlABEnum[keyof typeof StepTemplateResponseFlowControlABEnum];
export const StepTemplateResponseFlowControlConsentListEnum = {
    None: 'OPT_IN_NONE',
    Single: 'OPT_IN_SINGLE',
    Double: 'OPT_IN_DOUBLE'
} as const;

export type StepTemplateResponseFlowControlConsentListEnum = typeof StepTemplateResponseFlowControlConsentListEnum[keyof typeof StepTemplateResponseFlowControlConsentListEnum];

/**
 * 
 * @export
 * @interface TaskDto
 */
export interface TaskDto {
    /**
     * 
     * @type {AssigneeDto}
     * @memberof TaskDto
     */
    'assignee'?: AssigneeDto;
    /**
     * List of presaved attachment links
     * @type {Array<AttachmentDto>}
     * @memberof TaskDto
     */
    'attachments'?: Array<AttachmentDto>;
    /**
     * Task creation date
     * @type {string}
     * @memberof TaskDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {DealDto}
     * @memberof TaskDto
     */
    'deal'?: DealDto;
    /**
     * Description of a task
     * @type {string}
     * @memberof TaskDto
     */
    'description'?: string;
    /**
     * Date and time when the task is expected to be finished
     * @type {string}
     * @memberof TaskDto
     */
    'dueDate'?: string;
    /**
     * Task id
     * @type {string}
     * @memberof TaskDto
     */
    'id'?: string;
    /**
     * 
     * @type {ProspectDto}
     * @memberof TaskDto
     */
    'prospect'?: ProspectDto;
    /**
     * Task completion status
     * @type {string}
     * @memberof TaskDto
     */
    'status'?: TaskDtoStatusEnum;
    /**
     * Type of the task
     * @type {string}
     * @memberof TaskDto
     */
    'type'?: TaskDtoTypeEnum;
    /**
     * Last Task update date
     * @type {string}
     * @memberof TaskDto
     */
    'updatedAt'?: string;
}

export const TaskDtoStatusEnum = {
    Unspecified: 'UNSPECIFIED',
    Upcoming: 'UPCOMING',
    Due: 'DUE',
    Overdue: 'OVERDUE',
    Complete: 'COMPLETE'
} as const;

export type TaskDtoStatusEnum = typeof TaskDtoStatusEnum[keyof typeof TaskDtoStatusEnum];
export const TaskDtoTypeEnum = {
    Call: 'CALL',
    AttendMeeting: 'ATTEND_MEETING',
    ApproveAiMessage: 'APPROVE_AI_MESSAGE',
    Other: 'OTHER'
} as const;

export type TaskDtoTypeEnum = typeof TaskDtoTypeEnum[keyof typeof TaskDtoTypeEnum];

/**
 * 
 * @export
 * @interface TaskLine
 */
export interface TaskLine {
    /**
     * 
     * @type {AssigneeDto}
     * @memberof TaskLine
     */
    'assignee'?: AssigneeDto;
    /**
     * Date and time when the task is expected to be finished
     * @type {string}
     * @memberof TaskLine
     */
    'dueDate'?: string;
    /**
     * Task id
     * @type {string}
     * @memberof TaskLine
     */
    'id'?: string;
    /**
     * 
     * @type {ProspectDto}
     * @memberof TaskLine
     */
    'prospect'?: ProspectDto;
    /**
     * Task completion status
     * @type {string}
     * @memberof TaskLine
     */
    'status'?: TaskLineStatusEnum;
    /**
     * Type of the task
     * @type {string}
     * @memberof TaskLine
     */
    'type'?: TaskLineTypeEnum;
}

export const TaskLineStatusEnum = {
    Unspecified: 'UNSPECIFIED',
    Upcoming: 'UPCOMING',
    Due: 'DUE',
    Overdue: 'OVERDUE',
    Complete: 'COMPLETE'
} as const;

export type TaskLineStatusEnum = typeof TaskLineStatusEnum[keyof typeof TaskLineStatusEnum];
export const TaskLineTypeEnum = {
    Call: 'CALL',
    AttendMeeting: 'ATTEND_MEETING',
    ApproveAiMessage: 'APPROVE_AI_MESSAGE',
    Other: 'OTHER'
} as const;

export type TaskLineTypeEnum = typeof TaskLineTypeEnum[keyof typeof TaskLineTypeEnum];

/**
 * 
 * @export
 * @interface TaskRequest
 */
export interface TaskRequest {
    /**
     * Reference id of a task assignee
     * @type {string}
     * @memberof TaskRequest
     */
    'assigneeId': string;
    /**
     * List of presaved attachment links
     * @type {Array<AttachmentDto>}
     * @memberof TaskRequest
     */
    'attachments'?: Array<AttachmentDto>;
    /**
     * Reference id of a related Deal
     * @type {string}
     * @memberof TaskRequest
     */
    'dealId'?: string;
    /**
     * Description of a task
     * @type {string}
     * @memberof TaskRequest
     */
    'description'?: string;
    /**
     * A date when the task is expected to be completed
     * @type {string}
     * @memberof TaskRequest
     */
    'dueDate': string;
    /**
     * Reference id of a related Prospect
     * @type {string}
     * @memberof TaskRequest
     */
    'prospectId': string;
    /**
     * Task completion status
     * @type {string}
     * @memberof TaskRequest
     */
    'status': TaskRequestStatusEnum;
    /**
     * Type of the task
     * @type {string}
     * @memberof TaskRequest
     */
    'type': TaskRequestTypeEnum;
}

export const TaskRequestStatusEnum = {
    Unspecified: 'UNSPECIFIED',
    Upcoming: 'UPCOMING',
    Due: 'DUE',
    Overdue: 'OVERDUE',
    Complete: 'COMPLETE'
} as const;

export type TaskRequestStatusEnum = typeof TaskRequestStatusEnum[keyof typeof TaskRequestStatusEnum];
export const TaskRequestTypeEnum = {
    Call: 'CALL',
    AttendMeeting: 'ATTEND_MEETING',
    ApproveAiMessage: 'APPROVE_AI_MESSAGE',
    Other: 'OTHER'
} as const;

export type TaskRequestTypeEnum = typeof TaskRequestTypeEnum[keyof typeof TaskRequestTypeEnum];

/**
 * 
 * @export
 * @interface TeamConfigRequest
 */
export interface TeamConfigRequest {
    /**
     * 
     * @type {number}
     * @memberof TeamConfigRequest
     */
    'ads_budget_gt'?: number;
    /**
     * 
     * @type {string}
     * @memberof TeamConfigRequest
     */
    'ads_budget_gt_unit'?: string;
    /**
     * 
     * @type {number}
     * @memberof TeamConfigRequest
     */
    'budget_gt'?: number;
    /**
     * 
     * @type {string}
     * @memberof TeamConfigRequest
     */
    'budget_gt_unit'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TeamConfigRequest
     */
    'company_size_in'?: Array<TeamConfigRequestCompanySizeInEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TeamConfigRequest
     */
    'industry_in'?: Array<TeamConfigRequestIndustryInEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof TeamConfigRequest
     */
    'is_pain_point'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeamConfigRequest
     */
    'is_pain_point_mapped'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeamConfigRequest
     */
    'is_require_address'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeamConfigRequest
     */
    'is_require_ads_budget'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeamConfigRequest
     */
    'is_require_budget'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeamConfigRequest
     */
    'is_require_company'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeamConfigRequest
     */
    'is_require_company_size'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeamConfigRequest
     */
    'is_require_industry'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeamConfigRequest
     */
    'is_require_job_title'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeamConfigRequest
     */
    'is_require_location'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeamConfigRequest
     */
    'is_require_pain_point'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeamConfigRequest
     */
    'is_require_seniority'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeamConfigRequest
     */
    'is_require_timeframe'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TeamConfigRequest
     */
    'location_city'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamConfigRequest
     */
    'location_proximity'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamConfigRequest
     */
    'location_proximity_unit'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamConfigRequest
     */
    'location_state'?: string;
    /**
     * 
     * @type {number}
     * @memberof TeamConfigRequest
     */
    'qual_conditions_min'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof TeamConfigRequest
     */
    'seniority_in'?: Array<TeamConfigRequestSeniorityInEnum>;
    /**
     * 
     * @type {string}
     * @memberof TeamConfigRequest
     */
    'team_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof TeamConfigRequest
     */
    'timeframe'?: number;
    /**
     * 
     * @type {string}
     * @memberof TeamConfigRequest
     */
    'timeframe_unit'?: string;
}

export const TeamConfigRequestCompanySizeInEnum = {
    _1To10: '1 to 10',
    _11To25: '11 to 25',
    _26To50: '26 to 50',
    _51To100: '51 to 100',
    _101To250: '101 to 250',
    _251To500: '251 to 500',
    _501To1000: '501 to 1000',
    _1001To5000: '1001 to 5000',
    _5001To10000: '5001 to 10000',
    _10000: '10000+'
} as const;

export type TeamConfigRequestCompanySizeInEnum = typeof TeamConfigRequestCompanySizeInEnum[keyof typeof TeamConfigRequestCompanySizeInEnum];
export const TeamConfigRequestIndustryInEnum = {
    FinancialServices: 'FINANCIAL_SERVICES',
    TechnologyAndSoftware: 'TECHNOLOGY_AND_SOFTWARE',
    HealthCareAndPharmaceutical: 'HEALTH_CARE_AND_PHARMACEUTICAL',
    Manufacturing: 'MANUFACTURING',
    Retail: 'RETAIL',
    RealEstateAndConstruction: 'REAL_ESTATE_AND_CONSTRUCTION',
    Education: 'EDUCATION',
    Automotive: 'AUTOMOTIVE',
    HospitalityAndTravel: 'HOSPITALITY_AND_TRAVEL',
    Telecommunications: 'TELECOMMUNICATIONS'
} as const;

export type TeamConfigRequestIndustryInEnum = typeof TeamConfigRequestIndustryInEnum[keyof typeof TeamConfigRequestIndustryInEnum];
export const TeamConfigRequestSeniorityInEnum = {
    Staff: 'STAFF',
    Manager: 'MANAGER',
    Director: 'DIRECTOR',
    Vp: 'VP',
    Cxo: 'CXO'
} as const;

export type TeamConfigRequestSeniorityInEnum = typeof TeamConfigRequestSeniorityInEnum[keyof typeof TeamConfigRequestSeniorityInEnum];

/**
 * 
 * @export
 * @interface TeamInvite
 */
export interface TeamInvite {
    /**
     * 
     * @type {string}
     * @memberof TeamInvite
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamInvite
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamInvite
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamInvite
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamInvite
     */
    'full_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamInvite
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamInvite
     */
    'role'?: TeamInviteRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof TeamInvite
     */
    'sentAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamInvite
     */
    'status'?: TeamInviteStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TeamInvite
     */
    'teamId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamInvite
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamInvite
     */
    'updatedBy'?: string;
}

export const TeamInviteRoleEnum = {
    SalesRepresentative: 'SALES_REPRESENTATIVE',
    SalesManager: 'SALES_MANAGER',
    Admin: 'ADMIN',
    Superadmin: 'SUPERADMIN'
} as const;

export type TeamInviteRoleEnum = typeof TeamInviteRoleEnum[keyof typeof TeamInviteRoleEnum];
export const TeamInviteStatusEnum = {
    Draft: 'DRAFT',
    Sent: 'SENT',
    Accepted: 'ACCEPTED'
} as const;

export type TeamInviteStatusEnum = typeof TeamInviteStatusEnum[keyof typeof TeamInviteStatusEnum];

/**
 * 
 * @export
 * @interface TeamInviteRequest
 */
export interface TeamInviteRequest {
    /**
     * 
     * @type {string}
     * @memberof TeamInviteRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamInviteRequest
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamInviteRequest
     */
    'role'?: TeamInviteRequestRoleEnum;
}

export const TeamInviteRequestRoleEnum = {
    SalesRepresentative: 'SALES_REPRESENTATIVE',
    SalesManager: 'SALES_MANAGER',
    Admin: 'ADMIN',
    Superadmin: 'SUPERADMIN'
} as const;

export type TeamInviteRequestRoleEnum = typeof TeamInviteRequestRoleEnum[keyof typeof TeamInviteRequestRoleEnum];

/**
 * 
 * @export
 * @interface TeamJoinRequest
 */
export interface TeamJoinRequest {
    /**
     * 
     * @type {string}
     * @memberof TeamJoinRequest
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface TeamRequest
 */
export interface TeamRequest {
    /**
     * String value for Team entity\'s \'domain\'
     * @type {string}
     * @memberof TeamRequest
     */
    'domain'?: string;
    /**
     * String value for Team location
     * @type {string}
     * @memberof TeamRequest
     */
    'location'?: string;
    /**
     * String link to a Team logo stored in a GCP storage
     * @type {string}
     * @memberof TeamRequest
     */
    'logoImgUrl'?: string;
    /**
     * String value for Team entity\'s \'name\'
     * @type {string}
     * @memberof TeamRequest
     */
    'name'?: string;
    /**
     * String value for Team phone number
     * @type {string}
     * @memberof TeamRequest
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface TextResponse
 */
export interface TextResponse {
    /**
     * 
     * @type {string}
     * @memberof TextResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface Timestamp
 */
export interface Timestamp {
    /**
     * 
     * @type {number}
     * @memberof Timestamp
     */
    'nanos'?: number;
    /**
     * 
     * @type {number}
     * @memberof Timestamp
     */
    'seconds'?: number;
}
/**
 * 
 * @export
 * @interface Timestamp2
 */
export interface Timestamp2 {
    /**
     * 
     * @type {number}
     * @memberof Timestamp2
     */
    'nanos'?: number;
    /**
     * 
     * @type {number}
     * @memberof Timestamp2
     */
    'seconds'?: number;
}
/**
 * 
 * @export
 * @interface TransferNumberDto
 */
export interface TransferNumberDto {
    /**
     * 
     * @type {string}
     * @memberof TransferNumberDto
     */
    'agentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferNumberDto
     */
    'agent_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferNumberDto
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransferNumberDto
     */
    'is_user'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransferNumberDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferNumberDto
     */
    'number'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferNumberDto
     */
    'team_id'?: string;
}
/**
 * 
 * @export
 * @interface TransferNumberRequest
 */
export interface TransferNumberRequest {
    /**
     * 
     * @type {string}
     * @memberof TransferNumberRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferNumberRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferNumberRequest
     */
    'number'?: string;
}
/**
 * 
 * @export
 * @interface Trigger
 */
export interface Trigger {
    /**
     * 
     * @type {object}
     * @memberof Trigger
     */
    'action'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof Trigger
     */
    'active'?: boolean;
    /**
     * The TimeStamp value representing the date/time the Trigger object was created by the \'createdBy\' source
     * @type {string}
     * @memberof Trigger
     */
    'createdAt'?: string;
    /**
     * The String value field representing creation source of the Trigger object
     * @type {string}
     * @memberof Trigger
     */
    'createdBy'?: string;
    /**
     * 
     * @type {object}
     * @memberof Trigger
     */
    'event'?: object;
    /**
     * The number of times the trigger was executed
     * @type {number}
     * @memberof Trigger
     */
    'executionCount'?: number;
    /**
     * Trigger PK Identifier
     * @type {string}
     * @memberof Trigger
     */
    'id'?: string;
    /**
     * The String value field representing the name of the Trigger object
     * @type {string}
     * @memberof Trigger
     */
    'name'?: string;
    /**
     * The timestamp value representing the date/time the trigger object was last updated
     * @type {string}
     * @memberof Trigger
     */
    'updatedAt'?: string;
    /**
     * The string value representing creation source, usually user Id
     * @type {string}
     * @memberof Trigger
     */
    'updatedBy'?: string;
}
/**
 * 
 * @export
 * @interface TriggerMultipleSequencesResponse
 */
export interface TriggerMultipleSequencesResponse {
    /**
     * 
     * @type {number}
     * @memberof TriggerMultipleSequencesResponse
     */
    'successfulCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof TriggerMultipleSequencesResponse
     */
    'skippedDNCCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof TriggerMultipleSequencesResponse
     */
    'skippedOtherCount'?: number;
}
/**
 * 
 * @export
 * @interface TriggerRequest
 */
export interface TriggerRequest {
    /**
     * 
     * @type {object}
     * @memberof TriggerRequest
     */
    'action'?: object;
    /**
     * 
     * @type {object}
     * @memberof TriggerRequest
     */
    'event'?: object;
    /**
     * Trigger \'isActive\' boolean field representing if the trigger is being applied, does not affect visibility
     * @type {boolean}
     * @memberof TriggerRequest
     */
    'isActive'?: boolean;
    /**
     * The String value field representing the name of the Trigger object being requested
     * @type {string}
     * @memberof TriggerRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UpdateTeamUserRequest
 */
export interface UpdateTeamUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateTeamUserRequest
     */
    'teamId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTeamUserRequest
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTeamUserRequest
     */
    'userRole': UpdateTeamUserRequestUserRoleEnum;
}

export const UpdateTeamUserRequestUserRoleEnum = {
    SalesRepresentative: 'SALES_REPRESENTATIVE',
    SalesManager: 'SALES_MANAGER',
    Admin: 'ADMIN',
    Superadmin: 'SUPERADMIN'
} as const;

export type UpdateTeamUserRequestUserRoleEnum = typeof UpdateTeamUserRequestUserRoleEnum[keyof typeof UpdateTeamUserRequestUserRoleEnum];

/**
 * 
 * @export
 * @interface WebhookRequestCreate
 */
export interface WebhookRequestCreate {
    /**
     * Webhook name.
     * @type {string}
     * @memberof WebhookRequestCreate
     */
    'name'?: string;
    /**
     * Webhook description
     * @type {string}
     * @memberof WebhookRequestCreate
     */
    'description'?: string;
    /**
     * Webhook type
     * @type {string}
     * @memberof WebhookRequestCreate
     */
    'type'?: WebhookRequestCreateTypeEnum;
}

export const WebhookRequestCreateTypeEnum = {
    Contact: 'CONTACT'
} as const;

export type WebhookRequestCreateTypeEnum = typeof WebhookRequestCreateTypeEnum[keyof typeof WebhookRequestCreateTypeEnum];


/**
 * AdminTeamControllerApi - axios parameter creator
 * @export
 */
export const AdminTeamControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {KnLookupRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeams: async (body: KnLookupRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getTeams', 'body', body)
            const localVarPath = `/admin/teams/lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teamId 
         * @param {KnLookupRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (teamId: string, body: KnLookupRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('getUsers', 'teamId', teamId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getUsers', 'body', body)
            const localVarPath = `/admin/teams/{teamId}/users`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        impersonateUser: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('impersonateUser', 'userId', userId)
            const localVarPath = `/admin/users/{userId}/impersonate`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopImpersonation: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/users/stop-impersonation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminTeamControllerApi - functional programming interface
 * @export
 */
export const AdminTeamControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminTeamControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {KnLookupRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeams(body: KnLookupRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnLookupResponseKnTeam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeams(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AdminTeamControllerApi.getTeams']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} teamId 
         * @param {KnLookupRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(teamId: string, body: KnLookupRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnLookupResponseKnTeamUserRole>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(teamId, body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AdminTeamControllerApi.getUsers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async impersonateUser(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.impersonateUser(userId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AdminTeamControllerApi.impersonateUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stopImpersonation(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stopImpersonation(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AdminTeamControllerApi.stopImpersonation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AdminTeamControllerApi - factory interface
 * @export
 */
export const AdminTeamControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminTeamControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {KnLookupRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeams(body: KnLookupRequest, options?: any): AxiosPromise<KnLookupResponseKnTeam> {
            return localVarFp.getTeams(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teamId 
         * @param {KnLookupRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(teamId: string, body: KnLookupRequest, options?: any): AxiosPromise<KnLookupResponseKnTeamUserRole> {
            return localVarFp.getUsers(teamId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        impersonateUser(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.impersonateUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopImpersonation(options?: any): AxiosPromise<void> {
            return localVarFp.stopImpersonation(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminTeamControllerApi - interface
 * @export
 * @interface AdminTeamControllerApi
 */
export interface AdminTeamControllerApiInterface {
    /**
     * 
     * @param {KnLookupRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeamControllerApiInterface
     */
    getTeams(body: KnLookupRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnLookupResponseKnTeam>;

    /**
     * 
     * @param {string} teamId 
     * @param {KnLookupRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeamControllerApiInterface
     */
    getUsers(teamId: string, body: KnLookupRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnLookupResponseKnTeamUserRole>;

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeamControllerApiInterface
     */
    impersonateUser(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeamControllerApiInterface
     */
    stopImpersonation(options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AdminTeamControllerApi - object-oriented interface
 * @export
 * @class AdminTeamControllerApi
 * @extends {BaseAPI}
 */
export class AdminTeamControllerApi extends BaseAPI implements AdminTeamControllerApiInterface {
    /**
     * 
     * @param {KnLookupRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeamControllerApi
     */
    public getTeams(body: KnLookupRequest, options?: RawAxiosRequestConfig) {
        return AdminTeamControllerApiFp(this.configuration).getTeams(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teamId 
     * @param {KnLookupRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeamControllerApi
     */
    public getUsers(teamId: string, body: KnLookupRequest, options?: RawAxiosRequestConfig) {
        return AdminTeamControllerApiFp(this.configuration).getUsers(teamId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeamControllerApi
     */
    public impersonateUser(userId: string, options?: RawAxiosRequestConfig) {
        return AdminTeamControllerApiFp(this.configuration).impersonateUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeamControllerApi
     */
    public stopImpersonation(options?: RawAxiosRequestConfig) {
        return AdminTeamControllerApiFp(this.configuration).stopImpersonation(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AiApi - axios parameter creator
 * @export
 */
export const AiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Persist AI Persona associated with the User\'s Team
         * @param {AiPersonaRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aiPersonaCreate: async (body: AiPersonaRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('aiPersonaCreate', 'body', body)
            const localVarPath = `/config/ai-persona`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get AI Persona associated with the User\'s Team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aiPersonaGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/config/ai-persona`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Persist AI Persona associated with the User\'s Team
         * @param {AiPersonaRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aiPersonaUpdate: async (body: AiPersonaRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('aiPersonaUpdate', 'body', body)
            const localVarPath = `/config/ai-persona`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Company Details associated with the User\'s Team
         * @param {CompanyDetailsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyDetailsCreate: async (body: CompanyDetailsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('companyDetailsCreate', 'body', body)
            const localVarPath = `/config/company-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Company Details associated with the User\'s Team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyDetailsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/config/company-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Company Details associated with the User\'s Team
         * @param {CompanyDetailsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyDetailsUpdate: async (body: CompanyDetailsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('companyDetailsUpdate', 'body', body)
            const localVarPath = `/config/company-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GenMsgCustom} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        genMsgCustom: async (body: GenMsgCustom, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('genMsgCustom', 'body', body)
            const localVarPath = `/message/draft/custom`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GenMsgSuggestMeetingTimes} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        genMsgSuggestMeetingTimes: async (body: GenMsgSuggestMeetingTimes, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('genMsgSuggestMeetingTimes', 'body', body)
            const localVarPath = `/message/draft/suggest-meeting-times`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Sales Process associated with the Team ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesProcessGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/config/sales-process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Persist Sales Process associated with the Team ID
         * @param {SalesProcessRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesProcessPersist: async (body: SalesProcessRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('salesProcessPersist', 'body', body)
            const localVarPath = `/config/sales-process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AiApi - functional programming interface
 * @export
 */
export const AiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Persist AI Persona associated with the User\'s Team
         * @param {AiPersonaRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aiPersonaCreate(body: AiPersonaRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiPersona>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aiPersonaCreate(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiApi.aiPersonaCreate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get AI Persona associated with the User\'s Team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aiPersonaGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiPersona>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aiPersonaGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiApi.aiPersonaGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Persist AI Persona associated with the User\'s Team
         * @param {AiPersonaRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aiPersonaUpdate(body: AiPersonaRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiPersona>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aiPersonaUpdate(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiApi.aiPersonaUpdate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create Company Details associated with the User\'s Team
         * @param {CompanyDetailsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyDetailsCreate(body: CompanyDetailsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiCompanyDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyDetailsCreate(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiApi.companyDetailsCreate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Company Details associated with the User\'s Team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyDetailsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiCompanyDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyDetailsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiApi.companyDetailsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update Company Details associated with the User\'s Team
         * @param {CompanyDetailsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyDetailsUpdate(body: CompanyDetailsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiCompanyDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyDetailsUpdate(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiApi.companyDetailsUpdate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {GenMsgCustom} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async genMsgCustom(body: GenMsgCustom, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TextResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.genMsgCustom(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiApi.genMsgCustom']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {GenMsgSuggestMeetingTimes} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async genMsgSuggestMeetingTimes(body: GenMsgSuggestMeetingTimes, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TextResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.genMsgSuggestMeetingTimes(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiApi.genMsgSuggestMeetingTimes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Sales Process associated with the Team ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salesProcessGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiSalesProcess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salesProcessGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiApi.salesProcessGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Persist Sales Process associated with the Team ID
         * @param {SalesProcessRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salesProcessPersist(body: SalesProcessRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiSalesProcess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salesProcessPersist(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiApi.salesProcessPersist']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AiApi - factory interface
 * @export
 */
export const AiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AiApiFp(configuration)
    return {
        /**
         * 
         * @summary Persist AI Persona associated with the User\'s Team
         * @param {AiPersonaRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aiPersonaCreate(body: AiPersonaRequest, options?: any): AxiosPromise<AiPersona> {
            return localVarFp.aiPersonaCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get AI Persona associated with the User\'s Team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aiPersonaGet(options?: any): AxiosPromise<AiPersona> {
            return localVarFp.aiPersonaGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Persist AI Persona associated with the User\'s Team
         * @param {AiPersonaRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aiPersonaUpdate(body: AiPersonaRequest, options?: any): AxiosPromise<AiPersona> {
            return localVarFp.aiPersonaUpdate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Company Details associated with the User\'s Team
         * @param {CompanyDetailsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyDetailsCreate(body: CompanyDetailsRequest, options?: any): AxiosPromise<AiCompanyDetails> {
            return localVarFp.companyDetailsCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Company Details associated with the User\'s Team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyDetailsGet(options?: any): AxiosPromise<AiCompanyDetails> {
            return localVarFp.companyDetailsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Company Details associated with the User\'s Team
         * @param {CompanyDetailsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyDetailsUpdate(body: CompanyDetailsRequest, options?: any): AxiosPromise<AiCompanyDetails> {
            return localVarFp.companyDetailsUpdate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GenMsgCustom} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        genMsgCustom(body: GenMsgCustom, options?: any): AxiosPromise<TextResponse> {
            return localVarFp.genMsgCustom(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GenMsgSuggestMeetingTimes} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        genMsgSuggestMeetingTimes(body: GenMsgSuggestMeetingTimes, options?: any): AxiosPromise<TextResponse> {
            return localVarFp.genMsgSuggestMeetingTimes(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Sales Process associated with the Team ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesProcessGet(options?: any): AxiosPromise<AiSalesProcess> {
            return localVarFp.salesProcessGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Persist Sales Process associated with the Team ID
         * @param {SalesProcessRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesProcessPersist(body: SalesProcessRequest, options?: any): AxiosPromise<AiSalesProcess> {
            return localVarFp.salesProcessPersist(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AiApi - interface
 * @export
 * @interface AiApi
 */
export interface AiApiInterface {
    /**
     * 
     * @summary Persist AI Persona associated with the User\'s Team
     * @param {AiPersonaRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiApiInterface
     */
    aiPersonaCreate(body: AiPersonaRequest, options?: RawAxiosRequestConfig): AxiosPromise<AiPersona>;

    /**
     * 
     * @summary Get AI Persona associated with the User\'s Team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiApiInterface
     */
    aiPersonaGet(options?: RawAxiosRequestConfig): AxiosPromise<AiPersona>;

    /**
     * 
     * @summary Persist AI Persona associated with the User\'s Team
     * @param {AiPersonaRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiApiInterface
     */
    aiPersonaUpdate(body: AiPersonaRequest, options?: RawAxiosRequestConfig): AxiosPromise<AiPersona>;

    /**
     * 
     * @summary Create Company Details associated with the User\'s Team
     * @param {CompanyDetailsRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiApiInterface
     */
    companyDetailsCreate(body: CompanyDetailsRequest, options?: RawAxiosRequestConfig): AxiosPromise<AiCompanyDetails>;

    /**
     * 
     * @summary Get Company Details associated with the User\'s Team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiApiInterface
     */
    companyDetailsGet(options?: RawAxiosRequestConfig): AxiosPromise<AiCompanyDetails>;

    /**
     * 
     * @summary Update Company Details associated with the User\'s Team
     * @param {CompanyDetailsRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiApiInterface
     */
    companyDetailsUpdate(body: CompanyDetailsRequest, options?: RawAxiosRequestConfig): AxiosPromise<AiCompanyDetails>;

    /**
     * 
     * @param {GenMsgCustom} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiApiInterface
     */
    genMsgCustom(body: GenMsgCustom, options?: RawAxiosRequestConfig): AxiosPromise<TextResponse>;

    /**
     * 
     * @param {GenMsgSuggestMeetingTimes} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiApiInterface
     */
    genMsgSuggestMeetingTimes(body: GenMsgSuggestMeetingTimes, options?: RawAxiosRequestConfig): AxiosPromise<TextResponse>;

    /**
     * 
     * @summary Get Sales Process associated with the Team ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiApiInterface
     */
    salesProcessGet(options?: RawAxiosRequestConfig): AxiosPromise<AiSalesProcess>;

    /**
     * 
     * @summary Persist Sales Process associated with the Team ID
     * @param {SalesProcessRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiApiInterface
     */
    salesProcessPersist(body: SalesProcessRequest, options?: RawAxiosRequestConfig): AxiosPromise<AiSalesProcess>;

}

/**
 * AiApi - object-oriented interface
 * @export
 * @class AiApi
 * @extends {BaseAPI}
 */
export class AiApi extends BaseAPI implements AiApiInterface {
    /**
     * 
     * @summary Persist AI Persona associated with the User\'s Team
     * @param {AiPersonaRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiApi
     */
    public aiPersonaCreate(body: AiPersonaRequest, options?: RawAxiosRequestConfig) {
        return AiApiFp(this.configuration).aiPersonaCreate(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get AI Persona associated with the User\'s Team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiApi
     */
    public aiPersonaGet(options?: RawAxiosRequestConfig) {
        return AiApiFp(this.configuration).aiPersonaGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Persist AI Persona associated with the User\'s Team
     * @param {AiPersonaRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiApi
     */
    public aiPersonaUpdate(body: AiPersonaRequest, options?: RawAxiosRequestConfig) {
        return AiApiFp(this.configuration).aiPersonaUpdate(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Company Details associated with the User\'s Team
     * @param {CompanyDetailsRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiApi
     */
    public companyDetailsCreate(body: CompanyDetailsRequest, options?: RawAxiosRequestConfig) {
        return AiApiFp(this.configuration).companyDetailsCreate(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Company Details associated with the User\'s Team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiApi
     */
    public companyDetailsGet(options?: RawAxiosRequestConfig) {
        return AiApiFp(this.configuration).companyDetailsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Company Details associated with the User\'s Team
     * @param {CompanyDetailsRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiApi
     */
    public companyDetailsUpdate(body: CompanyDetailsRequest, options?: RawAxiosRequestConfig) {
        return AiApiFp(this.configuration).companyDetailsUpdate(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GenMsgCustom} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiApi
     */
    public genMsgCustom(body: GenMsgCustom, options?: RawAxiosRequestConfig) {
        return AiApiFp(this.configuration).genMsgCustom(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GenMsgSuggestMeetingTimes} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiApi
     */
    public genMsgSuggestMeetingTimes(body: GenMsgSuggestMeetingTimes, options?: RawAxiosRequestConfig) {
        return AiApiFp(this.configuration).genMsgSuggestMeetingTimes(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Sales Process associated with the Team ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiApi
     */
    public salesProcessGet(options?: RawAxiosRequestConfig) {
        return AiApiFp(this.configuration).salesProcessGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Persist Sales Process associated with the Team ID
     * @param {SalesProcessRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiApi
     */
    public salesProcessPersist(body: SalesProcessRequest, options?: RawAxiosRequestConfig) {
        return AiApiFp(this.configuration).salesProcessPersist(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AiVoiceApi - axios parameter creator
 * @export
 */
export const AiVoiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {KnAiAgentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAiAgent: async (body: KnAiAgentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createAiAgent', 'body', body)
            const localVarPath = `/voice/ai-agents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deauthorizeAiVoiceTwilio: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/voice/settings/twilio/deauthorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAiAgent: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAiAgent', 'id', id)
            const localVarPath = `/voice/ai-agents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransferNumber: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTransferNumber', 'id', id)
            const localVarPath = `/voice/transfer-numbers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCallLogs: async (body: KnLookupRequest2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('exportCallLogs', 'body', body)
            const localVarPath = `/voice/calls/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchALlAiAgents: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/voice/ai-agents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchALlTransferNumbers: async (body: KnLookupRequest2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('fetchALlTransferNumbers', 'body', body)
            const localVarPath = `/voice/transfer-numbers/lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAiAgent: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAiAgent', 'id', id)
            const localVarPath = `/voice/ai-agents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAiVoiceTeamSettings: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/voice/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCall: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCall', 'id', id)
            const localVarPath = `/voice/calls/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCallDetails: async (id: string, pageNumber?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCallDetails', 'id', id)
            const localVarPath = `/voice/calls/{id}/details`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isConnectedTwilio: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/voice/settings/twilio`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTwilioPhoneNumbers: async (pageNumber?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/voice/phone-numbers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupAiAgent: async (body: KnLookupRequest2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('lookupAiAgent', 'body', body)
            const localVarPath = `/voice/ai-agents/lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupAiVoices: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/voice/ai-voices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupCalls: async (body: KnLookupRequest2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('lookupCalls', 'body', body)
            const localVarPath = `/voice/calls/lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AiAgentCallOutboundRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendCall: async (body: AiAgentCallOutboundRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('sendCall', 'body', body)
            const localVarPath = `/voice/calls/outbound`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AiAgentCallOutboundTestRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendCallTest: async (body: AiAgentCallOutboundTestRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('sendCallTest', 'body', body)
            const localVarPath = `/voice/calls/outbound-test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {KnAiAgentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAiAgent: async (id: string, body: KnAiAgentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAiAgent', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateAiAgent', 'body', body)
            const localVarPath = `/voice/ai-agents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AiVoiceTransferRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertAiVoiceTeamSettings: async (body: AiVoiceTransferRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('upsertAiVoiceTeamSettings', 'body', body)
            const localVarPath = `/voice/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AiVoiceTwilioRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertAiVoiceTwilio: async (body: AiVoiceTwilioRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('upsertAiVoiceTwilio', 'body', body)
            const localVarPath = `/voice/settings/twilio`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TransferNumberRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertTransferNumber: async (body: TransferNumberRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('upsertTransferNumber', 'body', body)
            const localVarPath = `/voice/transfer-numbers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        voiceTeamSummary: async (teamId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('voiceTeamSummary', 'teamId', teamId)
            const localVarPath = `/voice/team/{teamId}/summary`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AiVoiceApi - functional programming interface
 * @export
 */
export const AiVoiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AiVoiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {KnAiAgentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAiAgent(body: KnAiAgentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnAiAgent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAiAgent(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiVoiceApi.createAiAgent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deauthorizeAiVoiceTwilio(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiVoiceTeamSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deauthorizeAiVoiceTwilio(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiVoiceApi.deauthorizeAiVoiceTwilio']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAiAgent(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAiAgent(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiVoiceApi.deleteAiAgent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTransferNumber(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTransferNumber(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiVoiceApi.deleteTransferNumber']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportCallLogs(body: KnLookupRequest2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportCallLogs(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiVoiceApi.exportCallLogs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchALlAiAgents(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KnAiAgent>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchALlAiAgents(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiVoiceApi.fetchALlAiAgents']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchALlTransferNumbers(body: KnLookupRequest2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnLookupResponseTransferNumberDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchALlTransferNumbers(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiVoiceApi.fetchALlTransferNumbers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAiAgent(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnAiAgent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAiAgent(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiVoiceApi.getAiAgent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAiVoiceTeamSettings(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiVoiceTeamSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAiVoiceTeamSettings(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiVoiceApi.getAiVoiceTeamSettings']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCall(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CallLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCall(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiVoiceApi.getCall']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCallDetails(id: string, pageNumber?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnBqLookupResponseKnCallMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCallDetails(id, pageNumber, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiVoiceApi.getCallDetails']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isConnectedTwilio(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isConnectedTwilio(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiVoiceApi.isConnectedTwilio']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTwilioPhoneNumbers(pageNumber?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiAgentPhoneNumberListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTwilioPhoneNumbers(pageNumber, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiVoiceApi.listTwilioPhoneNumbers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lookupAiAgent(body: KnLookupRequest2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnLookupResponseKnAiAgent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lookupAiAgent(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiVoiceApi.lookupAiAgent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lookupAiVoices(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KnVoice>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lookupAiVoices(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiVoiceApi.lookupAiVoices']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lookupCalls(body: KnLookupRequest2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnPageResponseCallLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lookupCalls(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiVoiceApi.lookupCalls']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {AiAgentCallOutboundRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendCall(body: AiAgentCallOutboundRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendCall(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiVoiceApi.sendCall']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {AiAgentCallOutboundTestRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendCallTest(body: AiAgentCallOutboundTestRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendCallTest(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiVoiceApi.sendCallTest']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {KnAiAgentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAiAgent(id: string, body: KnAiAgentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnAiAgent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAiAgent(id, body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiVoiceApi.updateAiAgent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {AiVoiceTransferRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertAiVoiceTeamSettings(body: AiVoiceTransferRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiVoiceTeamSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertAiVoiceTeamSettings(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiVoiceApi.upsertAiVoiceTeamSettings']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {AiVoiceTwilioRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertAiVoiceTwilio(body: AiVoiceTwilioRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiVoiceTeamSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertAiVoiceTwilio(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiVoiceApi.upsertAiVoiceTwilio']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {TransferNumberRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertTransferNumber(body: TransferNumberRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferNumberDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertTransferNumber(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiVoiceApi.upsertTransferNumber']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async voiceTeamSummary(teamId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnVoiceTeamSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.voiceTeamSummary(teamId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiVoiceApi.voiceTeamSummary']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AiVoiceApi - factory interface
 * @export
 */
export const AiVoiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AiVoiceApiFp(configuration)
    return {
        /**
         * 
         * @param {KnAiAgentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAiAgent(body: KnAiAgentRequest, options?: any): AxiosPromise<KnAiAgent> {
            return localVarFp.createAiAgent(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deauthorizeAiVoiceTwilio(options?: any): AxiosPromise<AiVoiceTeamSettings> {
            return localVarFp.deauthorizeAiVoiceTwilio(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAiAgent(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAiAgent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransferNumber(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTransferNumber(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCallLogs(body: KnLookupRequest2, options?: any): AxiosPromise<void> {
            return localVarFp.exportCallLogs(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchALlAiAgents(options?: any): AxiosPromise<Array<KnAiAgent>> {
            return localVarFp.fetchALlAiAgents(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchALlTransferNumbers(body: KnLookupRequest2, options?: any): AxiosPromise<KnLookupResponseTransferNumberDto> {
            return localVarFp.fetchALlTransferNumbers(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAiAgent(id: string, options?: any): AxiosPromise<KnAiAgent> {
            return localVarFp.getAiAgent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAiVoiceTeamSettings(options?: any): AxiosPromise<AiVoiceTeamSettings> {
            return localVarFp.getAiVoiceTeamSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCall(id: string, options?: any): AxiosPromise<CallLog> {
            return localVarFp.getCall(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCallDetails(id: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<KnBqLookupResponseKnCallMessage> {
            return localVarFp.getCallDetails(id, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isConnectedTwilio(options?: any): AxiosPromise<boolean> {
            return localVarFp.isConnectedTwilio(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTwilioPhoneNumbers(pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<AiAgentPhoneNumberListResponse> {
            return localVarFp.listTwilioPhoneNumbers(pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupAiAgent(body: KnLookupRequest2, options?: any): AxiosPromise<KnLookupResponseKnAiAgent> {
            return localVarFp.lookupAiAgent(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupAiVoices(options?: any): AxiosPromise<Array<KnVoice>> {
            return localVarFp.lookupAiVoices(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupCalls(body: KnLookupRequest2, options?: any): AxiosPromise<KnPageResponseCallLog> {
            return localVarFp.lookupCalls(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AiAgentCallOutboundRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendCall(body: AiAgentCallOutboundRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendCall(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AiAgentCallOutboundTestRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendCallTest(body: AiAgentCallOutboundTestRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendCallTest(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {KnAiAgentRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAiAgent(id: string, body: KnAiAgentRequest, options?: any): AxiosPromise<KnAiAgent> {
            return localVarFp.updateAiAgent(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AiVoiceTransferRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertAiVoiceTeamSettings(body: AiVoiceTransferRequest, options?: any): AxiosPromise<AiVoiceTeamSettings> {
            return localVarFp.upsertAiVoiceTeamSettings(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AiVoiceTwilioRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertAiVoiceTwilio(body: AiVoiceTwilioRequest, options?: any): AxiosPromise<AiVoiceTeamSettings> {
            return localVarFp.upsertAiVoiceTwilio(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TransferNumberRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertTransferNumber(body: TransferNumberRequest, options?: any): AxiosPromise<TransferNumberDto> {
            return localVarFp.upsertTransferNumber(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        voiceTeamSummary(teamId: string, options?: any): AxiosPromise<KnVoiceTeamSummary> {
            return localVarFp.voiceTeamSummary(teamId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AiVoiceApi - interface
 * @export
 * @interface AiVoiceApi
 */
export interface AiVoiceApiInterface {
    /**
     * 
     * @param {KnAiAgentRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApiInterface
     */
    createAiAgent(body: KnAiAgentRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnAiAgent>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApiInterface
     */
    deauthorizeAiVoiceTwilio(options?: RawAxiosRequestConfig): AxiosPromise<AiVoiceTeamSettings>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApiInterface
     */
    deleteAiAgent(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApiInterface
     */
    deleteTransferNumber(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {KnLookupRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApiInterface
     */
    exportCallLogs(body: KnLookupRequest2, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApiInterface
     */
    fetchALlAiAgents(options?: RawAxiosRequestConfig): AxiosPromise<Array<KnAiAgent>>;

    /**
     * 
     * @param {KnLookupRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApiInterface
     */
    fetchALlTransferNumbers(body: KnLookupRequest2, options?: RawAxiosRequestConfig): AxiosPromise<KnLookupResponseTransferNumberDto>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApiInterface
     */
    getAiAgent(id: string, options?: RawAxiosRequestConfig): AxiosPromise<KnAiAgent>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApiInterface
     */
    getAiVoiceTeamSettings(options?: RawAxiosRequestConfig): AxiosPromise<AiVoiceTeamSettings>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApiInterface
     */
    getCall(id: string, options?: RawAxiosRequestConfig): AxiosPromise<CallLog>;

    /**
     * 
     * @param {string} id 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApiInterface
     */
    getCallDetails(id: string, pageNumber?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<KnBqLookupResponseKnCallMessage>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApiInterface
     */
    isConnectedTwilio(options?: RawAxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApiInterface
     */
    listTwilioPhoneNumbers(pageNumber?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<AiAgentPhoneNumberListResponse>;

    /**
     * 
     * @param {KnLookupRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApiInterface
     */
    lookupAiAgent(body: KnLookupRequest2, options?: RawAxiosRequestConfig): AxiosPromise<KnLookupResponseKnAiAgent>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApiInterface
     */
    lookupAiVoices(options?: RawAxiosRequestConfig): AxiosPromise<Array<KnVoice>>;

    /**
     * 
     * @param {KnLookupRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApiInterface
     */
    lookupCalls(body: KnLookupRequest2, options?: RawAxiosRequestConfig): AxiosPromise<KnPageResponseCallLog>;

    /**
     * 
     * @param {AiAgentCallOutboundRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApiInterface
     */
    sendCall(body: AiAgentCallOutboundRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {AiAgentCallOutboundTestRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApiInterface
     */
    sendCallTest(body: AiAgentCallOutboundTestRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {KnAiAgentRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApiInterface
     */
    updateAiAgent(id: string, body: KnAiAgentRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnAiAgent>;

    /**
     * 
     * @param {AiVoiceTransferRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApiInterface
     */
    upsertAiVoiceTeamSettings(body: AiVoiceTransferRequest, options?: RawAxiosRequestConfig): AxiosPromise<AiVoiceTeamSettings>;

    /**
     * 
     * @param {AiVoiceTwilioRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApiInterface
     */
    upsertAiVoiceTwilio(body: AiVoiceTwilioRequest, options?: RawAxiosRequestConfig): AxiosPromise<AiVoiceTeamSettings>;

    /**
     * 
     * @param {TransferNumberRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApiInterface
     */
    upsertTransferNumber(body: TransferNumberRequest, options?: RawAxiosRequestConfig): AxiosPromise<TransferNumberDto>;

    /**
     * 
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApiInterface
     */
    voiceTeamSummary(teamId: string, options?: RawAxiosRequestConfig): AxiosPromise<KnVoiceTeamSummary>;

}

/**
 * AiVoiceApi - object-oriented interface
 * @export
 * @class AiVoiceApi
 * @extends {BaseAPI}
 */
export class AiVoiceApi extends BaseAPI implements AiVoiceApiInterface {
    /**
     * 
     * @param {KnAiAgentRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApi
     */
    public createAiAgent(body: KnAiAgentRequest, options?: RawAxiosRequestConfig) {
        return AiVoiceApiFp(this.configuration).createAiAgent(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApi
     */
    public deauthorizeAiVoiceTwilio(options?: RawAxiosRequestConfig) {
        return AiVoiceApiFp(this.configuration).deauthorizeAiVoiceTwilio(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApi
     */
    public deleteAiAgent(id: string, options?: RawAxiosRequestConfig) {
        return AiVoiceApiFp(this.configuration).deleteAiAgent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApi
     */
    public deleteTransferNumber(id: string, options?: RawAxiosRequestConfig) {
        return AiVoiceApiFp(this.configuration).deleteTransferNumber(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {KnLookupRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApi
     */
    public exportCallLogs(body: KnLookupRequest2, options?: RawAxiosRequestConfig) {
        return AiVoiceApiFp(this.configuration).exportCallLogs(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApi
     */
    public fetchALlAiAgents(options?: RawAxiosRequestConfig) {
        return AiVoiceApiFp(this.configuration).fetchALlAiAgents(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {KnLookupRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApi
     */
    public fetchALlTransferNumbers(body: KnLookupRequest2, options?: RawAxiosRequestConfig) {
        return AiVoiceApiFp(this.configuration).fetchALlTransferNumbers(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApi
     */
    public getAiAgent(id: string, options?: RawAxiosRequestConfig) {
        return AiVoiceApiFp(this.configuration).getAiAgent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApi
     */
    public getAiVoiceTeamSettings(options?: RawAxiosRequestConfig) {
        return AiVoiceApiFp(this.configuration).getAiVoiceTeamSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApi
     */
    public getCall(id: string, options?: RawAxiosRequestConfig) {
        return AiVoiceApiFp(this.configuration).getCall(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApi
     */
    public getCallDetails(id: string, pageNumber?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return AiVoiceApiFp(this.configuration).getCallDetails(id, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApi
     */
    public isConnectedTwilio(options?: RawAxiosRequestConfig) {
        return AiVoiceApiFp(this.configuration).isConnectedTwilio(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApi
     */
    public listTwilioPhoneNumbers(pageNumber?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return AiVoiceApiFp(this.configuration).listTwilioPhoneNumbers(pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {KnLookupRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApi
     */
    public lookupAiAgent(body: KnLookupRequest2, options?: RawAxiosRequestConfig) {
        return AiVoiceApiFp(this.configuration).lookupAiAgent(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApi
     */
    public lookupAiVoices(options?: RawAxiosRequestConfig) {
        return AiVoiceApiFp(this.configuration).lookupAiVoices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {KnLookupRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApi
     */
    public lookupCalls(body: KnLookupRequest2, options?: RawAxiosRequestConfig) {
        return AiVoiceApiFp(this.configuration).lookupCalls(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AiAgentCallOutboundRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApi
     */
    public sendCall(body: AiAgentCallOutboundRequest, options?: RawAxiosRequestConfig) {
        return AiVoiceApiFp(this.configuration).sendCall(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AiAgentCallOutboundTestRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApi
     */
    public sendCallTest(body: AiAgentCallOutboundTestRequest, options?: RawAxiosRequestConfig) {
        return AiVoiceApiFp(this.configuration).sendCallTest(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {KnAiAgentRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApi
     */
    public updateAiAgent(id: string, body: KnAiAgentRequest, options?: RawAxiosRequestConfig) {
        return AiVoiceApiFp(this.configuration).updateAiAgent(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AiVoiceTransferRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApi
     */
    public upsertAiVoiceTeamSettings(body: AiVoiceTransferRequest, options?: RawAxiosRequestConfig) {
        return AiVoiceApiFp(this.configuration).upsertAiVoiceTeamSettings(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AiVoiceTwilioRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApi
     */
    public upsertAiVoiceTwilio(body: AiVoiceTwilioRequest, options?: RawAxiosRequestConfig) {
        return AiVoiceApiFp(this.configuration).upsertAiVoiceTwilio(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TransferNumberRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApi
     */
    public upsertTransferNumber(body: TransferNumberRequest, options?: RawAxiosRequestConfig) {
        return AiVoiceApiFp(this.configuration).upsertTransferNumber(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiVoiceApi
     */
    public voiceTeamSummary(teamId: string, options?: RawAxiosRequestConfig) {
        return AiVoiceApiFp(this.configuration).voiceTeamSummary(teamId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * Ap2ServiceApi - axios parameter creator
 * @export
 */
export const Ap2ServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Assigns a phone number to a given campaign
         * @param {CampaignPhoneNumberRequestDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignPhoneNumber: async (body: CampaignPhoneNumberRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('campaignPhoneNumber', 'body', body)
            const localVarPath = `/v1/a2p/campaign/phone-number`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a brand. Necessary for sending messages
         * @param {CreateBrandRequestDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBrand: async (body: CreateBrandRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createBrand', 'body', body)
            const localVarPath = `/v1/a2p/brand`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a campaign associated with a brand. Necessary for sending messages
         * @param {CreateCampaignRequestDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCampaign: async (body: CreateCampaignRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createCampaign', 'body', body)
            const localVarPath = `/v1/a2p/campaign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new messaging profiles
         * @param {MessagingProfileRequestDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessagingProfile: async (body: MessagingProfileRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createMessagingProfile', 'body', body)
            const localVarPath = `/v1/a2p/messaging-profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a brand given the team id
         * @param {string} ownerTeamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrand: async (ownerTeamId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ownerTeamId' is not null or undefined
            assertParamExists('getBrand', 'ownerTeamId', ownerTeamId)
            const localVarPath = `/v1/a2p/brand/{ownerTeamId}`
                .replace(`{${"ownerTeamId"}}`, encodeURIComponent(String(ownerTeamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a campaign given the team id
         * @param {string} ownerTeamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaign: async (ownerTeamId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ownerTeamId' is not null or undefined
            assertParamExists('getCampaign', 'ownerTeamId', ownerTeamId)
            const localVarPath = `/v1/a2p/campaign/{ownerTeamId}`
                .replace(`{${"ownerTeamId"}}`, encodeURIComponent(String(ownerTeamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves messaging profiles by the team id
         * @param {string} ownerTeamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagingProfile: async (ownerTeamId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ownerTeamId' is not null or undefined
            assertParamExists('getMessagingProfile', 'ownerTeamId', ownerTeamId)
            const localVarPath = `/v1/a2p/messaging-profile/{ownerTeamId}`
                .replace(`{${"ownerTeamId"}}`, encodeURIComponent(String(ownerTeamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a given phone number associated with a team
         * @param {string} ownerTeamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhoneNumber: async (ownerTeamId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ownerTeamId' is not null or undefined
            assertParamExists('getPhoneNumber', 'ownerTeamId', ownerTeamId)
            const localVarPath = `/v1/a2p/phone-number/{ownerTeamId}`
                .replace(`{${"ownerTeamId"}}`, encodeURIComponent(String(ownerTeamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a list of phone numbers available for purchase based on the filters
         * @param {PhoneNumberListFilter} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPhoneNumbers: async (body: PhoneNumberListFilter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('listPhoneNumbers', 'body', body)
            const localVarPath = `/v1/a2p/phone-number/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the filters to be used for listing phone numbers available for purchase
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPhoneNumbersFilters: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/a2p/phone-number/list/filters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Orders a given phone number and associates it with a messaging profile
         * @param {PhoneNumberOrderRequestDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        phoneNumberOrder: async (body: PhoneNumberOrderRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('phoneNumberOrder', 'body', body)
            const localVarPath = `/v1/a2p/phone-number`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Ap2ServiceApi - functional programming interface
 * @export
 */
export const Ap2ServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Ap2ServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Assigns a phone number to a given campaign
         * @param {CampaignPhoneNumberRequestDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignPhoneNumber(body: CampaignPhoneNumberRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignPhoneNumber(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['Ap2ServiceApi.campaignPhoneNumber']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Creates a brand. Necessary for sending messages
         * @param {CreateBrandRequestDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBrand(body: CreateBrandRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBrand(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['Ap2ServiceApi.createBrand']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Creates a campaign associated with a brand. Necessary for sending messages
         * @param {CreateCampaignRequestDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCampaign(body: CreateCampaignRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCampaign(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['Ap2ServiceApi.createCampaign']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Creates a new messaging profiles
         * @param {MessagingProfileRequestDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMessagingProfile(body: MessagingProfileRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMessagingProfile(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['Ap2ServiceApi.createMessagingProfile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieves a brand given the team id
         * @param {string} ownerTeamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrand(ownerTeamId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrand(ownerTeamId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['Ap2ServiceApi.getBrand']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieves a campaign given the team id
         * @param {string} ownerTeamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaign(ownerTeamId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCampaign(ownerTeamId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['Ap2ServiceApi.getCampaign']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieves messaging profiles by the team id
         * @param {string} ownerTeamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessagingProfile(ownerTeamId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessagingProfile(ownerTeamId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['Ap2ServiceApi.getMessagingProfile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieves a given phone number associated with a team
         * @param {string} ownerTeamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPhoneNumber(ownerTeamId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPhoneNumber(ownerTeamId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['Ap2ServiceApi.getPhoneNumber']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieves a list of phone numbers available for purchase based on the filters
         * @param {PhoneNumberListFilter} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPhoneNumbers(body: PhoneNumberListFilter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPhoneNumbers(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['Ap2ServiceApi.listPhoneNumbers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieves the filters to be used for listing phone numbers available for purchase
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPhoneNumbersFilters(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPhoneNumbersFilters(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['Ap2ServiceApi.listPhoneNumbersFilters']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Orders a given phone number and associates it with a messaging profile
         * @param {PhoneNumberOrderRequestDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async phoneNumberOrder(body: PhoneNumberOrderRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.phoneNumberOrder(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['Ap2ServiceApi.phoneNumberOrder']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * Ap2ServiceApi - factory interface
 * @export
 */
export const Ap2ServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Ap2ServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Assigns a phone number to a given campaign
         * @param {CampaignPhoneNumberRequestDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignPhoneNumber(body: CampaignPhoneNumberRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.campaignPhoneNumber(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a brand. Necessary for sending messages
         * @param {CreateBrandRequestDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBrand(body: CreateBrandRequestDto, options?: any): AxiosPromise<object> {
            return localVarFp.createBrand(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a campaign associated with a brand. Necessary for sending messages
         * @param {CreateCampaignRequestDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCampaign(body: CreateCampaignRequestDto, options?: any): AxiosPromise<object> {
            return localVarFp.createCampaign(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new messaging profiles
         * @param {MessagingProfileRequestDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessagingProfile(body: MessagingProfileRequestDto, options?: any): AxiosPromise<object> {
            return localVarFp.createMessagingProfile(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a brand given the team id
         * @param {string} ownerTeamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrand(ownerTeamId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getBrand(ownerTeamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a campaign given the team id
         * @param {string} ownerTeamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaign(ownerTeamId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getCampaign(ownerTeamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves messaging profiles by the team id
         * @param {string} ownerTeamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagingProfile(ownerTeamId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getMessagingProfile(ownerTeamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a given phone number associated with a team
         * @param {string} ownerTeamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhoneNumber(ownerTeamId: string, options?: any): AxiosPromise<object> {
            return localVarFp.getPhoneNumber(ownerTeamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a list of phone numbers available for purchase based on the filters
         * @param {PhoneNumberListFilter} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPhoneNumbers(body: PhoneNumberListFilter, options?: any): AxiosPromise<void> {
            return localVarFp.listPhoneNumbers(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the filters to be used for listing phone numbers available for purchase
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPhoneNumbersFilters(options?: any): AxiosPromise<void> {
            return localVarFp.listPhoneNumbersFilters(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Orders a given phone number and associates it with a messaging profile
         * @param {PhoneNumberOrderRequestDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        phoneNumberOrder(body: PhoneNumberOrderRequestDto, options?: any): AxiosPromise<object> {
            return localVarFp.phoneNumberOrder(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Ap2ServiceApi - interface
 * @export
 * @interface Ap2ServiceApi
 */
export interface Ap2ServiceApiInterface {
    /**
     * 
     * @summary Assigns a phone number to a given campaign
     * @param {CampaignPhoneNumberRequestDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Ap2ServiceApiInterface
     */
    campaignPhoneNumber(body: CampaignPhoneNumberRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Creates a brand. Necessary for sending messages
     * @param {CreateBrandRequestDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Ap2ServiceApiInterface
     */
    createBrand(body: CreateBrandRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @summary Creates a campaign associated with a brand. Necessary for sending messages
     * @param {CreateCampaignRequestDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Ap2ServiceApiInterface
     */
    createCampaign(body: CreateCampaignRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @summary Creates a new messaging profiles
     * @param {MessagingProfileRequestDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Ap2ServiceApiInterface
     */
    createMessagingProfile(body: MessagingProfileRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @summary Retrieves a brand given the team id
     * @param {string} ownerTeamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Ap2ServiceApiInterface
     */
    getBrand(ownerTeamId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Retrieves a campaign given the team id
     * @param {string} ownerTeamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Ap2ServiceApiInterface
     */
    getCampaign(ownerTeamId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Retrieves messaging profiles by the team id
     * @param {string} ownerTeamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Ap2ServiceApiInterface
     */
    getMessagingProfile(ownerTeamId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Retrieves a given phone number associated with a team
     * @param {string} ownerTeamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Ap2ServiceApiInterface
     */
    getPhoneNumber(ownerTeamId: string, options?: RawAxiosRequestConfig): AxiosPromise<object>;

    /**
     * 
     * @summary Retrieves a list of phone numbers available for purchase based on the filters
     * @param {PhoneNumberListFilter} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Ap2ServiceApiInterface
     */
    listPhoneNumbers(body: PhoneNumberListFilter, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Retrieves the filters to be used for listing phone numbers available for purchase
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Ap2ServiceApiInterface
     */
    listPhoneNumbersFilters(options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Orders a given phone number and associates it with a messaging profile
     * @param {PhoneNumberOrderRequestDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Ap2ServiceApiInterface
     */
    phoneNumberOrder(body: PhoneNumberOrderRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<object>;

}

/**
 * Ap2ServiceApi - object-oriented interface
 * @export
 * @class Ap2ServiceApi
 * @extends {BaseAPI}
 */
export class Ap2ServiceApi extends BaseAPI implements Ap2ServiceApiInterface {
    /**
     * 
     * @summary Assigns a phone number to a given campaign
     * @param {CampaignPhoneNumberRequestDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Ap2ServiceApi
     */
    public campaignPhoneNumber(body: CampaignPhoneNumberRequestDto, options?: RawAxiosRequestConfig) {
        return Ap2ServiceApiFp(this.configuration).campaignPhoneNumber(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a brand. Necessary for sending messages
     * @param {CreateBrandRequestDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Ap2ServiceApi
     */
    public createBrand(body: CreateBrandRequestDto, options?: RawAxiosRequestConfig) {
        return Ap2ServiceApiFp(this.configuration).createBrand(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a campaign associated with a brand. Necessary for sending messages
     * @param {CreateCampaignRequestDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Ap2ServiceApi
     */
    public createCampaign(body: CreateCampaignRequestDto, options?: RawAxiosRequestConfig) {
        return Ap2ServiceApiFp(this.configuration).createCampaign(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new messaging profiles
     * @param {MessagingProfileRequestDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Ap2ServiceApi
     */
    public createMessagingProfile(body: MessagingProfileRequestDto, options?: RawAxiosRequestConfig) {
        return Ap2ServiceApiFp(this.configuration).createMessagingProfile(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a brand given the team id
     * @param {string} ownerTeamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Ap2ServiceApi
     */
    public getBrand(ownerTeamId: string, options?: RawAxiosRequestConfig) {
        return Ap2ServiceApiFp(this.configuration).getBrand(ownerTeamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a campaign given the team id
     * @param {string} ownerTeamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Ap2ServiceApi
     */
    public getCampaign(ownerTeamId: string, options?: RawAxiosRequestConfig) {
        return Ap2ServiceApiFp(this.configuration).getCampaign(ownerTeamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves messaging profiles by the team id
     * @param {string} ownerTeamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Ap2ServiceApi
     */
    public getMessagingProfile(ownerTeamId: string, options?: RawAxiosRequestConfig) {
        return Ap2ServiceApiFp(this.configuration).getMessagingProfile(ownerTeamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a given phone number associated with a team
     * @param {string} ownerTeamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Ap2ServiceApi
     */
    public getPhoneNumber(ownerTeamId: string, options?: RawAxiosRequestConfig) {
        return Ap2ServiceApiFp(this.configuration).getPhoneNumber(ownerTeamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a list of phone numbers available for purchase based on the filters
     * @param {PhoneNumberListFilter} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Ap2ServiceApi
     */
    public listPhoneNumbers(body: PhoneNumberListFilter, options?: RawAxiosRequestConfig) {
        return Ap2ServiceApiFp(this.configuration).listPhoneNumbers(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the filters to be used for listing phone numbers available for purchase
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Ap2ServiceApi
     */
    public listPhoneNumbersFilters(options?: RawAxiosRequestConfig) {
        return Ap2ServiceApiFp(this.configuration).listPhoneNumbersFilters(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Orders a given phone number and associates it with a messaging profile
     * @param {PhoneNumberOrderRequestDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Ap2ServiceApi
     */
    public phoneNumberOrder(body: PhoneNumberOrderRequestDto, options?: RawAxiosRequestConfig) {
        return Ap2ServiceApiFp(this.configuration).phoneNumberOrder(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BillingApi - axios parameter creator
 * @export
 */
export const BillingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activates the billing account
         * @param {LinkAccountRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate: async (body: LinkAccountRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('activate', 'body', body)
            const localVarPath = `/billing/activate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingAccountInfo: async (teamId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('billingAccountInfo', 'teamId', teamId)
            const localVarPath = `/billing/teams/{teamId}/info`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingMinutes: async (teamId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('billingMinutes', 'teamId', teamId)
            const localVarPath = `/billing/teams/{teamId}/minutes`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingSms: async (teamId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('billingSms', 'teamId', teamId)
            const localVarPath = `/billing/teams/{teamId}/sms`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a Customer Portal checkout URL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerPortalUrl: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/billing/customer/portal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BillingApi - functional programming interface
 * @export
 */
export const BillingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BillingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Activates the billing account
         * @param {LinkAccountRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activate(body: LinkAccountRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activate(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BillingApi.activate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingAccountInfo(teamId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnAccountBillingInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingAccountInfo(teamId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BillingApi.billingAccountInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingMinutes(teamId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnBillingMinutesInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingMinutes(teamId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BillingApi.billingMinutes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingSms(teamId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnBillingSmsInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingSms(teamId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BillingApi.billingSms']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Creates a Customer Portal checkout URL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomerPortalUrl(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCustomerPortalUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomerPortalUrl(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BillingApi.createCustomerPortalUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * BillingApi - factory interface
 * @export
 */
export const BillingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BillingApiFp(configuration)
    return {
        /**
         * 
         * @summary Activates the billing account
         * @param {LinkAccountRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate(body: LinkAccountRequest, options?: any): AxiosPromise<void> {
            return localVarFp.activate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingAccountInfo(teamId: string, options?: any): AxiosPromise<KnAccountBillingInfoResponse> {
            return localVarFp.billingAccountInfo(teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingMinutes(teamId: string, options?: any): AxiosPromise<KnBillingMinutesInfoResponse> {
            return localVarFp.billingMinutes(teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingSms(teamId: string, options?: any): AxiosPromise<KnBillingSmsInfoResponse> {
            return localVarFp.billingSms(teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a Customer Portal checkout URL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerPortalUrl(options?: any): AxiosPromise<CreateCustomerPortalUrlResponse> {
            return localVarFp.createCustomerPortalUrl(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BillingApi - interface
 * @export
 * @interface BillingApi
 */
export interface BillingApiInterface {
    /**
     * 
     * @summary Activates the billing account
     * @param {LinkAccountRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApiInterface
     */
    activate(body: LinkAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApiInterface
     */
    billingAccountInfo(teamId: string, options?: RawAxiosRequestConfig): AxiosPromise<KnAccountBillingInfoResponse>;

    /**
     * 
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApiInterface
     */
    billingMinutes(teamId: string, options?: RawAxiosRequestConfig): AxiosPromise<KnBillingMinutesInfoResponse>;

    /**
     * 
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApiInterface
     */
    billingSms(teamId: string, options?: RawAxiosRequestConfig): AxiosPromise<KnBillingSmsInfoResponse>;

    /**
     * 
     * @summary Creates a Customer Portal checkout URL
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApiInterface
     */
    createCustomerPortalUrl(options?: RawAxiosRequestConfig): AxiosPromise<CreateCustomerPortalUrlResponse>;

}

/**
 * BillingApi - object-oriented interface
 * @export
 * @class BillingApi
 * @extends {BaseAPI}
 */
export class BillingApi extends BaseAPI implements BillingApiInterface {
    /**
     * 
     * @summary Activates the billing account
     * @param {LinkAccountRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public activate(body: LinkAccountRequest, options?: RawAxiosRequestConfig) {
        return BillingApiFp(this.configuration).activate(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingAccountInfo(teamId: string, options?: RawAxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingAccountInfo(teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingMinutes(teamId: string, options?: RawAxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingMinutes(teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingSms(teamId: string, options?: RawAxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingSms(teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a Customer Portal checkout URL
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public createCustomerPortalUrl(options?: RawAxiosRequestConfig) {
        return BillingApiFp(this.configuration).createCustomerPortalUrl(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CalendarOauthApi - axios parameter creator
 * @export
 */
export const CalendarOauthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the authorize URL for google calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarGoogleAuthorizeUrl: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calendar/oauth/google/authorize-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the access token and refresh token from google API and saves into database
         * @param {KnTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarGoogleTokenExchange: async (body: KnTokenRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('calendarGoogleTokenExchange', 'body', body)
            const localVarPath = `/calendar/oauth/google/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deauthorize the user\'s access to google calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleDeauthorize: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calendar/oauth/google/deauthorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the authorize URL for outlook (graphAPI) calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphAuthorizeUrl: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calendar/oauth/graph/authorize-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deauthorize the user\'s access to outlook (graphAPI) calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphDeauthorize: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calendar/oauth/graph/deauthorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the access token and refresh token from outlook (graphAPI) API and saves into database
         * @param {KnTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphTokenExchange: async (body: KnTokenRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('graphTokenExchange', 'body', body)
            const localVarPath = `/calendar/oauth/graph/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if the user has authorized outlook calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isGoOutlookCalendarAuthorized: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calendar/oauth/graph/is-authorized`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if the user has authorized google calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isGoogleCalendarAuthorized: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calendar/oauth/google/is-authorized`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalendarOauthApi - functional programming interface
 * @export
 */
export const CalendarOauthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalendarOauthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the authorize URL for google calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarGoogleAuthorizeUrl(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarGoogleAuthorizeUrl(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CalendarOauthApi.calendarGoogleAuthorizeUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get the access token and refresh token from google API and saves into database
         * @param {KnTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarGoogleTokenExchange(body: KnTokenRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarGoogleTokenExchange(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CalendarOauthApi.calendarGoogleTokenExchange']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Deauthorize the user\'s access to google calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async googleDeauthorize(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.googleDeauthorize(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CalendarOauthApi.googleDeauthorize']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get the authorize URL for outlook (graphAPI) calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async graphAuthorizeUrl(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.graphAuthorizeUrl(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CalendarOauthApi.graphAuthorizeUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Deauthorize the user\'s access to outlook (graphAPI) calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async graphDeauthorize(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.graphDeauthorize(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CalendarOauthApi.graphDeauthorize']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get the access token and refresh token from outlook (graphAPI) API and saves into database
         * @param {KnTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async graphTokenExchange(body: KnTokenRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.graphTokenExchange(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CalendarOauthApi.graphTokenExchange']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Check if the user has authorized outlook calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isGoOutlookCalendarAuthorized(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isGoOutlookCalendarAuthorized(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CalendarOauthApi.isGoOutlookCalendarAuthorized']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Check if the user has authorized google calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isGoogleCalendarAuthorized(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isGoogleCalendarAuthorized(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CalendarOauthApi.isGoogleCalendarAuthorized']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CalendarOauthApi - factory interface
 * @export
 */
export const CalendarOauthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalendarOauthApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the authorize URL for google calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarGoogleAuthorizeUrl(options?: any): AxiosPromise<string> {
            return localVarFp.calendarGoogleAuthorizeUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the access token and refresh token from google API and saves into database
         * @param {KnTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarGoogleTokenExchange(body: KnTokenRequest, options?: any): AxiosPromise<void> {
            return localVarFp.calendarGoogleTokenExchange(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deauthorize the user\'s access to google calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleDeauthorize(options?: any): AxiosPromise<void> {
            return localVarFp.googleDeauthorize(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the authorize URL for outlook (graphAPI) calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphAuthorizeUrl(options?: any): AxiosPromise<string> {
            return localVarFp.graphAuthorizeUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deauthorize the user\'s access to outlook (graphAPI) calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphDeauthorize(options?: any): AxiosPromise<void> {
            return localVarFp.graphDeauthorize(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the access token and refresh token from outlook (graphAPI) API and saves into database
         * @param {KnTokenRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        graphTokenExchange(body: KnTokenRequest, options?: any): AxiosPromise<void> {
            return localVarFp.graphTokenExchange(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if the user has authorized outlook calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isGoOutlookCalendarAuthorized(options?: any): AxiosPromise<boolean> {
            return localVarFp.isGoOutlookCalendarAuthorized(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if the user has authorized google calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isGoogleCalendarAuthorized(options?: any): AxiosPromise<boolean> {
            return localVarFp.isGoogleCalendarAuthorized(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CalendarOauthApi - interface
 * @export
 * @interface CalendarOauthApi
 */
export interface CalendarOauthApiInterface {
    /**
     * 
     * @summary Get the authorize URL for google calendar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarOauthApiInterface
     */
    calendarGoogleAuthorizeUrl(options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @summary Get the access token and refresh token from google API and saves into database
     * @param {KnTokenRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarOauthApiInterface
     */
    calendarGoogleTokenExchange(body: KnTokenRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Deauthorize the user\'s access to google calendar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarOauthApiInterface
     */
    googleDeauthorize(options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Get the authorize URL for outlook (graphAPI) calendar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarOauthApiInterface
     */
    graphAuthorizeUrl(options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @summary Deauthorize the user\'s access to outlook (graphAPI) calendar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarOauthApiInterface
     */
    graphDeauthorize(options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Get the access token and refresh token from outlook (graphAPI) API and saves into database
     * @param {KnTokenRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarOauthApiInterface
     */
    graphTokenExchange(body: KnTokenRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Check if the user has authorized outlook calendar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarOauthApiInterface
     */
    isGoOutlookCalendarAuthorized(options?: RawAxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * 
     * @summary Check if the user has authorized google calendar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarOauthApiInterface
     */
    isGoogleCalendarAuthorized(options?: RawAxiosRequestConfig): AxiosPromise<boolean>;

}

/**
 * CalendarOauthApi - object-oriented interface
 * @export
 * @class CalendarOauthApi
 * @extends {BaseAPI}
 */
export class CalendarOauthApi extends BaseAPI implements CalendarOauthApiInterface {
    /**
     * 
     * @summary Get the authorize URL for google calendar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarOauthApi
     */
    public calendarGoogleAuthorizeUrl(options?: RawAxiosRequestConfig) {
        return CalendarOauthApiFp(this.configuration).calendarGoogleAuthorizeUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the access token and refresh token from google API and saves into database
     * @param {KnTokenRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarOauthApi
     */
    public calendarGoogleTokenExchange(body: KnTokenRequest, options?: RawAxiosRequestConfig) {
        return CalendarOauthApiFp(this.configuration).calendarGoogleTokenExchange(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deauthorize the user\'s access to google calendar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarOauthApi
     */
    public googleDeauthorize(options?: RawAxiosRequestConfig) {
        return CalendarOauthApiFp(this.configuration).googleDeauthorize(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the authorize URL for outlook (graphAPI) calendar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarOauthApi
     */
    public graphAuthorizeUrl(options?: RawAxiosRequestConfig) {
        return CalendarOauthApiFp(this.configuration).graphAuthorizeUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deauthorize the user\'s access to outlook (graphAPI) calendar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarOauthApi
     */
    public graphDeauthorize(options?: RawAxiosRequestConfig) {
        return CalendarOauthApiFp(this.configuration).graphDeauthorize(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the access token and refresh token from outlook (graphAPI) API and saves into database
     * @param {KnTokenRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarOauthApi
     */
    public graphTokenExchange(body: KnTokenRequest, options?: RawAxiosRequestConfig) {
        return CalendarOauthApiFp(this.configuration).graphTokenExchange(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if the user has authorized outlook calendar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarOauthApi
     */
    public isGoOutlookCalendarAuthorized(options?: RawAxiosRequestConfig) {
        return CalendarOauthApiFp(this.configuration).isGoOutlookCalendarAuthorized(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if the user has authorized google calendar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarOauthApi
     */
    public isGoogleCalendarAuthorized(options?: RawAxiosRequestConfig) {
        return CalendarOauthApiFp(this.configuration).isGoogleCalendarAuthorized(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ConsentControllerApi - axios parameter creator
 * @export
 */
export const ConsentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a Consent entity object
         * @param {ConsentDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConsent: async (body: ConsentDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createConsent', 'body', body)
            const localVarPath = `/consent/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deauthorize gmail for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deauthorizeUserGmail: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/consent/v1/deauthorize/gmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deauthorize outlook gmail for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deauthorizeUserOutlook: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/consent/v1/deauthorize/outlook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a Consent entity object
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConsent: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteConsent', 'id', id)
            const localVarPath = `/consent/v1/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a Consent entity object
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsent: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getConsent', 'id', id)
            const localVarPath = `/consent/v1/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a Consent entity object based on the filter
         * @param {string} emailAddress 
         * @param {boolean} loadToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsentByFilter: async (emailAddress: string, loadToken: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailAddress' is not null or undefined
            assertParamExists('getConsentByFilter', 'emailAddress', emailAddress)
            // verify required parameter 'loadToken' is not null or undefined
            assertParamExists('getConsentByFilter', 'loadToken', loadToken)
            const localVarPath = `/consent/v1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }

            if (loadToken !== undefined) {
                localVarQueryParameter['loadToken'] = loadToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a List of Consent entity objects based on the current user/token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsentsForCurrentUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/consent/v1/lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks if gmail is authorized
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isConnectedUserGmail: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/consent/v1/self/gmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks if outlook is authorized
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isConnectedUserOutlook: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/consent/v1/self/outlook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a Consent entity object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectedTeamShared: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/consent/v1/team`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a Consent entity object
         * @param {string} id 
         * @param {ConsentDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConsent: async (id: string, body: ConsentDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateConsent', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateConsent', 'body', body)
            const localVarPath = `/consent/v1/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConsentControllerApi - functional programming interface
 * @export
 */
export const ConsentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConsentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a Consent entity object
         * @param {ConsentDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConsent(body: ConsentDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsentDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConsent(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConsentControllerApi.createConsent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Deauthorize gmail for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deauthorizeUserGmail(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deauthorizeUserGmail(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConsentControllerApi.deauthorizeUserGmail']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Deauthorize outlook gmail for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deauthorizeUserOutlook(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deauthorizeUserOutlook(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConsentControllerApi.deauthorizeUserOutlook']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes a Consent entity object
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConsent(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsentDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConsent(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConsentControllerApi.deleteConsent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a Consent entity object
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConsent(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsentDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConsent(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConsentControllerApi.getConsent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a Consent entity object based on the filter
         * @param {string} emailAddress 
         * @param {boolean} loadToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConsentByFilter(emailAddress: string, loadToken: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsentDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConsentByFilter(emailAddress, loadToken, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConsentControllerApi.getConsentByFilter']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a List of Consent entity objects based on the current user/token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConsentsForCurrentUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConsentDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConsentsForCurrentUser(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConsentControllerApi.getConsentsForCurrentUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Checks if gmail is authorized
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isConnectedUserGmail(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isConnectedUserGmail(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConsentControllerApi.isConnectedUserGmail']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Checks if outlook is authorized
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isConnectedUserOutlook(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isConnectedUserOutlook(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConsentControllerApi.isConnectedUserOutlook']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a Consent entity object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConnectedTeamShared(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConsentDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConnectedTeamShared(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConsentControllerApi.listConnectedTeamShared']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Updates a Consent entity object
         * @param {string} id 
         * @param {ConsentDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConsent(id: string, body: ConsentDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsentDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConsent(id, body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConsentControllerApi.updateConsent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ConsentControllerApi - factory interface
 * @export
 */
export const ConsentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConsentControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a Consent entity object
         * @param {ConsentDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConsent(body: ConsentDTO, options?: any): AxiosPromise<ConsentDTO> {
            return localVarFp.createConsent(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deauthorize gmail for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deauthorizeUserGmail(options?: any): AxiosPromise<void> {
            return localVarFp.deauthorizeUserGmail(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deauthorize outlook gmail for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deauthorizeUserOutlook(options?: any): AxiosPromise<void> {
            return localVarFp.deauthorizeUserOutlook(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a Consent entity object
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConsent(id: string, options?: any): AxiosPromise<ConsentDTO> {
            return localVarFp.deleteConsent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a Consent entity object
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsent(id: string, options?: any): AxiosPromise<ConsentDTO> {
            return localVarFp.getConsent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a Consent entity object based on the filter
         * @param {string} emailAddress 
         * @param {boolean} loadToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsentByFilter(emailAddress: string, loadToken: boolean, options?: any): AxiosPromise<ConsentDTO> {
            return localVarFp.getConsentByFilter(emailAddress, loadToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a List of Consent entity objects based on the current user/token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsentsForCurrentUser(options?: any): AxiosPromise<Array<ConsentDTO>> {
            return localVarFp.getConsentsForCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Checks if gmail is authorized
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isConnectedUserGmail(options?: any): AxiosPromise<boolean> {
            return localVarFp.isConnectedUserGmail(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Checks if outlook is authorized
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isConnectedUserOutlook(options?: any): AxiosPromise<boolean> {
            return localVarFp.isConnectedUserOutlook(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a Consent entity object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectedTeamShared(options?: any): AxiosPromise<Array<ConsentDTO>> {
            return localVarFp.listConnectedTeamShared(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a Consent entity object
         * @param {string} id 
         * @param {ConsentDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConsent(id: string, body: ConsentDTO, options?: any): AxiosPromise<ConsentDTO> {
            return localVarFp.updateConsent(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConsentControllerApi - interface
 * @export
 * @interface ConsentControllerApi
 */
export interface ConsentControllerApiInterface {
    /**
     * 
     * @summary Creates a Consent entity object
     * @param {ConsentDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentControllerApiInterface
     */
    createConsent(body: ConsentDTO, options?: RawAxiosRequestConfig): AxiosPromise<ConsentDTO>;

    /**
     * 
     * @summary Deauthorize gmail for user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentControllerApiInterface
     */
    deauthorizeUserGmail(options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Deauthorize outlook gmail for user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentControllerApiInterface
     */
    deauthorizeUserOutlook(options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Deletes a Consent entity object
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentControllerApiInterface
     */
    deleteConsent(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ConsentDTO>;

    /**
     * 
     * @summary Gets a Consent entity object
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentControllerApiInterface
     */
    getConsent(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ConsentDTO>;

    /**
     * 
     * @summary Gets a Consent entity object based on the filter
     * @param {string} emailAddress 
     * @param {boolean} loadToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentControllerApiInterface
     */
    getConsentByFilter(emailAddress: string, loadToken: boolean, options?: RawAxiosRequestConfig): AxiosPromise<ConsentDTO>;

    /**
     * 
     * @summary Gets a List of Consent entity objects based on the current user/token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentControllerApiInterface
     */
    getConsentsForCurrentUser(options?: RawAxiosRequestConfig): AxiosPromise<Array<ConsentDTO>>;

    /**
     * 
     * @summary Checks if gmail is authorized
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentControllerApiInterface
     */
    isConnectedUserGmail(options?: RawAxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * 
     * @summary Checks if outlook is authorized
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentControllerApiInterface
     */
    isConnectedUserOutlook(options?: RawAxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * 
     * @summary Gets a Consent entity object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentControllerApiInterface
     */
    listConnectedTeamShared(options?: RawAxiosRequestConfig): AxiosPromise<Array<ConsentDTO>>;

    /**
     * 
     * @summary Updates a Consent entity object
     * @param {string} id 
     * @param {ConsentDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentControllerApiInterface
     */
    updateConsent(id: string, body: ConsentDTO, options?: RawAxiosRequestConfig): AxiosPromise<ConsentDTO>;

}

/**
 * ConsentControllerApi - object-oriented interface
 * @export
 * @class ConsentControllerApi
 * @extends {BaseAPI}
 */
export class ConsentControllerApi extends BaseAPI implements ConsentControllerApiInterface {
    /**
     * 
     * @summary Creates a Consent entity object
     * @param {ConsentDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentControllerApi
     */
    public createConsent(body: ConsentDTO, options?: RawAxiosRequestConfig) {
        return ConsentControllerApiFp(this.configuration).createConsent(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deauthorize gmail for user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentControllerApi
     */
    public deauthorizeUserGmail(options?: RawAxiosRequestConfig) {
        return ConsentControllerApiFp(this.configuration).deauthorizeUserGmail(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deauthorize outlook gmail for user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentControllerApi
     */
    public deauthorizeUserOutlook(options?: RawAxiosRequestConfig) {
        return ConsentControllerApiFp(this.configuration).deauthorizeUserOutlook(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a Consent entity object
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentControllerApi
     */
    public deleteConsent(id: string, options?: RawAxiosRequestConfig) {
        return ConsentControllerApiFp(this.configuration).deleteConsent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a Consent entity object
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentControllerApi
     */
    public getConsent(id: string, options?: RawAxiosRequestConfig) {
        return ConsentControllerApiFp(this.configuration).getConsent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a Consent entity object based on the filter
     * @param {string} emailAddress 
     * @param {boolean} loadToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentControllerApi
     */
    public getConsentByFilter(emailAddress: string, loadToken: boolean, options?: RawAxiosRequestConfig) {
        return ConsentControllerApiFp(this.configuration).getConsentByFilter(emailAddress, loadToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a List of Consent entity objects based on the current user/token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentControllerApi
     */
    public getConsentsForCurrentUser(options?: RawAxiosRequestConfig) {
        return ConsentControllerApiFp(this.configuration).getConsentsForCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Checks if gmail is authorized
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentControllerApi
     */
    public isConnectedUserGmail(options?: RawAxiosRequestConfig) {
        return ConsentControllerApiFp(this.configuration).isConnectedUserGmail(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Checks if outlook is authorized
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentControllerApi
     */
    public isConnectedUserOutlook(options?: RawAxiosRequestConfig) {
        return ConsentControllerApiFp(this.configuration).isConnectedUserOutlook(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a Consent entity object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentControllerApi
     */
    public listConnectedTeamShared(options?: RawAxiosRequestConfig) {
        return ConsentControllerApiFp(this.configuration).listConnectedTeamShared(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a Consent entity object
     * @param {string} id 
     * @param {ConsentDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentControllerApi
     */
    public updateConsent(id: string, body: ConsentDTO, options?: RawAxiosRequestConfig) {
        return ConsentControllerApiFp(this.configuration).updateConsent(id, body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CrmApi - axios parameter creator
 * @export
 */
export const CrmApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * It will present with a screen that allows the user to select their account and grant access to your integration. After granting access, the user will be redirected back to your application via a redirect_url, which will have a code query parameter appended to it: \'...?code=xxxx\' You\'ll use that code and the client secret to get an access_token and refresh_token from HubSpot
         * @summary Generates the authorize URL.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hubspotAuthorizeUrl: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/crm/oauth/hubspot/authorize-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * It will remove the all the information from hubspot authorization from user in database
         * @summary Removes the hubspot authorization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hubspotDeauthorize: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/crm/oauth/hubspot/deauthorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * It uses the \'code\' obtained from \'/hubspot/authorize-url\' endpoint.
         * @summary Obtain the access token.
         * @param {KnTokenRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hubspotTokenExchange: async (body: KnTokenRequest2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('hubspotTokenExchange', 'body', body)
            const localVarPath = `/crm/oauth/hubspot/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if the user has authorized hubspot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isHubspotAuthorized: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/crm/oauth/hubspot/is-authorized`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CrmApi - functional programming interface
 * @export
 */
export const CrmApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CrmApiAxiosParamCreator(configuration)
    return {
        /**
         * It will present with a screen that allows the user to select their account and grant access to your integration. After granting access, the user will be redirected back to your application via a redirect_url, which will have a code query parameter appended to it: \'...?code=xxxx\' You\'ll use that code and the client secret to get an access_token and refresh_token from HubSpot
         * @summary Generates the authorize URL.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hubspotAuthorizeUrl(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hubspotAuthorizeUrl(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CrmApi.hubspotAuthorizeUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * It will remove the all the information from hubspot authorization from user in database
         * @summary Removes the hubspot authorization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hubspotDeauthorize(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hubspotDeauthorize(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CrmApi.hubspotDeauthorize']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * It uses the \'code\' obtained from \'/hubspot/authorize-url\' endpoint.
         * @summary Obtain the access token.
         * @param {KnTokenRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hubspotTokenExchange(body: KnTokenRequest2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hubspotTokenExchange(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CrmApi.hubspotTokenExchange']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Check if the user has authorized hubspot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isHubspotAuthorized(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isHubspotAuthorized(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CrmApi.isHubspotAuthorized']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CrmApi - factory interface
 * @export
 */
export const CrmApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CrmApiFp(configuration)
    return {
        /**
         * It will present with a screen that allows the user to select their account and grant access to your integration. After granting access, the user will be redirected back to your application via a redirect_url, which will have a code query parameter appended to it: \'...?code=xxxx\' You\'ll use that code and the client secret to get an access_token and refresh_token from HubSpot
         * @summary Generates the authorize URL.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hubspotAuthorizeUrl(options?: any): AxiosPromise<string> {
            return localVarFp.hubspotAuthorizeUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * It will remove the all the information from hubspot authorization from user in database
         * @summary Removes the hubspot authorization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hubspotDeauthorize(options?: any): AxiosPromise<void> {
            return localVarFp.hubspotDeauthorize(options).then((request) => request(axios, basePath));
        },
        /**
         * It uses the \'code\' obtained from \'/hubspot/authorize-url\' endpoint.
         * @summary Obtain the access token.
         * @param {KnTokenRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hubspotTokenExchange(body: KnTokenRequest2, options?: any): AxiosPromise<void> {
            return localVarFp.hubspotTokenExchange(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if the user has authorized hubspot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isHubspotAuthorized(options?: any): AxiosPromise<boolean> {
            return localVarFp.isHubspotAuthorized(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CrmApi - interface
 * @export
 * @interface CrmApi
 */
export interface CrmApiInterface {
    /**
     * It will present with a screen that allows the user to select their account and grant access to your integration. After granting access, the user will be redirected back to your application via a redirect_url, which will have a code query parameter appended to it: \'...?code=xxxx\' You\'ll use that code and the client secret to get an access_token and refresh_token from HubSpot
     * @summary Generates the authorize URL.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApiInterface
     */
    hubspotAuthorizeUrl(options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * It will remove the all the information from hubspot authorization from user in database
     * @summary Removes the hubspot authorization.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApiInterface
     */
    hubspotDeauthorize(options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * It uses the \'code\' obtained from \'/hubspot/authorize-url\' endpoint.
     * @summary Obtain the access token.
     * @param {KnTokenRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApiInterface
     */
    hubspotTokenExchange(body: KnTokenRequest2, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Check if the user has authorized hubspot
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApiInterface
     */
    isHubspotAuthorized(options?: RawAxiosRequestConfig): AxiosPromise<boolean>;

}

/**
 * CrmApi - object-oriented interface
 * @export
 * @class CrmApi
 * @extends {BaseAPI}
 */
export class CrmApi extends BaseAPI implements CrmApiInterface {
    /**
     * It will present with a screen that allows the user to select their account and grant access to your integration. After granting access, the user will be redirected back to your application via a redirect_url, which will have a code query parameter appended to it: \'...?code=xxxx\' You\'ll use that code and the client secret to get an access_token and refresh_token from HubSpot
     * @summary Generates the authorize URL.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApi
     */
    public hubspotAuthorizeUrl(options?: RawAxiosRequestConfig) {
        return CrmApiFp(this.configuration).hubspotAuthorizeUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * It will remove the all the information from hubspot authorization from user in database
     * @summary Removes the hubspot authorization.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApi
     */
    public hubspotDeauthorize(options?: RawAxiosRequestConfig) {
        return CrmApiFp(this.configuration).hubspotDeauthorize(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * It uses the \'code\' obtained from \'/hubspot/authorize-url\' endpoint.
     * @summary Obtain the access token.
     * @param {KnTokenRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApi
     */
    public hubspotTokenExchange(body: KnTokenRequest2, options?: RawAxiosRequestConfig) {
        return CrmApiFp(this.configuration).hubspotTokenExchange(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if the user has authorized hubspot
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApi
     */
    public isHubspotAuthorized(options?: RawAxiosRequestConfig) {
        return CrmApiFp(this.configuration).isHubspotAuthorized(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DataImportApi - axios parameter creator
 * @export
 */
export const DataImportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Filter Team\'s FirestoreImportJob documents based on a \'start-date\' (String) and \'end-date\' (String) range via paginated DTO KnDateRangeFilterRequest
         * @param {KnDateRangeFilterRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dateRangeFilterFirestoreImportJobs: async (request: KnDateRangeFilterRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('dateRangeFilterFirestoreImportJobs', 'request', request)
            const localVarPath = `/data-import/filter/date/range`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Team FirestoreImportJob documents by TeamId via SecurityContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFirestoreImportJobs: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/data-import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Keyword search Team\'s FirestoreImportJob documents via paginated and search-termed DTO KnKeywordSearchRequest
         * @param {KnKeywordSearchRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keywordSearchFirestoreImportJobs: async (request: KnKeywordSearchRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('keywordSearchFirestoreImportJobs', 'request', request)
            const localVarPath = `/data-import/keyword/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lookup Team\'s FirestoreImportJob document via paginated and filtered DTO KnLookupRequest
         * @param {KnLookupRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupFirestoreImportJobs: async (request: KnLookupRequest2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('lookupFirestoreImportJobs', 'request', request)
            const localVarPath = `/data-import/lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return Team\'s FirestoreImportJob documents via paginated DTO KnPageRequest
         * @param {KnPageRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageFirestoreImportJobs: async (request: KnPageRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('pageFirestoreImportJobs', 'request', request)
            const localVarPath = `/data-import/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Filter Team\'s FirestoreImportJob documents based on a ImportJobType {COMPANY, CONTACT, PRODUCT} via DTO KnFirestoreImportJobTyperRequest
         * @param {KnFirestoreImportTypeRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        typeFilterTeamImportJobs: async (request: KnFirestoreImportTypeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('typeFilterTeamImportJobs', 'request', request)
            const localVarPath = `/data-import/filter/import/type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataImportApi - functional programming interface
 * @export
 */
export const DataImportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataImportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Filter Team\'s FirestoreImportJob documents based on a \'start-date\' (String) and \'end-date\' (String) range via paginated DTO KnDateRangeFilterRequest
         * @param {KnDateRangeFilterRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dateRangeFilterFirestoreImportJobs(request: KnDateRangeFilterRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDateRangeFilterResponseKnFirestoreImportJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dateRangeFilterFirestoreImportJobs(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportApi.dateRangeFilterFirestoreImportJobs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Team FirestoreImportJob documents by TeamId via SecurityContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFirestoreImportJobs(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDocumentListResponseKnFirestoreImportJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFirestoreImportJobs(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportApi.getFirestoreImportJobs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Keyword search Team\'s FirestoreImportJob documents via paginated and search-termed DTO KnKeywordSearchRequest
         * @param {KnKeywordSearchRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async keywordSearchFirestoreImportJobs(request: KnKeywordSearchRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnKeywordSearchResponseKnFirestoreImportJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.keywordSearchFirestoreImportJobs(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportApi.keywordSearchFirestoreImportJobs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Lookup Team\'s FirestoreImportJob document via paginated and filtered DTO KnLookupRequest
         * @param {KnLookupRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lookupFirestoreImportJobs(request: KnLookupRequest2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnLookupResponseKnFirestoreImportJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lookupFirestoreImportJobs(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportApi.lookupFirestoreImportJobs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Return Team\'s FirestoreImportJob documents via paginated DTO KnPageRequest
         * @param {KnPageRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageFirestoreImportJobs(request: KnPageRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnPageResponseKnFirestoreImportJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageFirestoreImportJobs(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportApi.pageFirestoreImportJobs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Filter Team\'s FirestoreImportJob documents based on a ImportJobType {COMPANY, CONTACT, PRODUCT} via DTO KnFirestoreImportJobTyperRequest
         * @param {KnFirestoreImportTypeRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async typeFilterTeamImportJobs(request: KnFirestoreImportTypeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnFirestoreImportJobTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.typeFilterTeamImportJobs(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportApi.typeFilterTeamImportJobs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DataImportApi - factory interface
 * @export
 */
export const DataImportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataImportApiFp(configuration)
    return {
        /**
         * 
         * @summary Filter Team\'s FirestoreImportJob documents based on a \'start-date\' (String) and \'end-date\' (String) range via paginated DTO KnDateRangeFilterRequest
         * @param {KnDateRangeFilterRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dateRangeFilterFirestoreImportJobs(request: KnDateRangeFilterRequest, options?: any): AxiosPromise<KnDateRangeFilterResponseKnFirestoreImportJob> {
            return localVarFp.dateRangeFilterFirestoreImportJobs(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Team FirestoreImportJob documents by TeamId via SecurityContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFirestoreImportJobs(options?: any): AxiosPromise<KnDocumentListResponseKnFirestoreImportJob> {
            return localVarFp.getFirestoreImportJobs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Keyword search Team\'s FirestoreImportJob documents via paginated and search-termed DTO KnKeywordSearchRequest
         * @param {KnKeywordSearchRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keywordSearchFirestoreImportJobs(request: KnKeywordSearchRequest, options?: any): AxiosPromise<KnKeywordSearchResponseKnFirestoreImportJob> {
            return localVarFp.keywordSearchFirestoreImportJobs(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lookup Team\'s FirestoreImportJob document via paginated and filtered DTO KnLookupRequest
         * @param {KnLookupRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupFirestoreImportJobs(request: KnLookupRequest2, options?: any): AxiosPromise<KnLookupResponseKnFirestoreImportJob> {
            return localVarFp.lookupFirestoreImportJobs(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return Team\'s FirestoreImportJob documents via paginated DTO KnPageRequest
         * @param {KnPageRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageFirestoreImportJobs(request: KnPageRequest, options?: any): AxiosPromise<KnPageResponseKnFirestoreImportJob> {
            return localVarFp.pageFirestoreImportJobs(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Filter Team\'s FirestoreImportJob documents based on a ImportJobType {COMPANY, CONTACT, PRODUCT} via DTO KnFirestoreImportJobTyperRequest
         * @param {KnFirestoreImportTypeRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        typeFilterTeamImportJobs(request: KnFirestoreImportTypeRequest, options?: any): AxiosPromise<KnFirestoreImportJobTypeResponse> {
            return localVarFp.typeFilterTeamImportJobs(request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataImportApi - interface
 * @export
 * @interface DataImportApi
 */
export interface DataImportApiInterface {
    /**
     * 
     * @summary Filter Team\'s FirestoreImportJob documents based on a \'start-date\' (String) and \'end-date\' (String) range via paginated DTO KnDateRangeFilterRequest
     * @param {KnDateRangeFilterRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportApiInterface
     */
    dateRangeFilterFirestoreImportJobs(request: KnDateRangeFilterRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnDateRangeFilterResponseKnFirestoreImportJob>;

    /**
     * 
     * @summary Get All Team FirestoreImportJob documents by TeamId via SecurityContext
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportApiInterface
     */
    getFirestoreImportJobs(options?: RawAxiosRequestConfig): AxiosPromise<KnDocumentListResponseKnFirestoreImportJob>;

    /**
     * 
     * @summary Keyword search Team\'s FirestoreImportJob documents via paginated and search-termed DTO KnKeywordSearchRequest
     * @param {KnKeywordSearchRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportApiInterface
     */
    keywordSearchFirestoreImportJobs(request: KnKeywordSearchRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnKeywordSearchResponseKnFirestoreImportJob>;

    /**
     * 
     * @summary Lookup Team\'s FirestoreImportJob document via paginated and filtered DTO KnLookupRequest
     * @param {KnLookupRequest2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportApiInterface
     */
    lookupFirestoreImportJobs(request: KnLookupRequest2, options?: RawAxiosRequestConfig): AxiosPromise<KnLookupResponseKnFirestoreImportJob>;

    /**
     * 
     * @summary Return Team\'s FirestoreImportJob documents via paginated DTO KnPageRequest
     * @param {KnPageRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportApiInterface
     */
    pageFirestoreImportJobs(request: KnPageRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnPageResponseKnFirestoreImportJob>;

    /**
     * 
     * @summary Filter Team\'s FirestoreImportJob documents based on a ImportJobType {COMPANY, CONTACT, PRODUCT} via DTO KnFirestoreImportJobTyperRequest
     * @param {KnFirestoreImportTypeRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportApiInterface
     */
    typeFilterTeamImportJobs(request: KnFirestoreImportTypeRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnFirestoreImportJobTypeResponse>;

}

/**
 * DataImportApi - object-oriented interface
 * @export
 * @class DataImportApi
 * @extends {BaseAPI}
 */
export class DataImportApi extends BaseAPI implements DataImportApiInterface {
    /**
     * 
     * @summary Filter Team\'s FirestoreImportJob documents based on a \'start-date\' (String) and \'end-date\' (String) range via paginated DTO KnDateRangeFilterRequest
     * @param {KnDateRangeFilterRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportApi
     */
    public dateRangeFilterFirestoreImportJobs(request: KnDateRangeFilterRequest, options?: RawAxiosRequestConfig) {
        return DataImportApiFp(this.configuration).dateRangeFilterFirestoreImportJobs(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Team FirestoreImportJob documents by TeamId via SecurityContext
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportApi
     */
    public getFirestoreImportJobs(options?: RawAxiosRequestConfig) {
        return DataImportApiFp(this.configuration).getFirestoreImportJobs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Keyword search Team\'s FirestoreImportJob documents via paginated and search-termed DTO KnKeywordSearchRequest
     * @param {KnKeywordSearchRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportApi
     */
    public keywordSearchFirestoreImportJobs(request: KnKeywordSearchRequest, options?: RawAxiosRequestConfig) {
        return DataImportApiFp(this.configuration).keywordSearchFirestoreImportJobs(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lookup Team\'s FirestoreImportJob document via paginated and filtered DTO KnLookupRequest
     * @param {KnLookupRequest2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportApi
     */
    public lookupFirestoreImportJobs(request: KnLookupRequest2, options?: RawAxiosRequestConfig) {
        return DataImportApiFp(this.configuration).lookupFirestoreImportJobs(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return Team\'s FirestoreImportJob documents via paginated DTO KnPageRequest
     * @param {KnPageRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportApi
     */
    public pageFirestoreImportJobs(request: KnPageRequest, options?: RawAxiosRequestConfig) {
        return DataImportApiFp(this.configuration).pageFirestoreImportJobs(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Filter Team\'s FirestoreImportJob documents based on a ImportJobType {COMPANY, CONTACT, PRODUCT} via DTO KnFirestoreImportJobTyperRequest
     * @param {KnFirestoreImportTypeRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportApi
     */
    public typeFilterTeamImportJobs(request: KnFirestoreImportTypeRequest, options?: RawAxiosRequestConfig) {
        return DataImportApiFp(this.configuration).typeFilterTeamImportJobs(request, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DataImportCompanyApi - axios parameter creator
 * @export
 */
export const DataImportCompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create CompanyImportJob via DTO KnCreateCompanyImportJobRequest
         * @param {KnCreateCompanyImportJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompanyImportJob: async (request: KnCreateCompanyImportJobRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('createCompanyImportJob', 'request', request)
            const localVarPath = `/data-import/company/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Soft-Delete an existing CompanyImportJob document by CompanyImportJob\'s Id (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyImportJob: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCompanyImportJob', 'id', id)
            const localVarPath = `/data-import/company/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a Team\'s CompanyImportJob document by CompanyId (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyImportJobById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCompanyImportJobById', 'id', id)
            const localVarPath = `/data-import/company/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Team CompanyImportJob documents by TeamId via SecurityContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyImportJobs: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/data-import/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Keyword search Team\'s CompanyImportJob documents via paginated and search-termed DTO KnKeywordSearchRequest
         * @param {KnKeywordSearchRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keywordSearchCompanyImportJobs: async (request: KnKeywordSearchRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('keywordSearchCompanyImportJobs', 'request', request)
            const localVarPath = `/data-import/company/keyword/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lookup Team\'s CompanyImportJob document via paginated and filtered DTOKnLookupRequest
         * @param {KnLookupRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupCompanyImportJobs: async (request: KnLookupRequest2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('lookupCompanyImportJobs', 'request', request)
            const localVarPath = `/data-import/company/lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return Team\'s CompanyImportJob documents via paginated DTO KnPageRequest
         * @param {KnPageRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageCompanyImportJobs: async (request: KnPageRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('pageCompanyImportJobs', 'request', request)
            const localVarPath = `/data-import/company/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch update an existing CompanyImportJob document via DTO KnCompanyImportJobRequest
         * @param {KnCompanyImportJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCompanyImportJob: async (request: KnCompanyImportJobRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('patchCompanyImportJob', 'request', request)
            const localVarPath = `/data-import/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Process CompanyImportJob via DTO KnProcessCompanyImportJobRequest
         * @param {KnProcessCompanyImportJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processCompanyImportJob: async (request: KnProcessCompanyImportJobRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('processCompanyImportJob', 'request', request)
            const localVarPath = `/data-import/company/process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload CSV file and create new CompanyImportJob via MultipartFile
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCompanyCsv: async (file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadCompanyCsv', 'file', file)
            const localVarPath = `/data-import/company/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataImportCompanyApi - functional programming interface
 * @export
 */
export const DataImportCompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataImportCompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create CompanyImportJob via DTO KnCreateCompanyImportJobRequest
         * @param {KnCreateCompanyImportJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompanyImportJob(request: KnCreateCompanyImportJobRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnCreateImportJobResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompanyImportJob(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportCompanyApi.createCompanyImportJob']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Soft-Delete an existing CompanyImportJob document by CompanyImportJob\'s Id (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCompanyImportJob(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompanyImportJob(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportCompanyApi.deleteCompanyImportJob']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get a Team\'s CompanyImportJob document by CompanyId (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyImportJobById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDocumentResponseKnCompanyImportJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyImportJobById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportCompanyApi.getCompanyImportJobById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Team CompanyImportJob documents by TeamId via SecurityContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyImportJobs(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDocumentListResponseKnCompanyImportJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyImportJobs(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportCompanyApi.getCompanyImportJobs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Keyword search Team\'s CompanyImportJob documents via paginated and search-termed DTO KnKeywordSearchRequest
         * @param {KnKeywordSearchRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async keywordSearchCompanyImportJobs(request: KnKeywordSearchRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnKeywordSearchResponseKnCompanyImportJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.keywordSearchCompanyImportJobs(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportCompanyApi.keywordSearchCompanyImportJobs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Lookup Team\'s CompanyImportJob document via paginated and filtered DTOKnLookupRequest
         * @param {KnLookupRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lookupCompanyImportJobs(request: KnLookupRequest2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnLookupResponseKnCompanyImportJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lookupCompanyImportJobs(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportCompanyApi.lookupCompanyImportJobs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Return Team\'s CompanyImportJob documents via paginated DTO KnPageRequest
         * @param {KnPageRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageCompanyImportJobs(request: KnPageRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnPageResponseKnCompanyImportJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageCompanyImportJobs(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportCompanyApi.pageCompanyImportJobs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Patch update an existing CompanyImportJob document via DTO KnCompanyImportJobRequest
         * @param {KnCompanyImportJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCompanyImportJob(request: KnCompanyImportJobRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDocumentResponseKnCompanyImportJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCompanyImportJob(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportCompanyApi.patchCompanyImportJob']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Process CompanyImportJob via DTO KnProcessCompanyImportJobRequest
         * @param {KnProcessCompanyImportJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processCompanyImportJob(request: KnProcessCompanyImportJobRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnProcessImportJobResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processCompanyImportJob(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportCompanyApi.processCompanyImportJob']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Upload CSV file and create new CompanyImportJob via MultipartFile
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadCompanyCsv(file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnCreateImportJobResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadCompanyCsv(file, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportCompanyApi.uploadCompanyCsv']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DataImportCompanyApi - factory interface
 * @export
 */
export const DataImportCompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataImportCompanyApiFp(configuration)
    return {
        /**
         * 
         * @summary Create CompanyImportJob via DTO KnCreateCompanyImportJobRequest
         * @param {KnCreateCompanyImportJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompanyImportJob(request: KnCreateCompanyImportJobRequest, options?: any): AxiosPromise<KnCreateImportJobResponse> {
            return localVarFp.createCompanyImportJob(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Soft-Delete an existing CompanyImportJob document by CompanyImportJob\'s Id (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyImportJob(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCompanyImportJob(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a Team\'s CompanyImportJob document by CompanyId (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyImportJobById(id: string, options?: any): AxiosPromise<KnDocumentResponseKnCompanyImportJob> {
            return localVarFp.getCompanyImportJobById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Team CompanyImportJob documents by TeamId via SecurityContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyImportJobs(options?: any): AxiosPromise<KnDocumentListResponseKnCompanyImportJob> {
            return localVarFp.getCompanyImportJobs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Keyword search Team\'s CompanyImportJob documents via paginated and search-termed DTO KnKeywordSearchRequest
         * @param {KnKeywordSearchRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keywordSearchCompanyImportJobs(request: KnKeywordSearchRequest, options?: any): AxiosPromise<KnKeywordSearchResponseKnCompanyImportJob> {
            return localVarFp.keywordSearchCompanyImportJobs(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lookup Team\'s CompanyImportJob document via paginated and filtered DTOKnLookupRequest
         * @param {KnLookupRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupCompanyImportJobs(request: KnLookupRequest2, options?: any): AxiosPromise<KnLookupResponseKnCompanyImportJob> {
            return localVarFp.lookupCompanyImportJobs(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return Team\'s CompanyImportJob documents via paginated DTO KnPageRequest
         * @param {KnPageRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageCompanyImportJobs(request: KnPageRequest, options?: any): AxiosPromise<KnPageResponseKnCompanyImportJob> {
            return localVarFp.pageCompanyImportJobs(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch update an existing CompanyImportJob document via DTO KnCompanyImportJobRequest
         * @param {KnCompanyImportJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCompanyImportJob(request: KnCompanyImportJobRequest, options?: any): AxiosPromise<KnDocumentResponseKnCompanyImportJob> {
            return localVarFp.patchCompanyImportJob(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Process CompanyImportJob via DTO KnProcessCompanyImportJobRequest
         * @param {KnProcessCompanyImportJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processCompanyImportJob(request: KnProcessCompanyImportJobRequest, options?: any): AxiosPromise<KnProcessImportJobResponse> {
            return localVarFp.processCompanyImportJob(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload CSV file and create new CompanyImportJob via MultipartFile
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCompanyCsv(file: File, options?: any): AxiosPromise<KnCreateImportJobResponse> {
            return localVarFp.uploadCompanyCsv(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataImportCompanyApi - interface
 * @export
 * @interface DataImportCompanyApi
 */
export interface DataImportCompanyApiInterface {
    /**
     * 
     * @summary Create CompanyImportJob via DTO KnCreateCompanyImportJobRequest
     * @param {KnCreateCompanyImportJobRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportCompanyApiInterface
     */
    createCompanyImportJob(request: KnCreateCompanyImportJobRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnCreateImportJobResponse>;

    /**
     * 
     * @summary Soft-Delete an existing CompanyImportJob document by CompanyImportJob\'s Id (\'id\') via Path Variable
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportCompanyApiInterface
     */
    deleteCompanyImportJob(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Get a Team\'s CompanyImportJob document by CompanyId (\'id\') via Path Variable
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportCompanyApiInterface
     */
    getCompanyImportJobById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<KnDocumentResponseKnCompanyImportJob>;

    /**
     * 
     * @summary Get All Team CompanyImportJob documents by TeamId via SecurityContext
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportCompanyApiInterface
     */
    getCompanyImportJobs(options?: RawAxiosRequestConfig): AxiosPromise<KnDocumentListResponseKnCompanyImportJob>;

    /**
     * 
     * @summary Keyword search Team\'s CompanyImportJob documents via paginated and search-termed DTO KnKeywordSearchRequest
     * @param {KnKeywordSearchRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportCompanyApiInterface
     */
    keywordSearchCompanyImportJobs(request: KnKeywordSearchRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnKeywordSearchResponseKnCompanyImportJob>;

    /**
     * 
     * @summary Lookup Team\'s CompanyImportJob document via paginated and filtered DTOKnLookupRequest
     * @param {KnLookupRequest2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportCompanyApiInterface
     */
    lookupCompanyImportJobs(request: KnLookupRequest2, options?: RawAxiosRequestConfig): AxiosPromise<KnLookupResponseKnCompanyImportJob>;

    /**
     * 
     * @summary Return Team\'s CompanyImportJob documents via paginated DTO KnPageRequest
     * @param {KnPageRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportCompanyApiInterface
     */
    pageCompanyImportJobs(request: KnPageRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnPageResponseKnCompanyImportJob>;

    /**
     * 
     * @summary Patch update an existing CompanyImportJob document via DTO KnCompanyImportJobRequest
     * @param {KnCompanyImportJobRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportCompanyApiInterface
     */
    patchCompanyImportJob(request: KnCompanyImportJobRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnDocumentResponseKnCompanyImportJob>;

    /**
     * 
     * @summary Process CompanyImportJob via DTO KnProcessCompanyImportJobRequest
     * @param {KnProcessCompanyImportJobRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportCompanyApiInterface
     */
    processCompanyImportJob(request: KnProcessCompanyImportJobRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnProcessImportJobResponse>;

    /**
     * 
     * @summary Upload CSV file and create new CompanyImportJob via MultipartFile
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportCompanyApiInterface
     */
    uploadCompanyCsv(file: File, options?: RawAxiosRequestConfig): AxiosPromise<KnCreateImportJobResponse>;

}

/**
 * DataImportCompanyApi - object-oriented interface
 * @export
 * @class DataImportCompanyApi
 * @extends {BaseAPI}
 */
export class DataImportCompanyApi extends BaseAPI implements DataImportCompanyApiInterface {
    /**
     * 
     * @summary Create CompanyImportJob via DTO KnCreateCompanyImportJobRequest
     * @param {KnCreateCompanyImportJobRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportCompanyApi
     */
    public createCompanyImportJob(request: KnCreateCompanyImportJobRequest, options?: RawAxiosRequestConfig) {
        return DataImportCompanyApiFp(this.configuration).createCompanyImportJob(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Soft-Delete an existing CompanyImportJob document by CompanyImportJob\'s Id (\'id\') via Path Variable
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportCompanyApi
     */
    public deleteCompanyImportJob(id: string, options?: RawAxiosRequestConfig) {
        return DataImportCompanyApiFp(this.configuration).deleteCompanyImportJob(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a Team\'s CompanyImportJob document by CompanyId (\'id\') via Path Variable
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportCompanyApi
     */
    public getCompanyImportJobById(id: string, options?: RawAxiosRequestConfig) {
        return DataImportCompanyApiFp(this.configuration).getCompanyImportJobById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Team CompanyImportJob documents by TeamId via SecurityContext
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportCompanyApi
     */
    public getCompanyImportJobs(options?: RawAxiosRequestConfig) {
        return DataImportCompanyApiFp(this.configuration).getCompanyImportJobs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Keyword search Team\'s CompanyImportJob documents via paginated and search-termed DTO KnKeywordSearchRequest
     * @param {KnKeywordSearchRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportCompanyApi
     */
    public keywordSearchCompanyImportJobs(request: KnKeywordSearchRequest, options?: RawAxiosRequestConfig) {
        return DataImportCompanyApiFp(this.configuration).keywordSearchCompanyImportJobs(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lookup Team\'s CompanyImportJob document via paginated and filtered DTOKnLookupRequest
     * @param {KnLookupRequest2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportCompanyApi
     */
    public lookupCompanyImportJobs(request: KnLookupRequest2, options?: RawAxiosRequestConfig) {
        return DataImportCompanyApiFp(this.configuration).lookupCompanyImportJobs(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return Team\'s CompanyImportJob documents via paginated DTO KnPageRequest
     * @param {KnPageRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportCompanyApi
     */
    public pageCompanyImportJobs(request: KnPageRequest, options?: RawAxiosRequestConfig) {
        return DataImportCompanyApiFp(this.configuration).pageCompanyImportJobs(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch update an existing CompanyImportJob document via DTO KnCompanyImportJobRequest
     * @param {KnCompanyImportJobRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportCompanyApi
     */
    public patchCompanyImportJob(request: KnCompanyImportJobRequest, options?: RawAxiosRequestConfig) {
        return DataImportCompanyApiFp(this.configuration).patchCompanyImportJob(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Process CompanyImportJob via DTO KnProcessCompanyImportJobRequest
     * @param {KnProcessCompanyImportJobRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportCompanyApi
     */
    public processCompanyImportJob(request: KnProcessCompanyImportJobRequest, options?: RawAxiosRequestConfig) {
        return DataImportCompanyApiFp(this.configuration).processCompanyImportJob(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload CSV file and create new CompanyImportJob via MultipartFile
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportCompanyApi
     */
    public uploadCompanyCsv(file: File, options?: RawAxiosRequestConfig) {
        return DataImportCompanyApiFp(this.configuration).uploadCompanyCsv(file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DataImportContactApi - axios parameter creator
 * @export
 */
export const DataImportContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create ContactImportJob via DTO KnCreateContactImportJobRequest
         * @param {KnCreateContactImportJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContactImportJob: async (request: KnCreateContactImportJobRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('createContactImportJob', 'request', request)
            const localVarPath = `/data-import/contact/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Soft-Delete an existing ContactImportJob document by ContactImportJob\'s Id (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContactImportJob: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteContactImportJob', 'id', id)
            const localVarPath = `/data-import/contact/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a Team\'s ContactImportJob document by Id (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactImportJobById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getContactImportJobById', 'id', id)
            const localVarPath = `/data-import/contact/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Team ContactImportJob documents by TeamId via SecurityContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactImportJobs: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/data-import/contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Keyword search Team\'s ContactImportJob documents via paginated and search-termed DTO KnKeywordSearchRequest
         * @param {KnKeywordSearchRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keywordSearchContactImportJobs: async (request: KnKeywordSearchRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('keywordSearchContactImportJobs', 'request', request)
            const localVarPath = `/data-import/contact/keyword/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lookup Team\'s ContactImportJob document via paginated and filtered DTO KnLookupRequest
         * @param {KnLookupRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupContactImportJobs: async (request: KnLookupRequest2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('lookupContactImportJobs', 'request', request)
            const localVarPath = `/data-import/contact/lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return Team\'s ContactImportJob documents via paginated DTO KnPageRequest
         * @param {KnPageRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageContactImportJobs: async (request: KnPageRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('pageContactImportJobs', 'request', request)
            const localVarPath = `/data-import/contact/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch update an existing ContactImportJob document via DTO KnContactImportJobRequest
         * @param {KnContactImportJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchContactImportJob: async (request: KnContactImportJobRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('patchContactImportJob', 'request', request)
            const localVarPath = `/data-import/contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Process ContactImportJob via DTO KnProcessContactImportJobRequest
         * @param {KnProcessContactImportJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processContactImportJob: async (request: KnProcessContactImportJobRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('processContactImportJob', 'request', request)
            const localVarPath = `/data-import/contact/process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload CSV file and create new ContactImportJob via MultipartFile
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadContactCsv: async (file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadContactCsv', 'file', file)
            const localVarPath = `/data-import/contact/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataImportContactApi - functional programming interface
 * @export
 */
export const DataImportContactApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataImportContactApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create ContactImportJob via DTO KnCreateContactImportJobRequest
         * @param {KnCreateContactImportJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContactImportJob(request: KnCreateContactImportJobRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnCreateImportJobResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createContactImportJob(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportContactApi.createContactImportJob']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Soft-Delete an existing ContactImportJob document by ContactImportJob\'s Id (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContactImportJob(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContactImportJob(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportContactApi.deleteContactImportJob']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get a Team\'s ContactImportJob document by Id (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContactImportJobById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDocumentResponseKnContactImportJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContactImportJobById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportContactApi.getContactImportJobById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Team ContactImportJob documents by TeamId via SecurityContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContactImportJobs(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDocumentListResponseKnContactImportJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContactImportJobs(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportContactApi.getContactImportJobs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Keyword search Team\'s ContactImportJob documents via paginated and search-termed DTO KnKeywordSearchRequest
         * @param {KnKeywordSearchRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async keywordSearchContactImportJobs(request: KnKeywordSearchRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnKeywordSearchResponseKnContactImportJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.keywordSearchContactImportJobs(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportContactApi.keywordSearchContactImportJobs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Lookup Team\'s ContactImportJob document via paginated and filtered DTO KnLookupRequest
         * @param {KnLookupRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lookupContactImportJobs(request: KnLookupRequest2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnLookupResponseKnContactImportJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lookupContactImportJobs(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportContactApi.lookupContactImportJobs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Return Team\'s ContactImportJob documents via paginated DTO KnPageRequest
         * @param {KnPageRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageContactImportJobs(request: KnPageRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnPageResponseKnContactImportJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageContactImportJobs(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportContactApi.pageContactImportJobs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Patch update an existing ContactImportJob document via DTO KnContactImportJobRequest
         * @param {KnContactImportJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchContactImportJob(request: KnContactImportJobRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDocumentResponseKnContactImportJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchContactImportJob(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportContactApi.patchContactImportJob']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Process ContactImportJob via DTO KnProcessContactImportJobRequest
         * @param {KnProcessContactImportJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processContactImportJob(request: KnProcessContactImportJobRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnProcessImportJobResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processContactImportJob(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportContactApi.processContactImportJob']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Upload CSV file and create new ContactImportJob via MultipartFile
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadContactCsv(file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnCreateImportJobResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadContactCsv(file, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportContactApi.uploadContactCsv']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DataImportContactApi - factory interface
 * @export
 */
export const DataImportContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataImportContactApiFp(configuration)
    return {
        /**
         * 
         * @summary Create ContactImportJob via DTO KnCreateContactImportJobRequest
         * @param {KnCreateContactImportJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContactImportJob(request: KnCreateContactImportJobRequest, options?: any): AxiosPromise<KnCreateImportJobResponse> {
            return localVarFp.createContactImportJob(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Soft-Delete an existing ContactImportJob document by ContactImportJob\'s Id (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContactImportJob(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteContactImportJob(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a Team\'s ContactImportJob document by Id (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactImportJobById(id: string, options?: any): AxiosPromise<KnDocumentResponseKnContactImportJob> {
            return localVarFp.getContactImportJobById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Team ContactImportJob documents by TeamId via SecurityContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactImportJobs(options?: any): AxiosPromise<KnDocumentListResponseKnContactImportJob> {
            return localVarFp.getContactImportJobs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Keyword search Team\'s ContactImportJob documents via paginated and search-termed DTO KnKeywordSearchRequest
         * @param {KnKeywordSearchRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keywordSearchContactImportJobs(request: KnKeywordSearchRequest, options?: any): AxiosPromise<KnKeywordSearchResponseKnContactImportJob> {
            return localVarFp.keywordSearchContactImportJobs(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lookup Team\'s ContactImportJob document via paginated and filtered DTO KnLookupRequest
         * @param {KnLookupRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupContactImportJobs(request: KnLookupRequest2, options?: any): AxiosPromise<KnLookupResponseKnContactImportJob> {
            return localVarFp.lookupContactImportJobs(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return Team\'s ContactImportJob documents via paginated DTO KnPageRequest
         * @param {KnPageRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageContactImportJobs(request: KnPageRequest, options?: any): AxiosPromise<KnPageResponseKnContactImportJob> {
            return localVarFp.pageContactImportJobs(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch update an existing ContactImportJob document via DTO KnContactImportJobRequest
         * @param {KnContactImportJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchContactImportJob(request: KnContactImportJobRequest, options?: any): AxiosPromise<KnDocumentResponseKnContactImportJob> {
            return localVarFp.patchContactImportJob(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Process ContactImportJob via DTO KnProcessContactImportJobRequest
         * @param {KnProcessContactImportJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processContactImportJob(request: KnProcessContactImportJobRequest, options?: any): AxiosPromise<KnProcessImportJobResponse> {
            return localVarFp.processContactImportJob(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload CSV file and create new ContactImportJob via MultipartFile
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadContactCsv(file: File, options?: any): AxiosPromise<KnCreateImportJobResponse> {
            return localVarFp.uploadContactCsv(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataImportContactApi - interface
 * @export
 * @interface DataImportContactApi
 */
export interface DataImportContactApiInterface {
    /**
     * 
     * @summary Create ContactImportJob via DTO KnCreateContactImportJobRequest
     * @param {KnCreateContactImportJobRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportContactApiInterface
     */
    createContactImportJob(request: KnCreateContactImportJobRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnCreateImportJobResponse>;

    /**
     * 
     * @summary Soft-Delete an existing ContactImportJob document by ContactImportJob\'s Id (\'id\') via Path Variable
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportContactApiInterface
     */
    deleteContactImportJob(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Get a Team\'s ContactImportJob document by Id (\'id\') via Path Variable
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportContactApiInterface
     */
    getContactImportJobById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<KnDocumentResponseKnContactImportJob>;

    /**
     * 
     * @summary Get All Team ContactImportJob documents by TeamId via SecurityContext
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportContactApiInterface
     */
    getContactImportJobs(options?: RawAxiosRequestConfig): AxiosPromise<KnDocumentListResponseKnContactImportJob>;

    /**
     * 
     * @summary Keyword search Team\'s ContactImportJob documents via paginated and search-termed DTO KnKeywordSearchRequest
     * @param {KnKeywordSearchRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportContactApiInterface
     */
    keywordSearchContactImportJobs(request: KnKeywordSearchRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnKeywordSearchResponseKnContactImportJob>;

    /**
     * 
     * @summary Lookup Team\'s ContactImportJob document via paginated and filtered DTO KnLookupRequest
     * @param {KnLookupRequest2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportContactApiInterface
     */
    lookupContactImportJobs(request: KnLookupRequest2, options?: RawAxiosRequestConfig): AxiosPromise<KnLookupResponseKnContactImportJob>;

    /**
     * 
     * @summary Return Team\'s ContactImportJob documents via paginated DTO KnPageRequest
     * @param {KnPageRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportContactApiInterface
     */
    pageContactImportJobs(request: KnPageRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnPageResponseKnContactImportJob>;

    /**
     * 
     * @summary Patch update an existing ContactImportJob document via DTO KnContactImportJobRequest
     * @param {KnContactImportJobRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportContactApiInterface
     */
    patchContactImportJob(request: KnContactImportJobRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnDocumentResponseKnContactImportJob>;

    /**
     * 
     * @summary Process ContactImportJob via DTO KnProcessContactImportJobRequest
     * @param {KnProcessContactImportJobRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportContactApiInterface
     */
    processContactImportJob(request: KnProcessContactImportJobRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnProcessImportJobResponse>;

    /**
     * 
     * @summary Upload CSV file and create new ContactImportJob via MultipartFile
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportContactApiInterface
     */
    uploadContactCsv(file: File, options?: RawAxiosRequestConfig): AxiosPromise<KnCreateImportJobResponse>;

}

/**
 * DataImportContactApi - object-oriented interface
 * @export
 * @class DataImportContactApi
 * @extends {BaseAPI}
 */
export class DataImportContactApi extends BaseAPI implements DataImportContactApiInterface {
    /**
     * 
     * @summary Create ContactImportJob via DTO KnCreateContactImportJobRequest
     * @param {KnCreateContactImportJobRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportContactApi
     */
    public createContactImportJob(request: KnCreateContactImportJobRequest, options?: RawAxiosRequestConfig) {
        return DataImportContactApiFp(this.configuration).createContactImportJob(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Soft-Delete an existing ContactImportJob document by ContactImportJob\'s Id (\'id\') via Path Variable
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportContactApi
     */
    public deleteContactImportJob(id: string, options?: RawAxiosRequestConfig) {
        return DataImportContactApiFp(this.configuration).deleteContactImportJob(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a Team\'s ContactImportJob document by Id (\'id\') via Path Variable
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportContactApi
     */
    public getContactImportJobById(id: string, options?: RawAxiosRequestConfig) {
        return DataImportContactApiFp(this.configuration).getContactImportJobById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Team ContactImportJob documents by TeamId via SecurityContext
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportContactApi
     */
    public getContactImportJobs(options?: RawAxiosRequestConfig) {
        return DataImportContactApiFp(this.configuration).getContactImportJobs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Keyword search Team\'s ContactImportJob documents via paginated and search-termed DTO KnKeywordSearchRequest
     * @param {KnKeywordSearchRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportContactApi
     */
    public keywordSearchContactImportJobs(request: KnKeywordSearchRequest, options?: RawAxiosRequestConfig) {
        return DataImportContactApiFp(this.configuration).keywordSearchContactImportJobs(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lookup Team\'s ContactImportJob document via paginated and filtered DTO KnLookupRequest
     * @param {KnLookupRequest2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportContactApi
     */
    public lookupContactImportJobs(request: KnLookupRequest2, options?: RawAxiosRequestConfig) {
        return DataImportContactApiFp(this.configuration).lookupContactImportJobs(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return Team\'s ContactImportJob documents via paginated DTO KnPageRequest
     * @param {KnPageRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportContactApi
     */
    public pageContactImportJobs(request: KnPageRequest, options?: RawAxiosRequestConfig) {
        return DataImportContactApiFp(this.configuration).pageContactImportJobs(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch update an existing ContactImportJob document via DTO KnContactImportJobRequest
     * @param {KnContactImportJobRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportContactApi
     */
    public patchContactImportJob(request: KnContactImportJobRequest, options?: RawAxiosRequestConfig) {
        return DataImportContactApiFp(this.configuration).patchContactImportJob(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Process ContactImportJob via DTO KnProcessContactImportJobRequest
     * @param {KnProcessContactImportJobRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportContactApi
     */
    public processContactImportJob(request: KnProcessContactImportJobRequest, options?: RawAxiosRequestConfig) {
        return DataImportContactApiFp(this.configuration).processContactImportJob(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload CSV file and create new ContactImportJob via MultipartFile
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportContactApi
     */
    public uploadContactCsv(file: File, options?: RawAxiosRequestConfig) {
        return DataImportContactApiFp(this.configuration).uploadContactCsv(file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DataImportProductApi - axios parameter creator
 * @export
 */
export const DataImportProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create ProductImportJob via DTO KnCreateProductImportJobRequest
         * @param {KnCreateProductImportJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductImportJob: async (request: KnCreateProductImportJobRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('createProductImportJob', 'request', request)
            const localVarPath = `/data-import/product/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Soft-Delete an existing ProductImportJob document by ProductImportJob\'s Id (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductImportJob: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProductImportJob', 'id', id)
            const localVarPath = `/data-import/product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a Team\'s ProductImportJob document by CompanyId (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductImportJobById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProductImportJobById', 'id', id)
            const localVarPath = `/data-import/product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Team ProductImportJob documents by TeamId via SecurityContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductImportJobs: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/data-import/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Keyword search Team\'s ProductImportJob documents via paginated and search-termed DTO KnKeywordSearchRequest
         * @param {KnKeywordSearchRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keywordSearchProductImportJobs: async (request: KnKeywordSearchRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('keywordSearchProductImportJobs', 'request', request)
            const localVarPath = `/data-import/product/keyword/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lookup Team\'s ProductImportJob document via paginated and filtered DTO KnLookupRequest
         * @param {KnLookupRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupProductImportJobs: async (request: KnLookupRequest2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('lookupProductImportJobs', 'request', request)
            const localVarPath = `/data-import/product/lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return Team\'s ProductImportJob documents via paginated DTO KnPageRequest
         * @param {KnPageRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageProductImportJobs: async (request: KnPageRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('pageProductImportJobs', 'request', request)
            const localVarPath = `/data-import/product/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch update an existing ProductImportJob document via DTO KnProductImportJobRequest
         * @param {KnProductImportJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProductImportJob: async (request: KnProductImportJobRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('patchProductImportJob', 'request', request)
            const localVarPath = `/data-import/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Process ProductImportJob via DTO KnProcessProductImportJobRequest
         * @param {KnProcessProductImportJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processProductImportJob: async (request: KnProcessProductImportJobRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('processProductImportJob', 'request', request)
            const localVarPath = `/data-import/product/process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload CSV file and create new ProductImportJob via MultipartFile
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProductCsv: async (file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadProductCsv', 'file', file)
            const localVarPath = `/data-import/product/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataImportProductApi - functional programming interface
 * @export
 */
export const DataImportProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataImportProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create ProductImportJob via DTO KnCreateProductImportJobRequest
         * @param {KnCreateProductImportJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProductImportJob(request: KnCreateProductImportJobRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnCreateImportJobResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProductImportJob(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportProductApi.createProductImportJob']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Soft-Delete an existing ProductImportJob document by ProductImportJob\'s Id (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProductImportJob(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProductImportJob(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportProductApi.deleteProductImportJob']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get a Team\'s ProductImportJob document by CompanyId (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductImportJobById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDocumentResponseKnProductImportJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductImportJobById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportProductApi.getProductImportJobById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Team ProductImportJob documents by TeamId via SecurityContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductImportJobs(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDocumentListResponseKnProductImportJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductImportJobs(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportProductApi.getProductImportJobs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Keyword search Team\'s ProductImportJob documents via paginated and search-termed DTO KnKeywordSearchRequest
         * @param {KnKeywordSearchRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async keywordSearchProductImportJobs(request: KnKeywordSearchRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnKeywordSearchResponseKnProductImportJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.keywordSearchProductImportJobs(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportProductApi.keywordSearchProductImportJobs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Lookup Team\'s ProductImportJob document via paginated and filtered DTO KnLookupRequest
         * @param {KnLookupRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lookupProductImportJobs(request: KnLookupRequest2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnLookupResponseKnProductImportJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lookupProductImportJobs(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportProductApi.lookupProductImportJobs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Return Team\'s ProductImportJob documents via paginated DTO KnPageRequest
         * @param {KnPageRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageProductImportJobs(request: KnPageRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnPageResponseKnProductImportJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageProductImportJobs(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportProductApi.pageProductImportJobs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Patch update an existing ProductImportJob document via DTO KnProductImportJobRequest
         * @param {KnProductImportJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchProductImportJob(request: KnProductImportJobRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDocumentResponseKnProductImportJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchProductImportJob(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportProductApi.patchProductImportJob']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Process ProductImportJob via DTO KnProcessProductImportJobRequest
         * @param {KnProcessProductImportJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processProductImportJob(request: KnProcessProductImportJobRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnProcessImportJobResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processProductImportJob(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportProductApi.processProductImportJob']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Upload CSV file and create new ProductImportJob via MultipartFile
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadProductCsv(file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnCreateImportJobResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadProductCsv(file, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataImportProductApi.uploadProductCsv']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DataImportProductApi - factory interface
 * @export
 */
export const DataImportProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataImportProductApiFp(configuration)
    return {
        /**
         * 
         * @summary Create ProductImportJob via DTO KnCreateProductImportJobRequest
         * @param {KnCreateProductImportJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductImportJob(request: KnCreateProductImportJobRequest, options?: any): AxiosPromise<KnCreateImportJobResponse> {
            return localVarFp.createProductImportJob(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Soft-Delete an existing ProductImportJob document by ProductImportJob\'s Id (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductImportJob(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProductImportJob(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a Team\'s ProductImportJob document by CompanyId (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductImportJobById(id: string, options?: any): AxiosPromise<KnDocumentResponseKnProductImportJob> {
            return localVarFp.getProductImportJobById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Team ProductImportJob documents by TeamId via SecurityContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductImportJobs(options?: any): AxiosPromise<KnDocumentListResponseKnProductImportJob> {
            return localVarFp.getProductImportJobs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Keyword search Team\'s ProductImportJob documents via paginated and search-termed DTO KnKeywordSearchRequest
         * @param {KnKeywordSearchRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keywordSearchProductImportJobs(request: KnKeywordSearchRequest, options?: any): AxiosPromise<KnKeywordSearchResponseKnProductImportJob> {
            return localVarFp.keywordSearchProductImportJobs(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lookup Team\'s ProductImportJob document via paginated and filtered DTO KnLookupRequest
         * @param {KnLookupRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupProductImportJobs(request: KnLookupRequest2, options?: any): AxiosPromise<KnLookupResponseKnProductImportJob> {
            return localVarFp.lookupProductImportJobs(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return Team\'s ProductImportJob documents via paginated DTO KnPageRequest
         * @param {KnPageRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageProductImportJobs(request: KnPageRequest, options?: any): AxiosPromise<KnPageResponseKnProductImportJob> {
            return localVarFp.pageProductImportJobs(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch update an existing ProductImportJob document via DTO KnProductImportJobRequest
         * @param {KnProductImportJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProductImportJob(request: KnProductImportJobRequest, options?: any): AxiosPromise<KnDocumentResponseKnProductImportJob> {
            return localVarFp.patchProductImportJob(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Process ProductImportJob via DTO KnProcessProductImportJobRequest
         * @param {KnProcessProductImportJobRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processProductImportJob(request: KnProcessProductImportJobRequest, options?: any): AxiosPromise<KnProcessImportJobResponse> {
            return localVarFp.processProductImportJob(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload CSV file and create new ProductImportJob via MultipartFile
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProductCsv(file: File, options?: any): AxiosPromise<KnCreateImportJobResponse> {
            return localVarFp.uploadProductCsv(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataImportProductApi - interface
 * @export
 * @interface DataImportProductApi
 */
export interface DataImportProductApiInterface {
    /**
     * 
     * @summary Create ProductImportJob via DTO KnCreateProductImportJobRequest
     * @param {KnCreateProductImportJobRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportProductApiInterface
     */
    createProductImportJob(request: KnCreateProductImportJobRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnCreateImportJobResponse>;

    /**
     * 
     * @summary Soft-Delete an existing ProductImportJob document by ProductImportJob\'s Id (\'id\') via Path Variable
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportProductApiInterface
     */
    deleteProductImportJob(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Get a Team\'s ProductImportJob document by CompanyId (\'id\') via Path Variable
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportProductApiInterface
     */
    getProductImportJobById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<KnDocumentResponseKnProductImportJob>;

    /**
     * 
     * @summary Get All Team ProductImportJob documents by TeamId via SecurityContext
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportProductApiInterface
     */
    getProductImportJobs(options?: RawAxiosRequestConfig): AxiosPromise<KnDocumentListResponseKnProductImportJob>;

    /**
     * 
     * @summary Keyword search Team\'s ProductImportJob documents via paginated and search-termed DTO KnKeywordSearchRequest
     * @param {KnKeywordSearchRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportProductApiInterface
     */
    keywordSearchProductImportJobs(request: KnKeywordSearchRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnKeywordSearchResponseKnProductImportJob>;

    /**
     * 
     * @summary Lookup Team\'s ProductImportJob document via paginated and filtered DTO KnLookupRequest
     * @param {KnLookupRequest2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportProductApiInterface
     */
    lookupProductImportJobs(request: KnLookupRequest2, options?: RawAxiosRequestConfig): AxiosPromise<KnLookupResponseKnProductImportJob>;

    /**
     * 
     * @summary Return Team\'s ProductImportJob documents via paginated DTO KnPageRequest
     * @param {KnPageRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportProductApiInterface
     */
    pageProductImportJobs(request: KnPageRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnPageResponseKnProductImportJob>;

    /**
     * 
     * @summary Patch update an existing ProductImportJob document via DTO KnProductImportJobRequest
     * @param {KnProductImportJobRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportProductApiInterface
     */
    patchProductImportJob(request: KnProductImportJobRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnDocumentResponseKnProductImportJob>;

    /**
     * 
     * @summary Process ProductImportJob via DTO KnProcessProductImportJobRequest
     * @param {KnProcessProductImportJobRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportProductApiInterface
     */
    processProductImportJob(request: KnProcessProductImportJobRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnProcessImportJobResponse>;

    /**
     * 
     * @summary Upload CSV file and create new ProductImportJob via MultipartFile
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportProductApiInterface
     */
    uploadProductCsv(file: File, options?: RawAxiosRequestConfig): AxiosPromise<KnCreateImportJobResponse>;

}

/**
 * DataImportProductApi - object-oriented interface
 * @export
 * @class DataImportProductApi
 * @extends {BaseAPI}
 */
export class DataImportProductApi extends BaseAPI implements DataImportProductApiInterface {
    /**
     * 
     * @summary Create ProductImportJob via DTO KnCreateProductImportJobRequest
     * @param {KnCreateProductImportJobRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportProductApi
     */
    public createProductImportJob(request: KnCreateProductImportJobRequest, options?: RawAxiosRequestConfig) {
        return DataImportProductApiFp(this.configuration).createProductImportJob(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Soft-Delete an existing ProductImportJob document by ProductImportJob\'s Id (\'id\') via Path Variable
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportProductApi
     */
    public deleteProductImportJob(id: string, options?: RawAxiosRequestConfig) {
        return DataImportProductApiFp(this.configuration).deleteProductImportJob(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a Team\'s ProductImportJob document by CompanyId (\'id\') via Path Variable
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportProductApi
     */
    public getProductImportJobById(id: string, options?: RawAxiosRequestConfig) {
        return DataImportProductApiFp(this.configuration).getProductImportJobById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Team ProductImportJob documents by TeamId via SecurityContext
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportProductApi
     */
    public getProductImportJobs(options?: RawAxiosRequestConfig) {
        return DataImportProductApiFp(this.configuration).getProductImportJobs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Keyword search Team\'s ProductImportJob documents via paginated and search-termed DTO KnKeywordSearchRequest
     * @param {KnKeywordSearchRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportProductApi
     */
    public keywordSearchProductImportJobs(request: KnKeywordSearchRequest, options?: RawAxiosRequestConfig) {
        return DataImportProductApiFp(this.configuration).keywordSearchProductImportJobs(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lookup Team\'s ProductImportJob document via paginated and filtered DTO KnLookupRequest
     * @param {KnLookupRequest2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportProductApi
     */
    public lookupProductImportJobs(request: KnLookupRequest2, options?: RawAxiosRequestConfig) {
        return DataImportProductApiFp(this.configuration).lookupProductImportJobs(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return Team\'s ProductImportJob documents via paginated DTO KnPageRequest
     * @param {KnPageRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportProductApi
     */
    public pageProductImportJobs(request: KnPageRequest, options?: RawAxiosRequestConfig) {
        return DataImportProductApiFp(this.configuration).pageProductImportJobs(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch update an existing ProductImportJob document via DTO KnProductImportJobRequest
     * @param {KnProductImportJobRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportProductApi
     */
    public patchProductImportJob(request: KnProductImportJobRequest, options?: RawAxiosRequestConfig) {
        return DataImportProductApiFp(this.configuration).patchProductImportJob(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Process ProductImportJob via DTO KnProcessProductImportJobRequest
     * @param {KnProcessProductImportJobRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportProductApi
     */
    public processProductImportJob(request: KnProcessProductImportJobRequest, options?: RawAxiosRequestConfig) {
        return DataImportProductApiFp(this.configuration).processProductImportJob(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload CSV file and create new ProductImportJob via MultipartFile
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportProductApi
     */
    public uploadProductCsv(file: File, options?: RawAxiosRequestConfig) {
        return DataImportProductApiFp(this.configuration).uploadProductCsv(file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DealsApi - axios parameter creator
 * @export
 */
export const DealsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a new deal To add line-item you can send the productId, to be used as model or name and price. If both are sent product information will be override by name and price. 
         * @param {DealRequestCreate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeal: async (body: DealRequestCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createDeal', 'body', body)
            const localVarPath = `/deal-flow/deals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get deal by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeal: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDeal', 'id', id)
            const localVarPath = `/deal-flow/deals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get deals available for current user filtered accordingly to LookupDealRequest
         * @param {LookupDealRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDealsList: async (body: LookupDealRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getDealsList', 'body', body)
            const localVarPath = `/deal-flow/deals/lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete deal by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        softDeleteDeal: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('softDeleteDeal', 'id', id)
            const localVarPath = `/deal-flow/deals/soft-delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a deal. If you want to remove a line-item, just don\'t include it in the update, or send quantity=0. To add new line-item you can send the productId, to be used as model or name and price. If both are sent product information will be override by name and price. 
         * @param {DealRequestUpdate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeal: async (body: DealRequestUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateDeal', 'body', body)
            const localVarPath = `/deal-flow/deals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a deal\'s stage
         * @param {string} id 
         * @param {string} stageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDealStage: async (id: string, stageId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDealStage', 'id', id)
            // verify required parameter 'stageId' is not null or undefined
            assertParamExists('updateDealStage', 'stageId', stageId)
            const localVarPath = `/deal-flow/deals/update-stage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)

            if (stageId !== undefined) {
                localVarQueryParameter['stageId'] = stageId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DealsApi - functional programming interface
 * @export
 */
export const DealsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DealsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a new deal To add line-item you can send the productId, to be used as model or name and price. If both are sent product information will be override by name and price. 
         * @param {DealRequestCreate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDeal(body: DealRequestCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDeal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDeal(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DealsApi.createDeal']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get deal by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeal(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDeal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeal(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DealsApi.getDeal']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get deals available for current user filtered accordingly to LookupDealRequest
         * @param {LookupDealRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDealsList(body: LookupDealRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KnDeal>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDealsList(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DealsApi.getDealsList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Delete deal by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async softDeleteDeal(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.softDeleteDeal(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DealsApi.softDeleteDeal']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Updates a deal. If you want to remove a line-item, just don\'t include it in the update, or send quantity=0. To add new line-item you can send the productId, to be used as model or name and price. If both are sent product information will be override by name and price. 
         * @param {DealRequestUpdate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDeal(body: DealRequestUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDeal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeal(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DealsApi.updateDeal']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Updates a deal\'s stage
         * @param {string} id 
         * @param {string} stageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDealStage(id: string, stageId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDealStage(id, stageId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DealsApi.updateDealStage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DealsApi - factory interface
 * @export
 */
export const DealsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DealsApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a new deal To add line-item you can send the productId, to be used as model or name and price. If both are sent product information will be override by name and price. 
         * @param {DealRequestCreate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeal(body: DealRequestCreate, options?: any): AxiosPromise<KnDeal> {
            return localVarFp.createDeal(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get deal by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeal(id: string, options?: any): AxiosPromise<KnDeal> {
            return localVarFp.getDeal(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get deals available for current user filtered accordingly to LookupDealRequest
         * @param {LookupDealRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDealsList(body: LookupDealRequest, options?: any): AxiosPromise<Array<KnDeal>> {
            return localVarFp.getDealsList(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete deal by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        softDeleteDeal(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.softDeleteDeal(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a deal. If you want to remove a line-item, just don\'t include it in the update, or send quantity=0. To add new line-item you can send the productId, to be used as model or name and price. If both are sent product information will be override by name and price. 
         * @param {DealRequestUpdate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeal(body: DealRequestUpdate, options?: any): AxiosPromise<KnDeal> {
            return localVarFp.updateDeal(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a deal\'s stage
         * @param {string} id 
         * @param {string} stageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDealStage(id: string, stageId: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateDealStage(id, stageId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DealsApi - interface
 * @export
 * @interface DealsApi
 */
export interface DealsApiInterface {
    /**
     * 
     * @summary Creates a new deal To add line-item you can send the productId, to be used as model or name and price. If both are sent product information will be override by name and price. 
     * @param {DealRequestCreate} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealsApiInterface
     */
    createDeal(body: DealRequestCreate, options?: RawAxiosRequestConfig): AxiosPromise<KnDeal>;

    /**
     * 
     * @summary Get deal by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealsApiInterface
     */
    getDeal(id: string, options?: RawAxiosRequestConfig): AxiosPromise<KnDeal>;

    /**
     * 
     * @summary Get deals available for current user filtered accordingly to LookupDealRequest
     * @param {LookupDealRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealsApiInterface
     */
    getDealsList(body: LookupDealRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<KnDeal>>;

    /**
     * 
     * @summary Delete deal by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealsApiInterface
     */
    softDeleteDeal(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates a deal. If you want to remove a line-item, just don\'t include it in the update, or send quantity=0. To add new line-item you can send the productId, to be used as model or name and price. If both are sent product information will be override by name and price. 
     * @param {DealRequestUpdate} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealsApiInterface
     */
    updateDeal(body: DealRequestUpdate, options?: RawAxiosRequestConfig): AxiosPromise<KnDeal>;

    /**
     * 
     * @summary Updates a deal\'s stage
     * @param {string} id 
     * @param {string} stageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealsApiInterface
     */
    updateDealStage(id: string, stageId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * DealsApi - object-oriented interface
 * @export
 * @class DealsApi
 * @extends {BaseAPI}
 */
export class DealsApi extends BaseAPI implements DealsApiInterface {
    /**
     * 
     * @summary Creates a new deal To add line-item you can send the productId, to be used as model or name and price. If both are sent product information will be override by name and price. 
     * @param {DealRequestCreate} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealsApi
     */
    public createDeal(body: DealRequestCreate, options?: RawAxiosRequestConfig) {
        return DealsApiFp(this.configuration).createDeal(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get deal by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealsApi
     */
    public getDeal(id: string, options?: RawAxiosRequestConfig) {
        return DealsApiFp(this.configuration).getDeal(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get deals available for current user filtered accordingly to LookupDealRequest
     * @param {LookupDealRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealsApi
     */
    public getDealsList(body: LookupDealRequest, options?: RawAxiosRequestConfig) {
        return DealsApiFp(this.configuration).getDealsList(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete deal by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealsApi
     */
    public softDeleteDeal(id: string, options?: RawAxiosRequestConfig) {
        return DealsApiFp(this.configuration).softDeleteDeal(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a deal. If you want to remove a line-item, just don\'t include it in the update, or send quantity=0. To add new line-item you can send the productId, to be used as model or name and price. If both are sent product information will be override by name and price. 
     * @param {DealRequestUpdate} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealsApi
     */
    public updateDeal(body: DealRequestUpdate, options?: RawAxiosRequestConfig) {
        return DealsApiFp(this.configuration).updateDeal(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a deal\'s stage
     * @param {string} id 
     * @param {string} stageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealsApi
     */
    public updateDealStage(id: string, stageId: string, options?: RawAxiosRequestConfig) {
        return DealsApiFp(this.configuration).updateDealStage(id, stageId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DripTemplateControllerApi - axios parameter creator
 * @export
 */
export const DripTemplateControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a DRIP associated to a STEP template
         * @param {DripTemplateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDripTemplate: async (body: DripTemplateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createDripTemplate', 'body', body)
            const localVarPath = `/sequence-drip-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a DRIP template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDripTemplate: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDripTemplate', 'id', id)
            const localVarPath = `/sequence-drip-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all DRIPs associated to the  template
         * @param {LookupDripStepRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDrips: async (body: LookupDripStepRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getAllDrips', 'body', body)
            const localVarPath = `/sequence-drip-templates/drips-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a DRIP associated to a STEP template
         * @param {DripTemplateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDripTemplate: async (body: DripTemplateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateDripTemplate', 'body', body)
            const localVarPath = `/sequence-drip-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DripTemplateControllerApi - functional programming interface
 * @export
 */
export const DripTemplateControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DripTemplateControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a DRIP associated to a STEP template
         * @param {DripTemplateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDripTemplate(body: DripTemplateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DripTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDripTemplate(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DripTemplateControllerApi.createDripTemplate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes a DRIP template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDripTemplate(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDripTemplate(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DripTemplateControllerApi.deleteDripTemplate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all DRIPs associated to the  template
         * @param {LookupDripStepRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDrips(body: LookupDripStepRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnPageResponseDripStepResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDrips(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DripTemplateControllerApi.getAllDrips']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update a DRIP associated to a STEP template
         * @param {DripTemplateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDripTemplate(body: DripTemplateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DripTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDripTemplate(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DripTemplateControllerApi.updateDripTemplate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DripTemplateControllerApi - factory interface
 * @export
 */
export const DripTemplateControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DripTemplateControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a DRIP associated to a STEP template
         * @param {DripTemplateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDripTemplate(body: DripTemplateRequest, options?: any): AxiosPromise<DripTemplateResponse> {
            return localVarFp.createDripTemplate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a DRIP template
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDripTemplate(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDripTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all DRIPs associated to the  template
         * @param {LookupDripStepRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDrips(body: LookupDripStepRequest, options?: any): AxiosPromise<KnPageResponseDripStepResponse> {
            return localVarFp.getAllDrips(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a DRIP associated to a STEP template
         * @param {DripTemplateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDripTemplate(body: DripTemplateRequest, options?: any): AxiosPromise<DripTemplateResponse> {
            return localVarFp.updateDripTemplate(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DripTemplateControllerApi - interface
 * @export
 * @interface DripTemplateControllerApi
 */
export interface DripTemplateControllerApiInterface {
    /**
     * 
     * @summary Create a DRIP associated to a STEP template
     * @param {DripTemplateRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DripTemplateControllerApiInterface
     */
    createDripTemplate(body: DripTemplateRequest, options?: RawAxiosRequestConfig): AxiosPromise<DripTemplateResponse>;

    /**
     * 
     * @summary Deletes a DRIP template
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DripTemplateControllerApiInterface
     */
    deleteDripTemplate(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Gets all DRIPs associated to the  template
     * @param {LookupDripStepRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DripTemplateControllerApiInterface
     */
    getAllDrips(body: LookupDripStepRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnPageResponseDripStepResponse>;

    /**
     * 
     * @summary Update a DRIP associated to a STEP template
     * @param {DripTemplateRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DripTemplateControllerApiInterface
     */
    updateDripTemplate(body: DripTemplateRequest, options?: RawAxiosRequestConfig): AxiosPromise<DripTemplateResponse>;

}

/**
 * DripTemplateControllerApi - object-oriented interface
 * @export
 * @class DripTemplateControllerApi
 * @extends {BaseAPI}
 */
export class DripTemplateControllerApi extends BaseAPI implements DripTemplateControllerApiInterface {
    /**
     * 
     * @summary Create a DRIP associated to a STEP template
     * @param {DripTemplateRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DripTemplateControllerApi
     */
    public createDripTemplate(body: DripTemplateRequest, options?: RawAxiosRequestConfig) {
        return DripTemplateControllerApiFp(this.configuration).createDripTemplate(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a DRIP template
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DripTemplateControllerApi
     */
    public deleteDripTemplate(id: string, options?: RawAxiosRequestConfig) {
        return DripTemplateControllerApiFp(this.configuration).deleteDripTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all DRIPs associated to the  template
     * @param {LookupDripStepRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DripTemplateControllerApi
     */
    public getAllDrips(body: LookupDripStepRequest, options?: RawAxiosRequestConfig) {
        return DripTemplateControllerApiFp(this.configuration).getAllDrips(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a DRIP associated to a STEP template
     * @param {DripTemplateRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DripTemplateControllerApi
     */
    public updateDripTemplate(body: DripTemplateRequest, options?: RawAxiosRequestConfig) {
        return DripTemplateControllerApiFp(this.configuration).updateDripTemplate(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FacebookControllerApi - axios parameter creator
 * @export
 */
export const FacebookControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorize: async (code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('authorize', 'code', code)
            const localVarPath = `/facebook/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorizeUrl: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/facebook/authorize-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacebookUserId: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/facebook/id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeFacebookAccess: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/facebook/revoke`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FacebookControllerApi - functional programming interface
 * @export
 */
export const FacebookControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FacebookControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorize(code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorize(code, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FacebookControllerApi.authorize']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthorizeUrl(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthorizeUrl(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FacebookControllerApi.getAuthorizeUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFacebookUserId(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFacebookUserId(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FacebookControllerApi.getFacebookUserId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeFacebookAccess(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeFacebookAccess(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FacebookControllerApi.revokeFacebookAccess']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * FacebookControllerApi - factory interface
 * @export
 */
export const FacebookControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FacebookControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorize(code: string, options?: any): AxiosPromise<void> {
            return localVarFp.authorize(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorizeUrl(options?: any): AxiosPromise<string> {
            return localVarFp.getAuthorizeUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFacebookUserId(options?: any): AxiosPromise<string> {
            return localVarFp.getFacebookUserId(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeFacebookAccess(options?: any): AxiosPromise<boolean> {
            return localVarFp.revokeFacebookAccess(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FacebookControllerApi - interface
 * @export
 * @interface FacebookControllerApi
 */
export interface FacebookControllerApiInterface {
    /**
     * 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookControllerApiInterface
     */
    authorize(code: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookControllerApiInterface
     */
    getAuthorizeUrl(options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookControllerApiInterface
     */
    getFacebookUserId(options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookControllerApiInterface
     */
    revokeFacebookAccess(options?: RawAxiosRequestConfig): AxiosPromise<boolean>;

}

/**
 * FacebookControllerApi - object-oriented interface
 * @export
 * @class FacebookControllerApi
 * @extends {BaseAPI}
 */
export class FacebookControllerApi extends BaseAPI implements FacebookControllerApiInterface {
    /**
     * 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookControllerApi
     */
    public authorize(code: string, options?: RawAxiosRequestConfig) {
        return FacebookControllerApiFp(this.configuration).authorize(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookControllerApi
     */
    public getAuthorizeUrl(options?: RawAxiosRequestConfig) {
        return FacebookControllerApiFp(this.configuration).getAuthorizeUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookControllerApi
     */
    public getFacebookUserId(options?: RawAxiosRequestConfig) {
        return FacebookControllerApiFp(this.configuration).getFacebookUserId(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookControllerApi
     */
    public revokeFacebookAccess(options?: RawAxiosRequestConfig) {
        return FacebookControllerApiFp(this.configuration).revokeFacebookAccess(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FaqApi - axios parameter creator
 * @export
 */
export const FaqApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {KnFaqCrawlUrlRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crawl: async (body: KnFaqCrawlUrlRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('crawl', 'body', body)
            const localVarPath = `/faq/crawl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FaqApi - functional programming interface
 * @export
 */
export const FaqApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FaqApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {KnFaqCrawlUrlRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crawl(body: KnFaqCrawlUrlRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnFaqCrawlUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crawl(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FaqApi.crawl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * FaqApi - factory interface
 * @export
 */
export const FaqApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FaqApiFp(configuration)
    return {
        /**
         * 
         * @param {KnFaqCrawlUrlRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crawl(body: KnFaqCrawlUrlRequest, options?: any): AxiosPromise<KnFaqCrawlUrlResponse> {
            return localVarFp.crawl(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FaqApi - interface
 * @export
 * @interface FaqApi
 */
export interface FaqApiInterface {
    /**
     * 
     * @param {KnFaqCrawlUrlRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqApiInterface
     */
    crawl(body: KnFaqCrawlUrlRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnFaqCrawlUrlResponse>;

}

/**
 * FaqApi - object-oriented interface
 * @export
 * @class FaqApi
 * @extends {BaseAPI}
 */
export class FaqApi extends BaseAPI implements FaqApiInterface {
    /**
     * 
     * @param {KnFaqCrawlUrlRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqApi
     */
    public crawl(body: KnFaqCrawlUrlRequest, options?: RawAxiosRequestConfig) {
        return FaqApiFp(this.configuration).crawl(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GetSequenceContactsApi - axios parameter creator
 * @export
 */
export const GetSequenceContactsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all contacts enrolled into a sequence by sequenceTemplateId
         * @param {string} sequenceTemplateId 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequenceContacts: async (sequenceTemplateId: string, pageNumber?: number, pageSize?: number, name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sequenceTemplateId' is not null or undefined
            assertParamExists('getSequenceContacts', 'sequenceTemplateId', sequenceTemplateId)
            const localVarPath = `/sequence-templates/{sequenceTemplateId}/contacts`
                .replace(`{${"sequenceTemplateId"}}`, encodeURIComponent(String(sequenceTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetSequenceContactsApi - functional programming interface
 * @export
 */
export const GetSequenceContactsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetSequenceContactsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all contacts enrolled into a sequence by sequenceTemplateId
         * @param {string} sequenceTemplateId 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSequenceContacts(sequenceTemplateId: string, pageNumber?: number, pageSize?: number, name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnLookupResponse2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSequenceContacts(sequenceTemplateId, pageNumber, pageSize, name, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GetSequenceContactsApi.getSequenceContacts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * GetSequenceContactsApi - factory interface
 * @export
 */
export const GetSequenceContactsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetSequenceContactsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all contacts enrolled into a sequence by sequenceTemplateId
         * @param {string} sequenceTemplateId 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequenceContacts(sequenceTemplateId: string, pageNumber?: number, pageSize?: number, name?: string, options?: any): AxiosPromise<KnLookupResponse2> {
            return localVarFp.getSequenceContacts(sequenceTemplateId, pageNumber, pageSize, name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetSequenceContactsApi - interface
 * @export
 * @interface GetSequenceContactsApi
 */
export interface GetSequenceContactsApiInterface {
    /**
     * 
     * @summary Get all contacts enrolled into a sequence by sequenceTemplateId
     * @param {string} sequenceTemplateId 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetSequenceContactsApiInterface
     */
    getSequenceContacts(sequenceTemplateId: string, pageNumber?: number, pageSize?: number, name?: string, options?: RawAxiosRequestConfig): AxiosPromise<KnLookupResponse2>;

}

/**
 * GetSequenceContactsApi - object-oriented interface
 * @export
 * @class GetSequenceContactsApi
 * @extends {BaseAPI}
 */
export class GetSequenceContactsApi extends BaseAPI implements GetSequenceContactsApiInterface {
    /**
     * 
     * @summary Get all contacts enrolled into a sequence by sequenceTemplateId
     * @param {string} sequenceTemplateId 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetSequenceContactsApi
     */
    public getSequenceContacts(sequenceTemplateId: string, pageNumber?: number, pageSize?: number, name?: string, options?: RawAxiosRequestConfig) {
        return GetSequenceContactsApiFp(this.configuration).getSequenceContacts(sequenceTemplateId, pageNumber, pageSize, name, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GetTeamSummaryApi - axios parameter creator
 * @export
 */
export const GetTeamSummaryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Summary count of sequences by teamId
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamSummary: async (teamId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('getTeamSummary', 'teamId', teamId)
            const localVarPath = `/sequences/team/{teamId}/summary`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetTeamSummaryApi - functional programming interface
 * @export
 */
export const GetTeamSummaryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetTeamSummaryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Summary count of sequences by teamId
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamSummary(teamId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnTeamSummary2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamSummary(teamId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GetTeamSummaryApi.getTeamSummary']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * GetTeamSummaryApi - factory interface
 * @export
 */
export const GetTeamSummaryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetTeamSummaryApiFp(configuration)
    return {
        /**
         * 
         * @summary Summary count of sequences by teamId
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamSummary(teamId: string, options?: any): AxiosPromise<KnTeamSummary2> {
            return localVarFp.getTeamSummary(teamId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetTeamSummaryApi - interface
 * @export
 * @interface GetTeamSummaryApi
 */
export interface GetTeamSummaryApiInterface {
    /**
     * 
     * @summary Summary count of sequences by teamId
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetTeamSummaryApiInterface
     */
    getTeamSummary(teamId: string, options?: RawAxiosRequestConfig): AxiosPromise<KnTeamSummary2>;

}

/**
 * GetTeamSummaryApi - object-oriented interface
 * @export
 * @class GetTeamSummaryApi
 * @extends {BaseAPI}
 */
export class GetTeamSummaryApi extends BaseAPI implements GetTeamSummaryApiInterface {
    /**
     * 
     * @summary Summary count of sequences by teamId
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetTeamSummaryApi
     */
    public getTeamSummary(teamId: string, options?: RawAxiosRequestConfig) {
        return GetTeamSummaryApiFp(this.configuration).getTeamSummary(teamId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InviteApi - axios parameter creator
 * @export
 */
export const InviteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create team invite
         * @param {TeamInviteRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (body: TeamInviteRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('create', 'body', body)
            const localVarPath = `/team/invites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Join a team by code
         * @param {TeamJoinRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        join: async (body: TeamJoinRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('join', 'body', body)
            const localVarPath = `/team/invites/join`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List team invites for the current team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team/invites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send all invite drafts for the current team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAllDrafts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team/invites/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a new invite for a current team
         * @param {TeamInviteRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNow: async (body: TeamInviteRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('sendNow', 'body', body)
            const localVarPath = `/team/invites/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InviteApi - functional programming interface
 * @export
 */
export const InviteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InviteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create team invite
         * @param {TeamInviteRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(body: TeamInviteRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamInvite>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InviteApi.create']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Join a team by code
         * @param {TeamJoinRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async join(body: TeamJoinRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.join(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InviteApi.join']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary List team invites for the current team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TeamInvite>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InviteApi.list']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send all invite drafts for the current team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendAllDrafts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendAllDrafts(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InviteApi.sendAllDrafts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send a new invite for a current team
         * @param {TeamInviteRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNow(body: TeamInviteRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendNow(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InviteApi.sendNow']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * InviteApi - factory interface
 * @export
 */
export const InviteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InviteApiFp(configuration)
    return {
        /**
         * 
         * @summary Create team invite
         * @param {TeamInviteRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(body: TeamInviteRequest, options?: any): AxiosPromise<TeamInvite> {
            return localVarFp.create(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Join a team by code
         * @param {TeamJoinRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        join(body: TeamJoinRequest, options?: any): AxiosPromise<void> {
            return localVarFp.join(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List team invites for the current team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(options?: any): AxiosPromise<Array<TeamInvite>> {
            return localVarFp.list(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send all invite drafts for the current team
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAllDrafts(options?: any): AxiosPromise<void> {
            return localVarFp.sendAllDrafts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a new invite for a current team
         * @param {TeamInviteRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNow(body: TeamInviteRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendNow(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InviteApi - interface
 * @export
 * @interface InviteApi
 */
export interface InviteApiInterface {
    /**
     * 
     * @summary Create team invite
     * @param {TeamInviteRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InviteApiInterface
     */
    create(body: TeamInviteRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeamInvite>;

    /**
     * 
     * @summary Join a team by code
     * @param {TeamJoinRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InviteApiInterface
     */
    join(body: TeamJoinRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary List team invites for the current team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InviteApiInterface
     */
    list(options?: RawAxiosRequestConfig): AxiosPromise<Array<TeamInvite>>;

    /**
     * 
     * @summary Send all invite drafts for the current team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InviteApiInterface
     */
    sendAllDrafts(options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Send a new invite for a current team
     * @param {TeamInviteRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InviteApiInterface
     */
    sendNow(body: TeamInviteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * InviteApi - object-oriented interface
 * @export
 * @class InviteApi
 * @extends {BaseAPI}
 */
export class InviteApi extends BaseAPI implements InviteApiInterface {
    /**
     * 
     * @summary Create team invite
     * @param {TeamInviteRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InviteApi
     */
    public create(body: TeamInviteRequest, options?: RawAxiosRequestConfig) {
        return InviteApiFp(this.configuration).create(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Join a team by code
     * @param {TeamJoinRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InviteApi
     */
    public join(body: TeamJoinRequest, options?: RawAxiosRequestConfig) {
        return InviteApiFp(this.configuration).join(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List team invites for the current team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InviteApi
     */
    public list(options?: RawAxiosRequestConfig) {
        return InviteApiFp(this.configuration).list(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send all invite drafts for the current team
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InviteApi
     */
    public sendAllDrafts(options?: RawAxiosRequestConfig) {
        return InviteApiFp(this.configuration).sendAllDrafts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a new invite for a current team
     * @param {TeamInviteRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InviteApi
     */
    public sendNow(body: TeamInviteRequest, options?: RawAxiosRequestConfig) {
        return InviteApiFp(this.configuration).sendNow(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LeadFlowApi - axios parameter creator
 * @export
 */
export const LeadFlowApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LeadFlowEnroll} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrEnroll: async (body: LeadFlowEnroll, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getOrEnroll', 'body', body)
            const localVarPath = `/lead-flow/leads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} state 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadFlowLeadList: async (state: string, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'state' is not null or undefined
            assertParamExists('leadFlowLeadList', 'state', state)
            const localVarPath = `/lead-flow/leads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TeamConfigRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadFlowTeamConfigCreateOrUpdate: async (body: TeamConfigRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('leadFlowTeamConfigCreateOrUpdate', 'body', body)
            const localVarPath = `/lead-flow/team-config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadFlowTeamConfigGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lead-flow/team-config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportLeadFlow: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lead-flow/reports/qualifying`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeadFlowApi - functional programming interface
 * @export
 */
export const LeadFlowApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LeadFlowApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {LeadFlowEnroll} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrEnroll(body: LeadFlowEnroll, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnLead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrEnroll(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LeadFlowApi.getOrEnroll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} state 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadFlowLeadList(state: string, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnLookupResponseKnLead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadFlowLeadList(state, page, size, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LeadFlowApi.leadFlowLeadList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {TeamConfigRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadFlowTeamConfigCreateOrUpdate(body: TeamConfigRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadFlowTeamConfigCreateOrUpdate(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LeadFlowApi.leadFlowTeamConfigCreateOrUpdate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadFlowTeamConfigGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadFlowTeamConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadFlowTeamConfigGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LeadFlowApi.leadFlowTeamConfigGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportLeadFlow(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportLeadFlowQual>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportLeadFlow(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LeadFlowApi.reportLeadFlow']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * LeadFlowApi - factory interface
 * @export
 */
export const LeadFlowApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LeadFlowApiFp(configuration)
    return {
        /**
         * 
         * @param {LeadFlowEnroll} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrEnroll(body: LeadFlowEnroll, options?: any): AxiosPromise<KnLead> {
            return localVarFp.getOrEnroll(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} state 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadFlowLeadList(state: string, page?: number, size?: number, options?: any): AxiosPromise<KnLookupResponseKnLead> {
            return localVarFp.leadFlowLeadList(state, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TeamConfigRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadFlowTeamConfigCreateOrUpdate(body: TeamConfigRequest, options?: any): AxiosPromise<void> {
            return localVarFp.leadFlowTeamConfigCreateOrUpdate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadFlowTeamConfigGet(options?: any): AxiosPromise<LeadFlowTeamConfig> {
            return localVarFp.leadFlowTeamConfigGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportLeadFlow(options?: any): AxiosPromise<ReportLeadFlowQual> {
            return localVarFp.reportLeadFlow(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LeadFlowApi - interface
 * @export
 * @interface LeadFlowApi
 */
export interface LeadFlowApiInterface {
    /**
     * 
     * @param {LeadFlowEnroll} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadFlowApiInterface
     */
    getOrEnroll(body: LeadFlowEnroll, options?: RawAxiosRequestConfig): AxiosPromise<KnLead>;

    /**
     * 
     * @param {string} state 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadFlowApiInterface
     */
    leadFlowLeadList(state: string, page?: number, size?: number, options?: RawAxiosRequestConfig): AxiosPromise<KnLookupResponseKnLead>;

    /**
     * 
     * @param {TeamConfigRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadFlowApiInterface
     */
    leadFlowTeamConfigCreateOrUpdate(body: TeamConfigRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadFlowApiInterface
     */
    leadFlowTeamConfigGet(options?: RawAxiosRequestConfig): AxiosPromise<LeadFlowTeamConfig>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadFlowApiInterface
     */
    reportLeadFlow(options?: RawAxiosRequestConfig): AxiosPromise<ReportLeadFlowQual>;

}

/**
 * LeadFlowApi - object-oriented interface
 * @export
 * @class LeadFlowApi
 * @extends {BaseAPI}
 */
export class LeadFlowApi extends BaseAPI implements LeadFlowApiInterface {
    /**
     * 
     * @param {LeadFlowEnroll} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadFlowApi
     */
    public getOrEnroll(body: LeadFlowEnroll, options?: RawAxiosRequestConfig) {
        return LeadFlowApiFp(this.configuration).getOrEnroll(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} state 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadFlowApi
     */
    public leadFlowLeadList(state: string, page?: number, size?: number, options?: RawAxiosRequestConfig) {
        return LeadFlowApiFp(this.configuration).leadFlowLeadList(state, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TeamConfigRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadFlowApi
     */
    public leadFlowTeamConfigCreateOrUpdate(body: TeamConfigRequest, options?: RawAxiosRequestConfig) {
        return LeadFlowApiFp(this.configuration).leadFlowTeamConfigCreateOrUpdate(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadFlowApi
     */
    public leadFlowTeamConfigGet(options?: RawAxiosRequestConfig) {
        return LeadFlowApiFp(this.configuration).leadFlowTeamConfigGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadFlowApi
     */
    public reportLeadFlow(options?: RawAxiosRequestConfig) {
        return LeadFlowApiFp(this.configuration).reportLeadFlow(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LeadSourceControllerApi - axios parameter creator
 * @export
 */
export const LeadSourceControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel sequences, based on a lead source id
         * @param {string} leadSourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByLeadSourceId: async (leadSourceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leadSourceId' is not null or undefined
            assertParamExists('getByLeadSourceId', 'leadSourceId', leadSourceId)
            const localVarPath = `/sequences/lead-source/{leadSourceId}`
                .replace(`{${"leadSourceId"}}`, encodeURIComponent(String(leadSourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unenroll sequences, based on a lead source id
         * @param {string} leadSourceTriggerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unenrollSequencesByLeadSourceId: async (leadSourceTriggerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leadSourceTriggerId' is not null or undefined
            assertParamExists('unenrollSequencesByLeadSourceId', 'leadSourceTriggerId', leadSourceTriggerId)
            const localVarPath = `/sequences/lead-source/unenroll/{leadSourceTriggerId}`
                .replace(`{${"leadSourceTriggerId"}}`, encodeURIComponent(String(leadSourceTriggerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeadSourceControllerApi - functional programming interface
 * @export
 */
export const LeadSourceControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LeadSourceControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel sequences, based on a lead source id
         * @param {string} leadSourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByLeadSourceId(leadSourceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LeadSourceTriggerResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByLeadSourceId(leadSourceId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LeadSourceControllerApi.getByLeadSourceId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Unenroll sequences, based on a lead source id
         * @param {string} leadSourceTriggerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unenrollSequencesByLeadSourceId(leadSourceTriggerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unenrollSequencesByLeadSourceId(leadSourceTriggerId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LeadSourceControllerApi.unenrollSequencesByLeadSourceId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * LeadSourceControllerApi - factory interface
 * @export
 */
export const LeadSourceControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LeadSourceControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel sequences, based on a lead source id
         * @param {string} leadSourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByLeadSourceId(leadSourceId: string, options?: any): AxiosPromise<Array<LeadSourceTriggerResponse>> {
            return localVarFp.getByLeadSourceId(leadSourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unenroll sequences, based on a lead source id
         * @param {string} leadSourceTriggerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unenrollSequencesByLeadSourceId(leadSourceTriggerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.unenrollSequencesByLeadSourceId(leadSourceTriggerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LeadSourceControllerApi - interface
 * @export
 * @interface LeadSourceControllerApi
 */
export interface LeadSourceControllerApiInterface {
    /**
     * 
     * @summary Cancel sequences, based on a lead source id
     * @param {string} leadSourceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadSourceControllerApiInterface
     */
    getByLeadSourceId(leadSourceId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<LeadSourceTriggerResponse>>;

    /**
     * 
     * @summary Unenroll sequences, based on a lead source id
     * @param {string} leadSourceTriggerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadSourceControllerApiInterface
     */
    unenrollSequencesByLeadSourceId(leadSourceTriggerId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * LeadSourceControllerApi - object-oriented interface
 * @export
 * @class LeadSourceControllerApi
 * @extends {BaseAPI}
 */
export class LeadSourceControllerApi extends BaseAPI implements LeadSourceControllerApiInterface {
    /**
     * 
     * @summary Cancel sequences, based on a lead source id
     * @param {string} leadSourceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadSourceControllerApi
     */
    public getByLeadSourceId(leadSourceId: string, options?: RawAxiosRequestConfig) {
        return LeadSourceControllerApiFp(this.configuration).getByLeadSourceId(leadSourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unenroll sequences, based on a lead source id
     * @param {string} leadSourceTriggerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadSourceControllerApi
     */
    public unenrollSequencesByLeadSourceId(leadSourceTriggerId: string, options?: RawAxiosRequestConfig) {
        return LeadSourceControllerApiFp(this.configuration).unenrollSequencesByLeadSourceId(leadSourceTriggerId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MediaControllerApi - axios parameter creator
 * @export
 */
export const MediaControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadFile', 'file', file)
            const localVarPath = `/tasks/media`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MediaControllerApi - functional programming interface
 * @export
 */
export const MediaControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MediaControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(file, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MediaControllerApi.uploadFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * MediaControllerApi - factory interface
 * @export
 */
export const MediaControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MediaControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(file: File, options?: any): AxiosPromise<string> {
            return localVarFp.uploadFile(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MediaControllerApi - interface
 * @export
 * @interface MediaControllerApi
 */
export interface MediaControllerApiInterface {
    /**
     * 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaControllerApiInterface
     */
    uploadFile(file: File, options?: RawAxiosRequestConfig): AxiosPromise<string>;

}

/**
 * MediaControllerApi - object-oriented interface
 * @export
 * @class MediaControllerApi
 * @extends {BaseAPI}
 */
export class MediaControllerApi extends BaseAPI implements MediaControllerApiInterface {
    /**
     * 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaControllerApi
     */
    public uploadFile(file: File, options?: RawAxiosRequestConfig) {
        return MediaControllerApiFp(this.configuration).uploadFile(file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OutreachApi - axios parameter creator
 * @export
 */
export const OutreachApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates an Email entity object
         * @param {EmailDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmail: async (body: EmailDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createEmail', 'body', body)
            const localVarPath = `/outreach/v1/outbound/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a SMS entity object
         * @param {SmsDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSms: async (body: SmsDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createSms', 'body', body)
            const localVarPath = `/outreach/v1/outbound/sms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lookup Messages via paginated and filtered DTO KnLookupRequest
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupMessages: async (body: KnLookupRequest2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('lookupMessages', 'body', body)
            const localVarPath = `/outreach/v1/message/lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lookup unique conversations via paginated and filtered DTO KnLookupRequest
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupOutreachThreads: async (body: KnLookupRequest2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('lookupOutreachThreads', 'body', body)
            const localVarPath = `/outreach/v1/message/lookupOutreachThreads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lookup unique conversations via paginated and filtered DTO KnLookupRequest
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageOutreachThreads: async (body: KnLookupRequest2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('pageOutreachThreads', 'body', body)
            const localVarPath = `/outreach/v1/message/pageOutreachThreads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an Email entity object
         * @param {number} id 
         * @param {EmailDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmail: async (id: number, body: EmailDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEmail', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateEmail', 'body', body)
            const localVarPath = `/outreach/v1/outbound/email/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a SMS entity object
         * @param {number} id 
         * @param {SmsDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSms: async (id: number, body: SmsDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSms', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateSms', 'body', body)
            const localVarPath = `/outreach/v1/outbound/sms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OutreachApi - functional programming interface
 * @export
 */
export const OutreachApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OutreachApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates an Email entity object
         * @param {EmailDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEmail(body: EmailDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEmail(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OutreachApi.createEmail']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Creates a SMS entity object
         * @param {SmsDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSms(body: SmsDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSms(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OutreachApi.createSms']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Lookup Messages via paginated and filtered DTO KnLookupRequest
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lookupMessages(body: KnLookupRequest2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnLookupResponseMessageDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lookupMessages(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OutreachApi.lookupMessages']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Lookup unique conversations via paginated and filtered DTO KnLookupRequest
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lookupOutreachThreads(body: KnLookupRequest2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnLookupResponseMessageDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lookupOutreachThreads(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OutreachApi.lookupOutreachThreads']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Lookup unique conversations via paginated and filtered DTO KnLookupRequest
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageOutreachThreads(body: KnLookupRequest2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnPageResponseMessageDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageOutreachThreads(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OutreachApi.pageOutreachThreads']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Updates an Email entity object
         * @param {number} id 
         * @param {EmailDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmail(id: number, body: EmailDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmail(id, body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OutreachApi.updateEmail']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Updates a SMS entity object
         * @param {number} id 
         * @param {SmsDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSms(id: number, body: SmsDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSms(id, body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OutreachApi.updateSms']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * OutreachApi - factory interface
 * @export
 */
export const OutreachApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OutreachApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates an Email entity object
         * @param {EmailDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmail(body: EmailDTO, options?: any): AxiosPromise<number> {
            return localVarFp.createEmail(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a SMS entity object
         * @param {SmsDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSms(body: SmsDTO, options?: any): AxiosPromise<number> {
            return localVarFp.createSms(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lookup Messages via paginated and filtered DTO KnLookupRequest
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupMessages(body: KnLookupRequest2, options?: any): AxiosPromise<KnLookupResponseMessageDTO> {
            return localVarFp.lookupMessages(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lookup unique conversations via paginated and filtered DTO KnLookupRequest
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupOutreachThreads(body: KnLookupRequest2, options?: any): AxiosPromise<KnLookupResponseMessageDTO> {
            return localVarFp.lookupOutreachThreads(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lookup unique conversations via paginated and filtered DTO KnLookupRequest
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageOutreachThreads(body: KnLookupRequest2, options?: any): AxiosPromise<KnPageResponseMessageDTO> {
            return localVarFp.pageOutreachThreads(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an Email entity object
         * @param {number} id 
         * @param {EmailDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmail(id: number, body: EmailDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateEmail(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a SMS entity object
         * @param {number} id 
         * @param {SmsDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSms(id: number, body: SmsDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateSms(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OutreachApi - interface
 * @export
 * @interface OutreachApi
 */
export interface OutreachApiInterface {
    /**
     * 
     * @summary Creates an Email entity object
     * @param {EmailDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachApiInterface
     */
    createEmail(body: EmailDTO, options?: RawAxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @summary Creates a SMS entity object
     * @param {SmsDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachApiInterface
     */
    createSms(body: SmsDTO, options?: RawAxiosRequestConfig): AxiosPromise<number>;

    /**
     * 
     * @summary Lookup Messages via paginated and filtered DTO KnLookupRequest
     * @param {KnLookupRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachApiInterface
     */
    lookupMessages(body: KnLookupRequest2, options?: RawAxiosRequestConfig): AxiosPromise<KnLookupResponseMessageDTO>;

    /**
     * 
     * @summary Lookup unique conversations via paginated and filtered DTO KnLookupRequest
     * @param {KnLookupRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachApiInterface
     */
    lookupOutreachThreads(body: KnLookupRequest2, options?: RawAxiosRequestConfig): AxiosPromise<KnLookupResponseMessageDTO>;

    /**
     * 
     * @summary Lookup unique conversations via paginated and filtered DTO KnLookupRequest
     * @param {KnLookupRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachApiInterface
     */
    pageOutreachThreads(body: KnLookupRequest2, options?: RawAxiosRequestConfig): AxiosPromise<KnPageResponseMessageDTO>;

    /**
     * 
     * @summary Updates an Email entity object
     * @param {number} id 
     * @param {EmailDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachApiInterface
     */
    updateEmail(id: number, body: EmailDTO, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates a SMS entity object
     * @param {number} id 
     * @param {SmsDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachApiInterface
     */
    updateSms(id: number, body: SmsDTO, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * OutreachApi - object-oriented interface
 * @export
 * @class OutreachApi
 * @extends {BaseAPI}
 */
export class OutreachApi extends BaseAPI implements OutreachApiInterface {
    /**
     * 
     * @summary Creates an Email entity object
     * @param {EmailDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachApi
     */
    public createEmail(body: EmailDTO, options?: RawAxiosRequestConfig) {
        return OutreachApiFp(this.configuration).createEmail(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a SMS entity object
     * @param {SmsDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachApi
     */
    public createSms(body: SmsDTO, options?: RawAxiosRequestConfig) {
        return OutreachApiFp(this.configuration).createSms(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lookup Messages via paginated and filtered DTO KnLookupRequest
     * @param {KnLookupRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachApi
     */
    public lookupMessages(body: KnLookupRequest2, options?: RawAxiosRequestConfig) {
        return OutreachApiFp(this.configuration).lookupMessages(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lookup unique conversations via paginated and filtered DTO KnLookupRequest
     * @param {KnLookupRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachApi
     */
    public lookupOutreachThreads(body: KnLookupRequest2, options?: RawAxiosRequestConfig) {
        return OutreachApiFp(this.configuration).lookupOutreachThreads(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lookup unique conversations via paginated and filtered DTO KnLookupRequest
     * @param {KnLookupRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachApi
     */
    public pageOutreachThreads(body: KnLookupRequest2, options?: RawAxiosRequestConfig) {
        return OutreachApiFp(this.configuration).pageOutreachThreads(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an Email entity object
     * @param {number} id 
     * @param {EmailDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachApi
     */
    public updateEmail(id: number, body: EmailDTO, options?: RawAxiosRequestConfig) {
        return OutreachApiFp(this.configuration).updateEmail(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a SMS entity object
     * @param {number} id 
     * @param {SmsDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachApi
     */
    public updateSms(id: number, body: SmsDTO, options?: RawAxiosRequestConfig) {
        return OutreachApiFp(this.configuration).updateSms(id, body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OutreachOauthApi - axios parameter creator
 * @export
 */
export const OutreachOauthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a OutlookAccessToken entity object
         * @param {OutlookAccessTokenDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessToken: async (body: OutlookAccessTokenDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createAccessToken', 'body', body)
            const localVarPath = `/consent/v1/outlook/access-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a GmailAccessToken entity object
         * @param {GmailAccessTokenDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessToken1: async (body: GmailAccessTokenDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createAccessToken1', 'body', body)
            const localVarPath = `/consent/v1/gmail/access-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a OutlookAccessToken entity object by Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessToken: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAccessToken', 'id', id)
            const localVarPath = `/consent/v1/outlook/access-token/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a GmailAccessToken entity object by Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessToken1: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAccessToken1', 'id', id)
            const localVarPath = `/consent/v1/gmail/access-token/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a OutlookAccessToken entity object by Email Address
         * @param {string} [emailAddress] 
         * @param {string} [subscriptionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessTokenByFilter: async (emailAddress?: string, subscriptionId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/consent/v1/outlook/access-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }

            if (subscriptionId !== undefined) {
                localVarQueryParameter['subscriptionId'] = subscriptionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a GmailAccessToken entity object by Email Address
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessTokenByFilter1: async (emailAddress: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailAddress' is not null or undefined
            assertParamExists('getAccessTokenByFilter1', 'emailAddress', emailAddress)
            const localVarPath = `/consent/v1/gmail/access-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary It builds the Google Oauth URL with the required parameters for the Oauth2 flow
         * @param {RequestAuthorizeUrlDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleAuthorizeUrl: async (body: RequestAuthorizeUrlDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('googleAuthorizeUrl', 'body', body)
            const localVarPath = `/consent/v1/gmail/authorize-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exchange Google authorization code for an access/refresh tokens in the OAuth2 flow
         * @param {ExchangeAuthCodeDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleExchangeCode: async (body: ExchangeAuthCodeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('googleExchangeCode', 'body', body)
            const localVarPath = `/consent/v1/gmail/exchange-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestAuthorizeUrlDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outlookAuthorizeUrl: async (body: RequestAuthorizeUrlDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('outlookAuthorizeUrl', 'body', body)
            const localVarPath = `/consent/v1/outlook/authorize-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exchange Outlook authorization code for an access/refresh tokens in the OAuth2 flow
         * @param {ExchangeAuthCodeDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outlookExchangeCode: async (body: ExchangeAuthCodeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('outlookExchangeCode', 'body', body)
            const localVarPath = `/consent/v1/outlook/exchange-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a OutlookAccessToken entity object
         * @param {number} id 
         * @param {OutlookAccessTokenDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccessToken: async (id: number, body: OutlookAccessTokenDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAccessToken', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateAccessToken', 'body', body)
            const localVarPath = `/consent/v1/outlook/access-token/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a GmailAccessToken entity object
         * @param {number} id 
         * @param {GmailAccessTokenDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccessToken1: async (id: number, body: GmailAccessTokenDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAccessToken1', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateAccessToken1', 'body', body)
            const localVarPath = `/consent/v1/gmail/access-token/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OutreachOauthApi - functional programming interface
 * @export
 */
export const OutreachOauthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OutreachOauthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a OutlookAccessToken entity object
         * @param {OutlookAccessTokenDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccessToken(body: OutlookAccessTokenDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutlookAccessTokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAccessToken(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OutreachOauthApi.createAccessToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Creates a GmailAccessToken entity object
         * @param {GmailAccessTokenDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccessToken1(body: GmailAccessTokenDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmailAccessTokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAccessToken1(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OutreachOauthApi.createAccessToken1']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a OutlookAccessToken entity object by Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccessToken(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutlookAccessTokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccessToken(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OutreachOauthApi.getAccessToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a GmailAccessToken entity object by Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccessToken1(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmailAccessTokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccessToken1(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OutreachOauthApi.getAccessToken1']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a OutlookAccessToken entity object by Email Address
         * @param {string} [emailAddress] 
         * @param {string} [subscriptionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccessTokenByFilter(emailAddress?: string, subscriptionId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutlookAccessTokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccessTokenByFilter(emailAddress, subscriptionId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OutreachOauthApi.getAccessTokenByFilter']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a GmailAccessToken entity object by Email Address
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccessTokenByFilter1(emailAddress: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmailAccessTokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccessTokenByFilter1(emailAddress, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OutreachOauthApi.getAccessTokenByFilter1']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary It builds the Google Oauth URL with the required parameters for the Oauth2 flow
         * @param {RequestAuthorizeUrlDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async googleAuthorizeUrl(body: RequestAuthorizeUrlDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.googleAuthorizeUrl(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OutreachOauthApi.googleAuthorizeUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Exchange Google authorization code for an access/refresh tokens in the OAuth2 flow
         * @param {ExchangeAuthCodeDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async googleExchangeCode(body: ExchangeAuthCodeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.googleExchangeCode(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OutreachOauthApi.googleExchangeCode']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {RequestAuthorizeUrlDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outlookAuthorizeUrl(body: RequestAuthorizeUrlDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outlookAuthorizeUrl(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OutreachOauthApi.outlookAuthorizeUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Exchange Outlook authorization code for an access/refresh tokens in the OAuth2 flow
         * @param {ExchangeAuthCodeDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outlookExchangeCode(body: ExchangeAuthCodeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outlookExchangeCode(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OutreachOauthApi.outlookExchangeCode']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Updates a OutlookAccessToken entity object
         * @param {number} id 
         * @param {OutlookAccessTokenDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccessToken(id: number, body: OutlookAccessTokenDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutlookAccessTokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccessToken(id, body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OutreachOauthApi.updateAccessToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Updates a GmailAccessToken entity object
         * @param {number} id 
         * @param {GmailAccessTokenDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccessToken1(id: number, body: GmailAccessTokenDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmailAccessTokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccessToken1(id, body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OutreachOauthApi.updateAccessToken1']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * OutreachOauthApi - factory interface
 * @export
 */
export const OutreachOauthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OutreachOauthApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a OutlookAccessToken entity object
         * @param {OutlookAccessTokenDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessToken(body: OutlookAccessTokenDto, options?: any): AxiosPromise<OutlookAccessTokenDto> {
            return localVarFp.createAccessToken(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a GmailAccessToken entity object
         * @param {GmailAccessTokenDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessToken1(body: GmailAccessTokenDto, options?: any): AxiosPromise<GmailAccessTokenDto> {
            return localVarFp.createAccessToken1(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a OutlookAccessToken entity object by Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessToken(id: number, options?: any): AxiosPromise<OutlookAccessTokenDto> {
            return localVarFp.getAccessToken(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a GmailAccessToken entity object by Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessToken1(id: number, options?: any): AxiosPromise<GmailAccessTokenDto> {
            return localVarFp.getAccessToken1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a OutlookAccessToken entity object by Email Address
         * @param {string} [emailAddress] 
         * @param {string} [subscriptionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessTokenByFilter(emailAddress?: string, subscriptionId?: string, options?: any): AxiosPromise<OutlookAccessTokenDto> {
            return localVarFp.getAccessTokenByFilter(emailAddress, subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a GmailAccessToken entity object by Email Address
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessTokenByFilter1(emailAddress: string, options?: any): AxiosPromise<GmailAccessTokenDto> {
            return localVarFp.getAccessTokenByFilter1(emailAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary It builds the Google Oauth URL with the required parameters for the Oauth2 flow
         * @param {RequestAuthorizeUrlDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleAuthorizeUrl(body: RequestAuthorizeUrlDto, options?: any): AxiosPromise<string> {
            return localVarFp.googleAuthorizeUrl(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Exchange Google authorization code for an access/refresh tokens in the OAuth2 flow
         * @param {ExchangeAuthCodeDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleExchangeCode(body: ExchangeAuthCodeDto, options?: any): AxiosPromise<void> {
            return localVarFp.googleExchangeCode(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestAuthorizeUrlDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outlookAuthorizeUrl(body: RequestAuthorizeUrlDto, options?: any): AxiosPromise<string> {
            return localVarFp.outlookAuthorizeUrl(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Exchange Outlook authorization code for an access/refresh tokens in the OAuth2 flow
         * @param {ExchangeAuthCodeDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outlookExchangeCode(body: ExchangeAuthCodeDto, options?: any): AxiosPromise<void> {
            return localVarFp.outlookExchangeCode(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a OutlookAccessToken entity object
         * @param {number} id 
         * @param {OutlookAccessTokenDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccessToken(id: number, body: OutlookAccessTokenDto, options?: any): AxiosPromise<OutlookAccessTokenDto> {
            return localVarFp.updateAccessToken(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a GmailAccessToken entity object
         * @param {number} id 
         * @param {GmailAccessTokenDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccessToken1(id: number, body: GmailAccessTokenDto, options?: any): AxiosPromise<GmailAccessTokenDto> {
            return localVarFp.updateAccessToken1(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OutreachOauthApi - interface
 * @export
 * @interface OutreachOauthApi
 */
export interface OutreachOauthApiInterface {
    /**
     * 
     * @summary Creates a OutlookAccessToken entity object
     * @param {OutlookAccessTokenDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachOauthApiInterface
     */
    createAccessToken(body: OutlookAccessTokenDto, options?: RawAxiosRequestConfig): AxiosPromise<OutlookAccessTokenDto>;

    /**
     * 
     * @summary Creates a GmailAccessToken entity object
     * @param {GmailAccessTokenDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachOauthApiInterface
     */
    createAccessToken1(body: GmailAccessTokenDto, options?: RawAxiosRequestConfig): AxiosPromise<GmailAccessTokenDto>;

    /**
     * 
     * @summary Gets a OutlookAccessToken entity object by Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachOauthApiInterface
     */
    getAccessToken(id: number, options?: RawAxiosRequestConfig): AxiosPromise<OutlookAccessTokenDto>;

    /**
     * 
     * @summary Gets a GmailAccessToken entity object by Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachOauthApiInterface
     */
    getAccessToken1(id: number, options?: RawAxiosRequestConfig): AxiosPromise<GmailAccessTokenDto>;

    /**
     * 
     * @summary Gets a OutlookAccessToken entity object by Email Address
     * @param {string} [emailAddress] 
     * @param {string} [subscriptionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachOauthApiInterface
     */
    getAccessTokenByFilter(emailAddress?: string, subscriptionId?: string, options?: RawAxiosRequestConfig): AxiosPromise<OutlookAccessTokenDto>;

    /**
     * 
     * @summary Gets a GmailAccessToken entity object by Email Address
     * @param {string} emailAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachOauthApiInterface
     */
    getAccessTokenByFilter1(emailAddress: string, options?: RawAxiosRequestConfig): AxiosPromise<GmailAccessTokenDto>;

    /**
     * 
     * @summary It builds the Google Oauth URL with the required parameters for the Oauth2 flow
     * @param {RequestAuthorizeUrlDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachOauthApiInterface
     */
    googleAuthorizeUrl(body: RequestAuthorizeUrlDto, options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @summary Exchange Google authorization code for an access/refresh tokens in the OAuth2 flow
     * @param {ExchangeAuthCodeDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachOauthApiInterface
     */
    googleExchangeCode(body: ExchangeAuthCodeDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {RequestAuthorizeUrlDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachOauthApiInterface
     */
    outlookAuthorizeUrl(body: RequestAuthorizeUrlDto, options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @summary Exchange Outlook authorization code for an access/refresh tokens in the OAuth2 flow
     * @param {ExchangeAuthCodeDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachOauthApiInterface
     */
    outlookExchangeCode(body: ExchangeAuthCodeDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates a OutlookAccessToken entity object
     * @param {number} id 
     * @param {OutlookAccessTokenDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachOauthApiInterface
     */
    updateAccessToken(id: number, body: OutlookAccessTokenDto, options?: RawAxiosRequestConfig): AxiosPromise<OutlookAccessTokenDto>;

    /**
     * 
     * @summary Updates a GmailAccessToken entity object
     * @param {number} id 
     * @param {GmailAccessTokenDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachOauthApiInterface
     */
    updateAccessToken1(id: number, body: GmailAccessTokenDto, options?: RawAxiosRequestConfig): AxiosPromise<GmailAccessTokenDto>;

}

/**
 * OutreachOauthApi - object-oriented interface
 * @export
 * @class OutreachOauthApi
 * @extends {BaseAPI}
 */
export class OutreachOauthApi extends BaseAPI implements OutreachOauthApiInterface {
    /**
     * 
     * @summary Creates a OutlookAccessToken entity object
     * @param {OutlookAccessTokenDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachOauthApi
     */
    public createAccessToken(body: OutlookAccessTokenDto, options?: RawAxiosRequestConfig) {
        return OutreachOauthApiFp(this.configuration).createAccessToken(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a GmailAccessToken entity object
     * @param {GmailAccessTokenDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachOauthApi
     */
    public createAccessToken1(body: GmailAccessTokenDto, options?: RawAxiosRequestConfig) {
        return OutreachOauthApiFp(this.configuration).createAccessToken1(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a OutlookAccessToken entity object by Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachOauthApi
     */
    public getAccessToken(id: number, options?: RawAxiosRequestConfig) {
        return OutreachOauthApiFp(this.configuration).getAccessToken(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a GmailAccessToken entity object by Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachOauthApi
     */
    public getAccessToken1(id: number, options?: RawAxiosRequestConfig) {
        return OutreachOauthApiFp(this.configuration).getAccessToken1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a OutlookAccessToken entity object by Email Address
     * @param {string} [emailAddress] 
     * @param {string} [subscriptionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachOauthApi
     */
    public getAccessTokenByFilter(emailAddress?: string, subscriptionId?: string, options?: RawAxiosRequestConfig) {
        return OutreachOauthApiFp(this.configuration).getAccessTokenByFilter(emailAddress, subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a GmailAccessToken entity object by Email Address
     * @param {string} emailAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachOauthApi
     */
    public getAccessTokenByFilter1(emailAddress: string, options?: RawAxiosRequestConfig) {
        return OutreachOauthApiFp(this.configuration).getAccessTokenByFilter1(emailAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary It builds the Google Oauth URL with the required parameters for the Oauth2 flow
     * @param {RequestAuthorizeUrlDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachOauthApi
     */
    public googleAuthorizeUrl(body: RequestAuthorizeUrlDto, options?: RawAxiosRequestConfig) {
        return OutreachOauthApiFp(this.configuration).googleAuthorizeUrl(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Exchange Google authorization code for an access/refresh tokens in the OAuth2 flow
     * @param {ExchangeAuthCodeDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachOauthApi
     */
    public googleExchangeCode(body: ExchangeAuthCodeDto, options?: RawAxiosRequestConfig) {
        return OutreachOauthApiFp(this.configuration).googleExchangeCode(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestAuthorizeUrlDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachOauthApi
     */
    public outlookAuthorizeUrl(body: RequestAuthorizeUrlDto, options?: RawAxiosRequestConfig) {
        return OutreachOauthApiFp(this.configuration).outlookAuthorizeUrl(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Exchange Outlook authorization code for an access/refresh tokens in the OAuth2 flow
     * @param {ExchangeAuthCodeDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachOauthApi
     */
    public outlookExchangeCode(body: ExchangeAuthCodeDto, options?: RawAxiosRequestConfig) {
        return OutreachOauthApiFp(this.configuration).outlookExchangeCode(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a OutlookAccessToken entity object
     * @param {number} id 
     * @param {OutlookAccessTokenDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachOauthApi
     */
    public updateAccessToken(id: number, body: OutlookAccessTokenDto, options?: RawAxiosRequestConfig) {
        return OutreachOauthApiFp(this.configuration).updateAccessToken(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a GmailAccessToken entity object
     * @param {number} id 
     * @param {GmailAccessTokenDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutreachOauthApi
     */
    public updateAccessToken1(id: number, body: GmailAccessTokenDto, options?: RawAxiosRequestConfig) {
        return OutreachOauthApiFp(this.configuration).updateAccessToken1(id, body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PipelinesApi - axios parameter creator
 * @export
 */
export const PipelinesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a new Pipeline
         * @param {PipelineRequestCreate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPipeline: async (body: PipelineRequestCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createPipeline', 'body', body)
            const localVarPath = `/deal-flow/pipelines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes pipeline by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePipeline: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePipeline', 'id', id)
            const localVarPath = `/deal-flow/pipelines/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pipeline by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPipeline: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPipeline', 'id', id)
            const localVarPath = `/deal-flow/pipelines/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pipelines available for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPipelinesForUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/deal-flow/pipelines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a Pipeline
         * @param {PipelineRequestUpdate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePipeline: async (body: PipelineRequestUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updatePipeline', 'body', body)
            const localVarPath = `/deal-flow/pipelines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the pipeline`s stages order. The list should contain all stages within the pipeline in the correct order
         * @param {PipelineRequestUpdateOrder} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePipelineStagesOrder: async (body: PipelineRequestUpdateOrder, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updatePipelineStagesOrder', 'body', body)
            const localVarPath = `/deal-flow/pipelines/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PipelinesApi - functional programming interface
 * @export
 */
export const PipelinesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PipelinesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a new Pipeline
         * @param {PipelineRequestCreate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPipeline(body: PipelineRequestCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnPipeline>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPipeline(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PipelinesApi.createPipeline']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes pipeline by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePipeline(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePipeline(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PipelinesApi.deletePipeline']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get pipeline by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPipeline(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnPipeline>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPipeline(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PipelinesApi.getPipeline']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get pipelines available for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPipelinesForUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PipelineListResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPipelinesForUser(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PipelinesApi.getPipelinesForUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Updates a Pipeline
         * @param {PipelineRequestUpdate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePipeline(body: PipelineRequestUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePipeline(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PipelinesApi.updatePipeline']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Updates the pipeline`s stages order. The list should contain all stages within the pipeline in the correct order
         * @param {PipelineRequestUpdateOrder} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePipelineStagesOrder(body: PipelineRequestUpdateOrder, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePipelineStagesOrder(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PipelinesApi.updatePipelineStagesOrder']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PipelinesApi - factory interface
 * @export
 */
export const PipelinesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PipelinesApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a new Pipeline
         * @param {PipelineRequestCreate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPipeline(body: PipelineRequestCreate, options?: any): AxiosPromise<KnPipeline> {
            return localVarFp.createPipeline(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes pipeline by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePipeline(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePipeline(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pipeline by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPipeline(id: string, options?: any): AxiosPromise<KnPipeline> {
            return localVarFp.getPipeline(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pipelines available for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPipelinesForUser(options?: any): AxiosPromise<Array<PipelineListResponse>> {
            return localVarFp.getPipelinesForUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a Pipeline
         * @param {PipelineRequestUpdate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePipeline(body: PipelineRequestUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.updatePipeline(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the pipeline`s stages order. The list should contain all stages within the pipeline in the correct order
         * @param {PipelineRequestUpdateOrder} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePipelineStagesOrder(body: PipelineRequestUpdateOrder, options?: any): AxiosPromise<void> {
            return localVarFp.updatePipelineStagesOrder(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PipelinesApi - interface
 * @export
 * @interface PipelinesApi
 */
export interface PipelinesApiInterface {
    /**
     * 
     * @summary Creates a new Pipeline
     * @param {PipelineRequestCreate} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelinesApiInterface
     */
    createPipeline(body: PipelineRequestCreate, options?: RawAxiosRequestConfig): AxiosPromise<KnPipeline>;

    /**
     * 
     * @summary Deletes pipeline by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelinesApiInterface
     */
    deletePipeline(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Get pipeline by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelinesApiInterface
     */
    getPipeline(id: string, options?: RawAxiosRequestConfig): AxiosPromise<KnPipeline>;

    /**
     * 
     * @summary Get pipelines available for current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelinesApiInterface
     */
    getPipelinesForUser(options?: RawAxiosRequestConfig): AxiosPromise<Array<PipelineListResponse>>;

    /**
     * 
     * @summary Updates a Pipeline
     * @param {PipelineRequestUpdate} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelinesApiInterface
     */
    updatePipeline(body: PipelineRequestUpdate, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates the pipeline`s stages order. The list should contain all stages within the pipeline in the correct order
     * @param {PipelineRequestUpdateOrder} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelinesApiInterface
     */
    updatePipelineStagesOrder(body: PipelineRequestUpdateOrder, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * PipelinesApi - object-oriented interface
 * @export
 * @class PipelinesApi
 * @extends {BaseAPI}
 */
export class PipelinesApi extends BaseAPI implements PipelinesApiInterface {
    /**
     * 
     * @summary Creates a new Pipeline
     * @param {PipelineRequestCreate} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelinesApi
     */
    public createPipeline(body: PipelineRequestCreate, options?: RawAxiosRequestConfig) {
        return PipelinesApiFp(this.configuration).createPipeline(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes pipeline by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelinesApi
     */
    public deletePipeline(id: string, options?: RawAxiosRequestConfig) {
        return PipelinesApiFp(this.configuration).deletePipeline(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get pipeline by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelinesApi
     */
    public getPipeline(id: string, options?: RawAxiosRequestConfig) {
        return PipelinesApiFp(this.configuration).getPipeline(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get pipelines available for current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelinesApi
     */
    public getPipelinesForUser(options?: RawAxiosRequestConfig) {
        return PipelinesApiFp(this.configuration).getPipelinesForUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a Pipeline
     * @param {PipelineRequestUpdate} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelinesApi
     */
    public updatePipeline(body: PipelineRequestUpdate, options?: RawAxiosRequestConfig) {
        return PipelinesApiFp(this.configuration).updatePipeline(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the pipeline`s stages order. The list should contain all stages within the pipeline in the correct order
     * @param {PipelineRequestUpdateOrder} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelinesApi
     */
    public updatePipelineStagesOrder(body: PipelineRequestUpdateOrder, options?: RawAxiosRequestConfig) {
        return PipelinesApiFp(this.configuration).updatePipelineStagesOrder(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a new Product
         * @param {ProductRequestCreate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct: async (body: ProductRequestCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createProduct', 'body', body)
            const localVarPath = `/deal-flow/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes product by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProduct', 'id', id)
            const localVarPath = `/deal-flow/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get product by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProduct: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProduct', 'id', id)
            const localVarPath = `/deal-flow/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get products by name, status and teamId, if teamId is null, will get current user\'s team
         * @param {string} [name] 
         * @param {Array<GetProductsStatusEnum>} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts: async (name?: string, status?: Array<GetProductsStatusEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/deal-flow/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a Product
         * @param {ProductRequestUpdate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct: async (body: ProductRequestUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateProduct', 'body', body)
            const localVarPath = `/deal-flow/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a new Product
         * @param {ProductRequestCreate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProduct(body: ProductRequestCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProduct(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductsApi.createProduct']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes product by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProduct(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProduct(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductsApi.deleteProduct']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get product by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProduct(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProduct(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductsApi.getProduct']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get products by name, status and teamId, if teamId is null, will get current user\'s team
         * @param {string} [name] 
         * @param {Array<GetProductsStatusEnum>} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProducts(name?: string, status?: Array<GetProductsStatusEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KnProduct>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProducts(name, status, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductsApi.getProducts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Updates a Product
         * @param {ProductRequestUpdate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProduct(body: ProductRequestUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProduct(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProductsApi.updateProduct']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a new Product
         * @param {ProductRequestCreate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct(body: ProductRequestCreate, options?: any): AxiosPromise<KnProduct> {
            return localVarFp.createProduct(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes product by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProduct(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get product by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProduct(id: string, options?: any): AxiosPromise<KnProduct> {
            return localVarFp.getProduct(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get products by name, status and teamId, if teamId is null, will get current user\'s team
         * @param {string} [name] 
         * @param {Array<GetProductsStatusEnum>} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts(name?: string, status?: Array<GetProductsStatusEnum>, options?: any): AxiosPromise<Array<KnProduct>> {
            return localVarFp.getProducts(name, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a Product
         * @param {ProductRequestUpdate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct(body: ProductRequestUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.updateProduct(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductsApi - interface
 * @export
 * @interface ProductsApi
 */
export interface ProductsApiInterface {
    /**
     * 
     * @summary Creates a new Product
     * @param {ProductRequestCreate} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    createProduct(body: ProductRequestCreate, options?: RawAxiosRequestConfig): AxiosPromise<KnProduct>;

    /**
     * 
     * @summary Deletes product by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    deleteProduct(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Get product by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    getProduct(id: string, options?: RawAxiosRequestConfig): AxiosPromise<KnProduct>;

    /**
     * 
     * @summary Get products by name, status and teamId, if teamId is null, will get current user\'s team
     * @param {string} [name] 
     * @param {Array<GetProductsStatusEnum>} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    getProducts(name?: string, status?: Array<GetProductsStatusEnum>, options?: RawAxiosRequestConfig): AxiosPromise<Array<KnProduct>>;

    /**
     * 
     * @summary Updates a Product
     * @param {ProductRequestUpdate} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    updateProduct(body: ProductRequestUpdate, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI implements ProductsApiInterface {
    /**
     * 
     * @summary Creates a new Product
     * @param {ProductRequestCreate} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public createProduct(body: ProductRequestCreate, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).createProduct(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes product by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public deleteProduct(id: string, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).deleteProduct(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get product by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getProduct(id: string, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getProduct(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get products by name, status and teamId, if teamId is null, will get current user\'s team
     * @param {string} [name] 
     * @param {Array<GetProductsStatusEnum>} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getProducts(name?: string, status?: Array<GetProductsStatusEnum>, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getProducts(name, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a Product
     * @param {ProductRequestUpdate} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public updateProduct(body: ProductRequestUpdate, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).updateProduct(body, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetProductsStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type GetProductsStatusEnum = typeof GetProductsStatusEnum[keyof typeof GetProductsStatusEnum];


/**
 * ProspectCompanyApi - axios parameter creator
 * @export
 */
export const ProspectCompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new Company document via DTO KnCreateCompanyRequest
         * @param {KnCreateCompanyRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany: async (request: KnCreateCompanyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('createCompany', 'request', request)
            const localVarPath = `/prospect/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create via Data Import a new Company document via DTO KnCreateDataImportCompanyRequest
         * @param {KnCreateDataImportCompanyRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompanyViaDataImport: async (request: KnCreateDataImportCompanyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('createCompanyViaDataImport', 'request', request)
            const localVarPath = `/prospect/company/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Soft-Delete an existing Company document by CompanyId (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompany: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCompany', 'id', id)
            const localVarPath = `/prospect/company/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All User Company documents by UserId via SecurityContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanies: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/prospect/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a User\'s Company document by CompanyId (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCompanyById', 'id', id)
            const localVarPath = `/prospect/company/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Team Company documents by UserId via SecurityContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamCompanies: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/prospect/company/team`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a Team\'s Company document by CompanyId (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamCompanyByCompanyId: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTeamCompanyByCompanyId', 'id', id)
            const localVarPath = `/prospect/company/team/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Keyword search User\'s Company documents via paginated and search-termed DTO KnKeywordSearchRequest
         * @param {KnKeywordSearchRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keywordSearchCompanies: async (request: KnKeywordSearchRequest2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('keywordSearchCompanies', 'request', request)
            const localVarPath = `/prospect/company/keyword/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Keyword search Team\'s Company documents via paginated and search-termed DTO KnKeywordSearchRequest
         * @param {KnKeywordSearchRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keywordSearchTeamCompanies: async (request: KnKeywordSearchRequest2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('keywordSearchTeamCompanies', 'request', request)
            const localVarPath = `/prospect/company/team/keyword/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lookup User\'s Company document via filtered and paginated KnLookupRequest
         * @param {KnLookupRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupCompanies: async (request: KnLookupRequest2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('lookupCompanies', 'request', request)
            const localVarPath = `/prospect/company/lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lookup Team\'s Company document via filtered and paginated KnLookupRequest
         * @param {KnLookupRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupTeamCompanies: async (request: KnLookupRequest2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('lookupTeamCompanies', 'request', request)
            const localVarPath = `/prospect/company/team/lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return User\'s Company documents via paginated DTO KnPageRequest
         * @param {KnPageRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageCompanies: async (request: KnPageRequest2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('pageCompanies', 'request', request)
            const localVarPath = `/prospect/company/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return Team\'s Company documents via paginated DTO KnPageRequest
         * @param {KnPageRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageTeamCompanies: async (request: KnPageRequest2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('pageTeamCompanies', 'request', request)
            const localVarPath = `/prospect/company/team/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch update via Data Import an existing Company document via DTO KnCompany
         * @param {KnCompany} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCompanyViaDataImport: async (request: KnCompany, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('patchCompanyViaDataImport', 'request', request)
            const localVarPath = `/prospect/company/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put update an existing Company document via DTO KnCompanyRequest
         * @param {KnCompanyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompany: async (body: KnCompanyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('putCompany', 'body', body)
            const localVarPath = `/prospect/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProspectCompanyApi - functional programming interface
 * @export
 */
export const ProspectCompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProspectCompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new Company document via DTO KnCreateCompanyRequest
         * @param {KnCreateCompanyRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompany(request: KnCreateCompanyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDocumentResponseKnCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompany(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectCompanyApi.createCompany']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create via Data Import a new Company document via DTO KnCreateDataImportCompanyRequest
         * @param {KnCreateDataImportCompanyRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompanyViaDataImport(request: KnCreateDataImportCompanyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDocumentResponseKnCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompanyViaDataImport(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectCompanyApi.createCompanyViaDataImport']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Soft-Delete an existing Company document by CompanyId (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCompany(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompany(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectCompanyApi.deleteCompany']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get All User Company documents by UserId via SecurityContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanies(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDocumentListResponseKnCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanies(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectCompanyApi.getCompanies']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get a User\'s Company document by CompanyId (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDocumentResponseKnCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectCompanyApi.getCompanyById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Team Company documents by UserId via SecurityContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamCompanies(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDocumentListResponseKnTeamCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamCompanies(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectCompanyApi.getTeamCompanies']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get a Team\'s Company document by CompanyId (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamCompanyByCompanyId(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDocumentResponseKnTeamCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamCompanyByCompanyId(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectCompanyApi.getTeamCompanyByCompanyId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Keyword search User\'s Company documents via paginated and search-termed DTO KnKeywordSearchRequest
         * @param {KnKeywordSearchRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async keywordSearchCompanies(request: KnKeywordSearchRequest2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnKeywordSearchResponseKnCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.keywordSearchCompanies(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectCompanyApi.keywordSearchCompanies']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Keyword search Team\'s Company documents via paginated and search-termed DTO KnKeywordSearchRequest
         * @param {KnKeywordSearchRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async keywordSearchTeamCompanies(request: KnKeywordSearchRequest2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnKeywordSearchResponseKnTeamCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.keywordSearchTeamCompanies(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectCompanyApi.keywordSearchTeamCompanies']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Lookup User\'s Company document via filtered and paginated KnLookupRequest
         * @param {KnLookupRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lookupCompanies(request: KnLookupRequest2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnLookupResponseKnCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lookupCompanies(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectCompanyApi.lookupCompanies']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Lookup Team\'s Company document via filtered and paginated KnLookupRequest
         * @param {KnLookupRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lookupTeamCompanies(request: KnLookupRequest2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnLookupResponseKnTeamCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lookupTeamCompanies(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectCompanyApi.lookupTeamCompanies']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Return User\'s Company documents via paginated DTO KnPageRequest
         * @param {KnPageRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageCompanies(request: KnPageRequest2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnPageResponseKnCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageCompanies(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectCompanyApi.pageCompanies']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Return Team\'s Company documents via paginated DTO KnPageRequest
         * @param {KnPageRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageTeamCompanies(request: KnPageRequest2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnPageResponseKnTeamCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageTeamCompanies(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectCompanyApi.pageTeamCompanies']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Patch update via Data Import an existing Company document via DTO KnCompany
         * @param {KnCompany} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCompanyViaDataImport(request: KnCompany, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDocumentResponseKnCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCompanyViaDataImport(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectCompanyApi.patchCompanyViaDataImport']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Put update an existing Company document via DTO KnCompanyRequest
         * @param {KnCompanyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCompany(body: KnCompanyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDocumentResponseKnCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCompany(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectCompanyApi.putCompany']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ProspectCompanyApi - factory interface
 * @export
 */
export const ProspectCompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProspectCompanyApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new Company document via DTO KnCreateCompanyRequest
         * @param {KnCreateCompanyRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany(request: KnCreateCompanyRequest, options?: any): AxiosPromise<KnDocumentResponseKnCompany> {
            return localVarFp.createCompany(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create via Data Import a new Company document via DTO KnCreateDataImportCompanyRequest
         * @param {KnCreateDataImportCompanyRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompanyViaDataImport(request: KnCreateDataImportCompanyRequest, options?: any): AxiosPromise<KnDocumentResponseKnCompany> {
            return localVarFp.createCompanyViaDataImport(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Soft-Delete an existing Company document by CompanyId (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompany(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCompany(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All User Company documents by UserId via SecurityContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanies(options?: any): AxiosPromise<KnDocumentListResponseKnCompany> {
            return localVarFp.getCompanies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a User\'s Company document by CompanyId (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyById(id: string, options?: any): AxiosPromise<KnDocumentResponseKnCompany> {
            return localVarFp.getCompanyById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Team Company documents by UserId via SecurityContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamCompanies(options?: any): AxiosPromise<KnDocumentListResponseKnTeamCompany> {
            return localVarFp.getTeamCompanies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a Team\'s Company document by CompanyId (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamCompanyByCompanyId(id: string, options?: any): AxiosPromise<KnDocumentResponseKnTeamCompany> {
            return localVarFp.getTeamCompanyByCompanyId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Keyword search User\'s Company documents via paginated and search-termed DTO KnKeywordSearchRequest
         * @param {KnKeywordSearchRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keywordSearchCompanies(request: KnKeywordSearchRequest2, options?: any): AxiosPromise<KnKeywordSearchResponseKnCompany> {
            return localVarFp.keywordSearchCompanies(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Keyword search Team\'s Company documents via paginated and search-termed DTO KnKeywordSearchRequest
         * @param {KnKeywordSearchRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keywordSearchTeamCompanies(request: KnKeywordSearchRequest2, options?: any): AxiosPromise<KnKeywordSearchResponseKnTeamCompany> {
            return localVarFp.keywordSearchTeamCompanies(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lookup User\'s Company document via filtered and paginated KnLookupRequest
         * @param {KnLookupRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupCompanies(request: KnLookupRequest2, options?: any): AxiosPromise<KnLookupResponseKnCompany> {
            return localVarFp.lookupCompanies(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lookup Team\'s Company document via filtered and paginated KnLookupRequest
         * @param {KnLookupRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupTeamCompanies(request: KnLookupRequest2, options?: any): AxiosPromise<KnLookupResponseKnTeamCompany> {
            return localVarFp.lookupTeamCompanies(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return User\'s Company documents via paginated DTO KnPageRequest
         * @param {KnPageRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageCompanies(request: KnPageRequest2, options?: any): AxiosPromise<KnPageResponseKnCompany> {
            return localVarFp.pageCompanies(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return Team\'s Company documents via paginated DTO KnPageRequest
         * @param {KnPageRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageTeamCompanies(request: KnPageRequest2, options?: any): AxiosPromise<KnPageResponseKnTeamCompany> {
            return localVarFp.pageTeamCompanies(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch update via Data Import an existing Company document via DTO KnCompany
         * @param {KnCompany} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCompanyViaDataImport(request: KnCompany, options?: any): AxiosPromise<KnDocumentResponseKnCompany> {
            return localVarFp.patchCompanyViaDataImport(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put update an existing Company document via DTO KnCompanyRequest
         * @param {KnCompanyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompany(body: KnCompanyRequest, options?: any): AxiosPromise<KnDocumentResponseKnCompany> {
            return localVarFp.putCompany(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProspectCompanyApi - interface
 * @export
 * @interface ProspectCompanyApi
 */
export interface ProspectCompanyApiInterface {
    /**
     * 
     * @summary Create new Company document via DTO KnCreateCompanyRequest
     * @param {KnCreateCompanyRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApiInterface
     */
    createCompany(request: KnCreateCompanyRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnDocumentResponseKnCompany>;

    /**
     * 
     * @summary Create via Data Import a new Company document via DTO KnCreateDataImportCompanyRequest
     * @param {KnCreateDataImportCompanyRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApiInterface
     */
    createCompanyViaDataImport(request: KnCreateDataImportCompanyRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnDocumentResponseKnCompany>;

    /**
     * 
     * @summary Soft-Delete an existing Company document by CompanyId (\'id\') via Path Variable
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApiInterface
     */
    deleteCompany(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Get All User Company documents by UserId via SecurityContext
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApiInterface
     */
    getCompanies(options?: RawAxiosRequestConfig): AxiosPromise<KnDocumentListResponseKnCompany>;

    /**
     * 
     * @summary Get a User\'s Company document by CompanyId (\'id\') via Path Variable
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApiInterface
     */
    getCompanyById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<KnDocumentResponseKnCompany>;

    /**
     * 
     * @summary Get All Team Company documents by UserId via SecurityContext
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApiInterface
     */
    getTeamCompanies(options?: RawAxiosRequestConfig): AxiosPromise<KnDocumentListResponseKnTeamCompany>;

    /**
     * 
     * @summary Get a Team\'s Company document by CompanyId (\'id\') via Path Variable
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApiInterface
     */
    getTeamCompanyByCompanyId(id: string, options?: RawAxiosRequestConfig): AxiosPromise<KnDocumentResponseKnTeamCompany>;

    /**
     * 
     * @summary Keyword search User\'s Company documents via paginated and search-termed DTO KnKeywordSearchRequest
     * @param {KnKeywordSearchRequest2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApiInterface
     */
    keywordSearchCompanies(request: KnKeywordSearchRequest2, options?: RawAxiosRequestConfig): AxiosPromise<KnKeywordSearchResponseKnCompany>;

    /**
     * 
     * @summary Keyword search Team\'s Company documents via paginated and search-termed DTO KnKeywordSearchRequest
     * @param {KnKeywordSearchRequest2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApiInterface
     */
    keywordSearchTeamCompanies(request: KnKeywordSearchRequest2, options?: RawAxiosRequestConfig): AxiosPromise<KnKeywordSearchResponseKnTeamCompany>;

    /**
     * 
     * @summary Lookup User\'s Company document via filtered and paginated KnLookupRequest
     * @param {KnLookupRequest2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApiInterface
     */
    lookupCompanies(request: KnLookupRequest2, options?: RawAxiosRequestConfig): AxiosPromise<KnLookupResponseKnCompany>;

    /**
     * 
     * @summary Lookup Team\'s Company document via filtered and paginated KnLookupRequest
     * @param {KnLookupRequest2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApiInterface
     */
    lookupTeamCompanies(request: KnLookupRequest2, options?: RawAxiosRequestConfig): AxiosPromise<KnLookupResponseKnTeamCompany>;

    /**
     * 
     * @summary Return User\'s Company documents via paginated DTO KnPageRequest
     * @param {KnPageRequest2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApiInterface
     */
    pageCompanies(request: KnPageRequest2, options?: RawAxiosRequestConfig): AxiosPromise<KnPageResponseKnCompany>;

    /**
     * 
     * @summary Return Team\'s Company documents via paginated DTO KnPageRequest
     * @param {KnPageRequest2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApiInterface
     */
    pageTeamCompanies(request: KnPageRequest2, options?: RawAxiosRequestConfig): AxiosPromise<KnPageResponseKnTeamCompany>;

    /**
     * 
     * @summary Patch update via Data Import an existing Company document via DTO KnCompany
     * @param {KnCompany} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApiInterface
     */
    patchCompanyViaDataImport(request: KnCompany, options?: RawAxiosRequestConfig): AxiosPromise<KnDocumentResponseKnCompany>;

    /**
     * 
     * @summary Put update an existing Company document via DTO KnCompanyRequest
     * @param {KnCompanyRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApiInterface
     */
    putCompany(body: KnCompanyRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnDocumentResponseKnCompany>;

}

/**
 * ProspectCompanyApi - object-oriented interface
 * @export
 * @class ProspectCompanyApi
 * @extends {BaseAPI}
 */
export class ProspectCompanyApi extends BaseAPI implements ProspectCompanyApiInterface {
    /**
     * 
     * @summary Create new Company document via DTO KnCreateCompanyRequest
     * @param {KnCreateCompanyRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApi
     */
    public createCompany(request: KnCreateCompanyRequest, options?: RawAxiosRequestConfig) {
        return ProspectCompanyApiFp(this.configuration).createCompany(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create via Data Import a new Company document via DTO KnCreateDataImportCompanyRequest
     * @param {KnCreateDataImportCompanyRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApi
     */
    public createCompanyViaDataImport(request: KnCreateDataImportCompanyRequest, options?: RawAxiosRequestConfig) {
        return ProspectCompanyApiFp(this.configuration).createCompanyViaDataImport(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Soft-Delete an existing Company document by CompanyId (\'id\') via Path Variable
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApi
     */
    public deleteCompany(id: string, options?: RawAxiosRequestConfig) {
        return ProspectCompanyApiFp(this.configuration).deleteCompany(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All User Company documents by UserId via SecurityContext
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApi
     */
    public getCompanies(options?: RawAxiosRequestConfig) {
        return ProspectCompanyApiFp(this.configuration).getCompanies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a User\'s Company document by CompanyId (\'id\') via Path Variable
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApi
     */
    public getCompanyById(id: string, options?: RawAxiosRequestConfig) {
        return ProspectCompanyApiFp(this.configuration).getCompanyById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Team Company documents by UserId via SecurityContext
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApi
     */
    public getTeamCompanies(options?: RawAxiosRequestConfig) {
        return ProspectCompanyApiFp(this.configuration).getTeamCompanies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a Team\'s Company document by CompanyId (\'id\') via Path Variable
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApi
     */
    public getTeamCompanyByCompanyId(id: string, options?: RawAxiosRequestConfig) {
        return ProspectCompanyApiFp(this.configuration).getTeamCompanyByCompanyId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Keyword search User\'s Company documents via paginated and search-termed DTO KnKeywordSearchRequest
     * @param {KnKeywordSearchRequest2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApi
     */
    public keywordSearchCompanies(request: KnKeywordSearchRequest2, options?: RawAxiosRequestConfig) {
        return ProspectCompanyApiFp(this.configuration).keywordSearchCompanies(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Keyword search Team\'s Company documents via paginated and search-termed DTO KnKeywordSearchRequest
     * @param {KnKeywordSearchRequest2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApi
     */
    public keywordSearchTeamCompanies(request: KnKeywordSearchRequest2, options?: RawAxiosRequestConfig) {
        return ProspectCompanyApiFp(this.configuration).keywordSearchTeamCompanies(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lookup User\'s Company document via filtered and paginated KnLookupRequest
     * @param {KnLookupRequest2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApi
     */
    public lookupCompanies(request: KnLookupRequest2, options?: RawAxiosRequestConfig) {
        return ProspectCompanyApiFp(this.configuration).lookupCompanies(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lookup Team\'s Company document via filtered and paginated KnLookupRequest
     * @param {KnLookupRequest2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApi
     */
    public lookupTeamCompanies(request: KnLookupRequest2, options?: RawAxiosRequestConfig) {
        return ProspectCompanyApiFp(this.configuration).lookupTeamCompanies(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return User\'s Company documents via paginated DTO KnPageRequest
     * @param {KnPageRequest2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApi
     */
    public pageCompanies(request: KnPageRequest2, options?: RawAxiosRequestConfig) {
        return ProspectCompanyApiFp(this.configuration).pageCompanies(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return Team\'s Company documents via paginated DTO KnPageRequest
     * @param {KnPageRequest2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApi
     */
    public pageTeamCompanies(request: KnPageRequest2, options?: RawAxiosRequestConfig) {
        return ProspectCompanyApiFp(this.configuration).pageTeamCompanies(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch update via Data Import an existing Company document via DTO KnCompany
     * @param {KnCompany} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApi
     */
    public patchCompanyViaDataImport(request: KnCompany, options?: RawAxiosRequestConfig) {
        return ProspectCompanyApiFp(this.configuration).patchCompanyViaDataImport(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put update an existing Company document via DTO KnCompanyRequest
     * @param {KnCompanyRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectCompanyApi
     */
    public putCompany(body: KnCompanyRequest, options?: RawAxiosRequestConfig) {
        return ProspectCompanyApiFp(this.configuration).putCompany(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProspectContactApi - axios parameter creator
 * @export
 */
export const ProspectContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new Contact document via DTO KnCreateContactRequest
         * @param {KnCreateContactRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContact: async (request: KnCreateContactRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('createContact', 'request', request)
            const localVarPath = `/prospect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create via Data Import new Contact document via DTO KnContact
         * @param {KnCreateDataImportContactRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContactViaDataImport: async (request: KnCreateDataImportContactRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('createContactViaDataImport', 'request', request)
            const localVarPath = `/prospect/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User Contact documents by Contact\'s \'lastName\' via DTO KnContactLastNameRequest
         * @param {KnContactLastNameSearchRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTeamContactsByLastName: async (body: KnContactLastNameSearchRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getAllTeamContactsByLastName', 'body', body)
            const localVarPath = `/prospect/last-name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a User\'s Contact document by ContactId (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getContactById', 'id', id)
            const localVarPath = `/prospect/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All User Contact documents by UserId via SecurityContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContacts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/prospect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a Team\'s Contact document by ContactId (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamContactByContactId: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTeamContactByContactId', 'id', id)
            const localVarPath = `/prospect/team/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Team Contacts count by TeamId
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamContactSummary: async (teamId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('getTeamContactSummary', 'teamId', teamId)
            const localVarPath = `/prospect/team/{teamId}/summary`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Team Contact documents by UserId via SecurityContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamContacts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/prospect/team`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Hard-Delete an existing Contact document by ContactId (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hardDeleteContact: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('hardDeleteContact', 'id', id)
            const localVarPath = `/prospect/{id}/hard`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Keyword search User\'s Contact documents via paginated and search-termed DTO KnKeywordSearchRequest
         * @param {KnKeywordSearchRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keywordSearchContacts: async (request: KnKeywordSearchRequest2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('keywordSearchContacts', 'request', request)
            const localVarPath = `/prospect/keyword/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Keyword search Team\'s Contact documents via paginated and search-termed DTO KnKeywordSearchRequest
         * @param {KnKeywordSearchRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keywordSearchTeamContacts: async (request: KnKeywordSearchRequest2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('keywordSearchTeamContacts', 'request', request)
            const localVarPath = `/prospect/team/keyword/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lookup User\'s Contact documents via filtered and paginated KnLookupRequest
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupContacts: async (body: KnLookupRequest2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('lookupContacts', 'body', body)
            const localVarPath = `/prospect/lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get contacts by leadSourceId and filters applied
         * @param {KnLeadSourceLookupRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupContactsByContactNameAndLeadSourceId: async (body: KnLeadSourceLookupRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('lookupContactsByContactNameAndLeadSourceId', 'body', body)
            const localVarPath = `/prospect/lead-source-id/lookup/keyword/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lookup Contact documents via DTO KnContactIdListRequest
         * @param {KnContactIdListRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupContactsByIdList: async (request: KnContactIdListRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('lookupContactsByIdList', 'request', request)
            const localVarPath = `/prospect/lookup/id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lookup Team\'s Contact documents via filtered and paginated KnLookupRequest
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupTeamContacts: async (body: KnLookupRequest2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('lookupTeamContacts', 'body', body)
            const localVarPath = `/prospect/team/lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return User\'s Contact documents via paginated KnPageRequest
         * @param {KnPageRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageContacts: async (body: KnPageRequest2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('pageContacts', 'body', body)
            const localVarPath = `/prospect/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return Team\'s Contact documents via paginated KnPageRequest
         * @param {KnPageRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageTeamContacts: async (body: KnPageRequest2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('pageTeamContacts', 'body', body)
            const localVarPath = `/prospect/team/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch update an existing Contact document via DTO KnContactRequest
         * @param {KnContactUpdate} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchContact: async (request: KnContactUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('patchContact', 'request', request)
            const localVarPath = `/prospect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch update via Data Import an existing Contact document via DTO KnContact
         * @param {KnContact2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchContactViaDataImport: async (request: KnContact2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('patchContactViaDataImport', 'request', request)
            const localVarPath = `/prospect/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put update an existing Contact document via DTO KnContactRequest
         * @param {KnContactRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContact: async (body: KnContactRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('putContact', 'body', body)
            const localVarPath = `/prospect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Soft-Delete an existing Contact document by ContactId (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        softDeleteContact: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('softDeleteContact', 'id', id)
            const localVarPath = `/prospect/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProspectContactApi - functional programming interface
 * @export
 */
export const ProspectContactApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProspectContactApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new Contact document via DTO KnCreateContactRequest
         * @param {KnCreateContactRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContact(request: KnCreateContactRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDocumentResponseKnContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createContact(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectContactApi.createContact']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create via Data Import new Contact document via DTO KnContact
         * @param {KnCreateDataImportContactRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContactViaDataImport(request: KnCreateDataImportContactRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDocumentResponseKnContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createContactViaDataImport(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectContactApi.createContactViaDataImport']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get User Contact documents by Contact\'s \'lastName\' via DTO KnContactLastNameRequest
         * @param {KnContactLastNameSearchRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTeamContactsByLastName(body: KnContactLastNameSearchRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnContactLastNameSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTeamContactsByLastName(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectContactApi.getAllTeamContactsByLastName']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get a User\'s Contact document by ContactId (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContactById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDocumentResponseKnContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContactById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectContactApi.getContactById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get All User Contact documents by UserId via SecurityContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContacts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDocumentListResponseKnContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContacts(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectContactApi.getContacts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get a Team\'s Contact document by ContactId (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamContactByContactId(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDocumentResponseKnTeamContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamContactByContactId(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectContactApi.getTeamContactByContactId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Team Contacts count by TeamId
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamContactSummary(teamId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnTeamSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamContactSummary(teamId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectContactApi.getTeamContactSummary']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Team Contact documents by UserId via SecurityContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamContacts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDocumentListResponseKnTeamContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamContacts(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectContactApi.getTeamContacts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Hard-Delete an existing Contact document by ContactId (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hardDeleteContact(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hardDeleteContact(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectContactApi.hardDeleteContact']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Keyword search User\'s Contact documents via paginated and search-termed DTO KnKeywordSearchRequest
         * @param {KnKeywordSearchRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async keywordSearchContacts(request: KnKeywordSearchRequest2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnKeywordSearchResponseKnContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.keywordSearchContacts(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectContactApi.keywordSearchContacts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Keyword search Team\'s Contact documents via paginated and search-termed DTO KnKeywordSearchRequest
         * @param {KnKeywordSearchRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async keywordSearchTeamContacts(request: KnKeywordSearchRequest2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnKeywordSearchResponseKnTeamContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.keywordSearchTeamContacts(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectContactApi.keywordSearchTeamContacts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Lookup User\'s Contact documents via filtered and paginated KnLookupRequest
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lookupContacts(body: KnLookupRequest2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnLookupResponseKnContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lookupContacts(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectContactApi.lookupContacts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get contacts by leadSourceId and filters applied
         * @param {KnLeadSourceLookupRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lookupContactsByContactNameAndLeadSourceId(body: KnLeadSourceLookupRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnLookupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lookupContactsByContactNameAndLeadSourceId(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectContactApi.lookupContactsByContactNameAndLeadSourceId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Lookup Contact documents via DTO KnContactIdListRequest
         * @param {KnContactIdListRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lookupContactsByIdList(request: KnContactIdListRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDocumentListResponseKnContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lookupContactsByIdList(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectContactApi.lookupContactsByIdList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Lookup Team\'s Contact documents via filtered and paginated KnLookupRequest
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lookupTeamContacts(body: KnLookupRequest2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnLookupResponseKnTeamContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lookupTeamContacts(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectContactApi.lookupTeamContacts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Return User\'s Contact documents via paginated KnPageRequest
         * @param {KnPageRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageContacts(body: KnPageRequest2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnPageResponseKnContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageContacts(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectContactApi.pageContacts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Return Team\'s Contact documents via paginated KnPageRequest
         * @param {KnPageRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageTeamContacts(body: KnPageRequest2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnPageResponseKnTeamContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageTeamContacts(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectContactApi.pageTeamContacts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Patch update an existing Contact document via DTO KnContactRequest
         * @param {KnContactUpdate} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchContact(request: KnContactUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDocumentResponseKnContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchContact(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectContactApi.patchContact']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Patch update via Data Import an existing Contact document via DTO KnContact
         * @param {KnContact2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchContactViaDataImport(request: KnContact2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDocumentResponseKnContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchContactViaDataImport(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectContactApi.patchContactViaDataImport']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Put update an existing Contact document via DTO KnContactRequest
         * @param {KnContactRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putContact(body: KnContactRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDocumentResponseKnContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putContact(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectContactApi.putContact']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Soft-Delete an existing Contact document by ContactId (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async softDeleteContact(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.softDeleteContact(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectContactApi.softDeleteContact']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ProspectContactApi - factory interface
 * @export
 */
export const ProspectContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProspectContactApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new Contact document via DTO KnCreateContactRequest
         * @param {KnCreateContactRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContact(request: KnCreateContactRequest, options?: any): AxiosPromise<KnDocumentResponseKnContact> {
            return localVarFp.createContact(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create via Data Import new Contact document via DTO KnContact
         * @param {KnCreateDataImportContactRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContactViaDataImport(request: KnCreateDataImportContactRequest, options?: any): AxiosPromise<KnDocumentResponseKnContact> {
            return localVarFp.createContactViaDataImport(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User Contact documents by Contact\'s \'lastName\' via DTO KnContactLastNameRequest
         * @param {KnContactLastNameSearchRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTeamContactsByLastName(body: KnContactLastNameSearchRequest, options?: any): AxiosPromise<KnContactLastNameSearchResponse> {
            return localVarFp.getAllTeamContactsByLastName(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a User\'s Contact document by ContactId (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactById(id: string, options?: any): AxiosPromise<KnDocumentResponseKnContact> {
            return localVarFp.getContactById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All User Contact documents by UserId via SecurityContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContacts(options?: any): AxiosPromise<KnDocumentListResponseKnContact> {
            return localVarFp.getContacts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a Team\'s Contact document by ContactId (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamContactByContactId(id: string, options?: any): AxiosPromise<KnDocumentResponseKnTeamContact> {
            return localVarFp.getTeamContactByContactId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Team Contacts count by TeamId
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamContactSummary(teamId: string, options?: any): AxiosPromise<KnTeamSummary> {
            return localVarFp.getTeamContactSummary(teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Team Contact documents by UserId via SecurityContext
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamContacts(options?: any): AxiosPromise<KnDocumentListResponseKnTeamContact> {
            return localVarFp.getTeamContacts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Hard-Delete an existing Contact document by ContactId (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hardDeleteContact(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.hardDeleteContact(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Keyword search User\'s Contact documents via paginated and search-termed DTO KnKeywordSearchRequest
         * @param {KnKeywordSearchRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keywordSearchContacts(request: KnKeywordSearchRequest2, options?: any): AxiosPromise<KnKeywordSearchResponseKnContact> {
            return localVarFp.keywordSearchContacts(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Keyword search Team\'s Contact documents via paginated and search-termed DTO KnKeywordSearchRequest
         * @param {KnKeywordSearchRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keywordSearchTeamContacts(request: KnKeywordSearchRequest2, options?: any): AxiosPromise<KnKeywordSearchResponseKnTeamContact> {
            return localVarFp.keywordSearchTeamContacts(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lookup User\'s Contact documents via filtered and paginated KnLookupRequest
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupContacts(body: KnLookupRequest2, options?: any): AxiosPromise<KnLookupResponseKnContact> {
            return localVarFp.lookupContacts(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get contacts by leadSourceId and filters applied
         * @param {KnLeadSourceLookupRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupContactsByContactNameAndLeadSourceId(body: KnLeadSourceLookupRequest, options?: any): AxiosPromise<KnLookupResponse> {
            return localVarFp.lookupContactsByContactNameAndLeadSourceId(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lookup Contact documents via DTO KnContactIdListRequest
         * @param {KnContactIdListRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupContactsByIdList(request: KnContactIdListRequest, options?: any): AxiosPromise<KnDocumentListResponseKnContact> {
            return localVarFp.lookupContactsByIdList(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lookup Team\'s Contact documents via filtered and paginated KnLookupRequest
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupTeamContacts(body: KnLookupRequest2, options?: any): AxiosPromise<KnLookupResponseKnTeamContact> {
            return localVarFp.lookupTeamContacts(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return User\'s Contact documents via paginated KnPageRequest
         * @param {KnPageRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageContacts(body: KnPageRequest2, options?: any): AxiosPromise<KnPageResponseKnContact> {
            return localVarFp.pageContacts(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return Team\'s Contact documents via paginated KnPageRequest
         * @param {KnPageRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageTeamContacts(body: KnPageRequest2, options?: any): AxiosPromise<KnPageResponseKnTeamContact> {
            return localVarFp.pageTeamContacts(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch update an existing Contact document via DTO KnContactRequest
         * @param {KnContactUpdate} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchContact(request: KnContactUpdate, options?: any): AxiosPromise<KnDocumentResponseKnContact> {
            return localVarFp.patchContact(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch update via Data Import an existing Contact document via DTO KnContact
         * @param {KnContact2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchContactViaDataImport(request: KnContact2, options?: any): AxiosPromise<KnDocumentResponseKnContact> {
            return localVarFp.patchContactViaDataImport(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put update an existing Contact document via DTO KnContactRequest
         * @param {KnContactRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContact(body: KnContactRequest, options?: any): AxiosPromise<KnDocumentResponseKnContact> {
            return localVarFp.putContact(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Soft-Delete an existing Contact document by ContactId (\'id\') via Path Variable
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        softDeleteContact(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.softDeleteContact(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProspectContactApi - interface
 * @export
 * @interface ProspectContactApi
 */
export interface ProspectContactApiInterface {
    /**
     * 
     * @summary Create new Contact document via DTO KnCreateContactRequest
     * @param {KnCreateContactRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApiInterface
     */
    createContact(request: KnCreateContactRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnDocumentResponseKnContact>;

    /**
     * 
     * @summary Create via Data Import new Contact document via DTO KnContact
     * @param {KnCreateDataImportContactRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApiInterface
     */
    createContactViaDataImport(request: KnCreateDataImportContactRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnDocumentResponseKnContact>;

    /**
     * 
     * @summary Get User Contact documents by Contact\'s \'lastName\' via DTO KnContactLastNameRequest
     * @param {KnContactLastNameSearchRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApiInterface
     */
    getAllTeamContactsByLastName(body: KnContactLastNameSearchRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnContactLastNameSearchResponse>;

    /**
     * 
     * @summary Get a User\'s Contact document by ContactId (\'id\') via Path Variable
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApiInterface
     */
    getContactById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<KnDocumentResponseKnContact>;

    /**
     * 
     * @summary Get All User Contact documents by UserId via SecurityContext
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApiInterface
     */
    getContacts(options?: RawAxiosRequestConfig): AxiosPromise<KnDocumentListResponseKnContact>;

    /**
     * 
     * @summary Get a Team\'s Contact document by ContactId (\'id\') via Path Variable
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApiInterface
     */
    getTeamContactByContactId(id: string, options?: RawAxiosRequestConfig): AxiosPromise<KnDocumentResponseKnTeamContact>;

    /**
     * 
     * @summary Get Team Contacts count by TeamId
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApiInterface
     */
    getTeamContactSummary(teamId: string, options?: RawAxiosRequestConfig): AxiosPromise<KnTeamSummary>;

    /**
     * 
     * @summary Get All Team Contact documents by UserId via SecurityContext
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApiInterface
     */
    getTeamContacts(options?: RawAxiosRequestConfig): AxiosPromise<KnDocumentListResponseKnTeamContact>;

    /**
     * 
     * @summary Hard-Delete an existing Contact document by ContactId (\'id\') via Path Variable
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApiInterface
     */
    hardDeleteContact(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Keyword search User\'s Contact documents via paginated and search-termed DTO KnKeywordSearchRequest
     * @param {KnKeywordSearchRequest2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApiInterface
     */
    keywordSearchContacts(request: KnKeywordSearchRequest2, options?: RawAxiosRequestConfig): AxiosPromise<KnKeywordSearchResponseKnContact>;

    /**
     * 
     * @summary Keyword search Team\'s Contact documents via paginated and search-termed DTO KnKeywordSearchRequest
     * @param {KnKeywordSearchRequest2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApiInterface
     */
    keywordSearchTeamContacts(request: KnKeywordSearchRequest2, options?: RawAxiosRequestConfig): AxiosPromise<KnKeywordSearchResponseKnTeamContact>;

    /**
     * 
     * @summary Lookup User\'s Contact documents via filtered and paginated KnLookupRequest
     * @param {KnLookupRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApiInterface
     */
    lookupContacts(body: KnLookupRequest2, options?: RawAxiosRequestConfig): AxiosPromise<KnLookupResponseKnContact>;

    /**
     * 
     * @summary Get contacts by leadSourceId and filters applied
     * @param {KnLeadSourceLookupRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApiInterface
     */
    lookupContactsByContactNameAndLeadSourceId(body: KnLeadSourceLookupRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnLookupResponse>;

    /**
     * 
     * @summary Lookup Contact documents via DTO KnContactIdListRequest
     * @param {KnContactIdListRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApiInterface
     */
    lookupContactsByIdList(request: KnContactIdListRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnDocumentListResponseKnContact>;

    /**
     * 
     * @summary Lookup Team\'s Contact documents via filtered and paginated KnLookupRequest
     * @param {KnLookupRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApiInterface
     */
    lookupTeamContacts(body: KnLookupRequest2, options?: RawAxiosRequestConfig): AxiosPromise<KnLookupResponseKnTeamContact>;

    /**
     * 
     * @summary Return User\'s Contact documents via paginated KnPageRequest
     * @param {KnPageRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApiInterface
     */
    pageContacts(body: KnPageRequest2, options?: RawAxiosRequestConfig): AxiosPromise<KnPageResponseKnContact>;

    /**
     * 
     * @summary Return Team\'s Contact documents via paginated KnPageRequest
     * @param {KnPageRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApiInterface
     */
    pageTeamContacts(body: KnPageRequest2, options?: RawAxiosRequestConfig): AxiosPromise<KnPageResponseKnTeamContact>;

    /**
     * 
     * @summary Patch update an existing Contact document via DTO KnContactRequest
     * @param {KnContactUpdate} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApiInterface
     */
    patchContact(request: KnContactUpdate, options?: RawAxiosRequestConfig): AxiosPromise<KnDocumentResponseKnContact>;

    /**
     * 
     * @summary Patch update via Data Import an existing Contact document via DTO KnContact
     * @param {KnContact2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApiInterface
     */
    patchContactViaDataImport(request: KnContact2, options?: RawAxiosRequestConfig): AxiosPromise<KnDocumentResponseKnContact>;

    /**
     * 
     * @summary Put update an existing Contact document via DTO KnContactRequest
     * @param {KnContactRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApiInterface
     */
    putContact(body: KnContactRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnDocumentResponseKnContact>;

    /**
     * 
     * @summary Soft-Delete an existing Contact document by ContactId (\'id\') via Path Variable
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApiInterface
     */
    softDeleteContact(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * ProspectContactApi - object-oriented interface
 * @export
 * @class ProspectContactApi
 * @extends {BaseAPI}
 */
export class ProspectContactApi extends BaseAPI implements ProspectContactApiInterface {
    /**
     * 
     * @summary Create new Contact document via DTO KnCreateContactRequest
     * @param {KnCreateContactRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApi
     */
    public createContact(request: KnCreateContactRequest, options?: RawAxiosRequestConfig) {
        return ProspectContactApiFp(this.configuration).createContact(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create via Data Import new Contact document via DTO KnContact
     * @param {KnCreateDataImportContactRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApi
     */
    public createContactViaDataImport(request: KnCreateDataImportContactRequest, options?: RawAxiosRequestConfig) {
        return ProspectContactApiFp(this.configuration).createContactViaDataImport(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User Contact documents by Contact\'s \'lastName\' via DTO KnContactLastNameRequest
     * @param {KnContactLastNameSearchRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApi
     */
    public getAllTeamContactsByLastName(body: KnContactLastNameSearchRequest, options?: RawAxiosRequestConfig) {
        return ProspectContactApiFp(this.configuration).getAllTeamContactsByLastName(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a User\'s Contact document by ContactId (\'id\') via Path Variable
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApi
     */
    public getContactById(id: string, options?: RawAxiosRequestConfig) {
        return ProspectContactApiFp(this.configuration).getContactById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All User Contact documents by UserId via SecurityContext
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApi
     */
    public getContacts(options?: RawAxiosRequestConfig) {
        return ProspectContactApiFp(this.configuration).getContacts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a Team\'s Contact document by ContactId (\'id\') via Path Variable
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApi
     */
    public getTeamContactByContactId(id: string, options?: RawAxiosRequestConfig) {
        return ProspectContactApiFp(this.configuration).getTeamContactByContactId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Team Contacts count by TeamId
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApi
     */
    public getTeamContactSummary(teamId: string, options?: RawAxiosRequestConfig) {
        return ProspectContactApiFp(this.configuration).getTeamContactSummary(teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Team Contact documents by UserId via SecurityContext
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApi
     */
    public getTeamContacts(options?: RawAxiosRequestConfig) {
        return ProspectContactApiFp(this.configuration).getTeamContacts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Hard-Delete an existing Contact document by ContactId (\'id\') via Path Variable
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApi
     */
    public hardDeleteContact(id: string, options?: RawAxiosRequestConfig) {
        return ProspectContactApiFp(this.configuration).hardDeleteContact(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Keyword search User\'s Contact documents via paginated and search-termed DTO KnKeywordSearchRequest
     * @param {KnKeywordSearchRequest2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApi
     */
    public keywordSearchContacts(request: KnKeywordSearchRequest2, options?: RawAxiosRequestConfig) {
        return ProspectContactApiFp(this.configuration).keywordSearchContacts(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Keyword search Team\'s Contact documents via paginated and search-termed DTO KnKeywordSearchRequest
     * @param {KnKeywordSearchRequest2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApi
     */
    public keywordSearchTeamContacts(request: KnKeywordSearchRequest2, options?: RawAxiosRequestConfig) {
        return ProspectContactApiFp(this.configuration).keywordSearchTeamContacts(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lookup User\'s Contact documents via filtered and paginated KnLookupRequest
     * @param {KnLookupRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApi
     */
    public lookupContacts(body: KnLookupRequest2, options?: RawAxiosRequestConfig) {
        return ProspectContactApiFp(this.configuration).lookupContacts(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get contacts by leadSourceId and filters applied
     * @param {KnLeadSourceLookupRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApi
     */
    public lookupContactsByContactNameAndLeadSourceId(body: KnLeadSourceLookupRequest, options?: RawAxiosRequestConfig) {
        return ProspectContactApiFp(this.configuration).lookupContactsByContactNameAndLeadSourceId(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lookup Contact documents via DTO KnContactIdListRequest
     * @param {KnContactIdListRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApi
     */
    public lookupContactsByIdList(request: KnContactIdListRequest, options?: RawAxiosRequestConfig) {
        return ProspectContactApiFp(this.configuration).lookupContactsByIdList(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lookup Team\'s Contact documents via filtered and paginated KnLookupRequest
     * @param {KnLookupRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApi
     */
    public lookupTeamContacts(body: KnLookupRequest2, options?: RawAxiosRequestConfig) {
        return ProspectContactApiFp(this.configuration).lookupTeamContacts(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return User\'s Contact documents via paginated KnPageRequest
     * @param {KnPageRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApi
     */
    public pageContacts(body: KnPageRequest2, options?: RawAxiosRequestConfig) {
        return ProspectContactApiFp(this.configuration).pageContacts(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return Team\'s Contact documents via paginated KnPageRequest
     * @param {KnPageRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApi
     */
    public pageTeamContacts(body: KnPageRequest2, options?: RawAxiosRequestConfig) {
        return ProspectContactApiFp(this.configuration).pageTeamContacts(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch update an existing Contact document via DTO KnContactRequest
     * @param {KnContactUpdate} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApi
     */
    public patchContact(request: KnContactUpdate, options?: RawAxiosRequestConfig) {
        return ProspectContactApiFp(this.configuration).patchContact(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch update via Data Import an existing Contact document via DTO KnContact
     * @param {KnContact2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApi
     */
    public patchContactViaDataImport(request: KnContact2, options?: RawAxiosRequestConfig) {
        return ProspectContactApiFp(this.configuration).patchContactViaDataImport(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put update an existing Contact document via DTO KnContactRequest
     * @param {KnContactRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApi
     */
    public putContact(body: KnContactRequest, options?: RawAxiosRequestConfig) {
        return ProspectContactApiFp(this.configuration).putContact(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Soft-Delete an existing Contact document by ContactId (\'id\') via Path Variable
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectContactApi
     */
    public softDeleteContact(id: string, options?: RawAxiosRequestConfig) {
        return ProspectContactApiFp(this.configuration).softDeleteContact(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProspectFinderApi - axios parameter creator
 * @export
 */
export const ProspectFinderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Lookup and apply advanced filtering to KnBqContacts in BQ Data Warehouse via paginated KnProspectAdvancedFilterRequest
         * @param {KnProspectAdvancedFilterRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedFilterLookupKnProspects: async (request: KnProspectAdvancedFilterRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('advancedFilterLookupKnProspects', 'request', request)
            const localVarPath = `/prospect/finder/lookup/advanced`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new Contact document via DTO KnBqContactRequest
         * @param {KnBqContactRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContactFromProspectFinder: async (request: KnBqContactRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('createContactFromProspectFinder', 'request', request)
            const localVarPath = `/prospect/finder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Keyword search ProspectFinder BqCompany records \'name\' field via search-termed DTO KnBqCompanyNameSearchRequest
         * @param {KnBqKeywordSearchTermRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keywordSearchKnCompaniesByName: async (request: KnBqKeywordSearchTermRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('keywordSearchKnCompaniesByName', 'request', request)
            const localVarPath = `/prospect/finder/company/name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Keyword search KnBqContacts in BQ Data Warehouse via paginated KnBqKeywordSearchRequest
         * @param {KnBqKeywordSearchTermRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keywordSearchKnProspects: async (request: KnBqKeywordSearchTermRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('keywordSearchKnProspects', 'request', request)
            const localVarPath = `/prospect/finder/keyword/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Keyword search the most common KnAddress values for KnBqContact/BqEmployee present records via paginated DTO KnBqCategoryKeywordSearchRequest
         * @param {KnBqCategoryKeywordSearchRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keywordSearchMostCommonKnBqContactAddresses: async (request: KnBqCategoryKeywordSearchRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('keywordSearchMostCommonKnBqContactAddresses', 'request', request)
            const localVarPath = `/prospect/finder/category/address/keyword/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Keyword search the most common JobTitle String values for KnBqContact/BqEmployee present records via paginated DTO KnBqCategoryKeywordSearchRequest
         * @param {KnBqCategoryKeywordSearchRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keywordSearchMostCommonKnBqContactJobTitles: async (request: KnBqCategoryKeywordSearchRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('keywordSearchMostCommonKnBqContactJobTitles', 'request', request)
            const localVarPath = `/prospect/finder/category/job-title/keyword/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lookup KnProspects in BQ Data Warehouse via filtered and paginated KnLookupRequest
         * @param {KnBqLookupRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupKnProspects: async (request: KnBqLookupRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('lookupKnProspects', 'request', request)
            const localVarPath = `/prospect/finder/lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return the most common KnAddress values for KnBqContact/BqEmployee present records via paginated DTO KnBqCategoryPageRequest
         * @param {KnBqCategoryPageRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageMostCommonKnBqContactAddresses: async (request: KnBqCategoryPageRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('pageMostCommonKnBqContactAddresses', 'request', request)
            const localVarPath = `/prospect/finder/category/address/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return the most common JobTitle String values for KnBqContact/BqEmployee present records via paginated DTO KnBqCategoryPageRequest
         * @param {KnBqCategoryPageRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageMostCommonKnBqContactJobTitles: async (request: KnBqCategoryPageRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('pageMostCommonKnBqContactJobTitles', 'request', request)
            const localVarPath = `/prospect/finder/category/job-title/page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProspectFinderApi - functional programming interface
 * @export
 */
export const ProspectFinderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProspectFinderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Lookup and apply advanced filtering to KnBqContacts in BQ Data Warehouse via paginated KnProspectAdvancedFilterRequest
         * @param {KnProspectAdvancedFilterRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advancedFilterLookupKnProspects(request: KnProspectAdvancedFilterRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnBqLookupResponseKnBqContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advancedFilterLookupKnProspects(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectFinderApi.advancedFilterLookupKnProspects']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create new Contact document via DTO KnBqContactRequest
         * @param {KnBqContactRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContactFromProspectFinder(request: KnBqContactRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnDocumentResponseKnContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createContactFromProspectFinder(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectFinderApi.createContactFromProspectFinder']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Keyword search ProspectFinder BqCompany records \'name\' field via search-termed DTO KnBqCompanyNameSearchRequest
         * @param {KnBqKeywordSearchTermRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async keywordSearchKnCompaniesByName(request: KnBqKeywordSearchTermRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnBqKeywordSearchTermResponseKnBqCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.keywordSearchKnCompaniesByName(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectFinderApi.keywordSearchKnCompaniesByName']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Keyword search KnBqContacts in BQ Data Warehouse via paginated KnBqKeywordSearchRequest
         * @param {KnBqKeywordSearchTermRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async keywordSearchKnProspects(request: KnBqKeywordSearchTermRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnBqKeywordSearchTermResponseKnBqContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.keywordSearchKnProspects(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectFinderApi.keywordSearchKnProspects']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Keyword search the most common KnAddress values for KnBqContact/BqEmployee present records via paginated DTO KnBqCategoryKeywordSearchRequest
         * @param {KnBqCategoryKeywordSearchRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async keywordSearchMostCommonKnBqContactAddresses(request: KnBqCategoryKeywordSearchRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnBqCategoryKeywordSearchResponseKnAddress>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.keywordSearchMostCommonKnBqContactAddresses(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectFinderApi.keywordSearchMostCommonKnBqContactAddresses']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Keyword search the most common JobTitle String values for KnBqContact/BqEmployee present records via paginated DTO KnBqCategoryKeywordSearchRequest
         * @param {KnBqCategoryKeywordSearchRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async keywordSearchMostCommonKnBqContactJobTitles(request: KnBqCategoryKeywordSearchRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnBqCategoryKeywordSearchResponseString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.keywordSearchMostCommonKnBqContactJobTitles(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectFinderApi.keywordSearchMostCommonKnBqContactJobTitles']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Lookup KnProspects in BQ Data Warehouse via filtered and paginated KnLookupRequest
         * @param {KnBqLookupRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lookupKnProspects(request: KnBqLookupRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnBqLookupResponseKnBqContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lookupKnProspects(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectFinderApi.lookupKnProspects']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Return the most common KnAddress values for KnBqContact/BqEmployee present records via paginated DTO KnBqCategoryPageRequest
         * @param {KnBqCategoryPageRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageMostCommonKnBqContactAddresses(request: KnBqCategoryPageRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnBqCategoryPageResponseKnAddress>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageMostCommonKnBqContactAddresses(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectFinderApi.pageMostCommonKnBqContactAddresses']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Return the most common JobTitle String values for KnBqContact/BqEmployee present records via paginated DTO KnBqCategoryPageRequest
         * @param {KnBqCategoryPageRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageMostCommonKnBqContactJobTitles(request: KnBqCategoryPageRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnBqCategoryPageResponseString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageMostCommonKnBqContactJobTitles(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectFinderApi.pageMostCommonKnBqContactJobTitles']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ProspectFinderApi - factory interface
 * @export
 */
export const ProspectFinderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProspectFinderApiFp(configuration)
    return {
        /**
         * 
         * @summary Lookup and apply advanced filtering to KnBqContacts in BQ Data Warehouse via paginated KnProspectAdvancedFilterRequest
         * @param {KnProspectAdvancedFilterRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedFilterLookupKnProspects(request: KnProspectAdvancedFilterRequest, options?: any): AxiosPromise<KnBqLookupResponseKnBqContact> {
            return localVarFp.advancedFilterLookupKnProspects(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new Contact document via DTO KnBqContactRequest
         * @param {KnBqContactRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContactFromProspectFinder(request: KnBqContactRequest, options?: any): AxiosPromise<KnDocumentResponseKnContact> {
            return localVarFp.createContactFromProspectFinder(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Keyword search ProspectFinder BqCompany records \'name\' field via search-termed DTO KnBqCompanyNameSearchRequest
         * @param {KnBqKeywordSearchTermRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keywordSearchKnCompaniesByName(request: KnBqKeywordSearchTermRequest, options?: any): AxiosPromise<KnBqKeywordSearchTermResponseKnBqCompany> {
            return localVarFp.keywordSearchKnCompaniesByName(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Keyword search KnBqContacts in BQ Data Warehouse via paginated KnBqKeywordSearchRequest
         * @param {KnBqKeywordSearchTermRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keywordSearchKnProspects(request: KnBqKeywordSearchTermRequest, options?: any): AxiosPromise<KnBqKeywordSearchTermResponseKnBqContact> {
            return localVarFp.keywordSearchKnProspects(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Keyword search the most common KnAddress values for KnBqContact/BqEmployee present records via paginated DTO KnBqCategoryKeywordSearchRequest
         * @param {KnBqCategoryKeywordSearchRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keywordSearchMostCommonKnBqContactAddresses(request: KnBqCategoryKeywordSearchRequest, options?: any): AxiosPromise<KnBqCategoryKeywordSearchResponseKnAddress> {
            return localVarFp.keywordSearchMostCommonKnBqContactAddresses(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Keyword search the most common JobTitle String values for KnBqContact/BqEmployee present records via paginated DTO KnBqCategoryKeywordSearchRequest
         * @param {KnBqCategoryKeywordSearchRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keywordSearchMostCommonKnBqContactJobTitles(request: KnBqCategoryKeywordSearchRequest, options?: any): AxiosPromise<KnBqCategoryKeywordSearchResponseString> {
            return localVarFp.keywordSearchMostCommonKnBqContactJobTitles(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lookup KnProspects in BQ Data Warehouse via filtered and paginated KnLookupRequest
         * @param {KnBqLookupRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupKnProspects(request: KnBqLookupRequest, options?: any): AxiosPromise<KnBqLookupResponseKnBqContact> {
            return localVarFp.lookupKnProspects(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return the most common KnAddress values for KnBqContact/BqEmployee present records via paginated DTO KnBqCategoryPageRequest
         * @param {KnBqCategoryPageRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageMostCommonKnBqContactAddresses(request: KnBqCategoryPageRequest, options?: any): AxiosPromise<KnBqCategoryPageResponseKnAddress> {
            return localVarFp.pageMostCommonKnBqContactAddresses(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return the most common JobTitle String values for KnBqContact/BqEmployee present records via paginated DTO KnBqCategoryPageRequest
         * @param {KnBqCategoryPageRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageMostCommonKnBqContactJobTitles(request: KnBqCategoryPageRequest, options?: any): AxiosPromise<KnBqCategoryPageResponseString> {
            return localVarFp.pageMostCommonKnBqContactJobTitles(request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProspectFinderApi - interface
 * @export
 * @interface ProspectFinderApi
 */
export interface ProspectFinderApiInterface {
    /**
     * 
     * @summary Lookup and apply advanced filtering to KnBqContacts in BQ Data Warehouse via paginated KnProspectAdvancedFilterRequest
     * @param {KnProspectAdvancedFilterRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectFinderApiInterface
     */
    advancedFilterLookupKnProspects(request: KnProspectAdvancedFilterRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnBqLookupResponseKnBqContact>;

    /**
     * 
     * @summary Create new Contact document via DTO KnBqContactRequest
     * @param {KnBqContactRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectFinderApiInterface
     */
    createContactFromProspectFinder(request: KnBqContactRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnDocumentResponseKnContact>;

    /**
     * 
     * @summary Keyword search ProspectFinder BqCompany records \'name\' field via search-termed DTO KnBqCompanyNameSearchRequest
     * @param {KnBqKeywordSearchTermRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectFinderApiInterface
     */
    keywordSearchKnCompaniesByName(request: KnBqKeywordSearchTermRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnBqKeywordSearchTermResponseKnBqCompany>;

    /**
     * 
     * @summary Keyword search KnBqContacts in BQ Data Warehouse via paginated KnBqKeywordSearchRequest
     * @param {KnBqKeywordSearchTermRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectFinderApiInterface
     */
    keywordSearchKnProspects(request: KnBqKeywordSearchTermRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnBqKeywordSearchTermResponseKnBqContact>;

    /**
     * 
     * @summary Keyword search the most common KnAddress values for KnBqContact/BqEmployee present records via paginated DTO KnBqCategoryKeywordSearchRequest
     * @param {KnBqCategoryKeywordSearchRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectFinderApiInterface
     */
    keywordSearchMostCommonKnBqContactAddresses(request: KnBqCategoryKeywordSearchRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnBqCategoryKeywordSearchResponseKnAddress>;

    /**
     * 
     * @summary Keyword search the most common JobTitle String values for KnBqContact/BqEmployee present records via paginated DTO KnBqCategoryKeywordSearchRequest
     * @param {KnBqCategoryKeywordSearchRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectFinderApiInterface
     */
    keywordSearchMostCommonKnBqContactJobTitles(request: KnBqCategoryKeywordSearchRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnBqCategoryKeywordSearchResponseString>;

    /**
     * 
     * @summary Lookup KnProspects in BQ Data Warehouse via filtered and paginated KnLookupRequest
     * @param {KnBqLookupRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectFinderApiInterface
     */
    lookupKnProspects(request: KnBqLookupRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnBqLookupResponseKnBqContact>;

    /**
     * 
     * @summary Return the most common KnAddress values for KnBqContact/BqEmployee present records via paginated DTO KnBqCategoryPageRequest
     * @param {KnBqCategoryPageRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectFinderApiInterface
     */
    pageMostCommonKnBqContactAddresses(request: KnBqCategoryPageRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnBqCategoryPageResponseKnAddress>;

    /**
     * 
     * @summary Return the most common JobTitle String values for KnBqContact/BqEmployee present records via paginated DTO KnBqCategoryPageRequest
     * @param {KnBqCategoryPageRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectFinderApiInterface
     */
    pageMostCommonKnBqContactJobTitles(request: KnBqCategoryPageRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnBqCategoryPageResponseString>;

}

/**
 * ProspectFinderApi - object-oriented interface
 * @export
 * @class ProspectFinderApi
 * @extends {BaseAPI}
 */
export class ProspectFinderApi extends BaseAPI implements ProspectFinderApiInterface {
    /**
     * 
     * @summary Lookup and apply advanced filtering to KnBqContacts in BQ Data Warehouse via paginated KnProspectAdvancedFilterRequest
     * @param {KnProspectAdvancedFilterRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectFinderApi
     */
    public advancedFilterLookupKnProspects(request: KnProspectAdvancedFilterRequest, options?: RawAxiosRequestConfig) {
        return ProspectFinderApiFp(this.configuration).advancedFilterLookupKnProspects(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new Contact document via DTO KnBqContactRequest
     * @param {KnBqContactRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectFinderApi
     */
    public createContactFromProspectFinder(request: KnBqContactRequest, options?: RawAxiosRequestConfig) {
        return ProspectFinderApiFp(this.configuration).createContactFromProspectFinder(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Keyword search ProspectFinder BqCompany records \'name\' field via search-termed DTO KnBqCompanyNameSearchRequest
     * @param {KnBqKeywordSearchTermRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectFinderApi
     */
    public keywordSearchKnCompaniesByName(request: KnBqKeywordSearchTermRequest, options?: RawAxiosRequestConfig) {
        return ProspectFinderApiFp(this.configuration).keywordSearchKnCompaniesByName(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Keyword search KnBqContacts in BQ Data Warehouse via paginated KnBqKeywordSearchRequest
     * @param {KnBqKeywordSearchTermRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectFinderApi
     */
    public keywordSearchKnProspects(request: KnBqKeywordSearchTermRequest, options?: RawAxiosRequestConfig) {
        return ProspectFinderApiFp(this.configuration).keywordSearchKnProspects(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Keyword search the most common KnAddress values for KnBqContact/BqEmployee present records via paginated DTO KnBqCategoryKeywordSearchRequest
     * @param {KnBqCategoryKeywordSearchRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectFinderApi
     */
    public keywordSearchMostCommonKnBqContactAddresses(request: KnBqCategoryKeywordSearchRequest, options?: RawAxiosRequestConfig) {
        return ProspectFinderApiFp(this.configuration).keywordSearchMostCommonKnBqContactAddresses(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Keyword search the most common JobTitle String values for KnBqContact/BqEmployee present records via paginated DTO KnBqCategoryKeywordSearchRequest
     * @param {KnBqCategoryKeywordSearchRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectFinderApi
     */
    public keywordSearchMostCommonKnBqContactJobTitles(request: KnBqCategoryKeywordSearchRequest, options?: RawAxiosRequestConfig) {
        return ProspectFinderApiFp(this.configuration).keywordSearchMostCommonKnBqContactJobTitles(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lookup KnProspects in BQ Data Warehouse via filtered and paginated KnLookupRequest
     * @param {KnBqLookupRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectFinderApi
     */
    public lookupKnProspects(request: KnBqLookupRequest, options?: RawAxiosRequestConfig) {
        return ProspectFinderApiFp(this.configuration).lookupKnProspects(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return the most common KnAddress values for KnBqContact/BqEmployee present records via paginated DTO KnBqCategoryPageRequest
     * @param {KnBqCategoryPageRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectFinderApi
     */
    public pageMostCommonKnBqContactAddresses(request: KnBqCategoryPageRequest, options?: RawAxiosRequestConfig) {
        return ProspectFinderApiFp(this.configuration).pageMostCommonKnBqContactAddresses(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return the most common JobTitle String values for KnBqContact/BqEmployee present records via paginated DTO KnBqCategoryPageRequest
     * @param {KnBqCategoryPageRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectFinderApi
     */
    public pageMostCommonKnBqContactJobTitles(request: KnBqCategoryPageRequest, options?: RawAxiosRequestConfig) {
        return ProspectFinderApiFp(this.configuration).pageMostCommonKnBqContactJobTitles(request, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProspectOauthApi - axios parameter creator
 * @export
 */
export const ProspectOauthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Post request to generate and return a Google OAUTH Authorization URL
         * @param {KnAuthorizeUrlRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prospectOauthGoogleAuthorizeUrl: async (request: KnAuthorizeUrlRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('prospectOauthGoogleAuthorizeUrl', 'request', request)
            const localVarPath = `/prospect/oauth/google/authorize-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary POST request that removes a User\'s Google account authorizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prospectOauthGoogleDeauthorize: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/prospect/oauth/google/deauthorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary POST request that receives a KnTokenRequest, performs the exchange, and then persists the results
         * @param {KnTokenRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prospectOauthGoogleTokenExchange: async (request: KnTokenRequest2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('prospectOauthGoogleTokenExchange', 'request', request)
            const localVarPath = `/prospect/oauth/google/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProspectOauthApi - functional programming interface
 * @export
 */
export const ProspectOauthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProspectOauthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Post request to generate and return a Google OAUTH Authorization URL
         * @param {KnAuthorizeUrlRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prospectOauthGoogleAuthorizeUrl(request: KnAuthorizeUrlRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prospectOauthGoogleAuthorizeUrl(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectOauthApi.prospectOauthGoogleAuthorizeUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary POST request that removes a User\'s Google account authorizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prospectOauthGoogleDeauthorize(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prospectOauthGoogleDeauthorize(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectOauthApi.prospectOauthGoogleDeauthorize']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary POST request that receives a KnTokenRequest, performs the exchange, and then persists the results
         * @param {KnTokenRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prospectOauthGoogleTokenExchange(request: KnTokenRequest2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prospectOauthGoogleTokenExchange(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProspectOauthApi.prospectOauthGoogleTokenExchange']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ProspectOauthApi - factory interface
 * @export
 */
export const ProspectOauthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProspectOauthApiFp(configuration)
    return {
        /**
         * 
         * @summary Post request to generate and return a Google OAUTH Authorization URL
         * @param {KnAuthorizeUrlRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prospectOauthGoogleAuthorizeUrl(request: KnAuthorizeUrlRequest, options?: any): AxiosPromise<string> {
            return localVarFp.prospectOauthGoogleAuthorizeUrl(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary POST request that removes a User\'s Google account authorizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prospectOauthGoogleDeauthorize(options?: any): AxiosPromise<void> {
            return localVarFp.prospectOauthGoogleDeauthorize(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary POST request that receives a KnTokenRequest, performs the exchange, and then persists the results
         * @param {KnTokenRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prospectOauthGoogleTokenExchange(request: KnTokenRequest2, options?: any): AxiosPromise<void> {
            return localVarFp.prospectOauthGoogleTokenExchange(request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProspectOauthApi - interface
 * @export
 * @interface ProspectOauthApi
 */
export interface ProspectOauthApiInterface {
    /**
     * 
     * @summary Post request to generate and return a Google OAUTH Authorization URL
     * @param {KnAuthorizeUrlRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectOauthApiInterface
     */
    prospectOauthGoogleAuthorizeUrl(request: KnAuthorizeUrlRequest, options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @summary POST request that removes a User\'s Google account authorizations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectOauthApiInterface
     */
    prospectOauthGoogleDeauthorize(options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary POST request that receives a KnTokenRequest, performs the exchange, and then persists the results
     * @param {KnTokenRequest2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectOauthApiInterface
     */
    prospectOauthGoogleTokenExchange(request: KnTokenRequest2, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * ProspectOauthApi - object-oriented interface
 * @export
 * @class ProspectOauthApi
 * @extends {BaseAPI}
 */
export class ProspectOauthApi extends BaseAPI implements ProspectOauthApiInterface {
    /**
     * 
     * @summary Post request to generate and return a Google OAUTH Authorization URL
     * @param {KnAuthorizeUrlRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectOauthApi
     */
    public prospectOauthGoogleAuthorizeUrl(request: KnAuthorizeUrlRequest, options?: RawAxiosRequestConfig) {
        return ProspectOauthApiFp(this.configuration).prospectOauthGoogleAuthorizeUrl(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary POST request that removes a User\'s Google account authorizations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectOauthApi
     */
    public prospectOauthGoogleDeauthorize(options?: RawAxiosRequestConfig) {
        return ProspectOauthApiFp(this.configuration).prospectOauthGoogleDeauthorize(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary POST request that receives a KnTokenRequest, performs the exchange, and then persists the results
     * @param {KnTokenRequest2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectOauthApi
     */
    public prospectOauthGoogleTokenExchange(request: KnTokenRequest2, options?: RawAxiosRequestConfig) {
        return ProspectOauthApiFp(this.configuration).prospectOauthGoogleTokenExchange(request, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DashboardRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportActivities: async (body: DashboardRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('reportActivities', 'body', body)
            const localVarPath = `/reports/activities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Load report \'Lead Flow Performance by Source\'
         * @param {DashboardRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportLeadFlowPerfBySource: async (body: DashboardRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('reportLeadFlowPerfBySource', 'body', body)
            const localVarPath = `/reports/lead-flow-perf-by-source`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DashboardRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportLeadFlowPerformance: async (body: DashboardRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('reportLeadFlowPerformance', 'body', body)
            const localVarPath = `/reports/lead-flow-perf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Load report \'Prospects in Automation\'
         * @param {DashboardRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportProspectsInAutomation: async (body: DashboardRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('reportProspectsInAutomation', 'body', body)
            const localVarPath = `/reports/prospects-in-automation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DashboardRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportActivities(body: DashboardRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportActivities>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportActivities(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReportApi.reportActivities']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Load report \'Lead Flow Performance by Source\'
         * @param {DashboardRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportLeadFlowPerfBySource(body: DashboardRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportLeadFlowPerfByLeadSource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportLeadFlowPerfBySource(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReportApi.reportLeadFlowPerfBySource']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {DashboardRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportLeadFlowPerformance(body: DashboardRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportLeadFlowPerf>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportLeadFlowPerformance(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReportApi.reportLeadFlowPerformance']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Load report \'Prospects in Automation\'
         * @param {DashboardRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportProspectsInAutomation(body: DashboardRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportProspectAutomation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportProspectsInAutomation(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReportApi.reportProspectsInAutomation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportApiFp(configuration)
    return {
        /**
         * 
         * @param {DashboardRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportActivities(body: DashboardRequest, options?: any): AxiosPromise<ReportActivities> {
            return localVarFp.reportActivities(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Load report \'Lead Flow Performance by Source\'
         * @param {DashboardRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportLeadFlowPerfBySource(body: DashboardRequest, options?: any): AxiosPromise<ReportLeadFlowPerfByLeadSource> {
            return localVarFp.reportLeadFlowPerfBySource(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DashboardRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportLeadFlowPerformance(body: DashboardRequest, options?: any): AxiosPromise<ReportLeadFlowPerf> {
            return localVarFp.reportLeadFlowPerformance(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Load report \'Prospects in Automation\'
         * @param {DashboardRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportProspectsInAutomation(body: DashboardRequest, options?: any): AxiosPromise<ReportProspectAutomation> {
            return localVarFp.reportProspectsInAutomation(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportApi - interface
 * @export
 * @interface ReportApi
 */
export interface ReportApiInterface {
    /**
     * 
     * @param {DashboardRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApiInterface
     */
    reportActivities(body: DashboardRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReportActivities>;

    /**
     * 
     * @summary Load report \'Lead Flow Performance by Source\'
     * @param {DashboardRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApiInterface
     */
    reportLeadFlowPerfBySource(body: DashboardRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReportLeadFlowPerfByLeadSource>;

    /**
     * 
     * @param {DashboardRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApiInterface
     */
    reportLeadFlowPerformance(body: DashboardRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReportLeadFlowPerf>;

    /**
     * 
     * @summary Load report \'Prospects in Automation\'
     * @param {DashboardRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApiInterface
     */
    reportProspectsInAutomation(body: DashboardRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReportProspectAutomation>;

}

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI implements ReportApiInterface {
    /**
     * 
     * @param {DashboardRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportActivities(body: DashboardRequest, options?: RawAxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportActivities(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Load report \'Lead Flow Performance by Source\'
     * @param {DashboardRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportLeadFlowPerfBySource(body: DashboardRequest, options?: RawAxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportLeadFlowPerfBySource(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportLeadFlowPerformance(body: DashboardRequest, options?: RawAxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportLeadFlowPerformance(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Load report \'Prospects in Automation\'
     * @param {DashboardRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportProspectsInAutomation(body: DashboardRequest, options?: RawAxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportProspectsInAutomation(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SequenceMessagesApi - axios parameter creator
 * @export
 */
export const SequenceMessagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all valid tags that can be used to construct a message.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllValidTags: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sequence-messages/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SequenceMessagesApi - functional programming interface
 * @export
 */
export const SequenceMessagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SequenceMessagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all valid tags that can be used to construct a message.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllValidTags(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllValidTags(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SequenceMessagesApi.getAllValidTags']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * SequenceMessagesApi - factory interface
 * @export
 */
export const SequenceMessagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SequenceMessagesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all valid tags that can be used to construct a message.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllValidTags(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getAllValidTags(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SequenceMessagesApi - interface
 * @export
 * @interface SequenceMessagesApi
 */
export interface SequenceMessagesApiInterface {
    /**
     * 
     * @summary Get all valid tags that can be used to construct a message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceMessagesApiInterface
     */
    getAllValidTags(options?: RawAxiosRequestConfig): AxiosPromise<Array<string>>;

}

/**
 * SequenceMessagesApi - object-oriented interface
 * @export
 * @class SequenceMessagesApi
 * @extends {BaseAPI}
 */
export class SequenceMessagesApi extends BaseAPI implements SequenceMessagesApiInterface {
    /**
     * 
     * @summary Get all valid tags that can be used to construct a message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceMessagesApi
     */
    public getAllValidTags(options?: RawAxiosRequestConfig) {
        return SequenceMessagesApiFp(this.configuration).getAllValidTags(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SequenceStepTemplatesApi - axios parameter creator
 * @export
 */
export const SequenceStepTemplatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add attachments to a step template by id. Only EMAILS can have attachment.
         * @param {Array<File>} files 
         * @param {string} stepTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAttachmentsToStepTemplate: async (files: Array<File>, stepTemplateId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'files' is not null or undefined
            assertParamExists('addAttachmentsToStepTemplate', 'files', files)
            // verify required parameter 'stepTemplateId' is not null or undefined
            assertParamExists('addAttachmentsToStepTemplate', 'stepTemplateId', stepTemplateId)
            const localVarPath = `/sequence-step-templates/add-attachments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
            if (stepTemplateId !== undefined) { 
                localVarFormParams.append('stepTemplateId', stepTemplateId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a Step Template, associated to the sequence template by the sequenceTemplateId.  This endpoint can only be called for Sequence Templates in DRAFT status. You cannot Add, Delete or Edit step behavior for an ACTIVE sequence template. In order to do that you need to create a new version, that will be in DRAFT mode. To add attachments use /add-attachments endpoint.
         * @param {StepTemplateRequestCreate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStepTemplate: async (body: StepTemplateRequestCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createStepTemplate', 'body', body)
            const localVarPath = `/sequence-step-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete attachment by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAttachment: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAttachment', 'id', id)
            const localVarPath = `/sequence-step-templates/delete-attachment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get step templates by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStepTemplate: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getStepTemplate', 'id', id)
            const localVarPath = `/sequence-step-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This endpoint will remove the step from the sequence template, and wherever it was listed as a parent, it will be replaced  by its parents. This endpoint can only be called for Sequence Templates in DRAFT status. You cannot Add, Delete or Edit behavior for an  ACTIVE sequence template. In order to do that you need to create a new version, that will be in DRAFT mode.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeStepTemplate: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeStepTemplate', 'id', id)
            const localVarPath = `/sequence-step-templates/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This will switch places of two consecutive steps in the ordering of a sequence
         * @param {string} stepId1 
         * @param {string} stepId2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        switchConsecutiveStepsOrder: async (stepId1: string, stepId2: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepId1' is not null or undefined
            assertParamExists('switchConsecutiveStepsOrder', 'stepId1', stepId1)
            // verify required parameter 'stepId2' is not null or undefined
            assertParamExists('switchConsecutiveStepsOrder', 'stepId2', stepId2)
            const localVarPath = `/sequence-step-templates/switch-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)

            if (stepId1 !== undefined) {
                localVarQueryParameter['stepId1'] = stepId1;
            }

            if (stepId2 !== undefined) {
                localVarQueryParameter['stepId2'] = stepId2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a Step Template behavior. this can only be called for Sequence Templates in DRAFT status. You cannot Add, Delete or Edit behavior for an ACTIVE sequence template. In order to do that you need to create a new version, that will be in DRAFT mode. It allows editing the steps flow control and parents as needed, but users should be warned that Sequences that are already in progress will not be impacted by these changes, only new ones.
         * @param {StepTemplateRequestUpdateBehavior} requestUpdateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStepTemplateBehavior: async (requestUpdateDTO: StepTemplateRequestUpdateBehavior, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestUpdateDTO' is not null or undefined
            assertParamExists('updateStepTemplateBehavior', 'requestUpdateDTO', requestUpdateDTO)
            const localVarPath = `/sequence-step-templates/behavior`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestUpdateDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a Step Template information, this can be done with the ACTIVE sequence, no need to create a new DRAFT version. All messages sent after this update will be affected.
         * @param {StepTemplateRequestUpdateInfo} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStepTemplateInfo: async (body: StepTemplateRequestUpdateInfo, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateStepTemplateInfo', 'body', body)
            const localVarPath = `/sequence-step-templates/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SequenceStepTemplatesApi - functional programming interface
 * @export
 */
export const SequenceStepTemplatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SequenceStepTemplatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add attachments to a step template by id. Only EMAILS can have attachment.
         * @param {Array<File>} files 
         * @param {string} stepTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAttachmentsToStepTemplate(files: Array<File>, stepTemplateId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAttachmentsToStepTemplate(files, stepTemplateId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SequenceStepTemplatesApi.addAttachmentsToStepTemplate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create a Step Template, associated to the sequence template by the sequenceTemplateId.  This endpoint can only be called for Sequence Templates in DRAFT status. You cannot Add, Delete or Edit step behavior for an ACTIVE sequence template. In order to do that you need to create a new version, that will be in DRAFT mode. To add attachments use /add-attachments endpoint.
         * @param {StepTemplateRequestCreate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStepTemplate(body: StepTemplateRequestCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStepTemplate(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SequenceStepTemplatesApi.createStepTemplate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Delete attachment by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAttachment(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAttachment(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SequenceStepTemplatesApi.deleteAttachment']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get step templates by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStepTemplate(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStepTemplate(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SequenceStepTemplatesApi.getStepTemplate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary This endpoint will remove the step from the sequence template, and wherever it was listed as a parent, it will be replaced  by its parents. This endpoint can only be called for Sequence Templates in DRAFT status. You cannot Add, Delete or Edit behavior for an  ACTIVE sequence template. In order to do that you need to create a new version, that will be in DRAFT mode.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeStepTemplate(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeStepTemplate(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SequenceStepTemplatesApi.removeStepTemplate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary This will switch places of two consecutive steps in the ordering of a sequence
         * @param {string} stepId1 
         * @param {string} stepId2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async switchConsecutiveStepsOrder(stepId1: string, stepId2: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.switchConsecutiveStepsOrder(stepId1, stepId2, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SequenceStepTemplatesApi.switchConsecutiveStepsOrder']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Updates a Step Template behavior. this can only be called for Sequence Templates in DRAFT status. You cannot Add, Delete or Edit behavior for an ACTIVE sequence template. In order to do that you need to create a new version, that will be in DRAFT mode. It allows editing the steps flow control and parents as needed, but users should be warned that Sequences that are already in progress will not be impacted by these changes, only new ones.
         * @param {StepTemplateRequestUpdateBehavior} requestUpdateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStepTemplateBehavior(requestUpdateDTO: StepTemplateRequestUpdateBehavior, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStepTemplateBehavior(requestUpdateDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SequenceStepTemplatesApi.updateStepTemplateBehavior']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Updates a Step Template information, this can be done with the ACTIVE sequence, no need to create a new DRAFT version. All messages sent after this update will be affected.
         * @param {StepTemplateRequestUpdateInfo} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStepTemplateInfo(body: StepTemplateRequestUpdateInfo, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStepTemplateInfo(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SequenceStepTemplatesApi.updateStepTemplateInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * SequenceStepTemplatesApi - factory interface
 * @export
 */
export const SequenceStepTemplatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SequenceStepTemplatesApiFp(configuration)
    return {
        /**
         * 
         * @summary Add attachments to a step template by id. Only EMAILS can have attachment.
         * @param {Array<File>} files 
         * @param {string} stepTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAttachmentsToStepTemplate(files: Array<File>, stepTemplateId: string, options?: any): AxiosPromise<StepTemplateResponse> {
            return localVarFp.addAttachmentsToStepTemplate(files, stepTemplateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a Step Template, associated to the sequence template by the sequenceTemplateId.  This endpoint can only be called for Sequence Templates in DRAFT status. You cannot Add, Delete or Edit step behavior for an ACTIVE sequence template. In order to do that you need to create a new version, that will be in DRAFT mode. To add attachments use /add-attachments endpoint.
         * @param {StepTemplateRequestCreate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStepTemplate(body: StepTemplateRequestCreate, options?: any): AxiosPromise<StepTemplateResponse> {
            return localVarFp.createStepTemplate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete attachment by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAttachment(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAttachment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get step templates by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStepTemplate(id: string, options?: any): AxiosPromise<StepTemplateResponse> {
            return localVarFp.getStepTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This endpoint will remove the step from the sequence template, and wherever it was listed as a parent, it will be replaced  by its parents. This endpoint can only be called for Sequence Templates in DRAFT status. You cannot Add, Delete or Edit behavior for an  ACTIVE sequence template. In order to do that you need to create a new version, that will be in DRAFT mode.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeStepTemplate(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeStepTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This will switch places of two consecutive steps in the ordering of a sequence
         * @param {string} stepId1 
         * @param {string} stepId2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        switchConsecutiveStepsOrder(stepId1: string, stepId2: string, options?: any): AxiosPromise<void> {
            return localVarFp.switchConsecutiveStepsOrder(stepId1, stepId2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a Step Template behavior. this can only be called for Sequence Templates in DRAFT status. You cannot Add, Delete or Edit behavior for an ACTIVE sequence template. In order to do that you need to create a new version, that will be in DRAFT mode. It allows editing the steps flow control and parents as needed, but users should be warned that Sequences that are already in progress will not be impacted by these changes, only new ones.
         * @param {StepTemplateRequestUpdateBehavior} requestUpdateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStepTemplateBehavior(requestUpdateDTO: StepTemplateRequestUpdateBehavior, options?: any): AxiosPromise<StepTemplateResponse> {
            return localVarFp.updateStepTemplateBehavior(requestUpdateDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a Step Template information, this can be done with the ACTIVE sequence, no need to create a new DRAFT version. All messages sent after this update will be affected.
         * @param {StepTemplateRequestUpdateInfo} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStepTemplateInfo(body: StepTemplateRequestUpdateInfo, options?: any): AxiosPromise<StepTemplateResponse> {
            return localVarFp.updateStepTemplateInfo(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SequenceStepTemplatesApi - interface
 * @export
 * @interface SequenceStepTemplatesApi
 */
export interface SequenceStepTemplatesApiInterface {
    /**
     * 
     * @summary Add attachments to a step template by id. Only EMAILS can have attachment.
     * @param {Array<File>} files 
     * @param {string} stepTemplateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepTemplatesApiInterface
     */
    addAttachmentsToStepTemplate(files: Array<File>, stepTemplateId: string, options?: RawAxiosRequestConfig): AxiosPromise<StepTemplateResponse>;

    /**
     * 
     * @summary Create a Step Template, associated to the sequence template by the sequenceTemplateId.  This endpoint can only be called for Sequence Templates in DRAFT status. You cannot Add, Delete or Edit step behavior for an ACTIVE sequence template. In order to do that you need to create a new version, that will be in DRAFT mode. To add attachments use /add-attachments endpoint.
     * @param {StepTemplateRequestCreate} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepTemplatesApiInterface
     */
    createStepTemplate(body: StepTemplateRequestCreate, options?: RawAxiosRequestConfig): AxiosPromise<StepTemplateResponse>;

    /**
     * 
     * @summary Delete attachment by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepTemplatesApiInterface
     */
    deleteAttachment(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Get step templates by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepTemplatesApiInterface
     */
    getStepTemplate(id: string, options?: RawAxiosRequestConfig): AxiosPromise<StepTemplateResponse>;

    /**
     * 
     * @summary This endpoint will remove the step from the sequence template, and wherever it was listed as a parent, it will be replaced  by its parents. This endpoint can only be called for Sequence Templates in DRAFT status. You cannot Add, Delete or Edit behavior for an  ACTIVE sequence template. In order to do that you need to create a new version, that will be in DRAFT mode.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepTemplatesApiInterface
     */
    removeStepTemplate(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary This will switch places of two consecutive steps in the ordering of a sequence
     * @param {string} stepId1 
     * @param {string} stepId2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepTemplatesApiInterface
     */
    switchConsecutiveStepsOrder(stepId1: string, stepId2: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates a Step Template behavior. this can only be called for Sequence Templates in DRAFT status. You cannot Add, Delete or Edit behavior for an ACTIVE sequence template. In order to do that you need to create a new version, that will be in DRAFT mode. It allows editing the steps flow control and parents as needed, but users should be warned that Sequences that are already in progress will not be impacted by these changes, only new ones.
     * @param {StepTemplateRequestUpdateBehavior} requestUpdateDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepTemplatesApiInterface
     */
    updateStepTemplateBehavior(requestUpdateDTO: StepTemplateRequestUpdateBehavior, options?: RawAxiosRequestConfig): AxiosPromise<StepTemplateResponse>;

    /**
     * 
     * @summary Updates a Step Template information, this can be done with the ACTIVE sequence, no need to create a new DRAFT version. All messages sent after this update will be affected.
     * @param {StepTemplateRequestUpdateInfo} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepTemplatesApiInterface
     */
    updateStepTemplateInfo(body: StepTemplateRequestUpdateInfo, options?: RawAxiosRequestConfig): AxiosPromise<StepTemplateResponse>;

}

/**
 * SequenceStepTemplatesApi - object-oriented interface
 * @export
 * @class SequenceStepTemplatesApi
 * @extends {BaseAPI}
 */
export class SequenceStepTemplatesApi extends BaseAPI implements SequenceStepTemplatesApiInterface {
    /**
     * 
     * @summary Add attachments to a step template by id. Only EMAILS can have attachment.
     * @param {Array<File>} files 
     * @param {string} stepTemplateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepTemplatesApi
     */
    public addAttachmentsToStepTemplate(files: Array<File>, stepTemplateId: string, options?: RawAxiosRequestConfig) {
        return SequenceStepTemplatesApiFp(this.configuration).addAttachmentsToStepTemplate(files, stepTemplateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a Step Template, associated to the sequence template by the sequenceTemplateId.  This endpoint can only be called for Sequence Templates in DRAFT status. You cannot Add, Delete or Edit step behavior for an ACTIVE sequence template. In order to do that you need to create a new version, that will be in DRAFT mode. To add attachments use /add-attachments endpoint.
     * @param {StepTemplateRequestCreate} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepTemplatesApi
     */
    public createStepTemplate(body: StepTemplateRequestCreate, options?: RawAxiosRequestConfig) {
        return SequenceStepTemplatesApiFp(this.configuration).createStepTemplate(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete attachment by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepTemplatesApi
     */
    public deleteAttachment(id: string, options?: RawAxiosRequestConfig) {
        return SequenceStepTemplatesApiFp(this.configuration).deleteAttachment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get step templates by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepTemplatesApi
     */
    public getStepTemplate(id: string, options?: RawAxiosRequestConfig) {
        return SequenceStepTemplatesApiFp(this.configuration).getStepTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This endpoint will remove the step from the sequence template, and wherever it was listed as a parent, it will be replaced  by its parents. This endpoint can only be called for Sequence Templates in DRAFT status. You cannot Add, Delete or Edit behavior for an  ACTIVE sequence template. In order to do that you need to create a new version, that will be in DRAFT mode.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepTemplatesApi
     */
    public removeStepTemplate(id: string, options?: RawAxiosRequestConfig) {
        return SequenceStepTemplatesApiFp(this.configuration).removeStepTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This will switch places of two consecutive steps in the ordering of a sequence
     * @param {string} stepId1 
     * @param {string} stepId2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepTemplatesApi
     */
    public switchConsecutiveStepsOrder(stepId1: string, stepId2: string, options?: RawAxiosRequestConfig) {
        return SequenceStepTemplatesApiFp(this.configuration).switchConsecutiveStepsOrder(stepId1, stepId2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a Step Template behavior. this can only be called for Sequence Templates in DRAFT status. You cannot Add, Delete or Edit behavior for an ACTIVE sequence template. In order to do that you need to create a new version, that will be in DRAFT mode. It allows editing the steps flow control and parents as needed, but users should be warned that Sequences that are already in progress will not be impacted by these changes, only new ones.
     * @param {StepTemplateRequestUpdateBehavior} requestUpdateDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepTemplatesApi
     */
    public updateStepTemplateBehavior(requestUpdateDTO: StepTemplateRequestUpdateBehavior, options?: RawAxiosRequestConfig) {
        return SequenceStepTemplatesApiFp(this.configuration).updateStepTemplateBehavior(requestUpdateDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a Step Template information, this can be done with the ACTIVE sequence, no need to create a new DRAFT version. All messages sent after this update will be affected.
     * @param {StepTemplateRequestUpdateInfo} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceStepTemplatesApi
     */
    public updateStepTemplateInfo(body: StepTemplateRequestUpdateInfo, options?: RawAxiosRequestConfig) {
        return SequenceStepTemplatesApiFp(this.configuration).updateStepTemplateInfo(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SequenceTemplatesApi - axios parameter creator
 * @export
 */
export const SequenceTemplatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a copy of a sequence template with status \'DRAFT\'.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneSequenceTemplate: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cloneSequenceTemplate', 'id', id)
            const localVarPath = `/sequence-templates/clone/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Based on sequenceTemplateId, this will create a new \'DRAFT\' version of a sequence template, which will be a copy of current sequence template and its steps.  The sequenceTemplate needs to be either \'ACTIVE\' or \'INACTIVE\', if the sequenceTemplateId sent is in any other status, an error will be returned.  All updates for that sequence steps should be done using the sequence-step-templates endpoints.  By the moment that all updates on the sequence template steps are finished it needs to be updated to \'ACTIVE\' using the PUT endpoint from sequence-templates in order for users to start enrolling to it. If the route is not well formed, it wonÔÇÖt be possible to activate the sequence template.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewVersionSequenceTemplate: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createNewVersionSequenceTemplate', 'id', id)
            const localVarPath = `/sequence-templates/new-version/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new sequence template with status \'DRAFT\'. User can then add necessary steps to it, and when is finished, update the status to ACTIVE using PUT
         * @param {SequenceTemplateRequestCreate} createRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSequenceTemplate: async (createRequestDTO: SequenceTemplateRequestCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRequestDTO' is not null or undefined
            assertParamExists('createSequenceTemplate', 'createRequestDTO', createRequestDTO)
            const localVarPath = `/sequence-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get sequence templates by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequenceTemplate: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSequenceTemplate', 'id', id)
            const localVarPath = `/sequence-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all sequence templates available for current user\'s team
         * @param {string} [name] 
         * @param {Array<GetSequenceTemplatesForUserStatusEnum>} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequenceTemplatesForUser: async (name?: string, status?: Array<GetSequenceTemplatesForUserStatusEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sequence-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all sequence templates available for current user\'s team
         * @param {string} [name] 
         * @param {Array<GetSequenceTemplatesForUserGroupedByOriginalStatusEnum>} [status] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequenceTemplatesForUserGroupedByOriginal: async (name?: string, status?: Array<GetSequenceTemplatesForUserGroupedByOriginalStatusEnum>, pageNumber?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sequence-templates/grouped-by-original`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes sequence templates, and cancels all sequences in progress
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        softDeleteSequenceTemplate: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('softDeleteSequenceTemplate', 'id', id)
            const localVarPath = `/sequence-templates/soft-delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the current sequence based on the id field. This endpoint can only do updates in this order DRAFT -> ACTIVE -> INACTIVE ->DELETED It can never go back from ACTIVE to DRAFT, you can, however, create a new version using the POST /new-version method. If the sequence template being updated from DRAFT to ACTIVE has a previous version, the older version status will be automatically updated to either INACTIVE (when it has contacts still enrolled to it) or DELETED (when there aren’t contacts enrolled to it).
         * @param {SequenceTemplateRequestUpdate} requestUpdateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSequenceTemplate: async (requestUpdateDTO: SequenceTemplateRequestUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestUpdateDTO' is not null or undefined
            assertParamExists('updateSequenceTemplate', 'requestUpdateDTO', requestUpdateDTO)
            const localVarPath = `/sequence-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestUpdateDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SequenceTemplatesApi - functional programming interface
 * @export
 */
export const SequenceTemplatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SequenceTemplatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a copy of a sequence template with status \'DRAFT\'.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cloneSequenceTemplate(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SequenceTemplateResponseWithStepsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cloneSequenceTemplate(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SequenceTemplatesApi.cloneSequenceTemplate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Based on sequenceTemplateId, this will create a new \'DRAFT\' version of a sequence template, which will be a copy of current sequence template and its steps.  The sequenceTemplate needs to be either \'ACTIVE\' or \'INACTIVE\', if the sequenceTemplateId sent is in any other status, an error will be returned.  All updates for that sequence steps should be done using the sequence-step-templates endpoints.  By the moment that all updates on the sequence template steps are finished it needs to be updated to \'ACTIVE\' using the PUT endpoint from sequence-templates in order for users to start enrolling to it. If the route is not well formed, it wonÔÇÖt be possible to activate the sequence template.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNewVersionSequenceTemplate(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SequenceTemplateResponseWithStepsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewVersionSequenceTemplate(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SequenceTemplatesApi.createNewVersionSequenceTemplate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Creates a new sequence template with status \'DRAFT\'. User can then add necessary steps to it, and when is finished, update the status to ACTIVE using PUT
         * @param {SequenceTemplateRequestCreate} createRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSequenceTemplate(createRequestDTO: SequenceTemplateRequestCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SequenceTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSequenceTemplate(createRequestDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SequenceTemplatesApi.createSequenceTemplate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get sequence templates by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSequenceTemplate(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SequenceTemplateResponseWithStepsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSequenceTemplate(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SequenceTemplatesApi.getSequenceTemplate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get all sequence templates available for current user\'s team
         * @param {string} [name] 
         * @param {Array<GetSequenceTemplatesForUserStatusEnum>} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSequenceTemplatesForUser(name?: string, status?: Array<GetSequenceTemplatesForUserStatusEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SequenceTemplateResponseDesc>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSequenceTemplatesForUser(name, status, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SequenceTemplatesApi.getSequenceTemplatesForUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get all sequence templates available for current user\'s team
         * @param {string} [name] 
         * @param {Array<GetSequenceTemplatesForUserGroupedByOriginalStatusEnum>} [status] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSequenceTemplatesForUserGroupedByOriginal(name?: string, status?: Array<GetSequenceTemplatesForUserGroupedByOriginalStatusEnum>, pageNumber?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnPageResponseSequenceTemplateResponseGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSequenceTemplatesForUserGroupedByOriginal(name, status, pageNumber, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SequenceTemplatesApi.getSequenceTemplatesForUserGroupedByOriginal']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes sequence templates, and cancels all sequences in progress
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async softDeleteSequenceTemplate(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.softDeleteSequenceTemplate(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SequenceTemplatesApi.softDeleteSequenceTemplate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Updates the current sequence based on the id field. This endpoint can only do updates in this order DRAFT -> ACTIVE -> INACTIVE ->DELETED It can never go back from ACTIVE to DRAFT, you can, however, create a new version using the POST /new-version method. If the sequence template being updated from DRAFT to ACTIVE has a previous version, the older version status will be automatically updated to either INACTIVE (when it has contacts still enrolled to it) or DELETED (when there aren’t contacts enrolled to it).
         * @param {SequenceTemplateRequestUpdate} requestUpdateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSequenceTemplate(requestUpdateDTO: SequenceTemplateRequestUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSequenceTemplate(requestUpdateDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SequenceTemplatesApi.updateSequenceTemplate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * SequenceTemplatesApi - factory interface
 * @export
 */
export const SequenceTemplatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SequenceTemplatesApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a copy of a sequence template with status \'DRAFT\'.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneSequenceTemplate(id: string, options?: any): AxiosPromise<SequenceTemplateResponseWithStepsDTO> {
            return localVarFp.cloneSequenceTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Based on sequenceTemplateId, this will create a new \'DRAFT\' version of a sequence template, which will be a copy of current sequence template and its steps.  The sequenceTemplate needs to be either \'ACTIVE\' or \'INACTIVE\', if the sequenceTemplateId sent is in any other status, an error will be returned.  All updates for that sequence steps should be done using the sequence-step-templates endpoints.  By the moment that all updates on the sequence template steps are finished it needs to be updated to \'ACTIVE\' using the PUT endpoint from sequence-templates in order for users to start enrolling to it. If the route is not well formed, it wonÔÇÖt be possible to activate the sequence template.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewVersionSequenceTemplate(id: string, options?: any): AxiosPromise<SequenceTemplateResponseWithStepsDTO> {
            return localVarFp.createNewVersionSequenceTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new sequence template with status \'DRAFT\'. User can then add necessary steps to it, and when is finished, update the status to ACTIVE using PUT
         * @param {SequenceTemplateRequestCreate} createRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSequenceTemplate(createRequestDTO: SequenceTemplateRequestCreate, options?: any): AxiosPromise<SequenceTemplateResponse> {
            return localVarFp.createSequenceTemplate(createRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get sequence templates by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequenceTemplate(id: string, options?: any): AxiosPromise<SequenceTemplateResponseWithStepsDTO> {
            return localVarFp.getSequenceTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all sequence templates available for current user\'s team
         * @param {string} [name] 
         * @param {Array<GetSequenceTemplatesForUserStatusEnum>} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequenceTemplatesForUser(name?: string, status?: Array<GetSequenceTemplatesForUserStatusEnum>, options?: any): AxiosPromise<Array<SequenceTemplateResponseDesc>> {
            return localVarFp.getSequenceTemplatesForUser(name, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all sequence templates available for current user\'s team
         * @param {string} [name] 
         * @param {Array<GetSequenceTemplatesForUserGroupedByOriginalStatusEnum>} [status] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequenceTemplatesForUserGroupedByOriginal(name?: string, status?: Array<GetSequenceTemplatesForUserGroupedByOriginalStatusEnum>, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<KnPageResponseSequenceTemplateResponseGroup> {
            return localVarFp.getSequenceTemplatesForUserGroupedByOriginal(name, status, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes sequence templates, and cancels all sequences in progress
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        softDeleteSequenceTemplate(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.softDeleteSequenceTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the current sequence based on the id field. This endpoint can only do updates in this order DRAFT -> ACTIVE -> INACTIVE ->DELETED It can never go back from ACTIVE to DRAFT, you can, however, create a new version using the POST /new-version method. If the sequence template being updated from DRAFT to ACTIVE has a previous version, the older version status will be automatically updated to either INACTIVE (when it has contacts still enrolled to it) or DELETED (when there aren’t contacts enrolled to it).
         * @param {SequenceTemplateRequestUpdate} requestUpdateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSequenceTemplate(requestUpdateDTO: SequenceTemplateRequestUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.updateSequenceTemplate(requestUpdateDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SequenceTemplatesApi - interface
 * @export
 * @interface SequenceTemplatesApi
 */
export interface SequenceTemplatesApiInterface {
    /**
     * 
     * @summary Creates a copy of a sequence template with status \'DRAFT\'.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceTemplatesApiInterface
     */
    cloneSequenceTemplate(id: string, options?: RawAxiosRequestConfig): AxiosPromise<SequenceTemplateResponseWithStepsDTO>;

    /**
     * 
     * @summary Based on sequenceTemplateId, this will create a new \'DRAFT\' version of a sequence template, which will be a copy of current sequence template and its steps.  The sequenceTemplate needs to be either \'ACTIVE\' or \'INACTIVE\', if the sequenceTemplateId sent is in any other status, an error will be returned.  All updates for that sequence steps should be done using the sequence-step-templates endpoints.  By the moment that all updates on the sequence template steps are finished it needs to be updated to \'ACTIVE\' using the PUT endpoint from sequence-templates in order for users to start enrolling to it. If the route is not well formed, it wonÔÇÖt be possible to activate the sequence template.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceTemplatesApiInterface
     */
    createNewVersionSequenceTemplate(id: string, options?: RawAxiosRequestConfig): AxiosPromise<SequenceTemplateResponseWithStepsDTO>;

    /**
     * 
     * @summary Creates a new sequence template with status \'DRAFT\'. User can then add necessary steps to it, and when is finished, update the status to ACTIVE using PUT
     * @param {SequenceTemplateRequestCreate} createRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceTemplatesApiInterface
     */
    createSequenceTemplate(createRequestDTO: SequenceTemplateRequestCreate, options?: RawAxiosRequestConfig): AxiosPromise<SequenceTemplateResponse>;

    /**
     * 
     * @summary Get sequence templates by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceTemplatesApiInterface
     */
    getSequenceTemplate(id: string, options?: RawAxiosRequestConfig): AxiosPromise<SequenceTemplateResponseWithStepsDTO>;

    /**
     * 
     * @summary Get all sequence templates available for current user\'s team
     * @param {string} [name] 
     * @param {Array<GetSequenceTemplatesForUserStatusEnum>} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceTemplatesApiInterface
     */
    getSequenceTemplatesForUser(name?: string, status?: Array<GetSequenceTemplatesForUserStatusEnum>, options?: RawAxiosRequestConfig): AxiosPromise<Array<SequenceTemplateResponseDesc>>;

    /**
     * 
     * @summary Get all sequence templates available for current user\'s team
     * @param {string} [name] 
     * @param {Array<GetSequenceTemplatesForUserGroupedByOriginalStatusEnum>} [status] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceTemplatesApiInterface
     */
    getSequenceTemplatesForUserGroupedByOriginal(name?: string, status?: Array<GetSequenceTemplatesForUserGroupedByOriginalStatusEnum>, pageNumber?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<KnPageResponseSequenceTemplateResponseGroup>;

    /**
     * 
     * @summary Deletes sequence templates, and cancels all sequences in progress
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceTemplatesApiInterface
     */
    softDeleteSequenceTemplate(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Updates the current sequence based on the id field. This endpoint can only do updates in this order DRAFT -> ACTIVE -> INACTIVE ->DELETED It can never go back from ACTIVE to DRAFT, you can, however, create a new version using the POST /new-version method. If the sequence template being updated from DRAFT to ACTIVE has a previous version, the older version status will be automatically updated to either INACTIVE (when it has contacts still enrolled to it) or DELETED (when there aren’t contacts enrolled to it).
     * @param {SequenceTemplateRequestUpdate} requestUpdateDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceTemplatesApiInterface
     */
    updateSequenceTemplate(requestUpdateDTO: SequenceTemplateRequestUpdate, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * SequenceTemplatesApi - object-oriented interface
 * @export
 * @class SequenceTemplatesApi
 * @extends {BaseAPI}
 */
export class SequenceTemplatesApi extends BaseAPI implements SequenceTemplatesApiInterface {
    /**
     * 
     * @summary Creates a copy of a sequence template with status \'DRAFT\'.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceTemplatesApi
     */
    public cloneSequenceTemplate(id: string, options?: RawAxiosRequestConfig) {
        return SequenceTemplatesApiFp(this.configuration).cloneSequenceTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Based on sequenceTemplateId, this will create a new \'DRAFT\' version of a sequence template, which will be a copy of current sequence template and its steps.  The sequenceTemplate needs to be either \'ACTIVE\' or \'INACTIVE\', if the sequenceTemplateId sent is in any other status, an error will be returned.  All updates for that sequence steps should be done using the sequence-step-templates endpoints.  By the moment that all updates on the sequence template steps are finished it needs to be updated to \'ACTIVE\' using the PUT endpoint from sequence-templates in order for users to start enrolling to it. If the route is not well formed, it wonÔÇÖt be possible to activate the sequence template.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceTemplatesApi
     */
    public createNewVersionSequenceTemplate(id: string, options?: RawAxiosRequestConfig) {
        return SequenceTemplatesApiFp(this.configuration).createNewVersionSequenceTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new sequence template with status \'DRAFT\'. User can then add necessary steps to it, and when is finished, update the status to ACTIVE using PUT
     * @param {SequenceTemplateRequestCreate} createRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceTemplatesApi
     */
    public createSequenceTemplate(createRequestDTO: SequenceTemplateRequestCreate, options?: RawAxiosRequestConfig) {
        return SequenceTemplatesApiFp(this.configuration).createSequenceTemplate(createRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get sequence templates by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceTemplatesApi
     */
    public getSequenceTemplate(id: string, options?: RawAxiosRequestConfig) {
        return SequenceTemplatesApiFp(this.configuration).getSequenceTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all sequence templates available for current user\'s team
     * @param {string} [name] 
     * @param {Array<GetSequenceTemplatesForUserStatusEnum>} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceTemplatesApi
     */
    public getSequenceTemplatesForUser(name?: string, status?: Array<GetSequenceTemplatesForUserStatusEnum>, options?: RawAxiosRequestConfig) {
        return SequenceTemplatesApiFp(this.configuration).getSequenceTemplatesForUser(name, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all sequence templates available for current user\'s team
     * @param {string} [name] 
     * @param {Array<GetSequenceTemplatesForUserGroupedByOriginalStatusEnum>} [status] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceTemplatesApi
     */
    public getSequenceTemplatesForUserGroupedByOriginal(name?: string, status?: Array<GetSequenceTemplatesForUserGroupedByOriginalStatusEnum>, pageNumber?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return SequenceTemplatesApiFp(this.configuration).getSequenceTemplatesForUserGroupedByOriginal(name, status, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes sequence templates, and cancels all sequences in progress
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceTemplatesApi
     */
    public softDeleteSequenceTemplate(id: string, options?: RawAxiosRequestConfig) {
        return SequenceTemplatesApiFp(this.configuration).softDeleteSequenceTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the current sequence based on the id field. This endpoint can only do updates in this order DRAFT -> ACTIVE -> INACTIVE ->DELETED It can never go back from ACTIVE to DRAFT, you can, however, create a new version using the POST /new-version method. If the sequence template being updated from DRAFT to ACTIVE has a previous version, the older version status will be automatically updated to either INACTIVE (when it has contacts still enrolled to it) or DELETED (when there aren’t contacts enrolled to it).
     * @param {SequenceTemplateRequestUpdate} requestUpdateDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceTemplatesApi
     */
    public updateSequenceTemplate(requestUpdateDTO: SequenceTemplateRequestUpdate, options?: RawAxiosRequestConfig) {
        return SequenceTemplatesApiFp(this.configuration).updateSequenceTemplate(requestUpdateDTO, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetSequenceTemplatesForUserStatusEnum = {
    Active: 'ACTIVE',
    Draft: 'DRAFT',
    Inactive: 'INACTIVE',
    PausedInTemplateLevel: 'PAUSED_IN_TEMPLATE_LEVEL',
    PausedInTeamLevel: 'PAUSED_IN_TEAM_LEVEL',
    Deleted: 'DELETED'
} as const;
export type GetSequenceTemplatesForUserStatusEnum = typeof GetSequenceTemplatesForUserStatusEnum[keyof typeof GetSequenceTemplatesForUserStatusEnum];
/**
 * @export
 */
export const GetSequenceTemplatesForUserGroupedByOriginalStatusEnum = {
    Active: 'ACTIVE',
    Draft: 'DRAFT',
    Inactive: 'INACTIVE',
    PausedInTemplateLevel: 'PAUSED_IN_TEMPLATE_LEVEL',
    PausedInTeamLevel: 'PAUSED_IN_TEAM_LEVEL',
    Deleted: 'DELETED'
} as const;
export type GetSequenceTemplatesForUserGroupedByOriginalStatusEnum = typeof GetSequenceTemplatesForUserGroupedByOriginalStatusEnum[keyof typeof GetSequenceTemplatesForUserGroupedByOriginalStatusEnum];


/**
 * SequencesApi - axios parameter creator
 * @export
 */
export const SequencesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Triggers a sequence template for each contact, creating sequences
         * @param {SequenceRequestMultipleTrigger} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMultipleSequences: async (body: SequenceRequestMultipleTrigger, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createMultipleSequences', 'body', body)
            const localVarPath = `/sequences/trigger-multiple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Triggers a sequence template, creating a sequence
         * @param {SequenceRequestTrigger} sequenceRequestTrigger 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSequence: async (sequenceRequestTrigger: SequenceRequestTrigger, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sequenceRequestTrigger' is not null or undefined
            assertParamExists('createSequence', 'sequenceRequestTrigger', sequenceRequestTrigger)
            const localVarPath = `/sequences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sequenceRequestTrigger, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get sequence information by Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequence: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSequence', 'id', id)
            const localVarPath = `/sequences/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all sequences related to a contact.
         * @param {string} contactId 
         * @param {Array<GetSequencesByContactStatusEnum>} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequencesByContact: async (contactId: string, status?: Array<GetSequencesByContactStatusEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('getSequencesByContact', 'contactId', contactId)
            const localVarPath = `/sequences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)

            if (contactId !== undefined) {
                localVarQueryParameter['contactId'] = contactId;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pause sequence interactions and updates sequence status to PAUSED
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pauseSequence: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('pauseSequence', 'id', id)
            const localVarPath = `/sequences/pause/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pause sequences, based on a sequence template id
         * @param {string} sequenceTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pauseSequencesBySequenceTemplateId: async (sequenceTemplateId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sequenceTemplateId' is not null or undefined
            assertParamExists('pauseSequencesBySequenceTemplateId', 'sequenceTemplateId', sequenceTemplateId)
            const localVarPath = `/sequences/sequence-template/pause/{sequenceTemplateId}`
                .replace(`{${"sequenceTemplateId"}}`, encodeURIComponent(String(sequenceTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pause sequences for current team
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pauseSequencesForTeam: async (teamId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('pauseSequencesForTeam', 'teamId', teamId)
            const localVarPath = `/sequences/team/pause/{teamId}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resume sequence interactions and updates sequence status to IN_PROGRESS
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeSequence: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('resumeSequence', 'id', id)
            const localVarPath = `/sequences/resume/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resume sequences, based on a sequence template id
         * @param {string} sequenceTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeSequencesBySequenceTemplateId: async (sequenceTemplateId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sequenceTemplateId' is not null or undefined
            assertParamExists('resumeSequencesBySequenceTemplateId', 'sequenceTemplateId', sequenceTemplateId)
            const localVarPath = `/sequences/sequence-template/resume/{sequenceTemplateId}`
                .replace(`{${"sequenceTemplateId"}}`, encodeURIComponent(String(sequenceTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resume sequences for current team
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeSequencesForTeam: async (teamId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('resumeSequencesForTeam', 'teamId', teamId)
            const localVarPath = `/sequences/team/resume/{teamId}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Triggers many sequences template, based on a lead source id
         * @param {SequenceRequestLeadSourceTrigger} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerSequencesByLeadSourceId: async (body: SequenceRequestLeadSourceTrigger, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('triggerSequencesByLeadSourceId', 'body', body)
            const localVarPath = `/sequences/lead-source`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates sequence status to UNENROLLED, also cancelling all tasks within it
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unenrollSequence: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unenrollSequence', 'id', id)
            const localVarPath = `/sequences/unenroll/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SequencesApi - functional programming interface
 * @export
 */
export const SequencesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SequencesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Triggers a sequence template for each contact, creating sequences
         * @param {SequenceRequestMultipleTrigger} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMultipleSequences(body: SequenceRequestMultipleTrigger, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TriggerMultipleSequencesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMultipleSequences(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SequencesApi.createMultipleSequences']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Triggers a sequence template, creating a sequence
         * @param {SequenceRequestTrigger} sequenceRequestTrigger 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSequence(sequenceRequestTrigger: SequenceRequestTrigger, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SequenceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSequence(sequenceRequestTrigger, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SequencesApi.createSequence']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get sequence information by Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSequence(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SequenceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSequence(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SequencesApi.getSequence']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get all sequences related to a contact.
         * @param {string} contactId 
         * @param {Array<GetSequencesByContactStatusEnum>} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSequencesByContact(contactId: string, status?: Array<GetSequencesByContactStatusEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SequenceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSequencesByContact(contactId, status, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SequencesApi.getSequencesByContact']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Pause sequence interactions and updates sequence status to PAUSED
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pauseSequence(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pauseSequence(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SequencesApi.pauseSequence']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Pause sequences, based on a sequence template id
         * @param {string} sequenceTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pauseSequencesBySequenceTemplateId(sequenceTemplateId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pauseSequencesBySequenceTemplateId(sequenceTemplateId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SequencesApi.pauseSequencesBySequenceTemplateId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Pause sequences for current team
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pauseSequencesForTeam(teamId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pauseSequencesForTeam(teamId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SequencesApi.pauseSequencesForTeam']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Resume sequence interactions and updates sequence status to IN_PROGRESS
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resumeSequence(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resumeSequence(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SequencesApi.resumeSequence']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Resume sequences, based on a sequence template id
         * @param {string} sequenceTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resumeSequencesBySequenceTemplateId(sequenceTemplateId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resumeSequencesBySequenceTemplateId(sequenceTemplateId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SequencesApi.resumeSequencesBySequenceTemplateId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Resume sequences for current team
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resumeSequencesForTeam(teamId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resumeSequencesForTeam(teamId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SequencesApi.resumeSequencesForTeam']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Triggers many sequences template, based on a lead source id
         * @param {SequenceRequestLeadSourceTrigger} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async triggerSequencesByLeadSourceId(body: SequenceRequestLeadSourceTrigger, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TriggerMultipleSequencesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.triggerSequencesByLeadSourceId(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SequencesApi.triggerSequencesByLeadSourceId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Updates sequence status to UNENROLLED, also cancelling all tasks within it
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unenrollSequence(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unenrollSequence(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SequencesApi.unenrollSequence']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * SequencesApi - factory interface
 * @export
 */
export const SequencesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SequencesApiFp(configuration)
    return {
        /**
         * 
         * @summary Triggers a sequence template for each contact, creating sequences
         * @param {SequenceRequestMultipleTrigger} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMultipleSequences(body: SequenceRequestMultipleTrigger, options?: any): AxiosPromise<TriggerMultipleSequencesResponse> {
            return localVarFp.createMultipleSequences(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Triggers a sequence template, creating a sequence
         * @param {SequenceRequestTrigger} sequenceRequestTrigger 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSequence(sequenceRequestTrigger: SequenceRequestTrigger, options?: any): AxiosPromise<SequenceResponse> {
            return localVarFp.createSequence(sequenceRequestTrigger, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get sequence information by Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequence(id: string, options?: any): AxiosPromise<SequenceResponse> {
            return localVarFp.getSequence(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all sequences related to a contact.
         * @param {string} contactId 
         * @param {Array<GetSequencesByContactStatusEnum>} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequencesByContact(contactId: string, status?: Array<GetSequencesByContactStatusEnum>, options?: any): AxiosPromise<Array<SequenceResponse>> {
            return localVarFp.getSequencesByContact(contactId, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pause sequence interactions and updates sequence status to PAUSED
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pauseSequence(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.pauseSequence(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pause sequences, based on a sequence template id
         * @param {string} sequenceTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pauseSequencesBySequenceTemplateId(sequenceTemplateId: string, options?: any): AxiosPromise<void> {
            return localVarFp.pauseSequencesBySequenceTemplateId(sequenceTemplateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pause sequences for current team
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pauseSequencesForTeam(teamId: string, options?: any): AxiosPromise<void> {
            return localVarFp.pauseSequencesForTeam(teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resume sequence interactions and updates sequence status to IN_PROGRESS
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeSequence(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.resumeSequence(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resume sequences, based on a sequence template id
         * @param {string} sequenceTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeSequencesBySequenceTemplateId(sequenceTemplateId: string, options?: any): AxiosPromise<void> {
            return localVarFp.resumeSequencesBySequenceTemplateId(sequenceTemplateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resume sequences for current team
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeSequencesForTeam(teamId: string, options?: any): AxiosPromise<void> {
            return localVarFp.resumeSequencesForTeam(teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Triggers many sequences template, based on a lead source id
         * @param {SequenceRequestLeadSourceTrigger} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerSequencesByLeadSourceId(body: SequenceRequestLeadSourceTrigger, options?: any): AxiosPromise<TriggerMultipleSequencesResponse> {
            return localVarFp.triggerSequencesByLeadSourceId(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates sequence status to UNENROLLED, also cancelling all tasks within it
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unenrollSequence(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.unenrollSequence(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SequencesApi - interface
 * @export
 * @interface SequencesApi
 */
export interface SequencesApiInterface {
    /**
     * 
     * @summary Triggers a sequence template for each contact, creating sequences
     * @param {SequenceRequestMultipleTrigger} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApiInterface
     */
    createMultipleSequences(body: SequenceRequestMultipleTrigger, options?: RawAxiosRequestConfig): AxiosPromise<TriggerMultipleSequencesResponse>;

    /**
     * 
     * @summary Triggers a sequence template, creating a sequence
     * @param {SequenceRequestTrigger} sequenceRequestTrigger 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApiInterface
     */
    createSequence(sequenceRequestTrigger: SequenceRequestTrigger, options?: RawAxiosRequestConfig): AxiosPromise<SequenceResponse>;

    /**
     * 
     * @summary Get sequence information by Id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApiInterface
     */
    getSequence(id: string, options?: RawAxiosRequestConfig): AxiosPromise<SequenceResponse>;

    /**
     * 
     * @summary Get all sequences related to a contact.
     * @param {string} contactId 
     * @param {Array<GetSequencesByContactStatusEnum>} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApiInterface
     */
    getSequencesByContact(contactId: string, status?: Array<GetSequencesByContactStatusEnum>, options?: RawAxiosRequestConfig): AxiosPromise<Array<SequenceResponse>>;

    /**
     * 
     * @summary Pause sequence interactions and updates sequence status to PAUSED
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApiInterface
     */
    pauseSequence(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Pause sequences, based on a sequence template id
     * @param {string} sequenceTemplateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApiInterface
     */
    pauseSequencesBySequenceTemplateId(sequenceTemplateId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Pause sequences for current team
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApiInterface
     */
    pauseSequencesForTeam(teamId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Resume sequence interactions and updates sequence status to IN_PROGRESS
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApiInterface
     */
    resumeSequence(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Resume sequences, based on a sequence template id
     * @param {string} sequenceTemplateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApiInterface
     */
    resumeSequencesBySequenceTemplateId(sequenceTemplateId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Resume sequences for current team
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApiInterface
     */
    resumeSequencesForTeam(teamId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Triggers many sequences template, based on a lead source id
     * @param {SequenceRequestLeadSourceTrigger} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApiInterface
     */
    triggerSequencesByLeadSourceId(body: SequenceRequestLeadSourceTrigger, options?: RawAxiosRequestConfig): AxiosPromise<TriggerMultipleSequencesResponse>;

    /**
     * 
     * @summary Updates sequence status to UNENROLLED, also cancelling all tasks within it
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApiInterface
     */
    unenrollSequence(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * SequencesApi - object-oriented interface
 * @export
 * @class SequencesApi
 * @extends {BaseAPI}
 */
export class SequencesApi extends BaseAPI implements SequencesApiInterface {
    /**
     * 
     * @summary Triggers a sequence template for each contact, creating sequences
     * @param {SequenceRequestMultipleTrigger} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApi
     */
    public createMultipleSequences(body: SequenceRequestMultipleTrigger, options?: RawAxiosRequestConfig) {
        return SequencesApiFp(this.configuration).createMultipleSequences(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Triggers a sequence template, creating a sequence
     * @param {SequenceRequestTrigger} sequenceRequestTrigger 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApi
     */
    public createSequence(sequenceRequestTrigger: SequenceRequestTrigger, options?: RawAxiosRequestConfig) {
        return SequencesApiFp(this.configuration).createSequence(sequenceRequestTrigger, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get sequence information by Id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApi
     */
    public getSequence(id: string, options?: RawAxiosRequestConfig) {
        return SequencesApiFp(this.configuration).getSequence(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all sequences related to a contact.
     * @param {string} contactId 
     * @param {Array<GetSequencesByContactStatusEnum>} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApi
     */
    public getSequencesByContact(contactId: string, status?: Array<GetSequencesByContactStatusEnum>, options?: RawAxiosRequestConfig) {
        return SequencesApiFp(this.configuration).getSequencesByContact(contactId, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pause sequence interactions and updates sequence status to PAUSED
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApi
     */
    public pauseSequence(id: string, options?: RawAxiosRequestConfig) {
        return SequencesApiFp(this.configuration).pauseSequence(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pause sequences, based on a sequence template id
     * @param {string} sequenceTemplateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApi
     */
    public pauseSequencesBySequenceTemplateId(sequenceTemplateId: string, options?: RawAxiosRequestConfig) {
        return SequencesApiFp(this.configuration).pauseSequencesBySequenceTemplateId(sequenceTemplateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pause sequences for current team
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApi
     */
    public pauseSequencesForTeam(teamId: string, options?: RawAxiosRequestConfig) {
        return SequencesApiFp(this.configuration).pauseSequencesForTeam(teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resume sequence interactions and updates sequence status to IN_PROGRESS
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApi
     */
    public resumeSequence(id: string, options?: RawAxiosRequestConfig) {
        return SequencesApiFp(this.configuration).resumeSequence(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resume sequences, based on a sequence template id
     * @param {string} sequenceTemplateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApi
     */
    public resumeSequencesBySequenceTemplateId(sequenceTemplateId: string, options?: RawAxiosRequestConfig) {
        return SequencesApiFp(this.configuration).resumeSequencesBySequenceTemplateId(sequenceTemplateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resume sequences for current team
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApi
     */
    public resumeSequencesForTeam(teamId: string, options?: RawAxiosRequestConfig) {
        return SequencesApiFp(this.configuration).resumeSequencesForTeam(teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Triggers many sequences template, based on a lead source id
     * @param {SequenceRequestLeadSourceTrigger} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApi
     */
    public triggerSequencesByLeadSourceId(body: SequenceRequestLeadSourceTrigger, options?: RawAxiosRequestConfig) {
        return SequencesApiFp(this.configuration).triggerSequencesByLeadSourceId(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates sequence status to UNENROLLED, also cancelling all tasks within it
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequencesApi
     */
    public unenrollSequence(id: string, options?: RawAxiosRequestConfig) {
        return SequencesApiFp(this.configuration).unenrollSequence(id, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetSequencesByContactStatusEnum = {
    InProgress: 'IN_PROGRESS',
    PausedInProspectLevel: 'PAUSED_IN_PROSPECT_LEVEL',
    PausedInTemplateLevel: 'PAUSED_IN_TEMPLATE_LEVEL',
    PausedInTeamLevel: 'PAUSED_IN_TEAM_LEVEL',
    Completed: 'COMPLETED',
    ContactAnswered: 'CONTACT_ANSWERED',
    Canceled: 'CANCELED',
    Unenrolled: 'UNENROLLED'
} as const;
export type GetSequencesByContactStatusEnum = typeof GetSequencesByContactStatusEnum[keyof typeof GetSequencesByContactStatusEnum];


/**
 * StagesApi - axios parameter creator
 * @export
 */
export const StagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a new stage
         * @param {StageRequestCreate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStage: async (body: StageRequestCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createStage', 'body', body)
            const localVarPath = `/deal-flow/stages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes stage by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStage: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteStage', 'id', id)
            const localVarPath = `/deal-flow/stages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Stage by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStage: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getStage', 'id', id)
            const localVarPath = `/deal-flow/stages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a stage
         * @param {StageRequestUpdate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStage: async (body: StageRequestUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateStage', 'body', body)
            const localVarPath = `/deal-flow/stages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StagesApi - functional programming interface
 * @export
 */
export const StagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a new stage
         * @param {StageRequestCreate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStage(body: StageRequestCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnPipelineStage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStage(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StagesApi.createStage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes stage by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStage(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStage(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StagesApi.deleteStage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Stage by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStage(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnPipelineStage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStage(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StagesApi.getStage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Updates a stage
         * @param {StageRequestUpdate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStage(body: StageRequestUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnPipelineStage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStage(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StagesApi.updateStage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * StagesApi - factory interface
 * @export
 */
export const StagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StagesApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a new stage
         * @param {StageRequestCreate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStage(body: StageRequestCreate, options?: any): AxiosPromise<KnPipelineStage> {
            return localVarFp.createStage(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes stage by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStage(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteStage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Stage by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStage(id: string, options?: any): AxiosPromise<KnPipelineStage> {
            return localVarFp.getStage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a stage
         * @param {StageRequestUpdate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStage(body: StageRequestUpdate, options?: any): AxiosPromise<KnPipelineStage> {
            return localVarFp.updateStage(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StagesApi - interface
 * @export
 * @interface StagesApi
 */
export interface StagesApiInterface {
    /**
     * 
     * @summary Creates a new stage
     * @param {StageRequestCreate} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StagesApiInterface
     */
    createStage(body: StageRequestCreate, options?: RawAxiosRequestConfig): AxiosPromise<KnPipelineStage>;

    /**
     * 
     * @summary Deletes stage by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StagesApiInterface
     */
    deleteStage(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Get Stage by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StagesApiInterface
     */
    getStage(id: string, options?: RawAxiosRequestConfig): AxiosPromise<KnPipelineStage>;

    /**
     * 
     * @summary Updates a stage
     * @param {StageRequestUpdate} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StagesApiInterface
     */
    updateStage(body: StageRequestUpdate, options?: RawAxiosRequestConfig): AxiosPromise<KnPipelineStage>;

}

/**
 * StagesApi - object-oriented interface
 * @export
 * @class StagesApi
 * @extends {BaseAPI}
 */
export class StagesApi extends BaseAPI implements StagesApiInterface {
    /**
     * 
     * @summary Creates a new stage
     * @param {StageRequestCreate} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StagesApi
     */
    public createStage(body: StageRequestCreate, options?: RawAxiosRequestConfig) {
        return StagesApiFp(this.configuration).createStage(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes stage by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StagesApi
     */
    public deleteStage(id: string, options?: RawAxiosRequestConfig) {
        return StagesApiFp(this.configuration).deleteStage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Stage by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StagesApi
     */
    public getStage(id: string, options?: RawAxiosRequestConfig) {
        return StagesApiFp(this.configuration).getStage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a stage
     * @param {StageRequestUpdate} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StagesApi
     */
    public updateStage(body: StageRequestUpdate, options?: RawAxiosRequestConfig) {
        return StagesApiFp(this.configuration).updateStage(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TasksApi - axios parameter creator
 * @export
 */
export const TasksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Soft delete a Task record based on its PK ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('_delete', 'id', id)
            const localVarPath = `/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates and returns a new Task entity object
         * @param {TaskRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTask: async (body: TaskRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createTask', 'body', body)
            const localVarPath = `/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all existing Task objects
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (body: KnLookupRequest2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('findAll', 'body', body)
            const localVarPath = `/tasks/lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a unique Task entity object based on it PK ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get', 'id', id)
            const localVarPath = `/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing Task entity object returns the updated Task entity object 
         * @param {string} id 
         * @param {TaskRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTask: async (id: string, body: TaskRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTask', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateTask', 'body', body)
            const localVarPath = `/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TasksApi - functional programming interface
 * @export
 */
export const TasksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TasksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Soft delete a Task record based on its PK ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TasksApi._delete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Creates and returns a new Task entity object
         * @param {TaskRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTask(body: TaskRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTask(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TasksApi.createTask']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Returns all existing Task objects
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(body: KnLookupRequest2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnLookupResponseTaskLine>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAll(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TasksApi.findAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a unique Task entity object based on it PK ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TasksApi.get']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Updates an existing Task entity object returns the updated Task entity object 
         * @param {string} id 
         * @param {TaskRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTask(id: string, body: TaskRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTask(id, body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TasksApi.updateTask']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TasksApi - factory interface
 * @export
 */
export const TasksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TasksApiFp(configuration)
    return {
        /**
         * 
         * @summary Soft delete a Task record based on its PK ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp._delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates and returns a new Task entity object
         * @param {TaskRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTask(body: TaskRequest, options?: any): AxiosPromise<TaskDto> {
            return localVarFp.createTask(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all existing Task objects
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(body: KnLookupRequest2, options?: any): AxiosPromise<KnLookupResponseTaskLine> {
            return localVarFp.findAll(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a unique Task entity object based on it PK ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any): AxiosPromise<TaskDto> {
            return localVarFp.get(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an existing Task entity object returns the updated Task entity object 
         * @param {string} id 
         * @param {TaskRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTask(id: string, body: TaskRequest, options?: any): AxiosPromise<TaskDto> {
            return localVarFp.updateTask(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TasksApi - interface
 * @export
 * @interface TasksApi
 */
export interface TasksApiInterface {
    /**
     * 
     * @summary Soft delete a Task record based on its PK ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApiInterface
     */
    _delete(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Creates and returns a new Task entity object
     * @param {TaskRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApiInterface
     */
    createTask(body: TaskRequest, options?: RawAxiosRequestConfig): AxiosPromise<TaskDto>;

    /**
     * 
     * @summary Returns all existing Task objects
     * @param {KnLookupRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApiInterface
     */
    findAll(body: KnLookupRequest2, options?: RawAxiosRequestConfig): AxiosPromise<KnLookupResponseTaskLine>;

    /**
     * 
     * @summary Returns a unique Task entity object based on it PK ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApiInterface
     */
    get(id: string, options?: RawAxiosRequestConfig): AxiosPromise<TaskDto>;

    /**
     * 
     * @summary Updates an existing Task entity object returns the updated Task entity object 
     * @param {string} id 
     * @param {TaskRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApiInterface
     */
    updateTask(id: string, body: TaskRequest, options?: RawAxiosRequestConfig): AxiosPromise<TaskDto>;

}

/**
 * TasksApi - object-oriented interface
 * @export
 * @class TasksApi
 * @extends {BaseAPI}
 */
export class TasksApi extends BaseAPI implements TasksApiInterface {
    /**
     * 
     * @summary Soft delete a Task record based on its PK ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public _delete(id: string, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration)._delete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates and returns a new Task entity object
     * @param {TaskRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public createTask(body: TaskRequest, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).createTask(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all existing Task objects
     * @param {KnLookupRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public findAll(body: KnLookupRequest2, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).findAll(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a unique Task entity object based on it PK ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public get(id: string, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).get(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an existing Task entity object returns the updated Task entity object 
     * @param {string} id 
     * @param {TaskRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public updateTask(id: string, body: TaskRequest, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).updateTask(id, body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TeamUserRolesApi - axios parameter creator
 * @export
 */
export const TeamUserRolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a user to an existing Team
         * @param {AddTeamUserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser: async (body: AddTeamUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('addUser', 'body', body)
            const localVarPath = `/teams/users/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove specified user from their current team
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUser', 'id', id)
            const localVarPath = `/teams/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByUserId: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getByUserId', 'userId', userId)
            const localVarPath = `/teams/users/{userId}/team-user-roles`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamAllUserSummary: async (teamId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('getTeamAllUserSummary', 'teamId', teamId)
            const localVarPath = `/teams/users/{teamId}/summary`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of users that belong to a same team as a current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamUsers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/teams/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update team for a user
         * @param {UpdateTeamUserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserRole: async (body: UpdateTeamUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateUserRole', 'body', body)
            const localVarPath = `/teams/users/team-user-roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeamUserRolesApi - functional programming interface
 * @export
 */
export const TeamUserRolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeamUserRolesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a user to an existing Team
         * @param {AddTeamUserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUser(body: AddTeamUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnTeamUserRole>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUser(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TeamUserRolesApi.addUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Remove specified user from their current team
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TeamUserRolesApi.deleteUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByUserId(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnTeamUserRole>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByUserId(userId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TeamUserRolesApi.getByUserId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamAllUserSummary(teamId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnTeamAggregationSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamAllUserSummary(teamId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TeamUserRolesApi.getTeamAllUserSummary']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a list of users that belong to a same team as a current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamUsers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KnTeamUserRole>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamUsers(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TeamUserRolesApi.getTeamUsers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update team for a user
         * @param {UpdateTeamUserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserRole(body: UpdateTeamUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnTeamUserRole>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserRole(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TeamUserRolesApi.updateUserRole']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TeamUserRolesApi - factory interface
 * @export
 */
export const TeamUserRolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeamUserRolesApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a user to an existing Team
         * @param {AddTeamUserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser(body: AddTeamUserRequest, options?: any): AxiosPromise<KnTeamUserRole> {
            return localVarFp.addUser(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove specified user from their current team
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByUserId(userId: string, options?: any): AxiosPromise<KnTeamUserRole> {
            return localVarFp.getByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamAllUserSummary(teamId: string, options?: any): AxiosPromise<KnTeamAggregationSummary> {
            return localVarFp.getTeamAllUserSummary(teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of users that belong to a same team as a current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamUsers(options?: any): AxiosPromise<Array<KnTeamUserRole>> {
            return localVarFp.getTeamUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update team for a user
         * @param {UpdateTeamUserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserRole(body: UpdateTeamUserRequest, options?: any): AxiosPromise<KnTeamUserRole> {
            return localVarFp.updateUserRole(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeamUserRolesApi - interface
 * @export
 * @interface TeamUserRolesApi
 */
export interface TeamUserRolesApiInterface {
    /**
     * 
     * @summary Add a user to an existing Team
     * @param {AddTeamUserRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamUserRolesApiInterface
     */
    addUser(body: AddTeamUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnTeamUserRole>;

    /**
     * 
     * @summary Remove specified user from their current team
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamUserRolesApiInterface
     */
    deleteUser(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamUserRolesApiInterface
     */
    getByUserId(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<KnTeamUserRole>;

    /**
     * 
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamUserRolesApiInterface
     */
    getTeamAllUserSummary(teamId: string, options?: RawAxiosRequestConfig): AxiosPromise<KnTeamAggregationSummary>;

    /**
     * 
     * @summary Returns a list of users that belong to a same team as a current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamUserRolesApiInterface
     */
    getTeamUsers(options?: RawAxiosRequestConfig): AxiosPromise<Array<KnTeamUserRole>>;

    /**
     * 
     * @summary Update team for a user
     * @param {UpdateTeamUserRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamUserRolesApiInterface
     */
    updateUserRole(body: UpdateTeamUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnTeamUserRole>;

}

/**
 * TeamUserRolesApi - object-oriented interface
 * @export
 * @class TeamUserRolesApi
 * @extends {BaseAPI}
 */
export class TeamUserRolesApi extends BaseAPI implements TeamUserRolesApiInterface {
    /**
     * 
     * @summary Add a user to an existing Team
     * @param {AddTeamUserRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamUserRolesApi
     */
    public addUser(body: AddTeamUserRequest, options?: RawAxiosRequestConfig) {
        return TeamUserRolesApiFp(this.configuration).addUser(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove specified user from their current team
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamUserRolesApi
     */
    public deleteUser(id: string, options?: RawAxiosRequestConfig) {
        return TeamUserRolesApiFp(this.configuration).deleteUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamUserRolesApi
     */
    public getByUserId(userId: string, options?: RawAxiosRequestConfig) {
        return TeamUserRolesApiFp(this.configuration).getByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamUserRolesApi
     */
    public getTeamAllUserSummary(teamId: string, options?: RawAxiosRequestConfig) {
        return TeamUserRolesApiFp(this.configuration).getTeamAllUserSummary(teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of users that belong to a same team as a current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamUserRolesApi
     */
    public getTeamUsers(options?: RawAxiosRequestConfig) {
        return TeamUserRolesApiFp(this.configuration).getTeamUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update team for a user
     * @param {UpdateTeamUserRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamUserRolesApi
     */
    public updateUserRole(body: UpdateTeamUserRequest, options?: RawAxiosRequestConfig) {
        return TeamUserRolesApiFp(this.configuration).updateUserRole(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TeamsApi - axios parameter creator
 * @export
 */
export const TeamsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a Team entity object
         * @param {TeamRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeam: async (body: TeamRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createTeam', 'body', body)
            const localVarPath = `/teams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the team of the current user & patch the app metadata with current team and user role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentTeam: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/teams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a Team entity object
         * @param {string} id 
         * @param {TeamRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeam: async (id: string, body: TeamRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTeam', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateTeam', 'body', body)
            const localVarPath = `/teams/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a logo image to GCP storage to be available as a publicly available image link
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLogoPicture: async (file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadLogoPicture', 'file', file)
            const localVarPath = `/teams/logo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeamsApi - functional programming interface
 * @export
 */
export const TeamsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeamsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a Team entity object
         * @param {TeamRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTeam(body: TeamRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnTeam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTeam(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TeamsApi.createTeam']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get the team of the current user & patch the app metadata with current team and user role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentTeam(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnTeam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentTeam(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TeamsApi.getCurrentTeam']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Updates a Team entity object
         * @param {string} id 
         * @param {TeamRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTeam(id: string, body: TeamRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnTeam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTeam(id, body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TeamsApi.updateTeam']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Upload a logo image to GCP storage to be available as a publicly available image link
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadLogoPicture(file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadLogoPicture(file, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TeamsApi.uploadLogoPicture']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TeamsApi - factory interface
 * @export
 */
export const TeamsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeamsApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a Team entity object
         * @param {TeamRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeam(body: TeamRequest, options?: any): AxiosPromise<KnTeam> {
            return localVarFp.createTeam(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the team of the current user & patch the app metadata with current team and user role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentTeam(options?: any): AxiosPromise<KnTeam> {
            return localVarFp.getCurrentTeam(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a Team entity object
         * @param {string} id 
         * @param {TeamRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeam(id: string, body: TeamRequest, options?: any): AxiosPromise<KnTeam> {
            return localVarFp.updateTeam(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a logo image to GCP storage to be available as a publicly available image link
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLogoPicture(file: File, options?: any): AxiosPromise<void> {
            return localVarFp.uploadLogoPicture(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeamsApi - interface
 * @export
 * @interface TeamsApi
 */
export interface TeamsApiInterface {
    /**
     * 
     * @summary Creates a Team entity object
     * @param {TeamRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    createTeam(body: TeamRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnTeam>;

    /**
     * 
     * @summary Get the team of the current user & patch the app metadata with current team and user role
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    getCurrentTeam(options?: RawAxiosRequestConfig): AxiosPromise<KnTeam>;

    /**
     * 
     * @summary Updates a Team entity object
     * @param {string} id 
     * @param {TeamRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    updateTeam(id: string, body: TeamRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnTeam>;

    /**
     * 
     * @summary Upload a logo image to GCP storage to be available as a publicly available image link
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    uploadLogoPicture(file: File, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * TeamsApi - object-oriented interface
 * @export
 * @class TeamsApi
 * @extends {BaseAPI}
 */
export class TeamsApi extends BaseAPI implements TeamsApiInterface {
    /**
     * 
     * @summary Creates a Team entity object
     * @param {TeamRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApi
     */
    public createTeam(body: TeamRequest, options?: RawAxiosRequestConfig) {
        return TeamsApiFp(this.configuration).createTeam(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the team of the current user & patch the app metadata with current team and user role
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApi
     */
    public getCurrentTeam(options?: RawAxiosRequestConfig) {
        return TeamsApiFp(this.configuration).getCurrentTeam(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a Team entity object
     * @param {string} id 
     * @param {TeamRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApi
     */
    public updateTeam(id: string, body: TeamRequest, options?: RawAxiosRequestConfig) {
        return TeamsApiFp(this.configuration).updateTeam(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a logo image to GCP storage to be available as a publicly available image link
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApi
     */
    public uploadLogoPicture(file: File, options?: RawAxiosRequestConfig) {
        return TeamsApiFp(this.configuration).uploadLogoPicture(file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TriggerActionsApi - axios parameter creator
 * @export
 */
export const TriggerActionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns a List of available ActionType\'s
         * @param {ListByEventTypeEventEnum} event 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listByEventType: async (event: ListByEventTypeEventEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'event' is not null or undefined
            assertParamExists('listByEventType', 'event', event)
            const localVarPath = `/triggers/actions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)

            if (event !== undefined) {
                localVarQueryParameter['event'] = event;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TriggerActionsApi - functional programming interface
 * @export
 */
export const TriggerActionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TriggerActionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns a List of available ActionType\'s
         * @param {ListByEventTypeEventEnum} event 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listByEventType(event: ListByEventTypeEventEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listByEventType(event, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TriggerActionsApi.listByEventType']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TriggerActionsApi - factory interface
 * @export
 */
export const TriggerActionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TriggerActionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns a List of available ActionType\'s
         * @param {ListByEventTypeEventEnum} event 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listByEventType(event: ListByEventTypeEventEnum, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.listByEventType(event, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TriggerActionsApi - interface
 * @export
 * @interface TriggerActionsApi
 */
export interface TriggerActionsApiInterface {
    /**
     * 
     * @summary Returns a List of available ActionType\'s
     * @param {ListByEventTypeEventEnum} event 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggerActionsApiInterface
     */
    listByEventType(event: ListByEventTypeEventEnum, options?: RawAxiosRequestConfig): AxiosPromise<Array<string>>;

}

/**
 * TriggerActionsApi - object-oriented interface
 * @export
 * @class TriggerActionsApi
 * @extends {BaseAPI}
 */
export class TriggerActionsApi extends BaseAPI implements TriggerActionsApiInterface {
    /**
     * 
     * @summary Returns a List of available ActionType\'s
     * @param {ListByEventTypeEventEnum} event 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggerActionsApi
     */
    public listByEventType(event: ListByEventTypeEventEnum, options?: RawAxiosRequestConfig) {
        return TriggerActionsApiFp(this.configuration).listByEventType(event, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListByEventTypeEventEnum = {
    DealFlowStateChange: 'DEAL_FLOW_STATE_CHANGE',
    MeetingStateChange: 'MEETING_STATE_CHANGE',
    ProspectEngagement: 'PROSPECT_ENGAGEMENT',
    DealAssigneeChange: 'DEAL_ASSIGNEE_CHANGE',
    NewContact: 'NEW_CONTACT',
    ProspectNoResponse: 'PROSPECT_NO_RESPONSE'
} as const;
export type ListByEventTypeEventEnum = typeof ListByEventTypeEventEnum[keyof typeof ListByEventTypeEventEnum];


/**
 * TriggersApi - axios parameter creator
 * @export
 */
export const TriggersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a new Trigger object and returns it after successful creation
         * @param {TriggerRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTrigger: async (body: TriggerRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createTrigger', 'body', body)
            const localVarPath = `/triggers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Trigger by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrigger: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTrigger', 'id', id)
            const localVarPath = `/triggers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Team triggers summary
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamTriggerSummary: async (teamId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('getTeamTriggerSummary', 'teamId', teamId)
            const localVarPath = `/triggers/team/{teamId}/summary`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a single Trigger object
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrigger: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTrigger', 'id', id)
            const localVarPath = `/triggers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a TriggerPage DTO object contains a list of all Triggers
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTrigger: async (body: KnLookupRequest2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('listTrigger', 'body', body)
            const localVarPath = `/triggers/lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updated an existing Trigger object and returns it after successful PUT operation
         * @param {string} id 
         * @param {TriggerRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrigger: async (id: string, body: TriggerRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTrigger', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateTrigger', 'body', body)
            const localVarPath = `/triggers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TriggersApi - functional programming interface
 * @export
 */
export const TriggersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TriggersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a new Trigger object and returns it after successful creation
         * @param {TriggerRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTrigger(body: TriggerRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trigger>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTrigger(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TriggersApi.createTrigger']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Trigger by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTrigger(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTrigger(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TriggersApi.deleteTrigger']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Team triggers summary
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamTriggerSummary(teamId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnTeamTriggerSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamTriggerSummary(teamId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TriggersApi.getTeamTriggerSummary']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a single Trigger object
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrigger(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trigger>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrigger(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TriggersApi.getTrigger']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a TriggerPage DTO object contains a list of all Triggers
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTrigger(body: KnLookupRequest2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnLookupResponseTrigger>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTrigger(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TriggersApi.listTrigger']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Updated an existing Trigger object and returns it after successful PUT operation
         * @param {string} id 
         * @param {TriggerRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTrigger(id: string, body: TriggerRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trigger>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTrigger(id, body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TriggersApi.updateTrigger']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TriggersApi - factory interface
 * @export
 */
export const TriggersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TriggersApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a new Trigger object and returns it after successful creation
         * @param {TriggerRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTrigger(body: TriggerRequest, options?: any): AxiosPromise<Trigger> {
            return localVarFp.createTrigger(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Trigger by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrigger(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTrigger(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Team triggers summary
         * @param {string} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamTriggerSummary(teamId: string, options?: any): AxiosPromise<KnTeamTriggerSummary> {
            return localVarFp.getTeamTriggerSummary(teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a single Trigger object
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrigger(id: string, options?: any): AxiosPromise<Trigger> {
            return localVarFp.getTrigger(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a TriggerPage DTO object contains a list of all Triggers
         * @param {KnLookupRequest2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTrigger(body: KnLookupRequest2, options?: any): AxiosPromise<KnLookupResponseTrigger> {
            return localVarFp.listTrigger(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updated an existing Trigger object and returns it after successful PUT operation
         * @param {string} id 
         * @param {TriggerRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrigger(id: string, body: TriggerRequest, options?: any): AxiosPromise<Trigger> {
            return localVarFp.updateTrigger(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TriggersApi - interface
 * @export
 * @interface TriggersApi
 */
export interface TriggersApiInterface {
    /**
     * 
     * @summary Creates a new Trigger object and returns it after successful creation
     * @param {TriggerRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggersApiInterface
     */
    createTrigger(body: TriggerRequest, options?: RawAxiosRequestConfig): AxiosPromise<Trigger>;

    /**
     * 
     * @summary Delete Trigger by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggersApiInterface
     */
    deleteTrigger(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Team triggers summary
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggersApiInterface
     */
    getTeamTriggerSummary(teamId: string, options?: RawAxiosRequestConfig): AxiosPromise<KnTeamTriggerSummary>;

    /**
     * 
     * @summary Returns a single Trigger object
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggersApiInterface
     */
    getTrigger(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Trigger>;

    /**
     * 
     * @summary Returns a TriggerPage DTO object contains a list of all Triggers
     * @param {KnLookupRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggersApiInterface
     */
    listTrigger(body: KnLookupRequest2, options?: RawAxiosRequestConfig): AxiosPromise<KnLookupResponseTrigger>;

    /**
     * 
     * @summary Updated an existing Trigger object and returns it after successful PUT operation
     * @param {string} id 
     * @param {TriggerRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggersApiInterface
     */
    updateTrigger(id: string, body: TriggerRequest, options?: RawAxiosRequestConfig): AxiosPromise<Trigger>;

}

/**
 * TriggersApi - object-oriented interface
 * @export
 * @class TriggersApi
 * @extends {BaseAPI}
 */
export class TriggersApi extends BaseAPI implements TriggersApiInterface {
    /**
     * 
     * @summary Creates a new Trigger object and returns it after successful creation
     * @param {TriggerRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggersApi
     */
    public createTrigger(body: TriggerRequest, options?: RawAxiosRequestConfig) {
        return TriggersApiFp(this.configuration).createTrigger(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Trigger by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggersApi
     */
    public deleteTrigger(id: string, options?: RawAxiosRequestConfig) {
        return TriggersApiFp(this.configuration).deleteTrigger(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Team triggers summary
     * @param {string} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggersApi
     */
    public getTeamTriggerSummary(teamId: string, options?: RawAxiosRequestConfig) {
        return TriggersApiFp(this.configuration).getTeamTriggerSummary(teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a single Trigger object
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggersApi
     */
    public getTrigger(id: string, options?: RawAxiosRequestConfig) {
        return TriggersApiFp(this.configuration).getTrigger(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a TriggerPage DTO object contains a list of all Triggers
     * @param {KnLookupRequest2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggersApi
     */
    public listTrigger(body: KnLookupRequest2, options?: RawAxiosRequestConfig) {
        return TriggersApiFp(this.configuration).listTrigger(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updated an existing Trigger object and returns it after successful PUT operation
     * @param {string} id 
     * @param {TriggerRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TriggersApi
     */
    public updateTrigger(id: string, body: TriggerRequest, options?: RawAxiosRequestConfig) {
        return TriggersApiFp(this.configuration).updateTrigger(id, body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Utilized for a User to accept the \'Kn Terms of Service (TOS)\'
         * @param {KnAcceptTermsOfService} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptTermsOfService: async (request: KnAcceptTermsOfService, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('acceptTermsOfService', 'request', request)
            const localVarPath = `/user/terms-of-service`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete current security user profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCurrentUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new or returns an existing User entity object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrCreate: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update current user information
         * @param {KnUserUpdateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfile: async (body: KnUserUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateUserProfile', 'body', body)
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a profile picture to GCP storage to be available as a publicly available image link
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProfilePicture: async (file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadProfilePicture', 'file', file)
            const localVarPath = `/user/profile-picture`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Utilized for a User to accept the \'Kn Terms of Service (TOS)\'
         * @param {KnAcceptTermsOfService} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptTermsOfService(request: KnAcceptTermsOfService, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptTermsOfService(request, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.acceptTermsOfService']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Delete current security user profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCurrentUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCurrentUser(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.deleteCurrentUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Creates a new or returns an existing User entity object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrCreate(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrCreate(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.getOrCreate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update current user information
         * @param {KnUserUpdateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserProfile(body: KnUserUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserProfile(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.updateUserProfile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Upload a profile picture to GCP storage to be available as a publicly available image link
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadProfilePicture(file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadProfilePicture(file, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.uploadProfilePicture']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Utilized for a User to accept the \'Kn Terms of Service (TOS)\'
         * @param {KnAcceptTermsOfService} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptTermsOfService(request: KnAcceptTermsOfService, options?: any): AxiosPromise<void> {
            return localVarFp.acceptTermsOfService(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete current security user profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCurrentUser(options?: any): AxiosPromise<void> {
            return localVarFp.deleteCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new or returns an existing User entity object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrCreate(options?: any): AxiosPromise<KnUser> {
            return localVarFp.getOrCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update current user information
         * @param {KnUserUpdateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfile(body: KnUserUpdateRequest, options?: any): AxiosPromise<KnUser> {
            return localVarFp.updateUserProfile(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a profile picture to GCP storage to be available as a publicly available image link
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProfilePicture(file: File, options?: any): AxiosPromise<void> {
            return localVarFp.uploadProfilePicture(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - interface
 * @export
 * @interface UsersApi
 */
export interface UsersApiInterface {
    /**
     * 
     * @summary Utilized for a User to accept the \'Kn Terms of Service (TOS)\'
     * @param {KnAcceptTermsOfService} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    acceptTermsOfService(request: KnAcceptTermsOfService, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Delete current security user profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    deleteCurrentUser(options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Creates a new or returns an existing User entity object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getOrCreate(options?: RawAxiosRequestConfig): AxiosPromise<KnUser>;

    /**
     * 
     * @summary Update current user information
     * @param {KnUserUpdateRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    updateUserProfile(body: KnUserUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<KnUser>;

    /**
     * 
     * @summary Upload a profile picture to GCP storage to be available as a publicly available image link
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    uploadProfilePicture(file: File, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI implements UsersApiInterface {
    /**
     * 
     * @summary Utilized for a User to accept the \'Kn Terms of Service (TOS)\'
     * @param {KnAcceptTermsOfService} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public acceptTermsOfService(request: KnAcceptTermsOfService, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).acceptTermsOfService(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete current security user profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteCurrentUser(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new or returns an existing User entity object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getOrCreate(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getOrCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update current user information
     * @param {KnUserUpdateRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUserProfile(body: KnUserUpdateRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUserProfile(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a profile picture to GCP storage to be available as a publicly available image link
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public uploadProfilePicture(file: File, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).uploadProfilePicture(file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WebhookAdminControllerApi - axios parameter creator
 * @export
 */
export const WebhookAdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {WebhookRequestCreate} dto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWebHookURL: async (dto: WebhookRequestCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dto' is not null or undefined
            assertParamExists('createWebHookURL', 'dto', dto)
            const localVarPath = `/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWebHook: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteWebHook', 'id', id)
            const localVarPath = `/webhook/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {Array<SearchWebHookTypeEnum>} [type] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchWebHook: async (name?: string, type?: Array<SearchWebHookTypeEnum>, pageNumber?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webhook/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WebhookRequestCreate} dto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWebHook: async (dto: WebhookRequestCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dto' is not null or undefined
            assertParamExists('updateWebHook', 'dto', dto)
            const localVarPath = `/webhook/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0_jwk required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0_jwk", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebhookAdminControllerApi - functional programming interface
 * @export
 */
export const WebhookAdminControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebhookAdminControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {WebhookRequestCreate} dto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWebHookURL(dto: WebhookRequestCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnWebhook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWebHookURL(dto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebhookAdminControllerApi.createWebHookURL']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWebHook(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWebHook(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebhookAdminControllerApi.deleteWebHook']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {Array<SearchWebHookTypeEnum>} [type] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchWebHook(name?: string, type?: Array<SearchWebHookTypeEnum>, pageNumber?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnPageResponseKnWebhook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchWebHook(name, type, pageNumber, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebhookAdminControllerApi.searchWebHook']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {WebhookRequestCreate} dto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWebHook(dto: WebhookRequestCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnWebhook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWebHook(dto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['WebhookAdminControllerApi.updateWebHook']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * WebhookAdminControllerApi - factory interface
 * @export
 */
export const WebhookAdminControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebhookAdminControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {WebhookRequestCreate} dto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWebHookURL(dto: WebhookRequestCreate, options?: any): AxiosPromise<KnWebhook> {
            return localVarFp.createWebHookURL(dto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWebHook(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWebHook(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {Array<SearchWebHookTypeEnum>} [type] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchWebHook(name?: string, type?: Array<SearchWebHookTypeEnum>, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<KnPageResponseKnWebhook> {
            return localVarFp.searchWebHook(name, type, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WebhookRequestCreate} dto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWebHook(dto: WebhookRequestCreate, options?: any): AxiosPromise<KnWebhook> {
            return localVarFp.updateWebHook(dto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebhookAdminControllerApi - interface
 * @export
 * @interface WebhookAdminControllerApi
 */
export interface WebhookAdminControllerApiInterface {
    /**
     * 
     * @param {WebhookRequestCreate} dto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhookAdminControllerApiInterface
     */
    createWebHookURL(dto: WebhookRequestCreate, options?: RawAxiosRequestConfig): AxiosPromise<KnWebhook>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhookAdminControllerApiInterface
     */
    deleteWebHook(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} [name] 
     * @param {Array<SearchWebHookTypeEnum>} [type] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhookAdminControllerApiInterface
     */
    searchWebHook(name?: string, type?: Array<SearchWebHookTypeEnum>, pageNumber?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<KnPageResponseKnWebhook>;

    /**
     * 
     * @param {WebhookRequestCreate} dto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhookAdminControllerApiInterface
     */
    updateWebHook(dto: WebhookRequestCreate, options?: RawAxiosRequestConfig): AxiosPromise<KnWebhook>;

}

/**
 * WebhookAdminControllerApi - object-oriented interface
 * @export
 * @class WebhookAdminControllerApi
 * @extends {BaseAPI}
 */
export class WebhookAdminControllerApi extends BaseAPI implements WebhookAdminControllerApiInterface {
    /**
     * 
     * @param {WebhookRequestCreate} dto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhookAdminControllerApi
     */
    public createWebHookURL(dto: WebhookRequestCreate, options?: RawAxiosRequestConfig) {
        return WebhookAdminControllerApiFp(this.configuration).createWebHookURL(dto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhookAdminControllerApi
     */
    public deleteWebHook(id: string, options?: RawAxiosRequestConfig) {
        return WebhookAdminControllerApiFp(this.configuration).deleteWebHook(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [name] 
     * @param {Array<SearchWebHookTypeEnum>} [type] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhookAdminControllerApi
     */
    public searchWebHook(name?: string, type?: Array<SearchWebHookTypeEnum>, pageNumber?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return WebhookAdminControllerApiFp(this.configuration).searchWebHook(name, type, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WebhookRequestCreate} dto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhookAdminControllerApi
     */
    public updateWebHook(dto: WebhookRequestCreate, options?: RawAxiosRequestConfig) {
        return WebhookAdminControllerApiFp(this.configuration).updateWebHook(dto, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const SearchWebHookTypeEnum = {
    Contact: 'CONTACT'
} as const;
export type SearchWebHookTypeEnum = typeof SearchWebHookTypeEnum[keyof typeof SearchWebHookTypeEnum];


