import DrawerLayout from "@/layouts/DrawerLayout";
import CallSummary from "@/pages/ai-voice/call-logs/call-summary/CallSummary";
import { Drawer } from "@mui/material";

type CallSummarySidePanelProps = {
  open: boolean;
  handleClose: () => void;
};

export default function CallSummarySidePanel({ open = false, handleClose }: CallSummarySidePanelProps) {
  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <DrawerLayout title="Call Summary" closeDrawer={handleClose} customWidth="980px">
        <CallSummary />
      </DrawerLayout>
    </Drawer>
  );
}
