import toast from "@/lib/toast";
import { TransferLibraryFiltersInput } from "@/pages/ai-voice/ai-agent-transfer-number-library";
import Services from "@/services";
import { KnFilter2, KnFilterOption2OperatorEnum, TransferNumberDto, TransferNumberRequest } from "@/services/generated";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

type State = {
  data?: TransferNumberDto[];
  totalCount?: number;
  loading?: boolean;
  error: string | null;
};

const initialState: State = {
  error: null,
};

export const deleteTransferNumber = createAsyncThunk(
  "aiVoice/listTransferPhoneNumber/deleteTransferNumber",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await Services.AiVoice.deleteTransferNumber(id);
      if (response.status === 200) {
        return { id };
      }
      return;
    } catch (error: any) {
      const response = error.response;

      if (response?.status === 412) {
        return rejectWithValue({ type: "agents_in_use", data: response.data.agents });
      }

      return rejectWithValue({ type: "unknown", data: error.message });
    }
  },
);

export const upsertTransferNumber = createAsyncThunk(
  "aiVoice/listTransferPhoneNumber/upsertTransferNumber",
  async (body: TransferNumberRequest, { rejectWithValue }) => {
    try {
      return await Services.AiVoice.upsertTransferNumber(body);
    } catch (error) {
      toast.error("Something went wrong!");

      return rejectWithValue(error.message);
    }
  },
);

export const lookupPhoneNumbers = createAsyncThunk(
  "aiVoice/listTransferPhoneNumber/lookupPhoneNumbers",
  async (input: TransferLibraryFiltersInput, { rejectWithValue }) => {
    try {
      const filters: KnFilter2[] = [];

      if (input.search) {
        filters.push({
          property: "name",
          options: [
            {
              operator: KnFilterOption2OperatorEnum.StartsWith,
              values: [input.search],
            },
          ],
        });
      }

      return await Services.AiVoice.fetchALlTransferNumbers({
        filters,
        pageSize: input.pageSize,
        page: input.page,
      });
    } catch (error) {
      toast.error("Something went wrong!");

      return rejectWithValue(error.message);
    }
  },
);

const listTransferPhoneNumberSlice = createSlice({
  name: "listTransferPhoneNumber",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(lookupPhoneNumbers.pending, (state) => {
        state.loading = true;
      })
      .addCase(lookupPhoneNumbers.fulfilled, (state, { payload }) => {
        state.data = payload.data.results || [];
        state.totalCount = payload.data.total || 0;
        state.loading = false;
      })
      .addCase(lookupPhoneNumbers.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(upsertTransferNumber.fulfilled, (state, { payload }) => {
        if (state.data?.find((phoneNumber) => phoneNumber.id === payload.data.id)) {
          state.data = state.data?.map((phoneNumber) =>
            phoneNumber.id === payload.data.id ? payload.data : phoneNumber,
          );
        } else {
          state.data = [payload.data, ...(state.data || [])];
        }
      })
      .addCase(deleteTransferNumber.fulfilled, (state, { payload }) => {
        if (payload?.id) {
          state.data = state.data?.filter((phoneNumber) => phoneNumber.id !== payload.id);
        }
      });
  },
  reducers: {},
});

export const {} = listTransferPhoneNumberSlice.actions;

export default listTransferPhoneNumberSlice.reducer;
