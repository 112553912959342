import { createBrowserRouter, Navigate } from "react-router-dom";
import { AuthenticationGuard } from "@/common/components/route-guard/AuthenticationGuard";
import { BillingGuard } from "@/common/components/route-guard/BillingGuard";
import { TeamGuard } from "@/common/components/route-guard/TeamGuard";
import { TermsOfServiceGuard } from "@/common/components/route-guard/TermsOfServiceGuard";
import routes from "@/common/constants/routes";

import DataImportLayout from "@/layouts/data-import/DataImportLayout";
import Layout from "@/layouts/layout";
import TriggersLayout from "@/layouts/triggers/TriggersLayout";
import {
  AiAgentTransferNumberLibraryPage,
  CreateSequencePage,
  CreateTriggerPage,
  DataImportPage,
  InboxPage,
  MainDashboardPage,
  ProspectsPage,
  SequenceBuilderPage,
  SettingsPage,
  TasksPage,
  TriggersPage,
} from "@/pages";
import AiAgentEdit from "@/pages/ai-voice/ai-agent-edit";
import AiAgentsPage from "@/pages/ai-voice/ai-agents";
import CallLogsPage from "@/pages/ai-voice/call-logs";
import NewDataImport from "@/pages/data-import/new-import";
import LeadFlowConfigPage from "@/pages/lead-flow/configure";
import Onboarding from "@/pages/onboarding";

import TermsOfServicePage from "@/pages/onboarding/steps/terms-of-service";
import UpdateTriggerPage from "@/pages/triggers/edit/UpdateTrigger";

import OauthCallback from "@/pages/oauth-callback";
import BillingSettings from "@/pages/onboarding/steps/billing";
import LeadFlowPage from "@/pages/lead-flow";

import { AdminGuard } from "../components/route-guard/AdminGuard";
import AdminPage from "@/pages/admin";
import CompanySettings from "@/pages/onboarding/steps/company";

import "react-toastify/dist/ReactToastify.css";
import "swiper/css";
import "swiper/css/grid";
import config from "./config";

export const router = createBrowserRouter([
  {
    path: routes.oauth.callback.path,
    element: <OauthCallback />,
  },
  {
    path: routes.onboard.path,
    element: (
      <AuthenticationGuard>
        <Onboarding />
      </AuthenticationGuard>
    ),
    children: [
      {
        path: routes.onboard.termsOfService.path,
        element: <TermsOfServicePage />,
      },
      {
        path: routes.onboard.team.path,
        element: (
          <TermsOfServiceGuard>
            <CompanySettings />
          </TermsOfServiceGuard>
        ),
      },
      {
        path: routes.onboard.billing.path,
        element: (
          <TermsOfServiceGuard>
            <TeamGuard>
              <BillingSettings />
            </TeamGuard>
          </TermsOfServiceGuard>
        ),
      },
    ],
  },
  {
    path: routes.root.path,
    element: (
      <AuthenticationGuard>
        <TermsOfServiceGuard>
          <TeamGuard>
            <BillingGuard>
              <Layout />
            </BillingGuard>
          </TeamGuard>
        </TermsOfServiceGuard>
      </AuthenticationGuard>
    ),
    children: [
      {
        index: true,
        element: <MainDashboardPage />,
      },
      {
        path: routes.tasks.path,
        element: <TasksPage />,
      },
      {
        path: routes.triggers.path,
        element: <TriggersLayout />,
        children: [
          {
            path: routes.triggers.table.path,
            element: <TriggersPage />,
          },
          {
            path: routes.triggers.create.path,
            element: <CreateTriggerPage />,
          },
          {
            path: routes.triggers.update.path,
            element: <UpdateTriggerPage />,
          },
        ],
      },
      {
        path: routes.prospects.path,
        element: <ProspectsPage />,
      },
      {
        path: routes.leadFlow.path,
        children: [
          {
            path: routes.leadFlow.dashboard.path,
            element: <LeadFlowPage />,
          },
          {
            path: routes.leadFlow.setup.path,
            element: <LeadFlowConfigPage />,
          },
        ],
      },
      {
        path: routes.sequence.path,
        children: [
          {
            path: routes.sequence.table.path,
            element: <SequenceBuilderPage />,
          },
          {
            path: routes.sequence.create.path,
            element: <CreateSequencePage />,
          },
          {
            path: routes.sequence.update.path,
            element: <CreateSequencePage />,
          },
        ],
      },
      {
        path: routes.inbox.path,
        element: <InboxPage />,
      },
      {
        path: routes.dataImport.path,
        element: <DataImportLayout />,
        children: [
          { path: routes.dataImport.table.path, element: <DataImportPage /> },
          { path: routes.dataImport.import.path, element: <NewDataImport /> },
        ],
      },
      {
        path: routes.aiVoice.path,
        children: [
          {
            path: routes.aiVoice.callLogs.path,
            element: <CallLogsPage />,
            children: [
              {
                path: routes.aiVoice.callLogs.id.path,
                element: <CallLogsPage />,
              },
            ],
          },
          {
            path: routes.aiVoice.agents.path,
            children: [
              {
                path: routes.aiVoice.agents.table.path,
                element: <AiAgentsPage />,
              },
              {
                path: routes.aiVoice.agents.create.path,
                element: <AiAgentEdit />,
              },
              {
                path: routes.aiVoice.agents.edit.path,
                element: <AiAgentEdit />,
              },
            ],
          },
          ...(config.flagEnableTransferNumberLibrary
            ? [
                {
                  path: routes.aiVoice.transferNumberLibrary.path,
                  element: <AiAgentTransferNumberLibraryPage />,
                  children: [
                    {
                      path: routes.aiVoice.transferNumberLibrary.id.path,
                      element: <AiAgentTransferNumberLibraryPage />,
                    },
                  ],
                },
              ]
            : []),
        ],
      },
      { path: routes.settings.path, element: <SettingsPage /> },
      {
        path: routes.admin.path,
        element: (
          <AdminGuard>
            <AdminPage />
          </AdminGuard>
        ),
      },
      { path: "*", element: <Navigate to={routes.root.path} /> },
    ],
  },
]);
