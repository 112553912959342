import { Box } from "@mui/material";
import { ExclamationOutline, X } from "heroicons-react";
import { styles } from "./loading-faq-warning-styles";

interface LoadingFAQWarningProps {
  closeIconClick: () => void;
}

export function LoadingFAQWarning({ closeIconClick }: LoadingFAQWarningProps) {
  return (
    <Box sx={styles.container}>
      <style>{styles.keyframe}</style>
      <ExclamationOutline size={24} />
      <span>Loading the Knowledge Base URL may take a moment. Please hold on--we're almost there!</span>
      <X size={18} color="#1A2135" style={styles.closeIcon} onClick={closeIconClick} />
    </Box>
  );
}
