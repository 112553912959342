import toast from "@/lib/toast";
import { getProspectsByLastName, setCurrentProspect } from "@/redux/reducers/prospects/slices/listProspects";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { Autocomplete as MuiAutocomplete } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { debounce } from "@mui/material/utils";
import { uniqueId } from "lodash";
import React, { useMemo, useState } from "react";

interface OptionType {
  label: string;
  value: string;
}

export default function ProspectsAutocomplete({ disabled }: { disabled?: boolean }) {
  const dispatch = useAppDispatch();

  const prospects = useAppSelector(({ prospects }) => prospects.listProspects.prospects);
  const currentProspect = useAppSelector((state) => state.prospects.listProspects.currentProspect);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedProspect, setSelectedProspect] = useState<OptionType | null>(() => {
    if (currentProspect) {
      return {
        label: `${currentProspect.firstName} ${currentProspect.lastName}`,
        value: currentProspect.id ?? "",
      };
    }
    return null;
  });

  const [search, setSearch] = useState(() => {
    if (currentProspect) {
      return `${currentProspect.firstName} ${currentProspect.lastName}`;
    }

    return "";
  });

  const options = useMemo(() => {
    let prospectsOptions: OptionType[] =
      prospects?.map((contact) => ({
        label: `${contact.firstName} ${contact.lastName}`,
        value: contact.id ?? "",
      })) ?? [];

    if (currentProspect && !prospectsOptions.some((prospect) => prospect.value === currentProspect.id)) {
      prospectsOptions = [
        {
          label: `${currentProspect.firstName} ${currentProspect.lastName}`,
          value: currentProspect.id ?? "",
        },
        ...prospectsOptions,
      ];
    }

    return prospectsOptions;
  }, [currentProspect, prospects]);

  const fetchWithDebounce = useMemo(
    () =>
      debounce(async (input: string) => {
        try {
          setLoading(true);

          await dispatch(
            getProspectsByLastName({
              lastName: input,
            }),
          ).unwrap();
        } catch (error) {
          toast.error("Error fetching prospects");
        } finally {
          setLoading(false);
          setOpen(true);
        }
      }, 1000),
    [dispatch],
  );

  const handleSelect = (_: React.SyntheticEvent, option: OptionType | null, reason: string) => {
    setSelectedProspect(option);

    if (option) {
      const prospect = prospects?.find((prospect) => prospect.id === option.value);

      if (prospect) {
        setSearch(option.label);
        dispatch(setCurrentProspect(prospect));
      }
    }
  };

  return (
    <MuiAutocomplete
      disabled={disabled}
      id="async-prospects-autocomplete"
      open={open}
      value={selectedProspect}
      getOptionKey={(option) => uniqueId(option.value)}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={(_, reason) => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      options={options}
      getOptionLabel={(option) => option.label}
      loading={loading}
      onChange={handleSelect}
      filterOptions={(x) => x}
      sx={{
        "& .MuiInputBase-root": {
          padding: "0px 9px",
        },
        "& .MuiFormLabel-root": {
          fontSize: "14px",
        },
        "& .MuiAutocomplete-inputRoot": {
          height: "44px",
        },
        "& .MuiAutocomplete-listbox": {
          "& .MuiAutocomplete-option": {
            padding: "10px 12px",
            "&[data-focus='true']": {
              backgroundColor: "#E7EBED",
            },
          },
        },
      }}
      inputValue={search}
      onInputChange={(event, value, reason) => {
        if (reason === "reset") {
          return;
        }

        setSearch(value);

        fetchWithDebounce(value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Select"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
