import { DealFlowOptions } from "@/pages/triggers/edit/UpdateTrigger";
import { ListByEventTypeEventEnum } from "@/services/generated";
import { KnContactLeadSourceEnum } from "@/types/enums";
import { TriggerActionType } from "../type";

export enum TriggersType {
  LEAD = "LEAD",
  DEAL = "DEAL",
}

export type TriggerOption = {
  checked?: boolean;
  image: JSX.Element;
  title: string;
  description: string;
  type: TriggersType;
};

export type TriggerForm = {
  name: string;
  type: TriggersType;
  webhookId: string;
  event: {
    eventType: ListByEventTypeEventEnum | "";
    dealFlowIdType: DealFlowOptions;
    dealFlowId: string;
    dealFlowStage: string;
    leadSource: KnContactLeadSourceEnum | "ALL";
  };
  action: {
    actionType: string | "";
    receiver: string[];
    message: string;
    pipelineId?: string; // Not used?
    dealFlowId: string; // Create Deal
    stageId?: string; // Create Deal, Set Deal FLow Stage
    name: string; // Create Deal
    assigneeId: string; // Create Deal
    // Task
    type: string;
    assignee: string;
    sequenceId: string;
    // Attachment
    // Delay
    // delay: ""
  };
  deleted: boolean;
};

export const ACTION_DEFAULT = {
  actionType: TriggerActionType.EnrollSequence,
  receiver: [],
  message: "",
  pipelineId: "",
  dealFlowId: "",
  stageId: "",
  name: "",
  assigneeId: "",
  type: "",
  assignee: "",
  sequenceId: "",
};
