import Searchbar from "@/common/components/searchbar/Searchbar";
import { useAppDispatch } from "@/redux/store";
import Box from "@mui/material/Box";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { TransferLibraryFiltersInput } from "..";

type TransferNumberFiltersProps = {
  filters: TransferLibraryFiltersInput;
  setFilters: (
    filters: TransferLibraryFiltersInput | ((prevFilters: TransferLibraryFiltersInput) => TransferLibraryFiltersInput),
  ) => void;
  loading?: boolean;
};

export default function TransferNumberFilters({ filters, setFilters, loading }: TransferNumberFiltersProps) {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState<string>("");

  const debouncedSetFilters = useMemo(
    () =>
      debounce((value: string) => {
        setFilters((prevFilters) => ({
          ...prevFilters,
          search: value,
        }));
      }, 500),
    [setFilters],
  );

  useEffect(() => {
    debouncedSetFilters(search);

    return () => debouncedSetFilters.cancel();
  }, [debouncedSetFilters, search]);

  return (
    <form>
      <Box
        sx={{
          display: "grid",
          alignItems: "center",
          gridTemplateColumns: "3fr 1fr 1fr  1fr 1fr",
          gap: "16px",
        }}
      >
        <Searchbar
          sx={{ maxWidth: 300 }}
          placeholder="Type to search"
          value={search}
          onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(value);
          }}
        />

        {/*
        <Box sx={{ width: "100%" }}>
          <Dropdown
            label="Agent"
            placeholder="Agent"
            options={agentsOptions}
            onChange={(option) => {
              setFilters({ ...filters });
            }}
          />
        </Box>
      */}
      </Box>
    </form>
  );
}
