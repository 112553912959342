import { Box, Divider, Stack, Typography } from "@mui/material";
import Button from "@/common/components/button";
import { useNavigate } from "react-router-dom";
import Stepper from "@/common/components/stepper/Stepper";
import { useForm, FormProvider } from "react-hook-form";
import React from "react";
import { createTrigger } from "@/redux/reducers/triggers/slices/createTrigger";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import routes from "@/common/constants/routes";
import { ObjectStep } from "@/pages/triggers/edit/steps/ObjectStep";
import ActionStep from "@/pages/triggers/edit/steps/action";
import LeadTriggers from "@/pages/triggers/edit/steps/event/lead";
import DealTriggers from "@/pages/triggers/edit/steps/event/deal";
import { ACTION_DEFAULT, TriggerForm } from "@/pages/triggers/edit/types";
import { KnContactLeadSourceEnum } from "@/types/enums";
import { ArrowLongLeftIcon, ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { ListByEventTypeEventEnum } from "@/services/generated";

export enum TriggersType {
  LEAD = "LEAD",
}

export enum DealFlowOptions {
  ALL = "ALL",
  SPECIFIC_DEAL_FLOW = "Specific Deal Flow",
}

export default function CreateTrigger() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { createTriggerPending } = useAppSelector(({ triggers }) => triggers.listTriggers);

  const methods = useForm<TriggerForm>({
    defaultValues: {
      name: "",
      type: TriggersType.LEAD,
      event: {
        eventType: ListByEventTypeEventEnum.NewContact,
        dealFlowIdType: DealFlowOptions.ALL,
        dealFlowId: "",
        dealFlowStage: "",
        leadSource: KnContactLeadSourceEnum.Ui,
      },
      webhookId: "",
      action: {
        ...ACTION_DEFAULT,
      },
      deleted: false,
    },
  });

  const { getValues } = methods;

  const [activeStep, setActiveStep] = React.useState<{
    idx: number;
    label: string;
  }>({ idx: 0, label: "Type" });

  const handleCancel = () => {
    navigate(routes.triggers.table.path);
  };

  const isDisabled = () => {
    const actionSelected = getValues("action.actionType");
    if (actionSelected) {
      switch (actionSelected) {
        case "CREATE_DEAL":
          if (
            watch("action.name") &&
            watch("action.dealFlowId") &&
            watch("action.stageId") &&
            watch("action.assigneeId")
          ) {
            return false;
          }
          return true;
        case "ENROLL_SEQUENCE":
          if (watch("action.sequenceId")) {
            return false;
          }
          return true;
        case "ENROLL_LEAD_FLOW":
          return false;
        default:
          return true;
      }
    }
    return true;
  };

  const onSubmit = (data: TriggerForm) => {
    try {
      dispatch(createTrigger(data)).then(() => {
        navigate(routes.triggers.table.path);
      });
    } catch (error) {
      return;
    }
  };

  const goToNextStep = () => {
    if (activeStep.idx === 0 && !getValues("name")) {
      // setError("name", "Name is required");
      return null;
    }

    if (activeStep.idx === 2) return;
    setActiveStep((prevActiveStep) => {
      return { ...prevActiveStep, idx: prevActiveStep.idx + 1 };
    });
  };

  const goToPreviousStep = () => {
    if (activeStep.idx === 0) return;
    setActiveStep((prevActiveStep) => {
      return { ...prevActiveStep, idx: prevActiveStep.idx - 1 };
    });
  };

  const { watch } = methods;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography fontSize="24px" fontWeight="bold">
          Add New Trigger
        </Typography>

        <Button sx={{ fontSize: "14px" }} data-testid="openTaskDrawer" variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
      </Stack>

      <FullLoadingOverlay loading={createTriggerPending} overlay />

      <Stack
        direction="column"
        style={{
          background: "#fff",
          padding: "20px",
        }}
      >
        <div>
          <Stepper
            steps={[
              { idx: 0, label: "Object" },
              { idx: 1, label: "Event" },
              { idx: 2, label: "Action" },
            ]}
            activeStep={activeStep.idx}
          />
        </div>

        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormProvider {...methods}>
            <Stack
              direction="column"
              style={{
                background: "#fff",
                padding: "42px 24px",
                maxWidth: "1500px",
                margin: "0 auto",
              }}
            >
              {activeStep.idx === 0 && <ObjectStep />}
              {activeStep.idx === 1 ? watch("type") === TriggersType.LEAD ? <LeadTriggers /> : <DealTriggers /> : null}
              {activeStep.idx === 2 && <ActionStep />}
            </Stack>

            <Divider style={{ margin: "20px 0px" }} />

            <div
              style={{
                display: "flex",
                justifyContent: activeStep.idx === 0 ? "flex-end" : "space-between",
                gap: "20px",
                marginTop: "20px",
              }}
            >
              {activeStep.idx > 0 && (
                <Button
                  leftIcon={<ArrowLongLeftIcon />}
                  sx={{ fontSize: "14px" }}
                  variant="secondary"
                  type="button"
                  onClick={goToPreviousStep}
                >
                  Previous
                </Button>
              )}

              {activeStep.idx < 2 && (
                <Button
                  rightIcon={<ArrowLongRightIcon />}
                  sx={{ fontSize: "14px" }}
                  variant="primary"
                  type="button"
                  onClick={goToNextStep}
                  disabled={
                    (activeStep.idx === 0 && !watch("name")) ||
                    (activeStep.idx === 1 && !watch("event.eventType")) ||
                    (activeStep.idx === 1 &&
                      watch("event.eventType") === "NEW_CONTACT" &&
                      watch("event.leadSource") === "inbound_webhook" &&
                      !watch("webhookId")) ||
                    (activeStep.idx === 2 && !watch("action.actionType"))
                  }
                >
                  Next
                </Button>
              )}
              {activeStep.idx === 2 && (
                <Button
                  disabled={isDisabled()}
                  rightIcon={<ArrowLongRightIcon />}
                  sx={{ fontSize: "14px" }}
                  variant="primary"
                  type="submit"
                >
                  Create Trigger
                </Button>
              )}
            </div>
          </FormProvider>
        </form>
      </Stack>
    </Box>
  );
}
