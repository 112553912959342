import routes from "@/common/constants/routes";
import DrawerLayout from "@/layouts/DrawerLayout";
import toast from "@/lib/toast";
import { createNewVersionSequenceTemplate } from "@/redux/reducers/sequences/slices/createSequence";
import { useAppDispatch } from "@/redux/store";
import { SequenceTemplateVersionResponse } from "@/services/generated";
import { SequenceTemplate } from "@/types/sequence";
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import StatusBadge from "../sequence-status";

interface SequenceVersionsProps {
  versions: SequenceTemplateVersionResponse[];
  sequence: SequenceTemplate;
  open?: boolean;
  onClose?: () => void;
}

export default function SequenceVersions({ open = false, onClose, sequence, versions }: SequenceVersionsProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const versionsStatuses = useMemo(() => {
    return sequence.versions?.map((version) => version.status) || [];
  }, [sequence]);

  const handleClose = () => {
    onClose?.();
  };

  const handleNewDraft = async () => {
    try {
      const activeSequence = versions.find((sequence) => sequence.status === "ACTIVE");

      if (!activeSequence?.id) return;

      const newVersion = await dispatch(createNewVersionSequenceTemplate(activeSequence.id)).unwrap();

      if (newVersion.id) {
        handleNavigate(newVersion.id);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const handleNavigate = (id: string) => {
    navigate(routes.sequence.update.with({ id: id }));
    handleClose();
  };

  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <DrawerLayout title="Versions" closeDrawer={handleClose}>
        <Box sx={{ gap: 3, height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <TableContainer sx={{ mt: 2, border: "1px solid #E7EBED", borderRadius: "8px", overflow: "hidden" }}>
            <Table>
              <TableHead sx={{ backgroundColor: theme.palette.tableRow.heading }}>
                <TableRow sx={{ textTransform: "uppercase" }}>
                  <TableCell sx={{ fontWeight: 600 }}>Version</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {versions
                  ? versions.map((version) => (
                      <TableRow
                        key={`versions-${version.id}`}
                        onClick={() => handleNavigate(version.id!)}
                        sx={{
                          cursor: "pointer",
                          "&:nth-of-type(odd)": {
                            backgroundColor: "#FFFFFF",
                          },
                          "&:nth-of-type(even)": {
                            backgroundColor: "#FBFBFB",
                          },
                        }}
                      >
                        <TableCell>
                          {sequence.name} - Version {version.version}
                        </TableCell>
                        <TableCell>{version.status && <StatusBadge status={version.status} />}</TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ display: "flex", gap: 1, alignSelf: "end" }}>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            {!versionsStatuses.includes("DRAFT") && !versionsStatuses.includes("INACTIVE") && (
              <Button variant="primary" onClick={handleNewDraft}>
                Create New Draft
              </Button>
            )}
          </Box>
        </Box>
      </DrawerLayout>
    </Drawer>
  );
}
