import Dropdown from "@/common/components/dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import Link from "@/common/components/link";
import ViewAll from "@/common/components/view-all/ViewAll";
import toast from "@/lib/toast";
import { AiAgentForm } from "@/pages/ai-voice/ai-agent-edit";
import { KnAiAgentTransferNumberRequest, KnTransferNumberHours } from "@/services/generated";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Box, FormControl, IconButton, Typography, useTheme } from "@mui/material";
import { cloneDeep, uniqueId } from "lodash";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { MEETING_TIME_OPTIONS, MEETING_TIMEZONE_OPTIONS } from "../../../../call-flow-step";

interface TransferNumberHoursProps {
  index: number;
}

const DAYS_OF_WEEK_OPTIONS = [
  { label: "Sunday", value: "SUNDAY" },
  { label: "Monday", value: "MONDAY" },
  { label: "Tuesday", value: "TUESDAY" },
  { label: "Wednesday", value: "WEDNESDAY" },
  { label: "Thursday", value: "THURSDAY" },
  { label: "Friday", value: "FRIDAY" },
  { label: "Saturday", value: "SATURDAY" },
];

export default function TransferNumberHours({ index }: TransferNumberHoursProps) {
  const theme = useTheme();

  const form = useFormContext<AiAgentForm>();

  const { control, watch, setValue, formState } = form;

  const formValue: KnAiAgentTransferNumberRequest = watch(`transfer_numbers.${index}`);

  const timeZone: string = watch(`transfer_numbers.${index}.time_zone`);

  const transferHours: KnTransferNumberHours[] = watch(`transfer_numbers.${index}.transfer_number_hours`);

  const transferHoursArray = useFieldArray({
    control,
    name: `transfer_numbers.${index}.transfer_number_hours`,
  });

  const getError = (hourIndex: number) => {
    return formState.errors?.transfer_numbers?.[index]?.transfer_number_hours?.[hourIndex]?.message;
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        flexDirection: "column",
        backgroundColor: "#f8f9fa",
        borderRadius: "8px",
        padding: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 2,
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "14px",
            }}
          >
            {formValue.number}
          </Typography>
          <Typography
            sx={{
              color: "#6b747b",
              fontSize: "14px",
            }}
          >
            {formValue.name}
          </Typography>
        </Box>
        <Link
          underline="none"
          size="md"
          onClick={() => {
            const transferNumbers = form.getValues("transfer_numbers") as KnAiAgentTransferNumberRequest[];

            transferNumbers.forEach((transferNumber) => {
              if (transferNumber.transfer_number_library_id !== formValue.transfer_number_library_id) {
                transferNumber.time_zone = formValue.time_zone;
                transferNumber.transfer_number_hours = formValue.transfer_number_hours;
              }
            });

            setValue(`transfer_numbers`, cloneDeep(transferNumbers) as never);

            toast.success("Copied to all selected numbers");
          }}
        >
          Copy to All Selected Numbers
        </Link>
      </Box>
      <Box>
        <FormControl>
          <FormLabel label="Timezone" />
          <Controller
            control={control}
            name={`transfer_numbers.${index}.time_zone`}
            render={({ field, fieldState }) => (
              <Dropdown
                {...field}
                value={timeZone}
                error={fieldState.error?.message}
                onChange={(option) => {
                  field.onChange(option.value);
                }}
                options={MEETING_TIMEZONE_OPTIONS}
              />
            )}
          />
        </FormControl>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {transferHours.map((transferHour, hourIndex) => (
          <Box>
            <Box key={uniqueId("transferhour")} sx={{ display: "flex", gap: 2 }}>
              <FormControl fullWidth>
                <FormLabel label="Day" />
                <Controller
                  control={control}
                  name={`transfer_numbers.${index}.transfer_number_hours.${hourIndex}.day_of_week`}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      {...field}
                      error={fieldState.error?.message || getError(hourIndex)}
                      hideErrorMessage={!!getError(hourIndex)}
                      onChange={(option) => {
                        field.onChange(option.value);
                      }}
                      options={DAYS_OF_WEEK_OPTIONS}
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth>
                <FormLabel label="Start Time" infoIcon />
                <Controller
                  control={control}
                  name={`transfer_numbers.${index}.transfer_number_hours.${hourIndex}.start_time`}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      value={field.value as any}
                      error={fieldState.error?.message || getError(hourIndex)}
                      hideErrorMessage={!!getError(hourIndex)}
                      onChange={(option) => {
                        field.onChange(option.value);
                      }}
                      options={MEETING_TIME_OPTIONS}
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth>
                <FormLabel label="End Time" infoIcon />
                <Controller
                  control={control}
                  name={`transfer_numbers.${index}.transfer_number_hours.${hourIndex}.end_time`}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      value={field.value as any}
                      error={fieldState.error?.message || getError(hourIndex)}
                      hideErrorMessage={!!getError(hourIndex)}
                      customError={fieldState.error?.message}
                      onChange={(option) => {
                        field.onChange(option.value);
                      }}
                      options={MEETING_TIME_OPTIONS}
                    />
                  )}
                />
              </FormControl>
              <IconButton
                onClick={() => {
                  transferHoursArray.remove(hourIndex);
                }}
                sx={{ display: "flex", alignSelf: "end", mb: 0.3 }}
              >
                <TrashIcon width={24} height={24} color={theme.palette.error.main} />
              </IconButton>
            </Box>
            {getError(hourIndex) && (
              <Typography variant="body2" style={{ color: theme.palette.commonColors.danger, fontSize: "12px" }}>
                {getError(hourIndex)}
              </Typography>
            )}
          </Box>
        ))}
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <ViewAll
            sx={{ mt: 2 }}
            onClick={() => {
              transferHoursArray.append({});
            }}
            leftIcon={<PlusIcon height={16} color="#fff" />}
          >
            Add Hours
          </ViewAll>
        </Box>
      </Box>
    </Box>
  );
}
