import * as React from "react";
import { useAppDispatch } from "@/redux/store";
import MenuOptions from "@/common/components/menu-options/MenuOptions";

import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { deleteTransferNumber } from "@/redux/reducers/ai-voice/listTransferPhoneNumber";
import toast from "@/lib/toast";
import { CellContext } from "@tanstack/react-table";
import { TransferNumberDto } from "@/services/generated";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import routes from "@/common/constants/routes";

enum TransferNumberActionsEnum {
  Edit = "Edit",
  Delete = "Delete",
}

const options: { label: string; value: TransferNumberActionsEnum; icon: React.ReactElement }[] = [
  {
    label: "Edit Transfer Number",
    value: TransferNumberActionsEnum.Edit,
    icon: <PencilSquareIcon width={16} />,
  },
  {
    label: "Delete Transfer Number",
    value: TransferNumberActionsEnum.Delete,
    icon: <TrashIcon width={16} />,
  },
];

type TableActionButtonProps = {
  params: CellContext<
    TransferNumberDto & {
      id: string;
    },
    unknown
  >;
};

export default function TableActionButton({ params }: TableActionButtonProps) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MenuOptions
      open={open}
      anchorEl={anchorEl}
      handleClick={handleClick}
      handleClose={handleClose}
      options={options}
      onClick={(option) => {
        const value = option.value as TransferNumberActionsEnum;
        if (value === TransferNumberActionsEnum.Edit) {
          navigate(routes.aiVoice.transferNumberLibrary.id.with({ id: params.row.original.id }));
          handleClose();
        } else if (value === TransferNumberActionsEnum.Delete) {
          dispatch(deleteTransferNumber(params.row.original.id))
            .unwrap()
            .then(() => {
              toast.success("Transfer Number deleted successfully.");
            })
            .catch((error) => {
              if (error.type === "agents_in_use") {
                toast.warning(
                  <Box>
                    <Typography fontSize="16px">Number currently in use on</Typography>
                    <Box gap={1} display="flex">
                      {error.data?.map((agentName: string, index: number) => (
                        <Typography fontWeight={500}>
                          {agentName}
                          {index < error.data.length - 1 ? "," : ""}
                        </Typography>
                      ))}
                    </Box>
                    <Typography fontSize="16px">Please remove number from agent before proceeding.</Typography>
                  </Box>,
                );
              } else {
                toast.error("Something went wrong!");
              }
            });

          handleClose();
        }
      }}
    />
  );
}
