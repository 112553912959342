import config from "@/common/constants/config";

import { router } from "@/common/constants/router";

import store from "@/redux/store";
import { ConfirmProvider } from "material-ui-confirm";
import * as React from "react";
import { useEffect } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "swiper/css";
import "swiper/css/grid";
import userflow from "userflow.js";
import "./index.css";
import ProvidersWrapper from "./providers/Wrapper";

const UnhandledError: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error?.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

const errorHandler = (error: Error, info: { componentStack: string }) => {
  // Do something with the error
  // E.g. log to an error logging client here
};

const App: React.FC = () => {
  useEffect(() => {
    userflow.init(config.userflowToken);
  }, []);

  return (
    <ErrorBoundary FallbackComponent={UnhandledError} onError={errorHandler}>
      <ToastContainer />
      <ProvidersWrapper>
        <ConfirmProvider>
          <Provider store={store}>
            <RouterProvider router={router} />
          </Provider>
        </ConfirmProvider>
      </ProvidersWrapper>
    </ErrorBoundary>
  );
};

export default App;
