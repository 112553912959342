import toast from "@/lib/toast";
import { Search } from "@mui/icons-material";
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteOwnerState,
  AutocompleteRenderGetTagProps,
  Box,
  InputBase,
  Autocomplete as MuiAutocomplete,
  Paper,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { debounce } from "@mui/material/utils";
import { clear } from "console";
import { uniqueId } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";

export interface OptionType<T> {
  label: string;
  value: T;
}

type GenericAutocompleteProps<T> = {
  disabled?: boolean;
  onChange: (
    event: React.SyntheticEvent,
    value: OptionType<T>[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<OptionType<T>> | undefined,
  ) => void;
  state: T[];
  fetchData: (input: string) => Promise<any>;
  mapData: (data: T) => OptionType<T>;
  placeholder?: string;
  isOptionEqualToValue?: (option: OptionType<T>, value: OptionType<T>) => boolean;
  getOptionKey?: (option: OptionType<T>) => string;
  error?: string;
  value?: OptionType<T>[];
  renderOption?: (props: any, option: OptionType<T>, { selected }: any) => JSX.Element;
  renderTags?: (
    value: OptionType<T>[],
    getTagProps: AutocompleteRenderGetTagProps,
    ownerState: AutocompleteOwnerState<OptionType<T>, true, false, false, "div">,
  ) => React.ReactNode;
};

export default function MultiAutocomplete<T>({
  disabled,
  onChange,
  state,
  fetchData,
  mapData,
  placeholder = "Select",
  isOptionEqualToValue,
  getOptionKey,
  error,
  value,
  renderOption,
  renderTags,
}: GenericAutocompleteProps<T>) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<OptionType<T>[]>([]);
  const [inputValue, setInputValue] = useState("");

  const fetchOptionsData = useCallback(
    async (input?: string) => {
      try {
        setLoading(true);

        await fetchData(input ?? "");
      } catch (error) {
        toast.error("Error while fetching options");
      } finally {
        setLoading(false);
      }
    },
    [fetchData],
  );

  const fetchWithDebounce = useMemo(() => debounce(fetchOptionsData, 1000), [fetchOptionsData]);

  useEffect(() => {
    const optionsUpdate = state.map(mapData);

    setOptions(optionsUpdate);
  }, [mapData, state]);

  useEffect(() => {
    fetchOptionsData();
  }, []);

  const CustomPaper = (props: any) => {
    return (
      <Paper
        {...props}
        sx={{
          width: "324px",
          padding: "6px",
        }}
      >
        {props.children}
      </Paper>
    );
  };

  return (
    <MuiAutocomplete
      multiple
      componentsProps={{
        popupIndicator: {
          sx: {
            marginRight: "8px",
            marginTop: "4px",
          },
        },
      }}
      popupIcon={
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 5L8 9L12 5" stroke="#374667" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      }
      renderInput={(params) => <TextField {...params} placeholder={!value || value?.length === 0 ? placeholder : ""} />}
      renderOption={renderOption}
      renderTags={renderTags}
      value={value}
      getOptionKey={getOptionKey}
      disabled={disabled}
      id="async-job-titles-autocomplete"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      disableClearable
      PaperComponent={CustomPaper}
      options={options}
      getOptionLabel={(option) => option.label}
      loading={loading}
      onChange={onChange}
      placeholder={placeholder}
      inputValue={inputValue}
      filterOptions={(x) => x}
      onInputChange={(event, newInputValue, reason) => {
        if (event?.type !== "click") {
          setLoading(true);
          setInputValue(newInputValue);
          fetchWithDebounce(newInputValue);
        }
      }}
      isOptionEqualToValue={isOptionEqualToValue}
      sx={{
        "& .MuiInputBase-root": {
          padding: "0px 9px",
        },
        "& .MuiFormLabel-root": {
          fontSize: "14px",
        },
        "& .MuiAutocomplete-inputRoot": {
          minHeight: "44px",
        },
        "& .MuiAutocomplete-listbox": {
          "& .MuiAutocomplete-option": {
            padding: "10px 12px",
            borderBottom: "solid 1px #e7ebed",
          },
          '&[data-focus="true"]': {
            backgroundColor: "#fff",
          },
        },
        ...(error && {
          "& .MuiOutlinedInput-root": {
            borderColor: "red",
            borderWidth: "2px",
            borderStyle: "solid",
          },
        }),
      }}
    />
  );
}
