import { ReactComponent as Delete } from "@/common/assets/svg/delete_trigger.svg";
import { ReactComponent as Duplicated } from "@/common/assets/svg/duplicated.svg";
import { ReactComponent as Edit } from "@/common/assets/svg/edit.svg";
import { ElipseIcon } from "@/common/components/elipse-icon";
import MenuOptions, { MenuOption } from "@/common/components/menu-options/MenuOptions";
import routes from "@/common/constants/routes";
import useConfirmModal from "@/common/hooks/useConfirmModal";
import toast from "@/lib/toast";
import { duplicateSequence, updateSequence } from "@/redux/reducers/sequences/slices/createSequence";
import {
  getSequencesTemplates,
  pauseBySequenceTemplateId,
  resumeBySequenceTemplateId,
} from "@/redux/reducers/sequences/slices/listSequences";
import { useAppDispatch } from "@/redux/store";
import { SequenceTemplateResponseStatusEnum } from "@/services/generated";
import theme from "@/theme/theme";
import { SequenceTemplate } from "@/types/sequence";
import { CellContext } from "@tanstack/react-table";
import * as React from "react";
import { useNavigate } from "react-router-dom";

const getOptions = (status?: SequenceTemplateResponseStatusEnum, canPause?: boolean) => {
  if (!status) {
    return [];
  }

  const baseOptions = [
    {
      label: "Edit Sequence",
      value: "edit",
      icon: <Edit />,
    },
    {
      label: "Duplicate Sequence",
      value: "duplicateSequence",
      icon: <Duplicated />,
    },
    {
      label: "Delete Sequence",
      value: "delete",
      icon: <Delete />,
    },
  ];

  if (
    status === SequenceTemplateResponseStatusEnum.PausedInTeamLevel ||
    status === SequenceTemplateResponseStatusEnum.PausedInTemplateLevel
  ) {
    return [
      {
        label: "Resume Sequence",
        value: "resume",
        icon: <ElipseIcon color={theme.palette.error.main} />,
      },
      ...baseOptions,
    ];
  }

  if (canPause) {
    return [
      {
        label: "Pause Sequence",
        value: "pause",
        icon: <ElipseIcon color={theme.palette.primary.main} />,
      },
      ...baseOptions,
    ];
  }

  return baseOptions;
};

type TableActionButtonProps = {
  params: CellContext<SequenceTemplate, unknown>;
};

export default function TableActionButton(props: TableActionButtonProps) {
  const { params } = props;
  const options = getOptions(params.row.original.status, params.row.original.canPause);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { deleteConfirm } = useConfirmModal();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = () => {
    navigate(
      routes.sequence.update.with({ id: params.row.original.latestSequenceTemplateId ?? params.row.original.id }),
    );
  };

  const handleSelectOption = (option: MenuOption) => {
    if (option.value === "delete") {
      deleteConfirm().then(() => {
        dispatch(
          updateSequence({
            id: params.row.original.latestSequenceTemplateId ?? params.row.original.id,
            name: params.row.original.name,
            status: "DELETED",
          }),
        ).catch(() => {
          toast.error("Something went wrong!");
        });
      });
    } else if (option.value === "pause") {
      deleteConfirm({
        title: "Pause Sequence",
        textDiv: <div>Confirm pausing {params.row.original.name} Sequence?</div>,
      })
        .then(() => {
          if (params.row.original.latestSequenceTemplateId) {
            dispatch(pauseBySequenceTemplateId(params.row.original.latestSequenceTemplateId))
              .then(() => {
                dispatch(getSequencesTemplates());
              })
              .catch(() => {
                toast.error("Something went wrong!");
              });
          }
        })
        .catch(() => {
          return;
        });
    } else if (option.value === "resume") {
      deleteConfirm({
        title: "Resume Sequence",
        textDiv: <div>Confirm resume {params.row.original.name} Sequence?</div>,
      })
        .then(() => {
          if (params.row.original.latestSequenceTemplateId) {
            dispatch(resumeBySequenceTemplateId(params.row.original.latestSequenceTemplateId))
              .then(() => {
                dispatch(getSequencesTemplates());
              })
              .catch(() => {
                toast.error("Something went wrong!");
              });
          }
        })
        .catch(() => {
          return;
        });
    } else if (option.value === "duplicateSequence") {
      dispatch(duplicateSequence({ id: params.row.original.latestSequenceTemplateId ?? params.row.original.id }));
    } else {
      handleNavigate();
    }
    handleClose();
  };

  return (
    <MenuOptions
      open={open}
      anchorEl={anchorEl}
      handleClick={handleClick}
      handleClose={handleClose}
      options={options}
      onClick={handleSelectOption}
    />
  );
}
