import Button from "@/common/components/button";

import { KnAiAgentCallToActionEnum, KnAiAgentTransferEnum, KnAiAgentTransferNumber } from "@/services/generated";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { AiAgentForm } from "../..";
import CallToAction from "./call-to-action";
import config from "@/common/constants/config";
import { CallToActionVariants, KnAiAgentOtherCallToActionEnum } from "./types";

export type CTAOption = {
  label: string;
  value: CTAValue;
};

export type CTAValue = {
  type: KnAiAgentCallToActionEnum;
  variant: CallToActionVariants;
};

const DEFAULT_CTA = {
  label: "Select",
  value: {
    type: KnAiAgentCallToActionEnum.None,
    variant: KnAiAgentCallToActionEnum.None,
  },
};

const regularCallToActions: CTAOption[] = [
  {
    label: "Book a Meeting",
    value: { type: KnAiAgentCallToActionEnum.BookMeeting, variant: KnAiAgentCallToActionEnum.BookMeeting },
  },
  {
    label: "Live Transfer",
    value: { type: KnAiAgentCallToActionEnum.LiveTransfer, variant: KnAiAgentCallToActionEnum.LiveTransfer },
  },
];

const otherCallToActions: CTAOption[] = [
  {
    label: "Live Transfer - Transfer Number Library",
    value: {
      type: KnAiAgentCallToActionEnum.LiveTransfer,
      variant: KnAiAgentOtherCallToActionEnum.LiveTransferNumberLibrary,
    },
  },
];

const callToActions = [...regularCallToActions, ...(config.flagEnableTransferNumberLibrary ? otherCallToActions : [])];

interface CallToActionBuilderProps {
  isCallFlowDiscoveryTransfer: boolean;
}

export default function CallToActionBuilder({ isCallFlowDiscoveryTransfer }: CallToActionBuilderProps) {
  const { getValues, setValue } = useFormContext<AiAgentForm>();

  const [primaryCallToAction, setPrimaryCallToAction] = useState<CTAOption>(DEFAULT_CTA);
  const [otherCallToAction, setOtherCallToAction] = useState<CTAOption | undefined>(undefined);

  const [availableCallToActions, setAvailableCallToActions] = useState<CTAOption[]>([]);

  useEffect(() => {
    const callToAction = getValues("call_to_action");

    setValue(
      "call_to_action",
      (isCallFlowDiscoveryTransfer ? KnAiAgentCallToActionEnum.LiveTransfer : callToAction) as never,
    );
  }, [getValues, isCallFlowDiscoveryTransfer, setValue]);

  useEffect(() => {
    setAvailableCallToActions(
      callToActions.filter((cta) => {
        if (isCallFlowDiscoveryTransfer) {
          return cta.value.type === KnAiAgentCallToActionEnum.LiveTransfer;
        }

        return cta.value.type !== primaryCallToAction.value.type && cta.value.type !== otherCallToAction?.value.type;
      }),
    );
  }, [otherCallToAction?.value, primaryCallToAction.value, isCallFlowDiscoveryTransfer]);

  useEffect(() => {
    // TODO: Default values
    const callToAction = getValues("call_to_action") as KnAiAgentCallToActionEnum;

    const transfer = getValues("transfer") as KnAiAgentTransferEnum;
    const transferNumbers = getValues("transfer_numbers") as KnAiAgentTransferNumber[];

    switch (callToAction) {
      case "BOOK_MEETING":
        setPrimaryCallToAction({
          value: {
            type: callToAction,
            variant: callToAction,
          },
          label: "Book a Meeting",
        });
        break;
      case "LIVE_TRANSFER":
        if (transfer) {
          setPrimaryCallToAction({
            value: {
              type: callToAction,
              variant: callToAction,
            },
            label: "Live Transfer",
          });
        } else if (transferNumbers) {
          setPrimaryCallToAction({
            value: {
              type: callToAction,
              variant: KnAiAgentOtherCallToActionEnum.LiveTransferNumberLibrary,
            },
            label: "Live Transfer",
          });
        }

        break;
      default:
        setPrimaryCallToAction(DEFAULT_CTA);
    }

    const callToAction2 = getValues("call_to_action_2")?.[0] as KnAiAgentCallToActionEnum;

    switch (callToAction2) {
      case "BOOK_MEETING":
        setOtherCallToAction({
          value: {
            type: callToAction2,
            variant: callToAction2,
          },
          label: "Book a Meeting",
        });
        break;
      case "LIVE_TRANSFER":
        if (transfer) {
          setOtherCallToAction({
            value: {
              type: callToAction2,
              variant: callToAction2,
            },
            label: "Live Transfer",
          });
        } else if (transferNumbers) {
          setOtherCallToAction({
            value: {
              type: callToAction2,
              variant: KnAiAgentOtherCallToActionEnum.LiveTransferNumberLibrary,
            },
            label: "Live Transfer",
          });
        }

        break;
      default:
        setOtherCallToAction(undefined);
    }
  }, [getValues, isCallFlowDiscoveryTransfer]);

  return (
    <Box>
      <CallToAction
        isPrimary
        callToAction={primaryCallToAction}
        onRemove={() => {
          if (otherCallToAction) {
            setPrimaryCallToAction(otherCallToAction);

            setValue("call_to_action", otherCallToAction.value.type as never);
          } else {
            setPrimaryCallToAction(DEFAULT_CTA);

            setValue("call_to_action", "NONE" as never);
          }
          setValue("call_to_action_2", [] as never);

          setOtherCallToAction(undefined);
        }}
        setCallToAction={(callToAction) => {
          const option = callToActions.find((cta) => cta.value.variant === callToAction) ?? DEFAULT_CTA;

          setPrimaryCallToAction(option);
        }}
        callToActionOptions={availableCallToActions}
        isCallFlowDiscoveryTransfer={isCallFlowDiscoveryTransfer}
      />
      {otherCallToAction && (
        <CallToAction
          onRemove={() => {
            setOtherCallToAction(undefined);

            setValue("call_to_action_2", [] as never);
          }}
          switchPrimary={() => {
            const oldPrimary = primaryCallToAction;

            setPrimaryCallToAction((previousPrimary) => {
              setValue("call_to_action_2", [previousPrimary.value.type] as never);

              return otherCallToAction;
            });

            setOtherCallToAction((previousOther) => {
              setValue("call_to_action", previousOther?.value.type as never);

              return oldPrimary;
            });
          }}
          setCallToAction={(callToAction) => {
            const option = callToActions.find((cta) => cta.value.variant === callToAction) ?? DEFAULT_CTA;

            setOtherCallToAction(option);
          }}
          callToAction={otherCallToAction}
          callToActionOptions={availableCallToActions}
          isCallFlowDiscoveryTransfer={isCallFlowDiscoveryTransfer}
        />
      )}

      {!isCallFlowDiscoveryTransfer && (
        <Button
          size="sm"
          sx={{
            backgroundColor: "#374667",
            color: "#fff",
            ...(!otherCallToAction && {
              "&:hover": {
                backgroundColor: "transparent",
                borderColor: "#54658D",
                color: "#54658D",
              },
            }),
          }}
          leftIcon={<PlusIcon />}
          onClick={() => {
            setOtherCallToAction(DEFAULT_CTA);
          }}
          disabled={!!otherCallToAction}
        >
          Add Call To Action
        </Button>
      )}
    </Box>
  );
}
