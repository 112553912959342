import {
  KnAiAgentCallDirectionEnum,
  KnAiAgentCallFlowEnum,
  KnAiAgentRequest,
  KnAiAgentRequestBookingModeEnum,
  KnAiAgentRequestCallToActionEnum,
  KnAiAgentRequestTransferEnum,
  KnAiAgentRequestLanguageEnum,
  KnAiAgentRequestCallToAction2Enum,
  KnAiAgentCallToActionEnum,
  KnTransferNumberHoursDayOfWeekEnum,
  KnAiAgentTransferNumber,
} from "@/services/generated";
import * as Yup from "yup";
import {
  bookingModeValues,
  DEFAULT_TRANSFER_PHRASE,
  reqKnowledgeBase,
  showAndReqBookingMode,
  showAndReqBookingWith,
  showAndReqCallToAction,
  showAndReqPrompt,
  showAndReqPromptQualify,
  showAndReqTransfer,
} from "./utils";

export const AI_AGENT_DEFAULT: AiAgentForm = {
  // Agent Type
  call_direction: KnAiAgentCallDirectionEnum.Outbound,
  // Agent
  language: KnAiAgentRequestLanguageEnum.EnUs,
  // hook_statement: "",
  catch_webhook: "",
  interrupt: 2,
  creativity: 2,
  transfer_phrase: DEFAULT_TRANSFER_PHRASE,
  transfer_numbers: [],
  // Call Flow
  double_call: false,
  business_hours: [],
  call_to_action: KnAiAgentCallToActionEnum.None,
  call_to_action_2: [],
};

export const validateStepRequired = <T extends Yup.Schema>(schema: T, step: number) =>
  schema.when("$step", {
    is: (s: number) => s === step,
    then: (schema) => schema.required(),
  });

export const validateStepNotEmpty = (schema: Yup.ArraySchema<AiAgentForm[] | undefined, Yup.AnyObject>, step: number) =>
  schema.when("$step", {
    is: (s: number) => s === step,
    then: (schema) => schema.min(1),
  });

export const schema: Yup.ObjectSchema<KnAiAgentRequest> = Yup.object({
  // Agent Type
  call_direction: validateStepRequired(
    Yup.string<KnAiAgentCallDirectionEnum>().label("Call Direction").oneOf(Object.values(KnAiAgentCallDirectionEnum)),
    0,
  ),

  // Agent
  agent_label: validateStepRequired(Yup.string().label("Internal Agent Label"), 1),
  language: validateStepRequired(
    Yup.string<KnAiAgentRequestLanguageEnum>().oneOf(Object.values(KnAiAgentRequestLanguageEnum)).label("Language"),
    1,
  ),
  agent_phone_number: validateStepRequired(Yup.string().label("Agent Phone Number"), 1),
  voice_id: validateStepRequired(Yup.string().label("Voice"), 1),
  creativity: validateStepRequired(Yup.number().label("Creativity"), 1),
  interrupt: validateStepRequired(Yup.number().label("Interrupt"), 1),

  // Call Flow
  call_flow: validateStepRequired(
    Yup.string<KnAiAgentCallFlowEnum>().label("Call Flow").oneOf(Object.values(KnAiAgentCallFlowEnum)),
    2,
  ),
  call_flow_prompt: Yup.string()
    .label("Prompt")
    .when("call_flow", {
      is: showAndReqPrompt,
      then: (schema) => validateStepRequired(schema, 2),
    }),
  call_flow_prompt_qualify: Yup.string()
    // Can't name this label because it is reused as "Objections/Question Talk Tracks".
    // .label("Required Qualifiers")
    .when("call_flow", {
      is: showAndReqPromptQualify,
      then: (schema) => validateStepRequired(schema, 2),
    }),
  // hook_statement: Yup.string()
  //   .label("Hook Statement")
  //   .when("call_flow", {
  //     is: showAndReqHookStatement,
  //     then: (schema) => validateStepRequired(schema, 2),
  //   }),
  knowledge_base_url: Yup.string()
    .label("Knowledge Base URL")
    .when("call_flow", {
      is: reqKnowledgeBase,
      then: (schema) => validateStepRequired(schema, 2),
    }),
  knowledge_base_faq_id: Yup.string(),
  call_to_action: Yup.mixed<KnAiAgentRequestCallToActionEnum>()
    .oneOf(Object.values(KnAiAgentRequestCallToActionEnum))
    .label("Call to Action")
    .when("call_flow", {
      is: showAndReqCallToAction,
      then: (schema) => validateStepRequired(schema, 2),
    }),
  call_to_action_2: Yup.array(
    Yup.mixed<KnAiAgentRequestCallToAction2Enum>().oneOf(Object.values(KnAiAgentRequestCallToAction2Enum)).required(),
  ),
  booking_mode: Yup.string<KnAiAgentRequestBookingModeEnum>()
    .oneOf(bookingModeValues)
    .label("Booking Mode")
    .when(["call_flow", "call_to_action", "call_to_action_2"], {
      is: showAndReqBookingMode,
      then: (schema) => validateStepRequired(schema, 2),
    }),
  booking_with: Yup.array()
    .label("Booking With")
    .when(["call_flow", "call_to_action", "call_to_action_2", "booking_mode"], {
      is: showAndReqBookingWith,
      then: (schema) => validateStepNotEmpty(schema, 2),
    }),
  transfer: Yup.mixed<KnAiAgentRequestTransferEnum>()
    .label("Transfer")
    .when(["call_flow", "call_to_action", "call_to_action_2", "transfer_numbers"], {
      is: showAndReqTransfer,
      then: (schema) => validateStepRequired(schema, 2),
    }),
  transfer_phrase: Yup.string().label("Transfer Phrase"),
  transfer_numbers: Yup.array()
    .of(
      Yup.object().shape({
        transfer_number_library_id: Yup.string().required(),
        time_zone: Yup.string().required(),
        is_default: Yup.boolean(),
        number: Yup.string(),
        name: Yup.string(),
        transfer_number_hours: Yup.array()
          .of(
            Yup.object().shape({
              day_of_week: Yup.string<KnTransferNumberHoursDayOfWeekEnum>().required(),
              start_time: Yup.mixed().required(),
              end_time: Yup.mixed()
                .test("is-after", "End time must be after start time", function (value) {
                  return value && value > this.parent.start_time;
                })
                .required(),
            }),
          )
          .test("no-overlap", "Hours cannot overlap in the same day", function (value) {
            if (!value) return true;

            const hoursMap = new Map();

            for (let i = 0; i < value.length; i++) {
              const transferHour = value[i];

              if (hoursMap.has(transferHour.day_of_week)) {
                const previousHours = hoursMap.get(transferHour.day_of_week);

                for (let j = 0; j < previousHours.length; j++) {
                  const previousHour = previousHours[j];
                  const previousIndex = value.indexOf(previousHour);

                  if (
                    (transferHour.end_time > previousHour.start_time &&
                      previousHour.end_time > transferHour.end_time) ||
                    (transferHour.start_time < previousHour.end_time &&
                      previousHour.end_time < transferHour.end_time) ||
                    (previousHour.start_time === transferHour.start_time &&
                      previousHour.end_time === transferHour.end_time)
                  ) {
                    return this.createError({
                      path: `${this.path}[${i}]`,
                      message: `Hours cannot overlap in the same day`,
                      params: {
                        conflictingIndex: previousIndex,
                      },
                    });
                  }
                }

                hoursMap.set(transferHour.day_of_week, [...previousHours, transferHour]);

                continue;
              }

              hoursMap.set(transferHour.day_of_week, [transferHour]);
            }

            return true;
          }),
      }),
    )
    .label("Transfer Numbers"),
  catch_webhook: Yup.string().label("3rd Party Webhook"),
  double_call: validateStepRequired(Yup.boolean().label("Double Call"), 2),
  business_hours: validateStepRequired(Yup.array().label("Business Hours"), 2),

  buffer_time: Yup.number()
    .label("Meeting Buffer")
    .when(["call_flow", "call_to_action", "call_to_action_2"], {
      is: showAndReqBookingMode,
      then: (schema) => validateStepRequired(schema, 2),
    }),
  meeting_description: Yup.string()
    .label("Meeting Description")
    .when(["call_flow", "call_to_action", "call_to_action_2"], {
      is: showAndReqBookingMode,
      then: (schema) => validateStepRequired(schema, 2),
    }),
  meeting_duration: Yup.number()
    .label("Meeting Duration")
    .when(["call_flow", "call_to_action", "call_to_action_2"], {
      is: showAndReqBookingMode,
      then: (schema) => validateStepRequired(schema, 2),
    }),
  meeting_title: Yup.string()
    .label("Meeting Title")
    .when(["call_flow", "call_to_action", "call_to_action_2"], {
      is: showAndReqBookingMode,
      then: (schema) => validateStepRequired(schema, 2),
    }),
  notice_time: Yup.number()
    .label("Meeting Time")
    .when(["call_flow", "call_to_action", "call_to_action_2"], {
      is: showAndReqBookingMode,
      then: (schema) => validateStepRequired(schema, 2),
    }),
  time_zone: Yup.string()
    .label("Time Zone")
    .when(["call_flow", "call_to_action", "call_to_action_2"], {
      is: showAndReqBookingMode,
      then: (schema) => validateStepRequired(schema, 2),
    }),
}).required();

export type AiAgentForm = Yup.InferType<typeof schema>;
