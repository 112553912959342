import { Box } from "@kennected/components";
import { LoadingSpinnerIcon } from "./LoadingSpinnerIcon";

export const LoadingSpinner = ({
  loading = true,
  height = "60vh",
  maxHeight = "100%",
  spinnerHeight = 80,
  spinnerWidth = 80,
  spinnerMarginBottom = 200,
  boxStyles = true,
}: {
  loading?: boolean | undefined;
  height?: string;
  maxHeight?: string;
  spinnerHeight?: number;
  spinnerWidth?: number;
  spinnerMarginBottom?: number;
  boxStyles?: boolean;
}) => {
  if (!loading) return null;

  const styles = {
    overlay: {
      display: "flex",
      width: "100%",
      height,
      maxHeight,
      zIndex: 200,
      justifyContent: "center",
      alignItems: "center",
    },
  };

  return (
    <Box sx={boxStyles ? styles.overlay : undefined}>
      <LoadingSpinnerIcon
        spinnerHeight={spinnerHeight}
        spinnerWidth={spinnerWidth}
        spinnerMarginBottom={spinnerMarginBottom}
      />
    </Box>
  );
};
