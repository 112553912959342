import { keyframes } from "@emotion/react";

export const styles = {
  container: {
    position: "absolute",
    width: "fit-content",

    boxShadow: "0px 2px 6px gainsboro",

    transform: "translate(-50%, 0)",
    left: "50%",
    top: "-85px",

    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    gap: "4px",

    padding: "6px 12px",

    fontSize: "12px",

    backgroundColor: "#ffe49a",
    color: "#1A2135",
    animation: "comeFromTop .6s ease-in ",
  },
  closeIcon: {
    cursor: "pointer",
    opacity: 0.7,
  },

  keyframe: `
    @keyframes comeFromTop {
        from { top: -300px }        
        to { top: -85px }
      }     
    `,
};
