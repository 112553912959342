import { ReactComponent as Envelope } from "@/common/assets/svg/envelope_outline.svg";
import { ReactComponent as SupportAgentIcon } from "@/common/assets/svg/sidebar/book-open.svg";
import { ReactComponent as DocumentArrowUp } from "@/common/assets/svg/sidebar/document_arrow_up.svg";
import { ReactComponent as Home } from "@/common/assets/svg/sidebar/home.svg";
import { ReactComponent as Identification } from "@/common/assets/svg/sidebar/identification.svg";
import { ReactComponent as Phone } from "@/common/assets/svg/sidebar/phone.svg";
import { ReactComponent as QueueListIcon } from "@/common/assets/svg/sidebar/queue-list.svg";
import { ReactComponent as RocketLaunch } from "@/common/assets/svg/sidebar/rocket-launch.svg";
import { ReactComponent as SquaresPlus } from "@/common/assets/svg/sidebar/squares-plus.svg";
import { WrenchScrewdriverIcon } from "@heroicons/react/24/outline";

import config from "@/common/constants/config";
import routes from "@/common/constants/routes";
import { CogIcon, UserGroupIcon } from "@heroicons/react/24/outline";

export type DashboardMenuItem = {
  icon: JSX.Element;
  label: string;
  route: string;
  openInNewTab?: boolean;
  sublinks?: { label: string; route: string; hide?: boolean }[];
  hide?: boolean;
};

export const getDashboardMenuItems = (isAdmin: boolean): DashboardMenuItem[] => [
  {
    icon: <Home />,
    label: "Dashboard",
    route: routes.root.path,
  },
  {
    icon: <QueueListIcon />,
    label: "Tasks",
    route: routes.tasks.path,
    hide: !config.flagAiVoice,
  },
  {
    icon: <RocketLaunch />,
    label: "Triggers",
    route: routes.triggers.path,
    hide: !config.flagAiVoice,
  },
  {
    icon: <Identification />,
    label: "Contacts",
    route: routes.prospects.path,
    hide: !config.flagAiVoice,
  },
  {
    icon: <SquaresPlus />,
    label: "Sequences",
    route: routes.sequence.path,
    hide: !config.flagAiVoice,
  },
  {
    icon: <Envelope />,
    label: "Inbox",
    route: routes.inbox.path,
    hide: !config.flagAiVoice || !config.flagShowInbox,
  },
  {
    icon: <DocumentArrowUp />,
    label: "Data Import",
    route: routes.dataImport.path,
    hide: !config.flagAiVoice,
  },
  {
    icon: <Phone width={24} />,
    label: "AI Voice",
    route: routes.aiVoice.agents.table.path,
    hide: !config.flagAiVoice,
    sublinks: [
      {
        label: "Agents",
        route: routes.aiVoice.agents.table.path,
      },
      {
        label: "Call Logs",
        route: routes.aiVoice.callLogs.path,
      },
      ...(config.flagEnableTransferNumberLibrary
        ? [
            {
              label: "Transfer Number Library",
              route: routes.aiVoice.transferNumberLibrary.path,
            },
          ]
        : []),
    ],
  },
  ...(isAdmin
    ? [
        {
          icon: <WrenchScrewdriverIcon width={24} />,
          label: "Admin",
          route: routes.admin.path,
        },
      ]
    : []),
  {
    label: "Settings",
    icon: <CogIcon width={24} />,
    route: routes.settings.path,
  },
  {
    label: "Community",
    icon: <UserGroupIcon width={24} />,
    route: "https://salesai-community.mn.co/",
    openInNewTab: true,
  },
  {
    label: "Support Portal",
    icon: <SupportAgentIcon width={24} />,
    route: "https://support.salesai.com/tickets",
    openInNewTab: true,
  },
];
