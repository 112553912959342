import toast from "@/lib/toast";
import Services from "@/services";
import {
  KnContactLastNameSearchRequest,
  KnContactLastNameSearchResponse,
  KnContactRequest,
  KnFilterOption2OperatorEnum,
  KnLookupRequestSortDirectionEnum,
  KnLookupResponseKnTeamContact,
  KnPageResponseKnTeamContact,
  SequenceRequestMultipleTrigger,
  SequenceRequestTrigger,
} from "@/services/generated";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Prospect } from "./types";
import { RootState } from "@/redux/store";
import { DataImportSequenceContacts, DataImportSequenceContactsFiltersInput } from "@/types/prospects";
type State = {
  isShowSeqEnrollDialog: boolean;
  isShowSeqUnEnrollDialog: boolean;
  seqEnrollContactIdList: string[];
  seqEnrollPending: boolean;
  prospects?: Prospect[];
  prospectsInbox?: Prospect[];
  currentProspect?: Prospect | null;
  viewDrawerIsOpen?: boolean;
  editDrawerIsOpen?: boolean;
  totalCount?: number;
  assigneeDrawerIsOpen?: boolean;
  loading: boolean;
  error: string | null;
  createProspectIsLoading?: boolean;
  updateProspectIsLoading?: boolean;

  loadingProspectSummary: boolean;
  errorProspectSummary: number | null;
  prospectSummary: number | null | undefined;

  contacts?: DataImportSequenceContacts[];
  totalContacts: number | undefined;
  loadingContacts: boolean;
  errorContacts: string | null;
  filtersContacts: DataImportSequenceContactsFiltersInput;
};

type Filters = {
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: KnLookupRequestSortDirectionEnum;
  searchTerm?: string;
};

const initialState: State = {
  isShowSeqEnrollDialog: false,
  isShowSeqUnEnrollDialog: false,
  seqEnrollContactIdList: [],
  seqEnrollPending: false,
  loading: true,
  error: null,
  createProspectIsLoading: false,
  updateProspectIsLoading: false,
  viewDrawerIsOpen: false,
  editDrawerIsOpen: false,

  loadingProspectSummary: false,
  errorProspectSummary: null,
  prospectSummary: null,

  contacts: [],
  totalContacts: 0,
  loadingContacts: false,
  errorContacts: null,
  filtersContacts: {},
};

export const pauseSequenceById = createAsyncThunk(
  "prospects/pauseSequence",
  async (sequenceId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Sequences.pauseSequence(sequenceId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const resumeSequenceById = createAsyncThunk(
  "prospects/resumeSequenceById",
  async (sequenceId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Sequences.resumeSequence(sequenceId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const unenrollSequence = createAsyncThunk(
  "prospects/unenrollSequence",
  async (sequenceId: string, { rejectWithValue }) => {
    try {
      const response = await Services.Sequences.unenrollSequence(sequenceId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const triggerSeqEnrollContactId = createAsyncThunk(
  "prospects/triggerSeqEnrollContactId",
  async (request: SequenceRequestTrigger, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Sequences.createSequence(request);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const triggerSeqEnrollContactIdList = createAsyncThunk(
  "prospects/triggerSeqEnrollContactIdList",
  async (request: SequenceRequestMultipleTrigger, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Sequences.createMultipleSequences(request);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const createProspect = createAsyncThunk(
  "prospects/createProspect",
  async (newProspect: KnContactRequest, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Prospects.createContact(newProspect);
      return response.data?.result;
    } catch (error) {
      toast.error("Error on create Contact.");
      return rejectWithValue(error.message);
    }
  },
);

export const updateProspect = createAsyncThunk(
  "prospects/updateProspect",
  async (newProspect: KnContactRequest, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Prospects.putContact(newProspect);
      return response.data?.result;
    } catch (error) {
      toast.error("Error on update Contact.");
      return rejectWithValue(error.message);
    }
  },
);

export const getProspects = createAsyncThunk(
  "prospects/getProspects",
  async (filters: Filters = {}, { rejectWithValue }): Promise<KnPageResponseKnTeamContact> => {
    try {
      const response = await Services.Prospects.pageTeamContacts({
        page: filters?.page ?? 0,
        pageSize: filters?.pageSize ?? 10,
        sortBy: filters?.sortBy ?? "createdAt",
        sortDirection: filters?.sortDirection ?? KnLookupRequestSortDirectionEnum.Desc,
      });

      if (response.status !== 200 || !response.data) {
        return {};
      }

      return response.data;
    } catch (error) {
      return error;
    }
  },
);

export const getContactsBySequence = createAsyncThunk(
  "prospects/getContactsBySequence",
  async (leadSourceId: string, { getState, rejectWithValue, dispatch }) => {
    const appState = getState() as RootState;
    const contactsFilters = appState.prospects.listProspects.filtersContacts;
    const { name, pageNumber, pageSize } = contactsFilters;

    try {
      const filter = {
        property: "firstName",
        options: [
          {
            operator: KnFilterOption2OperatorEnum.StartsWith,
            values: [name || ""],
          },
        ],
      };

      const body = {
        page: pageNumber ?? 0,
        pageSize: pageSize ?? 10,
        sortBy: "createdAt",
        sortDirection: KnLookupRequestSortDirectionEnum.Desc,
        leadSourceId: leadSourceId,
        filters: [filter],
      };

      const response = await Services.Prospects.lookupContactsByContactNameAndLeadSourceId(body);

      return {
        contactsList: response.data.results as unknown as DataImportSequenceContacts[],
        total: response.data.total,
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const searchProspects = createAsyncThunk(
  "prospects/searchProspects",
  async (filters: Filters = {}, { rejectWithValue }): Promise<KnPageResponseKnTeamContact> => {
    try {
      const response = await Services.Prospects.keywordSearchTeamContacts({
        page: filters?.page ?? 0,
        pageSize: filters?.pageSize ?? 10,
        sortBy: filters?.sortBy ?? "createdAt",
        sortDirection: filters?.sortDirection ?? KnLookupRequestSortDirectionEnum.Desc,
        searchTermList: filters?.searchTerm?.split(" ") ?? [],
      });
      if (response.status !== 200 || !response.data) {
        return {};
      }

      return response.data;
    } catch (error) {
      return error;
    }
  },
);

// export const lookupProspectsInbox = createAsyncThunk(
//   "prospects/lookupProspectsInbox",
//   async (contactIdList: string[], { rejectWithValue }): Promise<KnLookupResponseKnTeamContact> => {
//     try {
//       const filter = {
//         property: "id",
//         options: [
//           {
//             operator: KnFilterOption2OperatorEnum.Equal,
//             values: contactIdList,
//           },
//         ],
//       };
//       const response = await Services.Prospects.lookupTeamContacts({
//         filters: [filter],
//         page: 0,
//         pageSize: contactIdList.length,
//         sortBy: "createdAt",
//         sortDirection: KnLookupRequestSortDirectionEnum.Desc,
//       });
//
//       if (response.status !== 200 || !response.data) {
//         return {};
//       }
//
//       return response.data;
//     } catch (error) {
//       return error;
//     }
//   },
// );

export const lookupProspectsInbox = createAsyncThunk(
  "prospects/lookupProspectsInbox",
  async (contactIdList: string[], { rejectWithValue }): Promise<KnLookupResponseKnTeamContact> => {
    try {
      const response = await Services.Prospects.lookupContactsByIdList({
        contactIdList: contactIdList,
      });

      if (response.status !== 200 || !response.data) {
        return {};
      }

      return response.data;
    } catch (error) {
      return error;
    }
  },
);

export const getProspectsByLastName = createAsyncThunk(
  "prospects/searchByLastName",
  async (
    request: KnContactLastNameSearchRequest,
    { rejectWithValue, dispatch },
  ): Promise<KnContactLastNameSearchResponse> => {
    try {
      const response = await Services.Prospects.getAllTeamContactsByLastName(request);
      return response.data;
    } catch (error) {
      return error;
    }
  },
);

export const getProspectById = createAsyncThunk(
  "prospects/getProspectById",
  async (ProspectId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Prospects.getContactById(ProspectId);
      return response.data?.result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const handleDeleteProspect = async (state: State, ProspectId: string) => {
  if (!state.prospects) return;
  const taskIndex = state.prospects.findIndex((Prospect) => Prospect.id === ProspectId);
  if (taskIndex !== -1) {
    state.prospects.splice(taskIndex, 1);
  }
  await Services.Prospects.softDeleteContact(ProspectId);
};

export const getProspectSummary = createAsyncThunk(
  "prospects/getProspectSummary",
  async (teamId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.Prospects.getTeamContactSummary(teamId);

      return response.data.contacts;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const listProspectsSlice = createSlice({
  name: "listProspects",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(updateProspect.pending, (state, { payload }) => {
      state.error = null;
      state.updateProspectIsLoading = true;
    });

    builder.addCase(updateProspect.fulfilled, (state, { payload }) => {
      const updatedProspect = payload;
      state.updateProspectIsLoading = false;
      state.prospects = state.prospects?.map((prospect) =>
        prospect.id === updatedProspect?.id ? { ...updatedProspect, createdAt: prospect.createdAt } : prospect,
      );
      state.error = null;
      state.editDrawerIsOpen = false;
    });

    builder.addCase(updateProspect.rejected, (state, { payload }) => {
      state.error = "Failed to update contact";
      state.editDrawerIsOpen = false;
      state.updateProspectIsLoading = false;
    });

    builder.addCase(createProspect.pending, (state, { payload }) => {
      state.createProspectIsLoading = true;
      state.error = null;
    });

    builder.addCase(createProspect.fulfilled, (state, { payload }) => {
      state.createProspectIsLoading = false;
      const prospect = { ...payload, createdAt: new Date().getTime() };
      state.error = null;
      state.prospects = [prospect, ...(state.prospects ?? [])];
      toast.success("Prospect created successfully");
    });

    builder.addCase(createProspect.rejected, (state, { payload }) => {
      state.createProspectIsLoading = false;
    });

    builder.addCase(getProspects.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(searchProspects.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getProspects.fulfilled, (state, { payload }) => {
      if (payload) {
        state.loading = false;
        state.prospects = payload.results as Prospect[];
        state.totalCount = payload.total ?? 0;
      }
    });

    builder.addCase(searchProspects.fulfilled, (state, { payload }) => {
      if (payload) {
        state.loading = false;
        state.prospects = payload.results as Prospect[];
        state.totalCount = payload.total ?? 0;
      }
    });

    builder.addCase(lookupProspectsInbox.fulfilled, (state, { payload }) => {
      if (payload) {
        state.prospectsInbox = payload.results as Prospect[];
      }
    });

    builder.addCase(getProspectsByLastName.pending, (state, { payload }) => {
      state.loading = true;
    });
    builder.addCase(getProspectsByLastName.fulfilled, (state, { payload }) => {
      state.prospects = payload.results as Prospect[];
      state.loading = false;
    });

    builder.addCase(triggerSeqEnrollContactId.pending, (state, { payload }) => {
      state.seqEnrollPending = true;
    });
    builder.addCase(triggerSeqEnrollContactId.fulfilled, (state, { payload }) => {
      state.seqEnrollPending = false;
    });

    builder.addCase(getProspectSummary.pending, (state, { payload }) => {
      state.loadingProspectSummary = true;
      state.errorProspectSummary = null;
    });
    builder.addCase(getProspectSummary.fulfilled, (state, { payload }) => {
      state.loadingProspectSummary = false;
      state.prospectSummary = payload;
    });

    builder.addCase(getProspectSummary.rejected, (state, { payload }) => {
      state.loadingProspectSummary = false;
    });

    builder.addCase(getContactsBySequence.pending, (state, { payload }) => {
      state.loadingContacts = true;
      state.errorContacts = null;
    });

    builder.addCase(getContactsBySequence.fulfilled, (state, { payload }) => {
      state.loadingContacts = false;
      state.contacts = payload.contactsList;
      state.totalContacts = payload.total;
    });

    builder.addCase(getContactsBySequence.rejected, (state, { payload }) => {
      state.loadingContacts = false;
    });
  },
  reducers: {
    setCurrentProspect: (state, action) => {
      state.currentProspect = action.payload;
    },

    deleteProspect: (state, action) => {
      handleDeleteProspect(state, action.payload);
    },
    openViewDrawer: (state) => {
      state.viewDrawerIsOpen = true;
    },
    closeViewDrawer: (state) => {
      state.viewDrawerIsOpen = false;
    },
    openEditDrawer: (state) => {
      state.editDrawerIsOpen = true;
    },
    closeEditDrawer: (state) => {
      state.editDrawerIsOpen = false;
    },
    updateCompanyNames: (state, action) => {
      if (state.prospects) {
        state.prospects = state.prospects.map((prospect) => {
          const company = action.payload.find((company: any) => company.id === prospect.companyId);
          if (company?.name !== undefined && company?.name) {
            return { ...prospect, companyName: company.name || "Google 2" };
          }
          return prospect;
        });
      }
    },
    showSeqEnrollDialog: (state) => {
      state.isShowSeqEnrollDialog = true;
    },
    hideSeqEnrollDialog: (state) => {
      state.isShowSeqEnrollDialog = false;
    },
    showSeqUnEnrollDialog: (state) => {
      state.isShowSeqUnEnrollDialog = true;
    },
    hideSeqUnEnrollDialog: (state) => {
      state.isShowSeqUnEnrollDialog = false;
    },
    setSeqEnrollContactIdList: (state, action) => {
      state.seqEnrollContactIdList = action.payload;
    },
    setContactsBySequenceFilters: (state, action: PayloadAction<DataImportSequenceContactsFiltersInput>) => {
      const newFilters = action.payload;
      state.filtersContacts = { ...state.filtersContacts, ...newFilters };
    },
  },
});

export const {
  showSeqUnEnrollDialog,
  hideSeqUnEnrollDialog,
  updateCompanyNames,
  showSeqEnrollDialog,
  hideSeqEnrollDialog,
  setSeqEnrollContactIdList,
  openEditDrawer,
  closeEditDrawer,
  closeViewDrawer,
  deleteProspect,
  openViewDrawer,
  setCurrentProspect,
  setContactsBySequenceFilters,
} = listProspectsSlice.actions;

export default listProspectsSlice.reducer;
