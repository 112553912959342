import { Box } from "@mui/material";

interface PrimaryBadgeProps {
  small?: boolean;
}

export default function PrimaryBadge({ small }: PrimaryBadgeProps) {
  const styles = {
    width: "60px",
    height: small ? "16px" : "24px",
    borderRadius: "50px",
    backgroundColor: "#3373c2",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Box sx={styles}>
      <span
        style={{
          fontSize: "12px",
          color: "#fff",
          fontWeight: 500,
        }}
      >
        Primary
      </span>
    </Box>
  );
}
