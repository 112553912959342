import { Box, FormControl, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import Button from "@/common/components/button";
import Checkbox from "@/common/components/checkbox/Checkbox";
import Dropdown from "@/common/components/dropdown";
import Input from "@/common/components/input";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import routes from "@/common/constants/routes";
import toast from "@/lib/toast";
import { getLeadFlowConfig, updateLeadConfig } from "@/redux/reducers/leadFlow/slices/configLeadFlow";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import {
  LeadFlowTeamConfigCompanySizeInEnum,
  LeadFlowTeamConfigIndustryInEnum,
  LeadFlowTeamConfigSeniorityInEnum,
  LeadFlowTeamConfigTimeframeUnitEnum,
  TeamConfigRequest,
  TeamConfigRequestCompanySizeInEnum,
  TeamConfigRequestIndustryInEnum,
  TeamConfigRequestSeniorityInEnum,
} from "@/services/generated";
import { useAuth0 } from "@auth0/auth0-react";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { SyntheticEvent, useEffect } from "react";
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import ConfigurationSteps from "./ConfigurationSteps";
import LeadQualificationCard from "./LeadQualificationCard";
import useConfirmModal from "@/common/hooks/useConfirmModal";

const SENIORITY_OPTIONS: { label: string; value: TeamConfigRequestSeniorityInEnum }[] = [
  {
    label: "Staff",
    value: TeamConfigRequestSeniorityInEnum.Staff,
  },
  {
    label: "Manager",
    value: TeamConfigRequestSeniorityInEnum.Manager,
  },
  {
    label: "Director",
    value: TeamConfigRequestSeniorityInEnum.Director,
  },
  {
    label: "VP",
    value: TeamConfigRequestSeniorityInEnum.Vp,
  },
  {
    label: "CXO",
    value: TeamConfigRequestSeniorityInEnum.Cxo,
  },
];

const INDUSTRY_OPTIONS: { label: string; value: TeamConfigRequestIndustryInEnum }[] = [
  {
    label: "Banking & Finance",
    value: TeamConfigRequestIndustryInEnum.FinancialServices,
  },
  {
    label: "Technology And Software",
    value: TeamConfigRequestIndustryInEnum.TechnologyAndSoftware,
  },
  {
    label: "Health Care And Pharmaceutical",
    value: TeamConfigRequestIndustryInEnum.HealthCareAndPharmaceutical,
  },
  {
    label: "Manufacturing",
    value: TeamConfigRequestIndustryInEnum.Manufacturing,
  },
  {
    label: "Retail",
    value: TeamConfigRequestIndustryInEnum.Retail,
  },
  {
    label: "Real Estate And Construction",
    value: TeamConfigRequestIndustryInEnum.RealEstateAndConstruction,
  },
  {
    label: "Education",
    value: TeamConfigRequestIndustryInEnum.Education,
  },
  {
    label: "Automotive",
    value: TeamConfigRequestIndustryInEnum.Automotive,
  },
  {
    label: "Hospitality And Travel",
    value: TeamConfigRequestIndustryInEnum.HospitalityAndTravel,
  },
];

const COMPANY_SIZE_OPTIONS = Object.entries(LeadFlowTeamConfigCompanySizeInEnum).map((pair) => {
  const value = pair[1];
  return {
    label: `${value} Employees`,
    value: value,
  };
});

const TIMEFRAME_OPTIONS: {
  label: string;
  value: LeadFlowTeamConfigTimeframeUnitEnum;
}[] = [
  {
    label: "Hours",
    value: LeadFlowTeamConfigTimeframeUnitEnum.Hours,
  },
  {
    label: "Days",
    value: LeadFlowTeamConfigTimeframeUnitEnum.Days,
  },
  {
    label: "Weeks",
    value: LeadFlowTeamConfigTimeframeUnitEnum.Weeks,
  },
  {
    label: "Months",
    value: LeadFlowTeamConfigTimeframeUnitEnum.Months,
  },
  {
    label: "Years",
    value: LeadFlowTeamConfigTimeframeUnitEnum.Years,
  },
];

const CURRENCY_OPTIONS = [
  {
    label: "USD",
    value: "USD",
  },
];

const PROXIMITY_UNIT_OPTIONS = [
  {
    label: "Miles",
    value: "MILES",
  },
  {
    label: "Kilometers",
    value: "KILOMETERS",
  },
];

const schema: Yup.ObjectSchema<TeamConfigRequest> = Yup.object({
  ads_budget_gt: Yup.number()
    .optional()
    .transform((value, originalValue) => (originalValue === "" ? undefined : value)),
  ads_budget_gt_unit: Yup.string().optional(),
  budget_gt: Yup.number().optional(),
  budget_gt_unit: Yup.string().optional(),
  company_size_in: Yup.array(
    Yup.mixed<TeamConfigRequestCompanySizeInEnum | LeadFlowTeamConfigCompanySizeInEnum>().defined(),
  ).optional(),
  industry_in: Yup.array(
    Yup.mixed<TeamConfigRequestIndustryInEnum | LeadFlowTeamConfigIndustryInEnum>().defined(),
  ).optional(),
  is_pain_point: Yup.boolean().optional(),
  is_pain_point_mapped: Yup.boolean().optional(),
  is_require_address: Yup.boolean().optional(),
  is_require_ads_budget: Yup.boolean().optional(),
  is_require_budget: Yup.boolean().optional(),
  is_require_company: Yup.boolean().optional(),
  is_require_company_size: Yup.boolean().optional(),
  is_require_industry: Yup.boolean().optional(),
  is_require_job_title: Yup.boolean().optional(),
  is_require_location: Yup.boolean().optional(),
  is_require_pain_point: Yup.boolean().optional(),
  is_require_seniority: Yup.boolean().optional(),
  is_require_timeframe: Yup.boolean().optional(),
  location_city: Yup.string().optional(),
  location_proximity: Yup.string().optional(),
  location_proximity_unit: Yup.string().optional(),
  location_state: Yup.string().optional(),
  qual_conditions_min: Yup.number().optional(),
  seniority_in: Yup.array(
    Yup.mixed<TeamConfigRequestSeniorityInEnum | LeadFlowTeamConfigSeniorityInEnum>().defined(),
  ).optional(),
  team_id: Yup.string().optional(),
  timeframe: Yup.number()
    .optional()
    .transform((value, originalValue) => (originalValue === "" ? undefined : value)),
  timeframe_unit: Yup.mixed<LeadFlowTeamConfigTimeframeUnitEnum>()
    .oneOf(Object.values(LeadFlowTeamConfigTimeframeUnitEnum))
    .optional()
    .transform((value, originalValue) => (originalValue === "" ? undefined : value)),
}).required();

export type LeadFlowConfigForm = Yup.InferType<typeof schema>;

const DEFAULT_VALUES: LeadFlowConfigForm = {
  ads_budget_gt: 0,
  ads_budget_gt_unit: "",
  budget_gt: 0,
  budget_gt_unit: "",
  company_size_in: [],
  industry_in: [],
  is_pain_point: false,
  is_pain_point_mapped: false,
  is_require_address: false,
  is_require_ads_budget: false,
  is_require_budget: false,
  is_require_company: false,
  is_require_company_size: false,
  is_require_industry: false,
  is_require_job_title: false,
  is_require_location: false,
  is_require_pain_point: false,
  is_require_seniority: false,
  is_require_timeframe: false,
  location_city: "",
  location_proximity: "",
  location_proximity_unit: "",
  location_state: "",
  qual_conditions_min: 0,
  seniority_in: [],
  team_id: "",
  timeframe: 0,
  timeframe_unit: LeadFlowTeamConfigTimeframeUnitEnum.Days,
};

function applyDefaults<T extends object>(config: T, defaults: T): T {
  return _.defaults(
    _.mapValues(config, (value) => (value === null ? undefined : value)),
    defaults,
  );
}

export default function LeadFlowConfigPage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { user } = useAuth0();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { deleteConfirm } = useConfirmModal();

  const leadFlowConfig = useAppSelector(({ leadFlow }) => leadFlow.configLeadFlow.configuration);
  const isLoadingLeadFlowConfig = useAppSelector(({ leadFlow }) => leadFlow.configLeadFlow.loading);

  const { handleSubmit, control, setValue, getValues, reset } = useForm<TeamConfigRequest>({
    resolver: yupResolver(schema),
    defaultValues: applyDefaults<LeadFlowConfigForm>(leadFlowConfig || {}, DEFAULT_VALUES),
  });

  useEffect(() => {
    if (!user) return;
    reset({
      team_id: user.team_id,
    });
  }, [user, leadFlowConfig, reset]);

  useEffect(() => {
    if (leadFlowConfig === undefined && !isLoadingLeadFlowConfig) {
      dispatch(getLeadFlowConfig());
    }
  }, [dispatch, isLoadingLeadFlowConfig, leadFlowConfig]);

  const onSubmit: SubmitHandler<LeadFlowConfigForm> = async (data) => {
    try {
      await dispatch(updateLeadConfig(data)).unwrap();
      toast.success("Lead flow config updated successfully!");
    } catch (e) {
      console.error(e);
    }
  };

  const onError: SubmitErrorHandler<LeadFlowConfigForm> = (errors) => {
    console.log(errors);
  };

  if (isLoadingLeadFlowConfig) {
    return <FullLoadingOverlay loading />;
  }
  return (
    <main>
      <form>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Box sx={{ fontSize: "24px", fontWeight: "bold", color: "#000000" }}>{t("leadFlow.title")}</Box>
        </Box>
        <Box sx={{ display: "flex", gap: 3 }}>
          <ConfigurationSteps control={control} />
          <Box
            sx={{
              backgroundColor: theme.palette.common.white,
              p: 3,
              borderRadius: 2,
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Typography fontWeight={600} color={"#1A2135"} fontSize="16px">
                Lead Qualifications
              </Typography>

              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                <Controller
                  name="is_require_ads_budget"
                  control={control}
                  render={({ field }) => (
                    <LeadQualificationCard
                      label="Budget"
                      description="Set a budget range leads need to have"
                      onSetRequired={(value) => field.onChange(value)}
                      required={field.value}
                      expanded={!!leadFlowConfig?.ads_budget_gt || !!leadFlowConfig?.ads_budget_gt_unit}
                      onRemove={async () => {
                        await deleteConfirm();
                        setValue("ads_budget_gt", undefined);
                        setValue("ads_budget_gt_unit", undefined);
                        setValue("is_require_ads_budget", undefined);
                      }}
                    >
                      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                        <Typography fontSize={14} fontWeight={400} color={"#6B747B"}>
                          More than
                        </Typography>
                        <FormControl>
                          <Controller
                            name="ads_budget_gt"
                            control={control}
                            render={({ field, fieldState }) => (
                              <Input
                                {...field}
                                sx={{ width: 140 }}
                                error={fieldState.error?.message}
                                placeholder="Budget"
                                label="Budget Amount"
                                type="number"
                                onChange={(e) => field.onChange(e.target.value)}
                              />
                            )}
                          />
                        </FormControl>
                        <FormControl>
                          <Controller
                            name="ads_budget_gt_unit"
                            control={control}
                            render={({ field, fieldState }) => (
                              <Dropdown
                                {...field}
                                error={fieldState.error?.message}
                                label="Currency"
                                options={CURRENCY_OPTIONS}
                                onChange={(option) => {
                                  field.onChange(option.value);
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Box>
                    </LeadQualificationCard>
                  )}
                />
                <Controller
                  name="is_require_seniority"
                  control={control}
                  render={({ field }) => (
                    <LeadQualificationCard
                      label="Seniority"
                      description="What job position does the lead need"
                      onSetRequired={(value) => field.onChange(value)}
                      required={field.value}
                      expanded={!!leadFlowConfig?.seniority_in?.length}
                      onRemove={async () => {
                        await deleteConfirm();
                        setValue("seniority_in", undefined);
                        setValue("is_require_seniority", undefined);
                      }}
                    >
                      <FormControl>
                        <Controller
                          name="seniority_in"
                          control={control}
                          render={({ field }) => (
                            <Box>
                              {SENIORITY_OPTIONS.map((option) => (
                                <Box key={option.value}>
                                  <Checkbox
                                    onChange={() =>
                                      field.value?.includes(option.value)
                                        ? field.onChange(field.value?.filter((v) => v !== option.value))
                                        : field.onChange([...(field.value || []), option.value])
                                    }
                                    checked={field.value?.includes(option.value)}
                                    label={option.label}
                                  />
                                </Box>
                              ))}
                            </Box>
                          )}
                        />
                      </FormControl>
                    </LeadQualificationCard>
                  )}
                />
                <Controller
                  name="is_require_pain_point"
                  control={control}
                  render={({ field }) => (
                    <LeadQualificationCard
                      label="Pain Point Identified"
                      description="Have we identified their pain point & matched to product"
                      onSetRequired={(value) => field.onChange(value)}
                      required={field.value}
                      expanded={!!leadFlowConfig?.is_pain_point}
                      onRemove={async () => {
                        await deleteConfirm();
                        setValue("is_pain_point", undefined);
                        setValue("is_pain_point_mapped", undefined);
                        setValue("is_require_pain_point", undefined);
                      }}
                    >
                      <FormControl>
                        <Controller
                          name="is_pain_point"
                          control={control}
                          render={({ field }) => (
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                              <Box sx={{ display: "flex", gap: 1 }} key="yes">
                                <input type="radio" checked={field.value} onChange={() => field.onChange(true)} />
                                <Typography fontWeight={500}>Yes</Typography>
                              </Box>
                              <Box sx={{ display: "flex", gap: 1 }} key="no">
                                <input type="radio" checked={!field.value} onChange={() => field.onChange(false)} />
                                <Typography fontWeight={500}>No</Typography>
                              </Box>
                            </Box>
                          )}
                        />
                      </FormControl>
                    </LeadQualificationCard>
                  )}
                />
                <Controller
                  name="is_require_company_size"
                  control={control}
                  render={({ field }) => (
                    <LeadQualificationCard
                      label="Company Size"
                      description="Set range based on employees"
                      onSetRequired={(value) => field.onChange(value)}
                      required={field.value}
                      expanded={!!leadFlowConfig?.company_size_in?.length}
                      onRemove={async () => {
                        await deleteConfirm();
                        setValue("company_size_in", undefined);
                        setValue("is_require_company_size", undefined);
                      }}
                    >
                      <FormControl>
                        <Controller
                          name="company_size_in"
                          control={control}
                          render={({ field }) => (
                            <Box>
                              {COMPANY_SIZE_OPTIONS.map((option) => (
                                <Box key={option.value}>
                                  <Checkbox
                                    {...field}
                                    onChange={() =>
                                      field.value?.includes(option.value)
                                        ? field.onChange(field.value?.filter((v) => v !== option.value))
                                        : field.onChange([...(field.value || []), option.value])
                                    }
                                    checked={field.value?.includes(option.value)}
                                    label={option.label}
                                  />
                                </Box>
                              ))}
                            </Box>
                          )}
                        />
                      </FormControl>
                    </LeadQualificationCard>
                  )}
                />
                <Controller
                  name="is_require_location"
                  control={control}
                  render={({ field }) => (
                    <LeadQualificationCard
                      label="Location"
                      description="Does the lead match my location radius"
                      onSetRequired={(value) => field.onChange(value)}
                      required={field.value}
                      expanded={
                        !!leadFlowConfig?.location_state ||
                        !!leadFlowConfig?.location_city ||
                        !!leadFlowConfig?.location_proximity ||
                        !!leadFlowConfig?.location_proximity_unit
                      }
                      onRemove={async () => {
                        await deleteConfirm();
                        setValue("location_state", undefined);
                        setValue("location_city", undefined);
                        setValue("location_proximity", undefined);
                        setValue("location_proximity_unit", undefined);
                        setValue("is_require_location", undefined);
                      }}
                    >
                      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                        <Typography fontSize={14} fontWeight={400} color={"#6B747B"}>
                          Within
                        </Typography>
                        <FormControl>
                          <Controller
                            name="location_proximity"
                            control={control}
                            render={({ field, fieldState }) => (
                              <Input
                                {...field}
                                error={fieldState.error?.message}
                                placeholder="Proximity"
                                label="Proximity"
                                type="number"
                                onChange={(e) => field.onChange(e.target.value)}
                              />
                            )}
                          />
                        </FormControl>
                        <FormControl>
                          <Controller
                            name="location_proximity_unit"
                            control={control}
                            render={({ field, fieldState }) => (
                              <Dropdown
                                {...field}
                                error={fieldState.error?.message}
                                placeholder="Proximity Unit"
                                label="Proximity Unit"
                                options={PROXIMITY_UNIT_OPTIONS}
                                onChange={(option) => field.onChange(option.value)}
                              />
                            )}
                          />
                        </FormControl>
                        <Typography fontSize={14} fontWeight={400} color={"#6B747B"}>
                          Of
                        </Typography>
                        <FormControl>
                          <Controller
                            name="location_city"
                            control={control}
                            render={({ field, fieldState }) => (
                              <Input
                                {...field}
                                error={fieldState.error?.message}
                                placeholder="City"
                                label="City"
                                onChange={(e) => field.onChange(e.target.value)}
                              />
                            )}
                          />
                        </FormControl>
                        <FormControl>
                          <Controller
                            name="location_state"
                            control={control}
                            render={({ field, fieldState }) => (
                              <Input
                                {...field}
                                error={fieldState.error?.message}
                                placeholder="State"
                                label="State"
                                onChange={(e) => field.onChange(e.target.value)}
                              />
                            )}
                          />
                        </FormControl>
                      </Box>
                    </LeadQualificationCard>
                  )}
                />
                <Controller
                  name="is_require_industry"
                  control={control}
                  render={({ field }) => (
                    <LeadQualificationCard
                      label="Industry"
                      description="Select from a list of industries"
                      onSetRequired={(value) => field.onChange(value)}
                      required={field.value}
                      expanded={!!leadFlowConfig?.industry_in?.length}
                      onRemove={async () => {
                        await deleteConfirm();
                        setValue("industry_in", undefined);
                        setValue("is_require_industry", undefined);
                      }}
                    >
                      <FormControl>
                        <Controller
                          name="industry_in"
                          control={control}
                          render={({ field }) => (
                            <Box>
                              {INDUSTRY_OPTIONS.map((option) => (
                                <Box key={option.value}>
                                  <Checkbox
                                    {...field}
                                    onChange={() =>
                                      field.value?.includes(option.value)
                                        ? field.onChange(field.value?.filter((v) => v !== option.value))
                                        : field.onChange([...(field.value || []), option.value])
                                    }
                                    checked={field.value?.includes(option.value)}
                                    label={option.label}
                                  />
                                </Box>
                              ))}
                            </Box>
                          )}
                        />
                      </FormControl>
                    </LeadQualificationCard>
                  )}
                />
                <Controller
                  name="is_require_timeframe"
                  control={control}
                  render={({ field }) => (
                    <LeadQualificationCard
                      label="Time Frame"
                      description="Set range for when they are looking to buy"
                      onSetRequired={(value) => field.onChange(value)}
                      required={field.value}
                      expanded={!!leadFlowConfig?.timeframe || !!leadFlowConfig?.timeframe_unit}
                      onRemove={async () => {
                        await deleteConfirm();
                        setValue("timeframe", undefined);
                        setValue("timeframe_unit", undefined);
                        setValue("is_require_timeframe", undefined);
                      }}
                    >
                      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                        <Typography fontSize={14} fontWeight={400} color={"#6B747B"}>
                          Within
                        </Typography>
                        <FormControl>
                          <Controller
                            name="timeframe"
                            control={control}
                            render={({ field, fieldState }) => (
                              <Input
                                {...field}
                                error={fieldState.error?.message}
                                onChange={(e) => field.onChange(e.target.value)}
                                placeholder="x amount of"
                                label="Amount"
                                type="number"
                              />
                            )}
                          />
                        </FormControl>
                        <FormControl>
                          <Controller
                            name="timeframe_unit"
                            control={control}
                            render={({ field, fieldState }) => (
                              <Dropdown
                                {...field}
                                error={fieldState.error?.message}
                                onChange={(option) => field.onChange(option.value)}
                                label="Unit"
                                options={TIMEFRAME_OPTIONS}
                              />
                            )}
                          />
                        </FormControl>
                      </Box>
                    </LeadQualificationCard>
                  )}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            alignItems: "center",
            borderRadius: 2,
            width: "100%",
            display: "flex",
            gap: 2,
            my: 2,
            p: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              border: `1px solid #E7EBED`,
              backgroundColor: "#FBFBFB",
              borderRadius: "8px",
              padding: "16px 24px",
              width: "100%",
              gap: 2,
              alignItems: "center",
            }}
          >
            <Box sx={{ maxWidth: 400 }}>
              <Typography fontSize={14} fontWeight={600} color={"#1A2135"}>
                Qualification Percentage
              </Typography>
              <Typography fontSize={14} fontWeight={400} color={theme.palette.txt.placeholder}>
                Set minimum combination of "nice to haves" you require for a lead to be qualified
              </Typography>
            </Box>
            <Box sx={{ maxWidth: 100 }}>
              <FormControl>
                <Controller
                  name="qual_conditions_min"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Min"
                      label="Min Qualification Percentage"
                      type="number"
                      value={field.value}
                      inputProps={{ min: 1, max: 3 }}
                      onChange={(e) => {
                        let value = parseInt(e.target.value, 10);
                        if (value > 3) value = 3;
                        if (value < 1) value = 1;
                        field.onChange(value);
                      }}
                    />
                  )}
                />
              </FormControl>
            </Box>
            <Box sx={{ display: "flex", flex: "none" }}>
              <Typography fontSize={14} fontWeight={500} color={"#1A2135"}>
                out of 3
              </Typography>
            </Box>
          </Box>
          <Box sx={{ height: "max-content", display: "flex", flex: "none", gap: 2 }}>
            <Button
              onClick={(e: SyntheticEvent) => {
                e.preventDefault();
                navigate(routes.leadFlow.dashboard.path);
              }}
              variant="secondary"
              size="sm"
            >
              Cancel
            </Button>
            <Button
              onClick={(e: SyntheticEvent) => {
                e.preventDefault();
                handleSubmit(onSubmit, onError)();
              }}
              size="sm"
            >
              Save Flow
            </Button>
          </Box>
        </Box>
      </form>
    </main>
  );
}
