import { CallLogCallOutcomeEnum, KnAiAgentCallFlowEnum } from "@/services/generated";

type Option = {
  label: string;
  description: string;
  bgcolor?: string;
  color?: string;
};

export const CALL_FLOW_TYPE_OPTIONS: Record<KnAiAgentCallFlowEnum, Option> = {
  QUALIFY_AND_BOOK_A_MEETING: {
    label: "Prompt Based Simplified",
    description: "Simplified Rebuild of Prompt Based Call Flow",
  },
  BOOK_A_MEETING: {
    label: "Book a Meeting with a Contact",
    description:
      "The agent will greet the contact, clarify the purpose of the meeting, ask for the contact’s availability, offer available time slots, confirm the chosen slot, and send the meeting invite.",
  },
  QUALIFY_AND_LIVE_TRANSFER: {
    label: "Prompt & Qualify Rebuild",
    description: "Rebuild of Prompt & Qualify Call Flow",
  },
  ANSWER_FAQS: {
    label: "Answer FAQs for Customers",
    description:
      "The agent greets the customer, asks how it can assist, listens to the customer’s query, searches its knowledge base for relevant information, provides an answer, and offers additional resources if needed.",
  },
  CONFIRM_A_MEETING: {
    label: "Confirm a Meeting with a Contact",
    description:
      "The agent will greet the contact, explain the purpose of the call, confirm meeting call-summary, offer to resend the invite if needed, and provide reminders as requested.",
  },
  PROMPT_AND_QUALIFY: {
    label: "Prompt & Qualify",
    description:
      "The AI asks key questions to determine if a prospect is a good fit. Based on their responses, it guides them to the selected call to action.",
  },
  PROMPT: {
    label: "Prompt Based",
    description:
      "The AI follows a structured approach, asking questions and adapting based on responses. It smoothly moves the conversation toward the selected call to action.",
  },
  SCRIPT: {
    label: "Script Based",
    description:
      "Designed for industries with strict rules, the AI follows a fixed script to share company details and answer common questions before guiding the prospect to the selected call to action.",
  },
  TIER_ONE_SUPPORT: {
    label: "Tier One Support",
    description:
      "For most support calls, AI assistants use prompts to diagnose customer issues and adapt dynamically, either resolving the problem or connecting the caller to a live Tier 1 support agent. This flexible approach ensures efficient and personalized customer service.",
  },
  PROMPT_AND_QUALIFY_V1: {
    label: "Prompt & Qualify (Single CTA)",
    description:
      "The AI asks key questions to determine if a prospect is a good fit. Based on their responses, it guides them to the selected call to action.",
  },
  PROMPT_V1: {
    label: "Prompt Based (Single CTA)",
    description:
      "The AI follows a structured approach, asking questions and adapting based on responses. It smoothly moves the conversation toward the selected call to action.",
  },
  SCRIPT_V1: {
    label: "Script Based (Single CTA)",
    description:
      "Designed for industries with strict rules, the AI follows a fixed script to share company details and answer common questions before guiding the prospect to the selected call to action.",
  },
  SALES: {
    label: "Sales Inbound",
    description:
      "The agent will introduce itself, assessing the needs and suitability of the contact, and if qualified, continue the conversation.",
  },
  DISCOVERY_TRANSFER: {
    label: "Discovery & Transfer",
    description: "The agent will discover and transfer",
  },
};

export const CALL_FLOW_OUTCOME_OPTIONS: Partial<Record<CallLogCallOutcomeEnum, Option>> = {
  [CallLogCallOutcomeEnum.Unspecified]: {
    label: "Not Completed",
    description: "",
    bgcolor: "#D3D3D3",
  },
  [CallLogCallOutcomeEnum.Error]: {
    label: "Error",
    description: "",
    bgcolor: "#FF0000",
  },
  [CallLogCallOutcomeEnum.Inbound]: {
    label: "Inbound",
    description: "",
    bgcolor: "#b4dfff",
  },
  [CallLogCallOutcomeEnum.Dna]: {
    label: "DNA",
    description: "",
    bgcolor: "#121a29",
    color: "#ffe49a",
  },
  [CallLogCallOutcomeEnum.Dnc]: {
    label: "DNC",
    description: "",
    bgcolor: "#121a29",
    color: "#ffffff",
  },
  [CallLogCallOutcomeEnum.Voicemail]: {
    label: "Voicemail",
    description: "",
    bgcolor: "#ffc29a",
  },
  [CallLogCallOutcomeEnum.Answered]: {
    label: "Answered",
    description: "",
    bgcolor: "#3373c2",
    color: "#FFFFFF",
  },
  [CallLogCallOutcomeEnum.BookedAMeeting]: {
    label: "Booked",
    description: "",
    bgcolor: "#80f5c1",
  },
  [CallLogCallOutcomeEnum.Transferred]: {
    label: "Transferred",
    description: "",
    bgcolor: "#d9c4fd",
  },
};
