import { useRoles } from "@/common/components/roles/useRoles";
import { Box, Divider, Typography } from "@mui/material";

interface RolesRowProps {
  role: string;
  description: string;
  title?: boolean;
}

export const RolesRow = (props: RolesRowProps) => {
  const { role, description, title = false } = props;

  return (
    <Box
      sx={(theme) => ({
        display: "inline-flex",
        borderRadius: "6px",
        background: title ? theme.palette.tableRow.heading : "#FFF",
        alignItems: "center",
        padding: title ? "12px" : "20px",
        px: "10px",
        borderBottomLeftRadius: title ? "0px" : "6px",
        borderBottomRightRadius: title ? "0px" : "6px",
      })}
    >
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: title ? "600" : "500" }}>
          {role}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          marginRight: "20px",
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: title ? "600" : "400" }}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export const RolesBox = () => {
  const { userRoles } = useRoles();

  return (
    <>
      <Box
        sx={(theme) => ({
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: "8px",
          border: "1px solid #E7E7E7",
        })}
      >
        <RolesRow role={"ROLE NAME"} description={"ROLE DESCRIPTION"} title />

        {userRoles?.map((role) => (
          <>
            <Divider />
            <RolesRow role={role.roleName} description={role.roleDescription} />
          </>
        ))}
      </Box>
    </>
  );
};

export default RolesBox;
