import { XMarkIcon } from "@heroicons/react/24/outline";
import { Box, IconButton } from "@mui/material";
import PrimaryBadge from "./primary-badge";

interface TransferNumberSelectedProps {
  props: any;
  number: string;
  isDefault: boolean;
}

export default function TransferNumberSelected({ props, number, isDefault }: TransferNumberSelectedProps) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
        height: "24px",
        paddingLeft: "12px",
        borderRadius: "8px",
        backgroundColor: "#b4dfff",
      }}
      {...props}
    >
      <span
        style={{
          fontSize: "12px",
          color: "#1a2135",
        }}
      >
        {number}
      </span>

      {isDefault && <PrimaryBadge small />}

      <IconButton onClick={props.onDelete}>
        <XMarkIcon width={16} height={16} />
      </IconButton>
    </Box>
  );
}
