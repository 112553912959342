import Button from "@/common/components/button";
import Dropdown from "@/common/components/dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import { Box, Chip } from "@mui/material";
import BookMeetingForm from "./forms/book-meeting";
import TransferForm from "./forms/transfer/transfer/transfer";
import { useFormContext } from "react-hook-form";
import { AiAgentForm } from "../..";
import { useMemo } from "react";
import { CTAOption } from "./call-to-action-builder";
import TransferNumberLibrary from "./forms/transfer/transfer-number-library/transfer-number-library";
import config from "@/common/constants/config";
import { KnAiAgentCallToActionEnum } from "@/services/generated";
import { KnAiAgentOtherCallToActionEnum } from "./types";

interface CallToActionProps {
  callToAction: CTAOption;
  isPrimary?: boolean;
  onRemove: () => void;
  setCallToAction: (callToAction: string) => void;
  switchPrimary?: () => void;
  callToActionOptions: CTAOption[];
  isCallFlowDiscoveryTransfer: boolean;
}

export default function CallToAction({
  callToAction,
  isPrimary = false,
  onRemove,
  setCallToAction,
  callToActionOptions,
  switchPrimary,
  isCallFlowDiscoveryTransfer,
}: CallToActionProps) {
  const { setValue } = useFormContext<AiAgentForm>();

  const displayOptions = useMemo(() => {
    const options = [...callToActionOptions];

    if (callToAction.value) {
      options.push(callToAction);
    }

    return options;
  }, [callToAction, callToActionOptions]);

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", paddingBottom: "16px" }}>
        {isPrimary && !isCallFlowDiscoveryTransfer ? (
          <Chip
            label="Primary"
            sx={{
              backgroundColor: "#3373c2",
              color: "#fff",
            }}
          />
        ) : (
          <Box />
        )}
        <Box sx={{ display: "flex" }}>
          {!isPrimary && (
            <Button
              variant="secondary"
              size="sm"
              sx={{
                color: "#3373c2",
                border: "0px",
                fontWeight: "light",
              }}
              onClick={() => {
                switchPrimary?.();
              }}
            >
              Set as Primary
            </Button>
          )}
          {!isCallFlowDiscoveryTransfer && (
            <Button
              variant="secondary"
              size="sm"
              sx={{
                color: "#3373c2",
                border: "0px",
                fontWeight: "light",
              }}
              onClick={() => {
                onRemove();
              }}
            >
              Remove
            </Button>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          border: "1px solid #e7ebed",
          borderRadius: "8px",
          padding: "16px",
          marginBottom: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Box>
          <FormLabel label={"Call to Action"} aria-required />
          <Dropdown
            placeholder="Select"
            value={callToAction.value as any}
            options={displayOptions}
            isDisabled={callToActionOptions.length === 0 || isCallFlowDiscoveryTransfer}
            onChange={(option) => {
              setCallToAction(option.value.variant);

              if (option.value.variant === KnAiAgentCallToActionEnum.LiveTransfer) {
                setValue("transfer_numbers", [] as never);
              } else if (option.value.variant === KnAiAgentOtherCallToActionEnum.LiveTransferNumberLibrary) {
                setValue("transfer", undefined as never);
              }

              if (isPrimary) {
                setValue("call_to_action", option.value.type as never);
              } else {
                if (option.value) {
                  setValue("call_to_action_2", [option.value.type] as never);
                } else {
                  setValue("call_to_action_2", [] as never);
                }
              }
            }}
          />
        </Box>
        <Box>
          {callToAction.value.variant === KnAiAgentCallToActionEnum.BookMeeting ? (
            <BookMeetingForm />
          ) : callToAction.value.variant === KnAiAgentCallToActionEnum.LiveTransfer ? (
            <TransferForm />
          ) : callToAction.value.variant === KnAiAgentOtherCallToActionEnum.LiveTransferNumberLibrary &&
            config.flagEnableTransferNumberLibrary ? (
            <TransferNumberLibrary />
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}
